import paper from 'paper'
import { Key } from "paper/dist/paper-core"
import { Direction } from "../design.schema"
import { ParkourConfig } from "../parkour.config"
import { TweenTo } from './detail.paper.extensions'
import { Selectable } from "./detail.selection"
import { PathObject } from "./parkour-objects/path-object"

export interface Selector {
    activity: SelectionActivity
    selectorKind: SelectorKind
    select(point?: paper.Point): void
    deselect(): void
    defocus(point?: paper.Point): void
    drag(delta: paper.Point, point: paper.Point): void
    rotate(angleDelta: number, center: paper.Point): void
    snap(angle: number): number
    create(pos: paper.Point, size: paper.Size, angle: number, canRotate?: boolean, selectorKind?: SelectorKind, dashArray?: number[]): paper.Item
    destroy(): void
    getSize(): paper.Size
    isSelected(): boolean
}

export enum SelectionActivity {
    IDLE = 1,
    MOVING = 2,
    ROTATING = 3
}

export enum SelectorKind {
    ANY = 1,
    CIRCLE,
    RECTANGLE,
}

export class CircleWithHandle implements Selector {
    public selGroup: paper.Group | undefined // drawing that appears when object is selected
    private rotHandle?: paper.Shape          // UI handle drawing to perform object rotation
    private readonly gapToHandle = this.cfg.scaleAccordingToFieldSize(0.7)
    private readonly handleRadius = this.cfg.scaleAccordingToFieldSize(0.7)
    private readonly minSelRadius = 200
    protected readonly selRadiusZoom: number = 1
    public activity: SelectionActivity = SelectionActivity.IDLE
    public selectorKind: SelectorKind = SelectorKind.CIRCLE
    private handleAngleZero: number = 0
    private readonly fillColorTransparent = '#ff742011'
    public radius: number = this.minSelRadius
    protected rectMargin: number = 100
    protected size?: paper.Size

    public readonly frameColor = '#ff7420'
    public readonly strokeColor = '#ff7420ff'
    public readonly fillColorSolid = '#fff7f1'
    public readonly strokeWidth = this.cfg.params.lineWidth

    constructor(public obj: Selectable, private cfg: ParkourConfig) {
    }

    snap(angle: number): number {
        if (Key.modifiers.control) {
            return angle
        }
        const quad = ~~(angle / 90)
        let degree = angle - quad * 90
        const val = Math.abs(degree)
        const snapBoundary = 2
        if (val < snapBoundary) {
            degree = 0
        } else if (val > 90 - snapBoundary) {
            degree = 90 * Math.sign(degree)
        }
        const trimmed = quad * 90 + degree
        // move selection group smoothly only for circle selector - don't snap
        if (this.selectorKind === SelectorKind.CIRCLE) {
            this.rotate(angle - trimmed, this.obj.getPosition())
        }
        return trimmed
    }

    getSize(): paper.Size {
        return this.selGroup?.bounds.size || new paper.Size(0, 0)
    }

    isSelected(): boolean {
        return this.selGroup?.visible || false
    }

    select(point?: paper.Point | undefined): void {
        if (this.rotHandle) {
            const t = this.rotHandle.data.tween
            if (t instanceof TweenTo) {
                t.stop()
            }
        }
        let radius = this.handleRadius
        this.activity = SelectionActivity.IDLE
        if (this.selGroup) {
            this.selGroup.visible = true
            if (!point === null) {
                this.activity = SelectionActivity.MOVING
            } else if (point) {
                if (this.rotHandle?.contains(point)) {
                    this.activity = SelectionActivity.ROTATING
                    radius = this.handleRadius * 1.5
                } else if (this.selGroup?.contains(point)) {
                    this.activity = SelectionActivity.MOVING
                }
            }
        }
        if (this.rotHandle) {
            this.rotHandle.radius = radius
        }
    }

    deselect(): void {
        this.activity = SelectionActivity.IDLE
        if (this.rotHandle) {
            const t = this.rotHandle.data.tween
            if (t instanceof TweenTo) {
                t.stop()
            }
            this.rotHandle.radius = this.handleRadius
            this.rotHandle.fillColor = new paper.Color(this.fillColorTransparent)
        }
        if (this.selGroup) {
            this.selGroup.visible = false
        }
    }

    defocus(point?: paper.Point): void {
        if (this.rotHandle && (!point || !this.rotHandle.contains(point))) {
            const r = this.rotHandle
            r.data.tween = new TweenTo(r, { 
                radius: this.handleRadius
            }, 75).then(() => {
                r.fillColor = new paper.Color(this.fillColorTransparent)
            })
        }
    }

    rotate(angleDelta: number, center: paper.Point) {
        this.selGroup?.rotate(angleDelta, center)
    }

    drag(delta: paper.Point, point: paper.Point): void {
        if (this.activity === SelectionActivity.MOVING) {
            this.obj.move(delta)
        } else if (this.activity === SelectionActivity.ROTATING) {
            this._rotateByHandle(point)
        }
    }

    create(pos: paper.Point, size: paper.Size, angle: number, canRotate?: boolean, selectorKind?: SelectorKind, dashArray?: number[]): paper.Item {
        const margin = this.rectMargin
        this.size = size.clone()

        if (this.selGroup) {
            this.selGroup.removeChildren()
        } else {
            this.selGroup = new paper.Group()
        }
        this.selGroup.visible = this.obj.isSelected()

        let rh: paper.Shape | undefined
        let lh: paper.Path | undefined
        if (canRotate) {
            // rotation handle line to circle
            lh = new paper.Path.Line({
                segments: [[0, 0], [0, -this.gapToHandle]],
                strokeColor: this.strokeColor,
                strokeWidth: this.strokeWidth,
                strokeScaling: false
            })
            this.selGroup.addChild(lh)
            if (this.rotHandle) {
                rh = this.rotHandle
                rh.position = new paper.Point([0, -this.gapToHandle - this.handleRadius])
            } else {
                // rotation handle circle
                let radius = this.handleRadius
                let fillColor = this.fillColorTransparent
                rh = new paper.Shape.Circle({
                    center: [0, -this.gapToHandle - this.handleRadius],
                    radius: radius,
                    strokeColor: this.strokeColor,
                    fillColor: fillColor,
                    strokeWidth: this.strokeWidth,
                    strokeScaling: false
                })
                rh.onMouseEnter = (ev: any) => {
                    if (this.rotHandle && ev?.event?.buttons === 0) {
                        const r = this.rotHandle
                        r.fillColor = new paper.Color(this.fillColorSolid)
                        r.data.tween = new TweenTo(r, { radius: this.handleRadius * 1.5 }, 75)
                    }
                }
                rh.onMouseLeave = (ev: any) => {
                    if (ev?.event?.buttons === 0) {
                        this.defocus()
                    }
                }
                this.rotHandle = rh
            }
            this.selGroup.addChild(this.rotHandle)
        }

        if (!selectorKind || selectorKind === SelectorKind.ANY) {
            this.selectorKind = SelectorKind.CIRCLE
        } else {
            this.selectorKind = selectorKind
        }

        if (this.selectorKind === SelectorKind.RECTANGLE) {
            const hw = size.width / 2
            const hh = size.height / 2
            const x = pos.x
            const y = pos.y
            const h = margin / 2
            const p = new paper.Path({
                strokeColor: this.strokeColor,
                strokeScaling: false,
                closed: true,
                dashArray: dashArray,
                segments: [
                    [[x - hw - margin, y - hh], null, [0, -h]],
                    [[x - hw, y - hh - margin], [-h, 0], null],
                    [[x + hw, y - hh - margin], null, [h, 0]],
                    [[x + hw + margin, y - hh], [0, -h], null],
                    [[x + hw + margin, y + hh], null, [0, h]],
                    [[x + hw, y + hh + margin], [h, 0], null],
                    [[x - hw, y + hh + margin], null, [-h, 0]],
                    [[x - hw - margin, y + hh], [0, h], null]]
            })
            if (rh && lh) {
                const corner = p.curves[2].getPointAtTime(0.5)
                rh.rotate(45, [0, 0])
                rh.translate(corner)
                lh.rotate(45, [0, 0])
                lh.translate(corner)
                this.handleAngleZero = rh.position.subtract(pos).angle + 90
            }
            this.selGroup.addChild(p)
        } else if (this.selectorKind === SelectorKind.CIRCLE) {
            const s = new paper.Point(size)
            this.radius = Math.max(this.selRadiusZoom * s.length / 2, this.minSelRadius)
            // circle around obstacle
            const r = new paper.Shape.Circle({
                center: pos,
                radius: this.radius,
                strokeColor: this.frameColor,
                strokeScaling: false,
                dashArray: dashArray,
            })
            this.handleAngleZero = 45
            const hp = pos.subtract([0, this.radius])
            rh?.translate(hp)
            rh?.rotate(45, pos)
            lh?.translate(hp)
            lh?.rotate(45, pos)
            this.selGroup.addChild(r)
        }
        this.selGroup.rotate(angle, pos)
        return this.selGroup
    }

    destroy() {
        const r = this.rotHandle
        if (r) {
            if (r.isInserted()) {
                if (r.onMouseEnter) {
                    r.onMouseEnter = null
                }
                if (r.onMouseLeave) {
                    r.onMouseLeave = null
                }
            }
            const t = r.data.tween
            if (t instanceof TweenTo) {
                t.stop()
            }
            if (r.isInserted()) {
                r.remove()
            }
            this.rotHandle = undefined
        }
        
    }

    private _rotateByHandle(point: any) {
        const x = point.x
        const y = point.y

        const pos = this.obj.getPosition()

        if (this.rotHandle && !Key.modifiers.shift) {
            const oldDist = Math.abs(this.rotHandle.position.getDistance(pos))
            const newDist = Math.abs(point.getDistance(pos))
            if (newDist > this.minSelRadius && this.obj.scaleObject(newDist / oldDist)) {
                this.obj.update()
            }
        }
        const angleRad = Math.atan2((x - pos.x), -(y - pos.y))
        const angle = angleRad * 180 / Math.PI - this.handleAngleZero
        this.obj.rotate(angle - this.obj.getRotation())
    }
}

enum SideIndex {
    EXIT_IN_FORWARD_DIRECTION = 0,
    ENTRY_IN_FORWARD_DIRECTION = 1
}

export class Connector {
    shape: paper.Shape.Circle
    private connectorRadius = 35
    private goColor = new paper.Color('#A4FF90')
    private noGoColor = new paper.Color('#F44336')
    private defaultColor = new paper.Color(this.parent.fillColorSolid)
    private attentionTimer?: NodeJS.Timeout

    colorGo() {
        this.shape.fillColor = this.goColor
    }

    colorNoGo() {
        this.shape.fillColor = this.noGoColor
    }

    colorDefault() {
        this.shape.fillColor = this.defaultColor
    }

    isColorGo(): boolean {
        return this.shape.fillColor?.equals(this.goColor) || false
    }

    isColorDefault(): boolean {
        return this.shape.fillColor?.equals(this.defaultColor) || false
    }

    isEntry(roundNo?: number): boolean {
        const direction = this.parent.obj.getArrowDirection(roundNo || 1)
        return this.index === SideIndex.ENTRY_IN_FORWARD_DIRECTION && direction === Direction.forward ||
            this.index === SideIndex.EXIT_IN_FORWARD_DIRECTION && direction === Direction.backward ||
            direction === Direction.both
    }

    isExit(roundNo?: number): boolean {
        const direction = this.parent.obj.getArrowDirection(roundNo || 1)
        return this.index === SideIndex.EXIT_IN_FORWARD_DIRECTION && direction === Direction.forward ||
            this.index === SideIndex.ENTRY_IN_FORWARD_DIRECTION && direction === Direction.backward || 
            direction === Direction.both
    }

    grow(factor: number) {
        if (factor > 0) {
            this.shape.radius = this.connectorRadius * factor
        }
    }

    private animateAttention(count?: boolean) {
        if (!count) {
            this.shape.data.attn = 2
        } else if (!this.shape.data.attn || --this.shape.data.attn === 0) {
            this.attentionTimer = setTimeout(() => {
                this.animateAttention()
            }, 4000)
            return
        }
        this.shape.data.tween = new TweenTo(this.shape, {
            radius: this.connectorRadius * 5,
            fillColor: '#F44336'
        }, {
            duration: 400,
            easing: 'easeInCubic'
        }).then(() => {
            this.shape.data.tween = new TweenTo(this.shape, {
                radius: this.connectorRadius,
                fillColor: this.parent.fillColorSolid
            }, {
                duration: 400,
                easing: 'easeOutCubic'
            }).then(() => {
                this.animateAttention(true)
            })
        })
    }

    attentionStart() {
        if (!this.attentionTimer) {
            this.attentionTimer = setTimeout(() => {
                this.animateAttention()
            }, 1000)
        }
    }

    attentionStop() {
        if (this.attentionTimer) {
            const t = this.shape.data.tween
            if (t instanceof TweenTo) {
                t.stop()
            }
            clearTimeout(this.attentionTimer)
            this.attentionTimer = undefined
            this.shape.radius = this.connectorRadius
            this.shape.fillColor = new paper.Color(this.parent.fillColorSolid)
        }
    }

    get position(): paper.Point {
        return this.shape.position
    }

    set position(point: paper.Point) {
        this.shape.position = point
    }

    get radius(): number {
        return this.shape.radius as number
    }

    // angle of connector center against object center
    get angle(): number {
        if (this.parent.selectorKind === SelectorKind.CIRCLE) {
            return this.position.subtract(this.parent.obj.getPosition()).angle - 90
        }
        return (this.parent.obj.angle + (this.index === SideIndex.EXIT_IN_FORWARD_DIRECTION ? -180 : 0)) % 360
    }

    constructor(public index: SideIndex, public parent: PathObjectSelector, center: paper.Point, pos: paper.Point) {
        this.shape = new paper.Shape.Circle({
            center: pos,
            radius: this.connectorRadius,
            strokeColor: this.parent.strokeColor,
            strokeWidth: this.parent.strokeWidth,
            strokeScaling: false,
            fillColor: this.parent.fillColorSolid
        })

        this.shape.onMouseEnter = (ev: any) => {
            if (ev && ev.target && !this.parent.obj.editMode) {
                const t = ev.target
                //t.bringToFront()
                this.attentionStop()
                t.data.tween = new TweenTo(t, {
                    radius: this.connectorRadius * 2,
                    fillColor: this.parent.fillColorSolid
                }, 75)
            }
        }
        
        this.shape.onMouseLeave = (ev: any) => {
            if (ev && ev.target && !this.parent.obj.editMode) {
                this.defocus()
            }
        }

        this.shape.onMouseDown = (ev: any) => {
            this.parent.obj.onEditStart(ev.point, this.index)
        }

        this.shape.rotate(this.parent.obj.angle, center)
    }

    defocus() {
        const s = this.shape
        s.data.tween = new TweenTo(s, { radius: this.connectorRadius }, 75)
    }

    alignDirectionAsEntry(round: number) {
        // when obstacle is not connected at all in given round,
        // align its direction so this connector is at the entry side of the obstacle
        const toObj = this.parent.obj
        if (toObj.getArrowDirection(round) === Direction.none &&
            (this.index === SideIndex.EXIT_IN_FORWARD_DIRECTION && toObj.getDirection(round, 1) === Direction.forward ||
                this.index === SideIndex.ENTRY_IN_FORWARD_DIRECTION && toObj.getDirection(round, 1) === Direction.backward)) {
            toObj.switchDirections(round)
        }
    }

    alignDirectionAsExit(round: number) {
        // when obstacle is not connected at all in given round,
        // align its direction so this connector is at the exit side of the obstacle
        const toObj = this.parent.obj
        if (toObj.getArrowDirection(round) === Direction.none &&
            (this.index === SideIndex.ENTRY_IN_FORWARD_DIRECTION && toObj.getDirection(round, 1) === Direction.forward ||
                this.index === SideIndex.EXIT_IN_FORWARD_DIRECTION && toObj.getDirection(round, 1) === Direction.backward)) {
            toObj.switchDirections(round)
        }
    }

    destroy() {
        if (this.shape.isInserted()) {
            if (this.shape.onMouseEnter) {
                this.shape.onMouseEnter = null
            }
            if (this.shape.onMouseLeave) {
                this.shape.onMouseLeave = null
            }
            if (this.shape.onMouseDown) {
                this.shape.onMouseDown = null
            }
        }
        const t = this.shape.data.tween
        if (t instanceof TweenTo) {
            t.stop()
        }
        if (this.shape.isInserted()) {
            this.shape.remove()
        }
    }
}

export class PathObjectSelector extends CircleWithHandle {
    // there are two connectors one on each end of the obstacle
    // the direction of the connector (forward or backward) depends on the obstacle direction
    public connectors: (Connector | undefined)[] = []
    constructor(public obj: PathObject, cfg: ParkourConfig) {
        super(obj, cfg)
    }

    create(pos: paper.Point, size: paper.Size, angle: number, canRotate?: boolean, selectorKind?: SelectorKind): paper.Item {
        while (this.connectors.length > 0) {
            this.connectors.pop()?.destroy()
        }
        const mainSelector = super.create(pos, size, angle, canRotate, selectorKind)
        let width, height
        if (this.selectorKind === SelectorKind.CIRCLE) {
            width = this.radius
            height = 0
        } else {
            width = (this.size?.height || 0) / 2 + this.rectMargin
            const exit = this.obj.getExitPoint(Direction.forward, true).rotate(-this.obj.angle, pos)
            height = exit.subtract(pos).x
        }
        if (this.obj.isFinish()) {
            this.connectors.push(undefined)
        } else {
            this.connectors.push(new Connector(SideIndex.EXIT_IN_FORWARD_DIRECTION, this, pos, pos.add([height, -width])))
        }
        if (this.obj.isStart()) {
            this.connectors.push(undefined)
        } else {
            this.connectors.push(new Connector(SideIndex.ENTRY_IN_FORWARD_DIRECTION, this, pos, pos.add([height, width])))
        }
        this.connectors.forEach(c => {
            if (c) {
                mainSelector.addChild(c.shape)
            }
        })
        return mainSelector
    }

    deselect() {
        super.deselect()
        this.connectors.forEach(c => c?.colorDefault())
    }

    getClosestConnector(p: paper.Point, filter?: (s: Connector) => boolean): [Connector | undefined, number] {
        let closest: Connector | undefined = undefined
        let closestIdx = -1
        let dist = Infinity
        for (let i = 0; i < this.connectors.length; i++) {
            const c = this.connectors[i]
            if (c && (!filter || filter(c))) {
                const d = c.position.getDistance(p)
                if (d < dist) {
                    dist = d
                    closest = c
                    closestIdx = i
                }
            }
        }
        return [closest, closestIdx]
    }

    destroy() {
        this.connectors.forEach(c => c?.destroy())
        this.connectors = []
        super.destroy()
    }
}
