<p-dialog [(visible)]="accountDeleteLogoutDlgVisible"
    [style]="{'min-width': '15vw'}"
    (keydown.enter)="okLogout()" (keydown.escape)="accountDeleteLogoutDlgVisible = false">
    <ng-template pTemplate="header">
        <span class="p-dialog-title"><i class="pi pi-trash mr-2"></i> <span i18n>Przeloguj się przed skasowaniem konta</span></span>
    </ng-template>
    <div i18n>W celu skasowania konta zostaniesz wylogowany. Następnie należy ponownie się zalogować i ponowić polecenie skasowania konta.</div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="accountDeleteLogoutDlgVisible = false" label="Anuluj"></p-button>
        <p-button icon="pi pi-check" (click)="okLogout()" label="Wyloguj" i18n-label></p-button>
    </ng-template>
</p-dialog>

<div class="m-3 flex flex-column">
    <div class="font-bold text-3xl mb-3" i18n>Profil użytkownika</div>

    <div class="flex my-2 border-none border-bottom-1 border-solid border-indigo-600">
        <div class="p-2 mr-2" [ngClass]="{'bg-indigo-100': activeTab == 0}">
            <a class="p-ripple p-element p-menuitem-link  p-menuitem-link-active" routerLink="/user">
                <span class="p-menuitem-icon pi pi-fw pi-home"></span>
                <span class="p-menuitem-text" i18n>Ustawienia</span>
            </a>
        </div>
        <div class="p-2 mr-2" [ngClass]="{'bg-indigo-100': activeTab == 1}">
            <a class="p-ripple p-element p-menuitem-link  p-menuitem-link-active" routerLink="/user-plan">
                <span class="p-menuitem-icon pi pi-fw pi-calendar"></span>
                <span class="p-menuitem-text" i18n>Plan cenowy</span>
            </a>
        </div>
        <div class="p-2 mr-2" [ngClass]="{'bg-indigo-100': activeTab == 2}">
            <a class="p-ripple p-element p-menuitem-link  p-menuitem-link-active" routerLink="/user-delete">
                <span class="p-menuitem-icon pi pi-fw pi-trash"></span>
                <span class="p-menuitem-text" i18n>Usunięcie konta</span>
            </a>
        </div>
    </div>

    <div *ngIf="activeTab == 0" class="px-3">
    <form [formGroup]="userProfileForm">
        <div class="formgrid grid pb-3">
            <div class="col-12 md:col-6 py-3">
                <label for="undoLimit" class="block mb-2" i18n>Limit cofania operacji</label>
                <p-inputNumber inputId="undoLimit" formControlName="undoLimit"
                    [min]="1" [max]="50" [step]="1" [showButtons]="true">
                </p-inputNumber>
            </div>
            <div class="md:col-6"></div>
            <div class="col-12 md:col-6 flex flex-column py-3" >
                <div class="p-1" tooltipPosition="top" i18n-pTooltip
                    pTooltip="Informacje o aktualizacjach, akcjach serwisowych, wsparcie techniczne i inne wiadomości dotyczące parkour.design.">
                    <p-checkbox inputId="newsletterConsent" [binary]="true" formControlName="newsletterConsent"></p-checkbox>
                    <label class="px-2" for="newsletterConsent" i18n>Zgoda na otrzymywanie wiadomości email dotyczących parkour.design</label>
                </div>
                <div class="p-1" tooltipPosition="top" i18n-pTooltip
                    pTooltip="Jeżeli chcesz wycofać zgodę na warunki użytkowania lub politykę prywatności, musisz usunąc konto użytkownika.">
                    <p-checkbox [disabled]="true" inputId="termsConsent" [binary]="true" formControlName="termsConsent"></p-checkbox>
                    <label class="px-2" for="termsConsent" i18n>Zgoda na warunki użytkowania i politykę prywatności</label>
                </div>
            </div>
            <div class="md:col-6"></div>
        </div>
    </form>

    <div class="pb-3" *ngIf="userProfile && userProfile.role && userProfile.role === 'admin'">
        <a [routerLink]="['/admin-home']" i18n>Otwórz Panel Administratora</a>
    </div>

    <p-button icon="pi pi-save" (click)="saveUserProfile()" label="Zapisz ustawienia" i18n-label></p-button>

</div>

 <div *ngIf="activeTab == 1" class="p-3 flex flex-column">
    <div class="text-5xl mx-auto pb-5" *ngIf="!userProfile?.testerPlan">
        <span *ngIf="!userProfile?.planSelected"
              i18n>Wybierz plan cenowy</span>
        <span *ngIf="userProfile?.planSelected"
              i18n>Zarządzaj swoim planem cenowym</span>
    </div>
    <app-pricing-panel></app-pricing-panel>
</div>

<div *ngIf="activeTab == 2" class="p-3 flex flex-column gap-3">
    <div i18n>Tutaj możesz skasować swoje konto w naszym serwisie parkour.design. Oznacza to wykasowanie wszystkich informacji o Tobie oraz wszystkich projektów, które stworzyłeś.</div>
    <div i18n>Jeśli naprawdę chcesz skasować konto, wpisz poniżej "OK" i naciśnij przycisk "Skasuj moje konto".</div>
    <div>
        <input type="text" pInputText
               placeholder="wpisz tutaj OK" i18n-placeholder
               [(ngModel)]="deleteConfirmation" />
    </div>
    <div>
        <p-button icon="pi pi-trash" severity="danger"
                  [disabled]="deleteConfirmation !== 'OK'"
                  (click)="deleteUserAccount()"
                  label="Skasuj moje konto" i18n-label></p-button>
    </div>
</div>

</div>
