<div class="mb-5 flex align-items-center justify-content-center gap-8">
    <div>
        <p-selectButton
            [options]="pricingIntervalOptions" [(ngModel)]="pricingIntervalOption"
            [unselectable]="true" optionLabel="name" optionValue="value"></p-selectButton>
    </div>
    <div>
        <p-dropdown
            styleClass="w-16rem"
            [options]="currencies" [(ngModel)]="selectedCurrency"
            optionLabel="name" optionValue="value"></p-dropdown>
    </div>

</div>

<div class="mb-6 flex flex-column align-items-center justify-content-center text-lg text-600">
    <div class="mb-2 text-center line-height-3" i18n>Testuj przez 14 dni za darmo.</div>
    <div class="text-center line-height-3" i18n>Ceny nie zawierają podatku VAT.</div>
</div>

<div class="grid">
    <!-- STARTER / Startowy -->
    <div class="col-12 lg:col-4">
        <div class="p-3 h-full">
            <div class="shadow-2 p-3 h-full flex flex-column surface-card" style="border-radius: 6px">
                <div class="text-900 font-bold text-4xl mb-2" i18n>Startowy</div>
                <div class="text-600" i18n>dla hobbystów i początkujących</div>
                <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                <div class="flex align-items-center">
                    <span class="font-bold text-2xl text-900" i18n>Darmowy</span>
                    <!--<span class="ml-2 font-medium text-600">per month</span>-->
                </div>
                <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                <ul class="list-none p-0 m-0 flex-grow-1">
                    <li class="flex align-items-start mb-3">
                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                        <span class="text-900" i18n>Aplikacja dostępna przez przeglądarkę internetową</span>
                    </li>
                    <li class="flex align-items-start mb-3">
                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                        <span class="text-900" i18n>Projekty oraz wszystkie dane przechowywane w chmurze</span>
                    </li>
                    <li class="flex align-items-start mb-3">
                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                        <span class="text-900" i18n>Wszystkie projekty są prywatne - jedynie autor ma do nich dostęp</span>
                    </li>
                    <li class="flex align-items-start mb-3">
                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                        <span class="text-900" i18n>Liczba przeszkód ograniczona do 10</span>
                    </li>
                </ul>
                <hr class="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />
                <!-- user not logged in; user can only sign in/up first -->
                <button *ngIf="userProfile === null"
                        pButton pRipple label="Zarejestruj się teraz!" i18n-label class="p-3 w-full mt-auto"
                        [disabled]="advancedPlanLoading || mastersPlanLoading"
                        (click)="choosePlan('starter')"></button>
                <!-- user logged in but profile is not loaded yet or is loaded but plan is not loaded yet or all is loaded and plan is not starter;
                     so no action should be available to the user at this time -->
                <button *ngIf="userProfile === undefined || (userProfile && (currentPlan === undefined || currentPlan?.name != 'starter'))"
                        pButton pRipple label="&nbsp;" class="p-3 w-full mt-auto"
                        [disabled]="true"></button>
                <!-- profile is loaded but plan was never selected (and before planSelected field existed the plan is starter);
                     so the user can choose starter plan at this time -->
                <button *ngIf="userProfile && !userProfile.planSelected && !userProfile.testerPlan && currentPlan?.name == 'starter'"
                        pButton pRipple label="Wybierz" i18n-label class="p-3 w-full mt-auto"
                        [disabled]="advancedPlanLoading || mastersPlanLoading"
                        [loading]="starterPlanLoading"
                        (click)="choosePlan('starter')"></button>
                <!-- user profile loaded and choosen plan is starter; so no action is available to the user at this time -->
                <button *ngIf="userProfile && (userProfile.planSelected || userProfile.testerPlan) && currentPlan?.name == 'starter'"
                        pButton pRipple label="Twój obecny plan" i18n-label class="p-3 w-full mt-auto"
                        [disabled]="true"></button>
            </div>
        </div>
    </div>

    <!-- ADVANCED / Zaawansowany -->
    <div class="col-12 lg:col-4">
        <div class="p-3 h-full">
            <div class="shadow-2 p-3 h-full flex flex-column surface-card" style="border-radius: 6px">
                <div class="text-900 font-bold text-4xl mb-2" i18n>Zaawansowany</div>
                <div class="text-600" i18n>dla instruktorów i praktyków</div>
                <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                <div class="flex align-items-baseline">
                    <span class="font-bold text-2xl text-900">
                        {{ pricing.advanced[pricingIntervalOption][selectedCurrency] | number }}
                    </span>
                    <span class="font-bold text-lg text-900 ml-1">{{ selectedCurrency }}</span>
                    <span *ngIf="pricingIntervalOption == 'monthly'"  class="ml-2 font-medium text-600" i18n>miesięcznie</span>
                    <span *ngIf="pricingIntervalOption == 'yearly'" class="ml-2 font-medium text-600" i18n>rocznie</span>
                    <span *ngIf="pricingIntervalOption == 'yearly'" class="ml-2 font-medium text-green-500" i18n>(15% mniej)</span>
                </div>
                <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                <ul class="list-none p-0 m-0 flex-grow-1">
                    <li class="flex align-items-start mb-3">
                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                        <span class="text-900" i18n>Wszystko co w Startowym oraz...</span>
                    </li>
                    <li class="flex align-items-start mb-3">
                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                        <span class="text-900" i18n>Brak limitu na liczbę przeszkód</span>
                    </li>
                    <li class="flex align-items-start mb-3">
                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                        <span class="text-900" i18n>Stosowanie własnych obrazków i logo</span>
                    </li>
                    <li class="flex align-items-start mb-3">
                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                        <span class="text-900" i18n>Zapis projektu do PDF oraz wydruk</span>
                    </li>
                </ul>
                <hr class="mb-3 mx-0 border-top-1 border-none surface-border" />
                <!-- user logged in but profile is not loaded yet or is loaded but plan is not loaded yet or all is loaded but plan is masters (ie. not advanced);
                     so no action should be available to the user at this time -->
                <button *ngIf="userProfile === undefined || (userProfile && currentPlan === undefined) || currentPlan?.name == 'masters'"
                        pButton pRipple label="&nbsp;" class="p-3 w-full mt-auto"
                        [disabled]="true"></button>
                <!-- profile is defined (either user is not logged in or is logged and profile is loaded)
                     and if user is not logged in then selected plan must be starter;
                     so the user can choose this plan at this time -->
                <button *ngIf="userProfile !== undefined && (userProfile === null || currentPlan?.name == 'starter')"
                        pButton pRipple label="Kup teraz!" i18n-label class="p-3 w-full"
                        [disabled]="starterPlanLoading || mastersPlanLoading"
                        [loading]="advancedPlanLoading"
                        (click)="choosePlan('advanced')"></button>
                <!-- profile is loaded and advanced plan is selected;
                     so the user can go to stripe portal to manage the plan -->
                <button *ngIf="userProfile && currentPlan?.name == 'advanced'"
                        pButton pRipple label="Twój obecny plan - Zarządzaj na portalu Stripe" i18n-label class="p-3 w-full mt-auto"
                        [loading]="advancedPlanLoading"
                        (click)="teleportToStripePortal('advanced')"></button>
            </div>
        </div>
    </div>

    <!-- MASTERS / Mistrzowski -->
    <div class="col-12 lg:col-4">
        <div class="p-3 h-full">
            <div class="shadow-2 p-3 h-full flex flex-column surface-card" style="border-radius: 6px">
                <div class="text-900 font-bold text-4xl mb-2" i18n>Mistrzowski</div>
                <div class="text-600" i18n>dla wymagających gospodarzy torów</div>
                <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                <div class="flex align-items-baseline">
                    <span class="font-bold text-2xl text-900">
                        {{ pricing.masters[pricingIntervalOption][selectedCurrency] | number }}
                    </span>
                    <span class="font-bold text-lg text-900 ml-1">{{ selectedCurrency }}</span>
                    <span  *ngIf="pricingIntervalOption == 'monthly'"  class="ml-2 font-medium text-600" i18n>miesięcznie</span>
                    <span *ngIf="pricingIntervalOption == 'yearly'" class="ml-2 font-medium text-600" i18n>rocznie</span>
                    <span *ngIf="pricingIntervalOption == 'yearly'" class="ml-2 font-medium text-green-500" i18n>(15% mniej)</span>
                </div>
                <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                <ul class="list-none p-0 m-0 flex-grow-1" style="position: relative;">
                    <li class="flex align-items-start mb-3">
                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                        <span class="text-900" i18n>Wszystko to co w Zaawansowanym oraz...</span>
                    </li>
                    <li class="flex align-items-start mb-3">
                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                        <span class="text-900" i18n>Edycja materiałów przeszkód</span>
                    </li>
                    <li class="flex align-items-start mb-3">
                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                        <span class="text-900" i18n>Druk masterplanu oraz tabel ze szczegółowym opisem przeszkód</span>
                    </li>
                    <li class="flex align-items-start mb-3">
                        <i class="pi pi-check-circle text-green-500 mr-2"></i>
                        <span class="text-900" i18n>Praca również bez dostępu do sieci</span>
                    </li>
                </ul>
                <hr class="mb-3 mx-0 border-top-1 border-none surface-border" />
                <!-- user logged in but profile is not loaded yet or is loaded but plan is not loaded yet or all is loaded but plan is advanced (ie. not masters);
                     so no action should be available to the user at this time -->
                <button *ngIf="userProfile === undefined || (userProfile && currentPlan === undefined) || currentPlan?.name == 'advanced'"
                        pButton pRipple label="&nbsp;" class="p-3 w-full mt-auto"
                        [disabled]="true"></button>
                <!-- profile is defined (either user is not logged in or is logged and profile is loaded)
                     and if user is not logged in then selected plan must be starter;
                     so the user can choose this plan at this time -->
                <button *ngIf="userProfile !== undefined && (userProfile === null || currentPlan?.name == 'starter')"
                        pButton pRipple label="Kup teraz!" i18n-label class="p-3 w-full"
                        [disabled]="starterPlanLoading || advancedPlanLoading"
                        [loading]="mastersPlanLoading"
                        (click)="choosePlan('masters')"></button>
                <!-- profile is loaded and masters plan is selected;
                     so the user can go to stripe portal to manage the plan -->
                <button *ngIf="userProfile && currentPlan?.name == 'masters'"
                        pButton pRipple label="Twój obecny plan - Zarządzaj na portalu Stripe" i18n-label class="p-3 w-full mt-auto"
                        [loading]="mastersPlanLoading"
                        (click)="teleportToStripePortal('masters')"></button>
            </div>
        </div>
    </div>
</div>


<div class="surface-section text-center px-4 py-8 md:px-6 lg:px-8 w-full lg:w-6 mx-auto">
    <h1 class="text-900 font-bold text-5xl mx-auto" i18n>Często zadawane pytania</h1>

    <section class="grid text-left mx-auto">
        <div class="col-6">
            <div class="mb-6">
                <p class="mb-4 font-semibold" i18n>Jak mogę anulować lub zaktualizować moją kartę kredytową w serwisie Parkour.Design?</p>
                <p class="text-gray-800" i18n>Po zalogowaniu się,
                ponownie odwiedź stronę z cenami. Kliknięcie
                "Zarządzaj" pod odpowiednim planem umożliwi Ci
                anulowanie, aktualizację metody płatności lub pobranie
                paragonu.</p>
            </div>
            <div class="mb-6">
                <p class="mb-4 font-semibold" i18n>Dlaczego podatek VAT nie jest wliczony w cenę?</p>
                <p class="text-gray-800" i18n>Podatek VAT nie jest wliczony w cenę, ponieważ jego stawki różnią się w zależności od kraju. Na przykład, mogą wynosić od 5% do 25%, więc aby precyzyjniej określić cenę, VAT jest wyłączony. Na przykład – w Niemczech stawka VAT wynosi 19%, podczas gdy we Francji wynosi 20%.</p>
            </div>
        </div>
        <div class="col-6">
            <div class="mb-6">
                <p class="mb-4 font-semibold" i18n>Jakie metody płatności są dostępne?</p>
                <p class="text-gray-800" i18n>Aktualnie akceptujemy tylko karty kredytowe i karty debetowe. Skontaktuj się z nami pod adresem support(at)parkour.design, aby poprosić o inne opcje.</p>
            </div>
            <div class="mb-6">
                <p class="mb-4 font-semibold" i18n>Jak działa prawo autorskie na Parkour.Design? Kto jest właścicielem projektów, które tworzę?</p>
                <p class="text-gray-800">
                    <span i18n>Niezależnie od planu, jesteś właścicielem obrazów, które tworzysz na Parkour.Design. Możesz ich używać do celów osobistych lub komercyjnych, pod warunkiem przestrzegania naszych warunków użytkowania:</span>
                    <a routerLink="/terms" i18n>warunki użytkowania</a>.
                </p>
            </div>
        </div>
    </section>
</div>
