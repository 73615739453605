import { SorterModeSchema, SorterSchema } from "./design.schema"

export class SortMode<T> {
    active: boolean = false
    get disabled(): boolean {
        return this.isDisabled?.() || false
    }

    get icon(): string {
        return this.dirAsc ? this.iconAsc : this.iconDsc
    }

    constructor(
        public name: string,
        private iconAsc: string,
        private iconDsc: string,
        public dirAsc: boolean,
        public sort: (a: T, b: T, dirAsc: boolean) => number,
        public isDisabled?: () => boolean,
        activate?: boolean,
        public data?: any) {
        this.active = activate || false
    }

    toggleDirection() {
        this.dirAsc = !this.dirAsc
    }

    toJson(): SorterModeSchema {
        return {
            dirAsc: this.dirAsc,
            active: this.active,
        }
    }
}

export class Sorter<T> {
    activeMode?: SortMode<T>

    constructor(public getData: () => T[], public modes: SortMode<T>[]) {
        const m = this.modes.find(m => m.active)
        if (m) {
            this.sort(m)
            this.activeMode = m
        } else if (modes.length > 0) {
            this.activeMode = modes[0]
            this.activeMode.active = true
        }
    }

    sort(mode?: SortMode<T>) {
        if (mode) {
            this.activeMode = mode
        }
        const activeMode = this.activeMode
        if (activeMode) {
            this.modes.forEach(m => {
                m.active = (m === activeMode)
            })
            this.getData().sort((a: T, b: T) => activeMode.sort(a, b, activeMode.dirAsc))
        }
    }

    toggleSort(mode: SortMode<T>) {
        if (mode.active) {
            mode.toggleDirection()
        }
        this.sort(mode)
    }

    static sortDates(a: Date | undefined | null, b: Date | undefined | null, dirAsc: boolean): number {
        let c
        if (!a && !b) {
            return 0
        } else if (!a) {
            return 1
        } else if (!b) {
            return -1
        } else {
            c = a.getTime() - b.getTime()
        }
        return dirAsc ? c : -c
    }

    toJson(): SorterSchema {
        return {
            modes: this.modes.map(m => m.toJson())
        }
    }

    fromJson(json: SorterSchema) {
        if (json.modes.length === this.modes.length) {
            for (let i = 0; i < this.modes.length; i++) {
                const to = this.modes[i]
                const from = json.modes[i]
                to.dirAsc = from.dirAsc
                to.active = from.active || false
                if (to.active) {
                    this.sort(to)
                }
            }
        }
    }
}
