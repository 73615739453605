import { Injectable } from '@angular/core'

import { getApp } from '@angular/fire/app'
import { User } from '@angular/fire/auth'
import { Firestore, addDoc, collection, getDocs, onSnapshot, query, where } from '@angular/fire/firestore'
import { getFunctions, httpsCallable } from '@firebase/functions'
import { getStripePayments } from "@invertase/firestore-stripe-payments"
import { BehaviorSubject, combineLatest, defer, distinctUntilChanged, filter, from } from 'rxjs'

import { AuthService } from './auth.service'

import { timezoneToCurrency } from './timezonemap'

@Injectable({
    providedIn: 'root'
})
export class StripeService {
    user?: User

    payments: any = null
    private _products: any = { advanced: null, masters: null }

    products = new BehaviorSubject<any>(this._products)
    private subscriptionPlan = new BehaviorSubject<any>(undefined)

    constructor(
        protected auth: AuthService,
        private firestore: Firestore,
    ) {
        this.getUser()
        this.initStripe()
    }

    getUser() {
        this.auth.user.subscribe({
            next: (aUser: User | null) => {
                if (!aUser) {
                    return
                }
                this.user = aUser
                this._getUserSubsriptionPlan()
            },
            error: (err) => {
                console.error('error occured', err)
            }
        })
    }

    getSubscriptionPlan() {
        return this.subscriptionPlan.pipe(
            filter((plan: any) => plan !== undefined),
            distinctUntilChanged((prev, curr) => (prev === curr || (prev?.name === curr?.name && prev?.interval === curr?.interval)))
        )
    }

    private async _getUserSubsriptionPlan() {
        if (!this.user) {
            return
        }
        // create a query object to the current users active subscriptions
        const q = query(
            collection(this.firestore, 'stripe-customers', this.user.uid, 'subscriptions'),
            where('status', 'in', ['trialing', 'active'])
        );

        // fetch the active subscriptions
        const obsDocs = defer(() => from(getDocs(q)))

        const obs = combineLatest(obsDocs, this.products)
        obs.subscribe({
            next: ([subsResp, prods]: any[]) => {
                if (!prods || !prods.masters || !prods.advanced) {
                    return
                }
                if (subsResp.empty) {
                    // user doesn't have any active subscriptions
                    this.subscriptionPlan.next({ name: 'starter' })
                    return
                }

                // assuming user only has one active subscription max
                const sub = subsResp.docs[0].data()
                if (sub.quantity !== 1) {
                    console.error('bad subscription', sub)
                    return
                }
                const plan = { name: '', interval: '' }
                for (const [name, pp] of Object.entries(prods)) {
                    if (!pp) {
                        continue
                    }
                    const p = pp as any
                    if (p.monthly.id === sub.items[0].plan.id) {
                        plan.name = name
                        plan.interval = 'monthly'
                    } else if (p.yearly.id === sub.items[0].plan.id) {
                        plan.name = name
                        plan.interval = 'yearly'
                    }

                }
                if (plan.name) {
                    this.subscriptionPlan.next(plan)
                }
            },
            error: (err) => {
                console.error('error occured', err)
            }
        })
    }

    async initStripe() {
        const app = getApp()
        this.payments = getStripePayments(app, {
            productsCollection: "stripe-products",
            customersCollection: "stripe-customers",
        });

        const q = query(
            collection(this.firestore, 'stripe-products'),
            where('active', '==', true)
        );
        const querySnapshot = await getDocs(q);
        const productsPromises = querySnapshot.docs.map(async (productDoc) => {
            let productInfo = productDoc.data();

            // fetch prices subcollection per product
            const pricesCollection = collection(productDoc.ref, 'prices');
            const qp = query(pricesCollection, where('active', '==', true))
            const priceQuerySnapshot = await getDocs(qp)

            // process prices
            for (const priceDoc of priceQuerySnapshot.docs) {
                const price = priceDoc.data()
                // console.info('price', price)
                if (price.interval === 'year') {
                    productInfo['yearly'] = price
                    productInfo['yearly']['id'] = priceDoc.id
                    productInfo['yearlyPrice'] = price.unit_amount / 100
                } else {
                    productInfo['monthly'] = price
                    productInfo['monthly']['id'] = priceDoc.id
                    productInfo['monthlyPrice'] = price.unit_amount / 100
                }
            }
            return productInfo;
        });

        const products = await Promise.all(productsPromises);
        for (const p of products) {
            if (p.name === 'Advanced' || p.name === 'Developmental' || p.metadata?.level === '1') {
                this._products.advanced = p
            } else if (p.name === 'Masters' || p.metadata?.level === '2') {
                this._products.masters = p
            } else {
                console.info('unrecognized stripe prod', p)
            }
        }
        this.products.next(this._products)
    }

    async subscribeToPlan(plan: string, interval: string) {
        if (!this.user) {
            return
        }
        // console.info('this._products', plan, interval, this._products)
        const priceId = this._products[plan][interval].id
        if (!priceId) {
            console.error('stripe product not found', plan, interval)
            return
        }

        let checkoutSessionData = {
            price: priceId,
            trial_period_days: 14,
            success_url: window.location.origin + '/',
            cancel_url: window.location.origin + '/user-plan',
            automatic_tax: {
                enabled: true
            },
            allow_promotion_codes: true,
        };

        const checkoutSessionRef = await addDoc(
            collection(this.firestore, `stripe-customers/${this.user.uid}/checkout_sessions`),
            checkoutSessionData
        );

        // The Stripe extension creates a payment link for us
        onSnapshot(checkoutSessionRef, (snap) => {
            // this is invoked a few times by onSnapshot, every time it the record changes on server-side
            // until cs.url is present
            const cs = snap.data();
            if (cs?.error) {
                // handle error
                console.error('error occured', cs.error)
                return
            }
            if (cs?.url) {
                window.location.assign(cs.url);  // redirect to payment link
            }
        });
    }

    async teleportToStripePortal() {
        const app = getApp()
        const instance = getFunctions(app, 'europe-west1');
        const function_ref = httpsCallable(instance, 'ext-firestore-stripe-payments-createPortalLink');

        await function_ref({
            returnUrl: window.location.origin + '/user-plan'
        }).then(({ data }: any) => window.location.assign(data.url))
            .catch((e) => console.error('error', e.message))
    }

    getCurrentCurrecy() {
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
        const cityArr = tz.split("/")
        const city = cityArr[cityArr.length - 1]
        const currency = timezoneToCurrency[city]
        return currency
    }
}
