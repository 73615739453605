import paper from 'paper'
import { DesignTerminalObject, Direction } from "../../design.schema"
import { OutlinedText, TweenTo } from "../detail.paper.extensions"
import { Editable } from "../detail.selection"
import { SelectorKind } from "../detail.selectors"
import { Arrows, StraightArrows } from "./arrows"
import { ParkourObjectConfig } from "./parkour-object"
import { PathObject } from "./path-object"

export class Terminal extends PathObject implements Editable {
    label: string
    round12: number = 0
    txt: OutlinedText | undefined
    drawing: paper.Group = new paper.Group()
    arrowsColor: paper.Color = new paper.Color(this.cfg.params.colors.obstacleArrows || '#ff8080')
    txtWidth: number = 0
    txtHeight: number = 0
    width: number
    arrows?: Arrows
    cells: paper.Shape[] = []
    cellsInitialPos: paper.Point[] = []
    readonly cellRadius: number = this.cfg.scaleAccordingToFieldSize(0.1)
    readonly cellsDistanceLimit = 400
    cellsEditIdx: number = -1
    line: paper.Path
    directionalCellColorRed: paper.Color = new paper.Color('#f00')
    directionalCellColorWhite: paper.Color = new paper.Color('#fff')
    protected readonly preferredSelectorKind: SelectorKind = SelectorKind.RECTANGLE

    constructor(protected config: ParkourObjectConfig) {
        super(config)
        const object = config.object
        this.objectSize.height = 0
        if (!object.angle) {
            this.angle = 90
        }
        this.label = this.getKindName()
        this.allGroup.addChild(this.drawing)

        const defaultParams = {
            strokeColor: '#000',
            fillColor: '#fff',
            strokeWidth: 0.6 * this.cfg.params.lineWidth,
            strokeScaling: false
        }

        const cellsDistance = object.cellsDistance || this.cellsDistanceLimit
        const arrowDistance = object.arrowDistance || this.cellsDistanceLimit / 2
        this.cells.push(new paper.Shape.Circle({
            ...defaultParams,
            radius: this.cellRadius,
        }))
        this.cells.push(this.cells[0].clone())
        this.cellsInitialPos.push(this.initialPos.add([-cellsDistance / 2, 0]))
        this.cellsInitialPos.push(this.initialPos.add([cellsDistance / 2, 0]))
        this.cells[0].position = this.cellsInitialPos[0].clone()
        this.cells[1].position = this.cellsInitialPos[1].clone()
        if (!this.cfg.isNoRouteMode()) {
            this.cells[1].fillColor = this.directionalCellColorRed
        }

        for (let c of this.cells) {
            this.drawing.addChild(c)
            c.onMouseEnter = this.onMouseEnterCell.bind(this)
            c.onMouseLeave = this.onMouseLeaveCell.bind(this)
            c.onMouseDown = this.onMouseDownCell.bind(this)
        }

        const aw = 90, ah = 250
        const pos = this.initialPos.add([-aw / 2 - cellsDistance / 2 + arrowDistance, ah / 2])
        this.arrows?.destroy()
        this.setArrowDirection(Direction.forward)
        this.arrows = new StraightArrows(Direction.both, this.cfg, this.drawing, pos, aw, ah, this.arrowsColor)
        this.arrows.draw()
        this.arrows.setVisibleDirection(this.getArrowDirection())

        this.line = new paper.Path.Line({
            ...defaultParams,
            from: this.cells[0].position,
            to: this.cells[1].position,
            dashArray: [4, 4],
        })
        this.drawing.addChild(this.line)
        this.line.sendToBack()

        this.width = this.arrows.size.width * 2
        this.setArrowDirection(Direction.forward)
        this.objectReady(true)
    }

    get cellsDistance(): number {
        const p0 = this.cells[0].position
        const p1 = this.cells[1].position
        return p0.getDistance(p1)
    }

    getDirection(roundNo: number, passNo: number): Direction {
        return Direction.forward
    }

    setArrowDirection(direction: Direction, roundNo?: number): void {
        super.setArrowDirection(direction, roundNo)
        const dir = this.getArrowDirection()
        this.arrows?.setVisibleDirection(dir)
        if (dir === Direction.forward) {
            this.cells[0].fillColor = this.directionalCellColorWhite
            this.cells[1].fillColor = this.directionalCellColorRed
        } else if (dir === Direction.backward) {
            this.cells[0].fillColor = this.directionalCellColorRed
            this.cells[1].fillColor = this.directionalCellColorWhite
        } else {
            this.cells[0].fillColor = this.directionalCellColorWhite
            this.cells[1].fillColor = this.directionalCellColorWhite
        }
    }

    onMouseEnterCell(ev: any) {
        if (ev && ev.target && !this.editMode) {
            const t = ev.target
            t.bringToFront()
            t.data.tween = new TweenTo(t, { radius: this.cellRadius * 2 }, 75)
        }
    }

    onMouseLeaveCell(ev: any) {
        if (ev && ev.target && !this.editMode) {
            this.defocusCell(ev.target)
        }
    }

    onMouseDownCell(ev: any) {
        this.onEditStart(ev.point, this.cells.indexOf(ev.target), true)
    }

    defocusCell(cell: paper.Shape) {
        cell.data.tween = new TweenTo(cell, { radius: this.cellRadius }, 75)
    }

    select(point: paper.Point | undefined) {
        for (let c of this.cells) {
            const p = c.data.tween
            if (p instanceof TweenTo) {
                p.stop()
                const to: any = p.getTo()
                if (to.radius) {
                    c.radius = to.radius
                }
            }
        }
        const route = this.canvas.obstaclePath.route
        if (this.view?.canvas && this.view.userProfile.attentionForStart && this.isStart() && route.length === 1 && route[0].obstacle === this && 
            this.view.canvas.obstacles.length > 1) {
            const cs = this.selector.connectors
            if (cs.length > 0) {
                cs[0]?.attentionStart()
            }
        }
        super.select(point)
    }

    deselect() {
        super.deselect()
        this.attentionStop()
    }

    attentionStop() {
        const cs = this.selector.connectors
        if (this.isStart() && cs.length > 0) {
            cs[0]?.attentionStop()
        }
    }

    onMouseDown(point: paper.Point): boolean {
        if (this.cellsEditIdx < 0) {
            return super.onMouseDown(point)
        }
        this.cellsInitialPos[0] = this.cells[0].position
        this.cellsInitialPos[1] = this.cells[1].position
        return false
    }

    onMouseUp(point: paper.Point): boolean {
        if (this.cellsEditIdx < 0) {
            return super.onMouseUp(point)
        }
        this.onEditEndInternal()
        this.view?.saveData()
        return false
    }

    onMouseMove(point: paper.Point): boolean {
        if (this.cellsEditIdx < 0) {
            return super.onMouseMove(point)
        }
        const cellIdx = this.cellsEditIdx
        const otherEndIdx = cellIdx === 1 ? 0 : 1
        const otherEnd = this.cells[otherEndIdx].position
        const oldPos = this.cells[this.cellsEditIdx].position

        const cellDist = otherEnd.getDistance(point)
        if (cellDist < this.cellsDistanceLimit) {
            let vec = point.subtract(otherEnd)
            vec.length = this.cellsDistanceLimit
            point = otherEnd.add(vec)
        }

        this.cells[this.cellsEditIdx].position = point
        this.line.segments[this.cellsEditIdx].point = point

        if (this.arrows) {
            const angle = oldPos.subtract(otherEnd).getDirectedAngle(point.subtract(otherEnd))
            this.arrows.rotate(angle, otherEnd)

            const pos = this.arrows.position
            const arrowDist = pos.getDistance(point) || 0
            if (arrowDist < this.cellsDistanceLimit / 2) {
                let vec = otherEnd.subtract(point)
                vec.length = this.cellsDistanceLimit / 2
                this.arrows.position = point.add(vec)
            }
            this.canvas.updatePath()
        }
        this.angle = this.cells[1].position.subtract(this.cells[0].position).angle
        this.createSelectionGfx()
        return false
    }

    onMouseWheel(delta: number): boolean {
        if (this.cellsEditIdx < 0) {
            return super.onMouseWheel(delta)
        }
        return false
    }

    delete(): boolean {
        if (this.cellsEditIdx < 0) {
            return super.delete()
        }
        return false
    }

    onEditStart(point: paper.Point, idx: number, fromCells?: boolean): void {
        if (fromCells) {
            this.editMode = true
            this.cellsEditIdx = idx
        } else {
            super.onEditStart(point, idx)
        }
    }

    // called when edit is cancelled
    onEditEnd(animateTo?: paper.Point, idx?: number): boolean {
        if (this.cellsEditIdx < 0) {
            return super.onEditEnd(animateTo, idx)
        }
        this.cells[this.cellsEditIdx].position = this.cellsInitialPos[this.cellsEditIdx]
        this.onEditEndInternal()
        return false
    }

    onEditEndInternal() {
        this.defocusCell(this.cells[this.cellsEditIdx])
        this.editMode = false
        this.cellsEditIdx = -1
    }

    getExitPoint(direction: Direction, external?: boolean | undefined): paper.Point {
        return this.arrows?.position || super.getExitPoint(direction, external)
    }

    getEntryPoint(direction: Direction, external?: boolean | undefined): paper.Point {
        return this.arrows?.position || super.getEntryPoint(direction, external)
    }

    updateLabel(count: number) {
        this.label = this.getKindName()
        if (count > 1) {
            this.label += ' ' + count
        }
        if (this.txt) {
            this.txt.content = this.label
            this.txtWidth = this.txt.internalBounds.width
            this.txtHeight = this.txt.internalBounds.height
            let txtPos: paper.Point
            let angle = this.angle
            if (this.isFinish()) {
                angle += 180 // look at the opposite edge
                txtPos = this.getExitPoint(Direction.forward, true)
            } else {
                txtPos = this.getEntryPoint(Direction.forward, true)
            }
            this.txt.position = txtPos
            this.txt.setRotation(0)
            // do a trick to move start/finish text away from obstacle interior
            angle = angle * Math.PI / 180
            this.txt.translate([-(this.txtWidth / 2 + this.width) * (Math.sin(angle)), this.txtHeight * Math.cos(angle) * 2])
        }
    }

    getExternalSize(): paper.Size {
        const h = this.cellsDistance + this.cellRadius * 4
        return new paper.Size(h, this.width)
    }

    getPosition(): paper.Point {
        const p0 = this.cells[0].position
        const p1 = this.cells[1].position
        return p0.add(p1.subtract(p0).divide(2))
    }

    drawExtraElements(): void {
        if (this.txt && this.txt.isInserted()) {
            this.txt.remove()
        }
        this.txt = new OutlinedText({
            content: this.label,
            fillColor: this.cfg.params.colors.objectLabel,
            fontSize: this.cfg.getFontSize()
        })
        this.txtWidth = this.txt.internalBounds.width
        this.txtHeight = this.txt.internalBounds.height
        this.allGroup.addChild(this.txt)
        this.txt.bringToFront()
    }

    reset() {
        super.reset()
        this.updateLabel(0)
        this.setArrowDirection(Direction.forward)
    }

    isStart() {
        return this.kind.kind === 'start'
    }

    isFinish() {
        return this.kind.kind === 'finish'
    }

    isTerminal() {
        return true
    }

    destroy(): void {
        super.destroy()
        for (const c of this.cells) {
            const t = c.data.tween
            if (t instanceof TweenTo) {
                t.stop()
            }
            if (c.isInserted()) {
                if (c.onMouseEnter) {
                    c.onMouseEnter = null
                }
                if (c.onMouseLeave) {
                    c.onMouseLeave = null
                }
                if (c.onMouseDown) {
                    c.onMouseDown = null
                }
            }
        }
        if (this.txt && this.txt.isInserted()) {
            this.txt.remove()
        }
        this.txt = undefined
    }

    toJson(): DesignTerminalObject {
        return {
            ...super.toJson(),
            cellsDistance: this.cellsDistance,
            arrowDistance: this.arrows?.position.getDistance(this.cells[0].position) || this.cellsDistanceLimit / 2
        } as DesignTerminalObject
    }
}

