export const APP_CONFIG = {
    production: true,
    environment: 'WEB-PROD',

    firebase:
        window.location.hostname === "parkour.design" || window.location.hostname === "app.parkour.design" ?
            {
                apiKey: "AIzaSyAet2mvsSbJpJggNle9qfy6GxGhq5cZkVQ",
                //authDomain: "parkour-37a76.firebaseapp.com",
                authDomain: "app.parkour.design",
                projectId: "parkour-37a76",
                storageBucket: "parkour-37a76.appspot.com",
                messagingSenderId: "139534559588",
                appId: "1:139534559588:web:2b23e7ced198fccc56b271",
                measurementId: "G-1N6QXLZZWW"
            } : {
                apiKey: "AIzaSyDYBksx1GWZMHT0M0WzbS3js8sB5H4AQ9U",
                authDomain: "parkour-test.firebaseapp.com",
                projectId: "parkour-test",
                storageBucket: "parkour-test.appspot.com",
                messagingSenderId: "84156150154",
                appId: "1:84156150154:web:3cb61778ebeccae56dd873",
                measurementId: "G-GEL9XD5ZPD"
            }
};
