import paper from 'paper'
import { DesignBankObstacleObject, DesignObstacleObject, Direction } from '../../design.schema'
import { Arrows } from './arrows'
import { StraightArrows } from './arrows'
import { ParkourObjectConfig } from './parkour-object'
import { SelectorKind } from '../detail.selectors'
import { Obstacle } from './obstacle'


export class BankObstacle extends Obstacle {
    drawing: paper.Group = new paper.Group()
    arrowsColor: paper.Color = new paper.Color(this.cfg.params.colors.bankArrow || '#909090')
    arrows?: Arrows
    drawingSize: paper.Size | undefined
    entryAngle: number = 0
    exitAngle: number = 0
    arrowLength: number
    protected readonly preferredSelectorKind: SelectorKind = SelectorKind.RECTANGLE
    protected readonly snapRotation: boolean = true
    protected readonly canResize: boolean = true

    constructor(protected config: ParkourObjectConfig) {
        super(config)
        const object = config.object
        this.arrowLength = object.arrowLength || 500
        this.allGroup.addChild(this.drawing)
        this.drawObject(this.initialPos, 0)
        this.levelItem = this.drawing
    }

    setArrowDirection(direction: Direction, roundNo?: number): void {
        super.setArrowDirection(direction, roundNo)
        let dir = this.getArrowDirection()
        if (dir === Direction.none) {
            dir = Direction.both
        }
        this.arrows?.setVisibleDirection(dir)
    }

    scaleObject(scale: number): boolean {
        super.scaleObject(scale)
        this.arrowLength *= scale
        this.arrowLength = Math.max(this.arrowLength, 200)
        this.arrowLength = Math.min(this.arrowLength, 2000)
        this.drawObject(this.getPosition(), this.angle)
        return true
    }

    reset() {
        super.reset()
        if (this.crossOut) {
            this.crossOut.visible = false
        }
    }

    contains(point: paper.Point): boolean {
        if (this.externalFrame?.contains(point) || this.levelItem?.contains(point)) {
            return true
        }
        return false
    }

    drawObject(initialPos: paper.Point, angle: number) {
        this.drawing.removeChildren()
        if (!this.objectSize) {
            this.objectReady(false)
            return
        }

        const aw = 125
        const ah = this.arrowLength
        this.arrows?.destroy()
        let dir = this.getArrowDirection()
        if (dir === Direction.none) {
            dir = Direction.both
        }
        this.arrows = new StraightArrows(dir, this.cfg, this.drawing, initialPos.add([-aw / 2, ah / 2]), aw, ah, this.arrowsColor)
        this.arrows.draw()
        this.drawing.position = initialPos
        let b = this.drawing.internalBounds
        let p = this.getPosition()
        this.drawingSize = new paper.Size(Math.max(b.right - p.x, p.x - b.left) * 3, Math.max(b.bottom - p.y, p.y - b.top) * 2)
        this.objectSize.width = this.drawingSize.width
        this.objectSize.height = this.drawingSize.height
        if (angle !== 0) {
            this.drawing.rotate(angle, initialPos)
        }
        this.objectReady(true)
    }

    getEntryAngle(direction: Direction): number {
        if (direction === Direction.backward) {
            return this.exitAngle
        }
        return this.entryAngle
    }

    getExitAngle(direction: Direction): number {
        if (direction === Direction.backward) {
            return this.entryAngle
        }
        return this.exitAngle
    }

    getExternalSize(): paper.Size {
        return this.drawingSize || this.objectSize
    }

    destroy(): void {
        super.destroy()
        if (this.drawing.isInserted()) {
            this.drawing.remove()
        }
    }


    toSvg(): string | undefined {
        return this.drawing.exportSVG({
            asString: true
        }) as string
    }

    toJson(): DesignObstacleObject {
        return {
            ...super.toJson(),
            arrowLength: this.arrowLength,
        } as DesignBankObstacleObject
    }

    select(point?: paper.Point) {
        super.select(point)
        this.levelItem = this.allGroup
    }

    deselect() {
        super.deselect()
        this.levelItem = this.drawing
    }
}
