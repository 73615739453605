<div class="flex flex-column h-full max-h-full w-full max-w-full" (contextmenu)="false">

<p-toast></p-toast>

<div *ngIf="getLocationPath() != '/designer'"
    class="bg-indigo-400 text-white p-2 flex justify-content-between header-1-height">
    <div class="flex gap-1 align-items-center">
        <app-left-top-bar></app-left-top-bar>

        <div *ngIf="getLocationPath() == '/' && !(auth.user | async)" class="hidden lg:block text-xl">
            <a class="text-white pr-3" href="https://parkour.design" i18n>Wróć do strony serwisu</a>
        </div>

<!--
        <div class="mr-3">
            <a class="text-white" routerLink="/" i18n>Strona główna</a>
        </div>
        <div class="mr-3">
            <a class="text-white" routerLink="/designs" i18n>Projekty</a>
        </div>
        <div class="mr-3">
            <a class="text-white" routerLink="/guides" i18n>Poradniki filmowe</a>
        </div>
-->

    </div>
    <app-right-top-bar></app-right-top-bar>
</div>


<!-- splash screen showed before concluding if user is logged in or not -->
<div *ngIf="!auth.concluded" class="flex h-full w-full">
    <div class="text-900 text-8xl font-medium m-auto" i18n="@@WELCOME">Witaj w <b>Parkour</b></div>
</div>

<!-- show pages content when loaded -->
<ng-container *ngIf="auth.concluded">
    <router-outlet></router-outlet>
</ng-container>


<!-- confirmation popup for version update and app reload -->
<p-toast key="update-confirm" (onClose)="onUpdateReject()">
    <ng-template let-message pTemplate="message">
        <div class="flex flex-column w-full">
            <div class="mx-2 my-3" i18n>Nowa wersja aplikacji jest dostępna. Przeładować?</div>
            <div class="grid p-fluid">
                <div class="col-6">
                    <p-button (click)="onUpdateReject()" label="Później" i18n-label [text]="true"></p-button>
                </div>
                <div class="col-6">
                    <p-button (click)="onUpdateConfirm()" label="Przeładuj" i18n-label [text]="true"></p-button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>


</div>
