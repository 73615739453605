import paper from 'paper'
import { DesignSvgImageObject } from "../../design.schema"
import { ParkourObject, ParkourObjectConfig } from "./parkour-object"

export abstract class SvgImage extends ParkourObject {
    svg: paper.Shape | null = null
    imageSize: paper.Size

    constructor(protected config: ParkourObjectConfig) {
        super(config)
        const object = config.object
        this.imageSize = object.imageSize ? new paper.Size(object.imageSize) : new paper.Size(530, 530)
    }

    loadObject() {
        if (!this.kind.url) {
            return
        }
        (new paper.Shape).importSVG(this.kind.url, {
            insert: false,
            applyMatrix: false,
            onLoad: (item: any, svg: string) => {
                if (item) {
                    item.position = this.getPosition()
                    item.scale(this.imageSize.width / item.bounds.size.width)
                    this.imageSize = item.bounds.size
                    if (this.svg) {
                        this.svg.replaceWith(item)
                    } else {
                        this.allGroup.addChild(item)
                    }
                    this.svg = item
                    this.levelItem = item
                }
                this.objectReady(true)
            },
            onError: () => {
                this.objectReady(false)
            }
        })
    }

    getExternalSize(): paper.Size {
        return this.imageSize
    }

    protected scaleObjectInternal(scale: number): boolean {
        const s = this._trimScale(this.imageSize, scale, new paper.Size(100, 100))
        this.svg?.scale(s)
        this.imageSize = this.imageSize.multiply(s)
        return true
    }

    destroy(): void {
        super.destroy()
        if (this.svg && this.svg.isInserted()) {
            this.svg.remove()
        }
    }

    toJson(): DesignSvgImageObject {
        return {
            ...super.toJson(),
            imageSize: [this.imageSize.width, this.imageSize.height]
        } as DesignSvgImageObject
    }

    select(point?: paper.Point) {
        super.select(point)
        this.levelItem = this.allGroup
    }

    deselect() {
        super.deselect()
        if (this.svg) {
            this.levelItem = this.svg
        }
    }
}
