import paper from 'paper'
import { ObstaclePathNode } from './detail.path.node'
import { OutlinedText } from './detail.paper.extensions'
import { DetailComponent } from './detail.component'
import { designPathPatternDistances } from '../design.schema'
import { LengthPipe } from '../pipes'

export class TapeMeasure {
    path?: paper.Path
    distance?: OutlinedText
    editable: boolean = true
    private static _lengthPipe: LengthPipe = new LengthPipe()

    constructor(private view: DetailComponent) {
    }

    begin(point: paper.Point) {
        this.end()
        this.path = new paper.Path({
            segments: [point, point],
            strokeColor: this.view.cfg.params.colors.tape,
            strokeWidth: this.view.cfg.params.tapeWidth,
            dashArray: designPathPatternDistances[this.view.cfg.params.tapePattern],
            strokeScaling: false
        })
        this.distance = new OutlinedText({
            content: '',
            strokeWidth: 1,
            strokeColor: this.view.cfg.params.colors.tape,
            fontSize: this.view.cfg.getFontSize() * 1.2
        })
        this.editable = true
    }

    private _drawDistance() {
        if (!this.path || !this.distance) {
            return
        }
        const dist = this.path.length
        const point = this.path.segments[this.path.segments.length - 1].point
        if (dist > 100) {
            this.distance.content = TapeMeasure._lengthPipe.transform(dist, this.view.cfg.params.distanceUnit, false, 1, 2) || '?'
            const offset = point.subtract(this.path.getPointAt(dist - 100)).multiply(this.distance.internalBounds.size.divide([110, 75]))
            this.distance.position = point.add(offset)
        }
    }

    extend(point: paper.Point) {
        if (!this.path || !this.editable) {
            return
        }
        this.path.add(point)
        this.path.smooth(ObstaclePathNode.smoothAttributes)
        this._drawDistance()
    }

    stretch(point: paper.Point) {
        if (!this.path || !this.editable) {
            return
        }
        this.path.segments[this.path.segments.length - 1].point = point
        this.path.smooth(ObstaclePathNode.smoothAttributes)
        this._drawDistance()
    }

    freeze() {
        this.editable = false
    }

    end() {
        if (this.path?.isInserted()) {
            this.path.remove()
        }
        if (this.distance?.isInserted()) {
            this.distance.remove()
        }
        this.path = undefined
        this.distance = undefined
        this.editable = false
    }

    destroy() {
        this.end()
    }

}