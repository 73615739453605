import { Direction } from "../../design.schema"
import { OutlinedText } from "../detail.paper.extensions"
import { SelectorKind } from "../detail.selectors"
import { ParkourObjectConfig } from "./parkour-object"
import { PathObject } from "./path-object"

export class FinishStart extends PathObject {
    txt: OutlinedText | undefined
    delta: number = 0
    protected readonly preferredSelectorKind: SelectorKind = SelectorKind.RECTANGLE

    constructor(protected config: ParkourObjectConfig) {
        super(config)
        const object = config.object
        this.objectSize.height = 0
        if (!object.angle) {
            this.angle = 90
        }
        this.setArrowDirection(Direction.forward, 1)
        this.setArrowDirection(Direction.none, 2)
        this.loadObject()
    }

    drawExtraElements(): void {
        if (this.txt && this.txt.isInserted()) {
            this.txt.remove()
        }
        this.txt = new OutlinedText({
            content: this.getKindName(),
            fillColor: this.cfg.params.colors.objectLabel,
            fontSize: this.cfg.getFontSize()
        })
        this.delta = Math.max(this.objectSize.width, this.objectSize.height)
        this.allGroup.addChild(this.txt)
        this.txt.bringToFront()
    }

    reset() {
        super.reset()
        this.setArrowDirection(Direction.forward, 1)
        this.setArrowDirection(Direction.none, 2)
        if (this.txt) {
            this.txt.position = this.getPosition().add([0, this.delta])
            this.txt.setRotation(0)
        }
    }

    destroy() {
        super.destroy()
        if (this.txt && this.txt.isInserted()) {
            this.txt.remove()
        }
        this.txt = undefined
    }

    isFinishStart() {
        return true
    }
}
