import { Injectable, Inject, LOCALE_ID } from '@angular/core';

import { getBuildVersion, getBuildDate } from '../utils';

@Injectable({
    providedIn: 'root'
})
export class BrevoService {
    lang: string

    constructor(
        @Inject(LOCALE_ID) public locale: string,
    ) {
        this.lang = locale
        this.updateUserData()
    }

    updateUserData(user?: any) {
        const data: any = {
            PARKOUR_BUILD_VERSION: getBuildVersion(),
            PARKOUR_BUILD_DATE: getBuildDate(),
            LANGUAGE: this.lang,
            TIMEZONE: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
        if (window.location.hostname !== "app.parkour.design") {
            return
        }
        if (user) {
            data['email'] = user.email
            if (user.displayName) {
                const parts = user.displayName.split(' ')
                if (parts.length > 0) {
                    data['firstName'] = parts[0]
                }
                if (parts.length > 1) {
                    data['lastName'] = parts[1]
                }
            }
        }
        if (user?.email && window.sendinblue) {
            window.sendinblue.identify(user.email, data)
        } else {
            console.error('cannot send brevo event - window.sendinblue is missing')
        }

        if (window.BrevoConversations) {
            window.BrevoConversations('updateIntegrationData', data)
        } else {
            console.error('cannot send brevo event - window.BrevoConversations is missing')
        }
    }

    updateUserPlan(email: string, plan: string) {
        if (window.location.hostname !== "app.parkour.design") {
            return
        }
        const data: any = {
            PARKOUR_PLAN: plan,
        }
        if (window.sendinblue) {
            window.sendinblue.identify(email, data)
        } else {
            console.error('cannot send brevo event - window.sendinblue is missing')
        }

        if (window.BrevoConversations) {
            data['email'] = email
            window.BrevoConversations('updateIntegrationData', data)
        } else {
            console.error('cannot send brevo event - window.BrevoConversations is missing')
        }
    }

    show() {
        if (window.BrevoConversations) {
            window.BrevoConversations('show')
        }
    }

    showAndOpen() {
        if (window.BrevoConversations) {
            window.BrevoConversations('show')
            window.BrevoConversations('openChat')
        }
    }

    hide() {
        if (window.BrevoConversations) {
            window.BrevoConversations('hide')
        }
    }

    sendEvent(name: string, data: any) {
        if (window.location.hostname !== "app.parkour.design") {
            return
        }
        if (window.sendinblue) {
            window.sendinblue.track(name, data)
        } else {
            console.error('cannot send brevo event - window.sendinblue is missing')
        }
    }
}

declare global {
    interface Window {
        sendinblue: any;
        BrevoConversations: any;
        BrevoConversationsSetup: any;
    }
}
