import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ResizeObserverModule } from '@ng-web-apis/resize-observer';

import { ButtonModule } from 'primeng/button';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SplitterModule } from 'primeng/splitter';
import { DialogModule } from 'primeng/dialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { CheckboxModule } from 'primeng/checkbox';
import { MenuModule } from 'primeng/menu';
import { TabMenuModule } from 'primeng/tabmenu';
import { AccordionModule } from 'primeng/accordion';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SliderModule } from 'primeng/slider';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { BlockUIModule } from 'primeng/blockui';
import { CalendarModule } from 'primeng/calendar';
import { PanelModule } from 'primeng/panel';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MultiSelectModule } from 'primeng/multiselect';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { TreeTableModule } from 'primeng/treetable';

import { DetailComponent } from './detail.component';
import { SharedModule } from '../shared/shared.module';
import { ImageSelectPanelComponent } from '../image-select-panel/image-select-panel.component';
import { NewColorPickerModule } from '../new-colorpicker/new-colorpicker';
import { ParkourObjectPanelComponent } from './parkour-object-panel/parkour-object-panel.component'
import { ParkourCompetitionParamsComponent } from './dialogs/parkour-competition-params/parkour-competition-params.component'
import { ParkourDisplayOptionsComponent } from './dialogs/parkour-display-options/parkour-display-options.component'
import { ParkourFieldSizeComponent } from './dialogs/parkour-field-size/parkour-field-size.component'
import { ParkourDebugOptionsComponent } from './dialogs/parkour-debug-options/parkour-debug-options.component'
import { ParkourDesignStatsComponent } from './dialogs/parkour-design-stats/parkour-design-stats.component'
import { ParkourMaterialsComponent } from './dialogs/parkour-materials/parkour-materials.component'
import { ParkourValidationComponent } from './dialogs/parkour-validation/parkour-validation.component'
import { LeftTopBarComponent } from '../left-top-bar/left-top-bar.component';
import { RightTopBarComponent } from '../right-top-bar/right-top-bar.component';
import { ToastModule } from 'primeng/toast'
import { ParkourCanvas } from '../parkour-canvas/parkour-canvas'
import { LengthPipe } from "../pipes";


@NgModule({
    declarations: [DetailComponent],
    imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,

    SharedModule,

    ResizeObserverModule,

    ButtonModule,
    ToggleButtonModule,
    SelectButtonModule,
    SplitButtonModule,
    SplitterModule,
    DialogModule,
    InputNumberModule,
    InputTextModule,
    TableModule,
    TooltipModule,
    CheckboxModule,
    MenuModule,
    TabMenuModule,
    AccordionModule,
    DropdownModule,
    OverlayPanelModule,
    SliderModule,
    ColorPickerModule,
    ProgressSpinnerModule,
    BlockUIModule,
    CalendarModule,
    PanelModule,
    ContextMenuModule,
    MultiSelectModule,
    TriStateCheckboxModule,
    ToastModule,
    TreeTableModule,

    ImageSelectPanelComponent,
    NewColorPickerModule,
    ParkourObjectPanelComponent,
    ParkourCompetitionParamsComponent,
    ParkourDisplayOptionsComponent,
    ParkourFieldSizeComponent,
    ParkourDebugOptionsComponent,
    ParkourDesignStatsComponent,
    ParkourMaterialsComponent,
    ParkourValidationComponent,
    LeftTopBarComponent,
    RightTopBarComponent,
    ParkourCanvas,
    LengthPipe
]
})
export class DetailModule { }
