import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';

import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';

import { AuthService } from '../services/auth.service';
import { getBuildVersion } from '../utils';

@Component({
    selector: 'app-right-top-bar',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        MenuModule,
        ButtonModule,
    ],
    templateUrl: './right-top-bar.component.html',
    styleUrl: './right-top-bar.component.scss'
})
export class RightTopBarComponent {
    downloadItems: MenuItem[]
    langItems: MenuItem[]
    selectedCountry: string = 'gb'

    constructor(
        protected auth: AuthService,
        protected router: Router,
    ) {
        const version = getBuildVersion()

        this.downloadItems = [{
            label: $localize`Aplikacja na Windows`,
            icon: 'fab fa-windows',
            url: `Parkour ${version}.exe`
        }, {
            // TODO: not build in CI yet
            //     label: $localize`Aplikacja na MacOS`,
            //     icon: 'fab fa-apple',
            //     url: `assets/parkour-${version}.dmg`
            // }, {
            label: $localize`Aplikacja na Linuxa`,
            icon: 'fab fa-linux',
            url: `Parkour-${version}.AppImage`
        }]

        // prepare language selection
        this.langItems = [{
            label: 'English',
            icon: 'flag flag-gb',
            command: () => { this.selectLanguage('en') },
        }, {
            label: 'Polish',
            icon: 'flag flag-pl',
            command: () => { this.selectLanguage('pl') },
            // }, {
            //     label: 'German',
            //     icon: 'flag flag-de',
            //     command: () => { this.selectLanguage('de') },
            // }, {
            //     label: 'French',
            //     icon: 'flag flag-fr',
            //     command: () => { this.selectLanguage('fr') },
            // }, {
            //     label: 'Dutch',
            //     icon: 'flag flag-nl',
            //     command: () => { this.selectLanguage('nl') },
            // }, {
            //     label: 'Swedish',
            //     icon: 'flag flag-se',
            //     command: () => { this.selectLanguage('sv') },
            // }, {
            //     label: 'Spanish',
            //     icon: 'flag flag-se',
            //     command: () => { this.selectLanguage('es') },
        }]
        const lang = document.querySelector('html')!.getAttribute('lang') || 'en'
        switch (lang) {
            case 'pl': this.selectedCountry = 'pl'; break;
            case 'en': this.selectedCountry = 'gb'; break;
            case 'de': this.selectedCountry = 'de'; break;
            case 'fr': this.selectedCountry = 'fr'; break;
            case 'nl': this.selectedCountry = 'nl'; break;
            case 'sv': this.selectedCountry = 'se'; break;
            case 'es': this.selectedCountry = 'es'; break;
        }
    }

    selectLanguage(lang: string) {
        const current = localStorage.getItem('locale')
        if (current) {
            localStorage.setItem('prevLocale', current)
        }
        document.cookie = `firebase-language-override=${lang}; SameSite=Strict;`;
        localStorage.setItem('locale', lang);
        document.location.reload()
    }

    async logout() {
        this.router.navigate(['/'])
        return await this.auth.logout()
    }
}
