<p-dialog header="Debug options"
    [(visible)]="visibility"
    [style]="{'min-width': '30rem', 'max-width': '40rem'}" 
    (keydown.enter)="onOkDialog()" (keydown.escape)="onCancelDialog()"
    (onShow)="onShowDialog()" (onClose)="onCloseDialog()" (onHide)="onHideDialog()">
    <div class="grid align-items-center justify-content-between">
        <div class="col-1">
            <p-checkbox [(ngModel)]="view.cfg.debugParams.imgBoundingBox" [binary]="true" inputId="debug1"></p-checkbox>
        </div>
        <div class="col-11">
            <label for="debug1">Object frame rectangle</label>
        </div>
        <div class="col-8">paper.js items</div>
        <div class="col-4">{{ paperjsItems }}</div>
        <div class="col-8">User profile version - read</div>
        <div class="col-4">{{ readUserProfileVer || 'undefined' }}</div>
        <div class="col-8">User profile version - current</div>
        <div class="col-4">{{ currentUserProfileVer }}</div>
        <div class="col-8">Heap used</div>
        <div class="col-4">{{ heapSizeUsed }}</div>
        <div class="col-8">Heap total</div>
        <div class="col-4">{{ heapSizeTotal }}</div>
        <div class="col-8">Heap limit</div>
        <div class="col-4">{{ heapSizeLimit }}</div>
    </div>
    <div class="mt-3 mb-1 text-red-500">
        {{ errorMsg }}
    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="onOkDialog()" label="OK"></p-button>
    </ng-template>
</p-dialog>
