import paper from 'paper'
import { DesignDrawingObject, DesignDrawingSegment, DesignPoint } from "../../design.schema"
import { Editable } from '../detail.selection'
import { SelectorKind } from '../detail.selectors'
import { UiCommandId } from '../detail.ui.commands.defs'
import { ParkourObject, ParkourObjectConfig } from "./parkour-object"
import { LayerId } from '../../parkour-canvas/parkour-canvas'

export class Drawing extends ParkourObject implements Editable {
    size: paper.Size
    path: paper.Path
    saveData: boolean = false
    strokeColor: string
    fillColor: string
    fillEnable: boolean
    editMode: boolean = false
    editSegment: number = -1
    selectedSegment?: paper.Segment = undefined
    readonly thisIsEditable: true = true
    locked: boolean = false

    public get focusLock(): boolean {
        return this.selectedSegment !== undefined
    }

    private readonly smoothOptions = {
        type: 'catmull-rom',
        factor: 1.0
    }
    protected canResize: boolean = true
    protected canMove: boolean = true
    protected canRotate: boolean = true
    protected readonly preferredSelectorKind: SelectorKind = SelectorKind.RECTANGLE
    protected readonly preferredLayer: LayerId = LayerId.FRONT
    protected readonly configurableLayer: boolean = true

    private get tolerance(): number {
        return paper.settings.handleSize / (2 * (this.canvas.paper ? this.canvas.paper.view.zoom : 1))
    }

    getExternalSize(): paper.Size {
        return this.size
    }

    smoothAllSegments() {
        this.path.smooth(this.smoothOptions)
        this._findBestAngle()
        this.view?.saveData()
    }

    smoothSelectedSegment() {
        this.selectedSegment?.smooth(this.smoothOptions)
        this._findBestAngle()
        this.view?.saveData()
    }

    straightenAllSegments() {
        this.path.clearHandles()
        this._findBestAngle()
        this.view?.saveData()
    }

    straightenSelectedSegment() {
        this.selectedSegment?.clearHandles()
        this._findBestAngle()
        this.view?.saveData()
    }

    constructor(protected config: ParkourObjectConfig) {
        super(config)
        const object = config.object
        if (!object.angle) {
            this.angle = 0
        }
        this.layer = object.layer || this.preferredLayer
        this.objectSize.height = 0
        this.objectSize.width = 0
        this.fillEnable = object.fillEnable || false
        this.fillColor = object.fillColor || '#aaee22'
        this.strokeColor = object.strokeColor || '#000'
        this.locked = object.locked || false
        this.canResize = this.canMove = this.canRotate = !this.locked
        let segments: (paper.Segment | paper.Point)[] = []
        if (object.segments && Array.isArray(object.segments)) {
            segments = object.segments.map(s => {
                return new paper.Segment(
                    new paper.Point(s.x + this.initialPos.x || 0, s.y + this.initialPos.y || 0),
                    s.handleIn ? new paper.Point(s.handleIn.x, s.handleIn.y) : undefined,
                    s.handleOut ? new paper.Point(s.handleOut.x, s.handleOut.y) : undefined
                )
            })
        }
        if (segments.length === 0) {
            segments = [this.initialPos]
        }
        this.path = new paper.Path({
            segments: segments,
            strokeWidth: object.strokeWidth || 10,
            strokeScaling: true,
            strokeColor: this.strokeColor,
            selectedColor: this.strokeColor,
            fillColor: this.fillEnable ? this.fillColor : null,
            selected: false,
            closed: object.closed || false
        })
        this.size = this.path.internalBounds.size.add(this.tolerance)
        this.externalFrame.position = this.path.bounds.center
        
        if (this.view && this.contextMenu) {
            const c = this.view.uiCommands
            this.contextMenu.add(
                c.getContextMenuItem(UiCommandId.CURVE_SMOOTH_ALL),
                c.getContextMenuItem(UiCommandId.CURVE_STRAIGHTEN_ALL),
                c.getContextMenuItem(UiCommandId.CURVE_SMOOTH_POINT),
                c.getContextMenuItem(UiCommandId.CURVE_STRAIGHTEN_POINT),
            )
        }
        this.allGroup.addChild(this.path)
        this.objectReady(true)
        this.levelItem = this.path
    }

    doLockUnlock() {
        if (this.locked) {
            // deselect object points
            this.deselect()
        }
        // keep the main object selection for lock and unlock
        this.select()
        this.canResize = this.canMove = this.canRotate = !this.locked
        this.view?.saveData()
        this.createSelectionGfx()
    }

    scaleObject(scale: number): boolean {
        if (this.locked) {
            return false
        }
        const s = this._trimScale(this.size, scale, new paper.Size(100, 100))
        this.path.scale(s)
        this.size = this.size.multiply(s)
        this._setPositionSize()
        return true
    }

    onMouseDown(point: paper.Point): boolean {
        if (this.locked) {
            return false
        }
        if (this.editSegment >= 0 && this.editSegment < this.path.segments.length) {
            const idx = this.editSegment === 0 ? 0 : this.path.segments.length - 1
            const opposite = this.editSegment === 0 ? this.path.segments.length - 1 : 0
            const neighbor = this.editSegment === 0 ? 1 : this.editSegment - 1
            this.path.segments[idx].selected = false
            if (this.path.segments[opposite].point.isClose(point, this.tolerance)) {
                this.path.closed = true
                this.onEditEnd()
            } else if (!this.path.segments[neighbor].point.isClose(point, this.tolerance)) {
                this.path.insert(idx, point)
                this._findBestAngle()
                this.createSelectionGfx()
                if (idx > 0) {
                    this.editSegment++
                }
                this.path.segments[this.editSegment].selected = true
            }
        }
        return true
    }

    onMouseUp(point: paper.Point): boolean {
        if (this.locked) {
            return false
        }
        return true
    }

    onMouseMove(point: paper.Point): boolean {
        if (this.locked) {
            return false
        }
        if (this.editSegment >= 0 && this.editSegment < this.path.segments.length) {
            this.path.segments[this.editSegment].point = point
        }
        return true
    }

    onMouseDrag(delta: paper.Point): boolean {
        if (this.locked) {
            return false
        }
        return true
    }

    onMouseWheel(delta: number) {
        if (this.locked) {
            return false
        }
        if (this.selectedSegment) {
            const lenIn1 = this.selectedSegment.handleIn.length
            const lenOut1 = this.selectedSegment.handleOut.length
            const lenIn2 = lenIn1 + (delta > 0 ? -15 : 15)
            const scale = lenIn2 / lenIn1
            if (lenIn1 > 1 && lenOut1 > 1) {
                this.selectedSegment.handleIn = this.selectedSegment.handleIn.multiply(scale)
                this.selectedSegment.handleOut = this.selectedSegment.handleOut.multiply(scale)
            }
        }
        return true
    }

    onEditStart(point: paper.Point, idx: number): void {
        if (!this.path.segments.length || this.path.closed || this.locked) {
            this.editMode = false
            return
        }
        this.path.segments[idx].selected = false
        if (idx === this.path.segments.length - 1) {
            idx = this.path.segments.length
        }
        this.path.insert(idx, point)
        this.path.segments[idx].selected = true
        this.editSegment = idx
        this.editMode = true
    }

    onEditEnd(): boolean {
        if (this.locked) {
            return false
        }
        if (this.editMode) {
            if (this.editSegment >= 0 && this.editSegment < this.path.segments.length) {
                this.path.removeSegment(this.editSegment)
            }
            this.editSegment = -1
            this.view?.saveData()
            this.editMode = false
            return this.path.segments.length <= 1
        }
        return false
    }

    delete(): boolean {
        let deleted: boolean = false
        this.path.segments.forEach((s, idx) => {
            if (s.selected) {
                this.path.removeSegment(idx)
                deleted = true
            }
        })
        if (this.path.segments.length === 2) {
            this.path.closed = false
        } else if (this.path.segments.length <= 1) {
            // delete whole object
            return true
        }
        return !deleted
    }

    updateContent() {
        this.path.fillColor = this.fillEnable ? new paper.Color(this.fillColor) : null
        this.path.strokeColor = new paper.Color(this.strokeColor)
        this.path.selectedColor = this.path.strokeColor
    }

    move(delta: paper.Point): void {
        if (this.locked) {
            return
        }
        const s = this.selectedSegment
        if (s && s.selected) {
            s.point = s.point.add(delta)
            this._findBestAngle()
            this.createSelectionGfx()
            this.changed = true
        } else {
            this.selectedSegment = undefined
            super.move(delta)
        }
    }

    private _setPositionSize() {
        const p = this.getPosition()
        this.allGroup.rotate(-this.angle, p)
        this.size = this.path.internalBounds.size.add(this.tolerance)
        this.externalFrame.position = this.path.bounds.center
        this.allGroup.rotate(this.angle, p)
    }

    private _findBestAngle() {
        const p = this.getPosition()
        const ab = (this.angle + 360) % 360
        const quarter = Math.floor(ab / 90)
        let bestArea: number = -1
        let bestAngle: number = -1

        const step = 0.5
        for (let a = 0; a < 90; a += step) {
            const size = this.path.bounds.size.add(this.tolerance)
            const area = Math.round(size.width + size.height)
            if (bestAngle < 0 || bestArea > area) {
                bestArea = area
                bestAngle = a
            }
            this.path.rotate(step, p)
        }
        this.path.rotate(-90, p)
        if (bestAngle >= 0) {
            bestAngle = (-bestAngle + 360) % 90 + quarter * 90
            this.angle = bestAngle
            this._setPositionSize()
            this.createSelectionGfx()
        }
    }

    select(point?: paper.Point): void {
        super.select(point)
        if (this.locked) {
            return
        }
        this.levelItem = this.allGroup
        this.path.selected = true
        let selected: boolean = false
        let selectedIdx: number = -1
        if (point) {
            this.selectedSegment = undefined
            for (let i = 0; i < this.path.segments.length; i++) {
                const s = this.path.segments[i]
                if (!selected && s.point.isClose(point, this.tolerance)) {
                    s.selected = selected = true
                    this.selectedSegment = s
                    selectedIdx = i
                } else {
                    if (s.selected) {
                        this.view?.saveData()
                    }
                    s.selected = false
                }
            }
        }
    }

    deselect(): void {
        super.deselect()
        this.path.selected = false
        this.selectedSegment = undefined
        this.levelItem = this.path
        this.onEditEnd()
        if (!this.locked) {
            this._findBestAngle()
        }
    }

    doubleClick(point?: paper.Point): boolean {
        if (!point) {
            return false
        }
        if (this.editMode || this.locked) {
            this.onEditEnd()
            return false
        }
        const result = this.path.hitTest(point, {
            tolerance: this.tolerance * 2,
            fill: false,
            stroke: true,
            segments: true,
            curve: false
        })
        if (result) {
            if (result.type === 'stroke' && result.location && result.location.index >= 0) {
                const s = this.path.insert(result.location.index + 1, point.clone())
                s.selected = true
                this.selectedSegment = s
                return true
            } else if (result.type === 'segment' && result.segment) {
                const idx = result.segment.index
                if (idx === 0 || idx === this.path.segments.length - 1) {
                    this.onEditStart(point, idx)
                }
            }
        }
        return false
    }

    contains(point: paper.Point): boolean {
        if (this.levelItem === this.path) {
            if (this.path.hitTest(point, {
                tolerance: this.tolerance * 2,
                fill: true,
                stroke: true,
                segments: true
            })) {
                return true
            }
            return false
        }
        return super.contains(point)
    }

    toJson(): DesignDrawingObject {
        const p = this.getPosition()
        this.path.rotate(-this.angle, p)
        const segments: DesignDrawingSegment[] = this.path.segments.map(s => {
            let segment: DesignDrawingSegment = {
                x: s.point.x - p.x,
                y: s.point.y - p.y,
                handleIn: s.handleIn ? {
                    x: s.handleIn.x,
                    y: s.handleIn.y
                } as DesignPoint : null,
                handleOut: s.handleOut ? {
                    x: s.handleOut.x,
                    y: s.handleOut.y
                } as DesignPoint : null
            } as DesignDrawingSegment
            return segment
        })
        this.path.rotate(this.angle, p)
        return {
            ...super.toJson(),
            fillColor: this.fillColor,
            fillEnable: this.fillEnable,
            strokeWidth: this.path.strokeWidth,
            strokeColor: this.strokeColor,
            closed: this.path.closed,
            locked: this.locked,
            segments: segments,
        } as DesignDrawingObject
    }
}
