import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { SkeletonModule } from 'primeng/skeleton';
import { ImageModule } from 'primeng/image';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SelectButtonModule } from 'primeng/selectbutton';
import { MultiSelectModule } from 'primeng/multiselect';

import { HomeComponent } from './home.component';
import { SharedModule } from '../shared/shared.module';
import { MyDesignsListComponent } from '../my-designs-list/my-designs-list.component';

@NgModule({
    declarations: [HomeComponent],
    imports: [
        CommonModule,
        RouterModule,

        SharedModule,
        FormsModule,
        ReactiveFormsModule,

        ButtonModule,
        TableModule,
        DialogModule,
        InputNumberModule,
        InputTextModule,
        OverlayPanelModule,
        TooltipModule,
        SkeletonModule,
        ImageModule,
        DropdownModule,
        FileUploadModule,
        ProgressSpinnerModule,
        SelectButtonModule,
        MultiSelectModule,

        MyDesignsListComponent,
    ],
    exports: [HomeComponent],
})
export class HomeModule { }
