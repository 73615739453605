import paper from 'paper'

export class TweenTo {
    private tween: paper.Tween
    private to: object | undefined

    constructor(shape: paper.Shape, to: object, options: object | number) {
        const t = shape.data.tween
        if (t instanceof TweenTo) {
            t.tween.stop()
        }
        shape.data.tween = this
        this.to = Object.assign(to)
        this.tween = shape.tweenTo(to, options).then(() => {
            shape.data.tween = undefined
        })
    }

    then(callback: Function): TweenTo {
        this.tween = this.tween.then(callback)
        return this
    }

    stop() {
        this.tween.stop()
    }

    getTo(): object | undefined {
        return this.to
    }

    toJSON(): string {
        return ''
    }
}

export class OutlinedText extends paper.Group {
    txt: paper.PointText
    outline: paper.PointText

    constructor(object: { [id: string]: any }) {
        super()
        this.txt = new paper.PointText({
            ...object,
            fillColor: object.fillColor || object.strokeColor || new paper.Color('#000'),
            strokeColor: object.strokeColor || object.fillColor || new paper.Color('#000'),
            strokeWidth: null, //object.strokeWidth || 1,
            strokeScaling: false,
            fontFamily: object.fontFamily || 'Courier New',
            fontSize: object.fontSize,
            justification: object.justification,
        })
        this.outline = this.txt.clone()
        this.outline.strokeColor = new paper.Color('#fff')
        this.outline.strokeWidth = 30 //this.txt.strokeWidth + 20
        this.addChild(this.txt)
        this.outline.insertBelow(this.txt)
    }

    setRotation(r: number) {
        this.txt.rotation = r
        this.outline.rotation = r
    }

    set content(c: string) {
        this.txt.content = c
        this.outline.content = c
    }

    get content(): string {
        return this.txt.content
    }

    set point(p: paper.Point) {
        this.txt.point = p
        this.outline.point = p
    }

    get point(): paper.Point {
        return this.txt.point
    }

    clone(): this {
        const c = super.clone()
        c.removeChildren()
        c.txt = this.txt.clone()
        c.addChild(c.txt)
        c.outline = this.outline.clone()
        c.outline.insertBelow(c.txt)
        return c
    }
}
