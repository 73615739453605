<!-- SIGN IN -->
<div *ngIf="signInDialogVisible" class="flex flex-column shadow-4 mx-auto mt-3 w-full lg:w-6"
          (keydown.enter)="login('email')">
    <div class="px-4 py-6 w-full xl:w-30rem m-auto">
        <div class="text-center mb-5">
            <!--
                <img src="assets/images/blocks/logos/hyper.svg" alt="Image" height="50" class="mb-3">
                -->
                <div class="text-900 text-5xl font-medium mb-3" i18n>Zaloguj się</div>
        </div>

        <div class="flex justify-content-center"> <!-- justify-content-between -->
            <button pButton pRipple class="ml-2 w-8 py-3 px-2 align-items-center justify-content-center" (click)="login('google')">
                <i class="pi pi-google mr-2"></i>
                <span i18n>Użyj konta Google</span>
                <span class="p-ink"></span>
            </button>
            <!--
                <button pButton pRipple class="w-6 py-3 px-2 align-items-center justify-content-center" (click)="login('facebook')">
                    <i class="pi pi-facebook mr-2"></i>
                    <span>Użyj konta Facebook</span>
                    <span class="p-ink"></span>
                </button>

                <button pButton pRipple class="ml-2 w-6 py-3 px-2 align-items-center justify-content-center" (click)="login('microsoft')">
                    <i class="pi pi-microsoft mr-2"></i>
                    <span>Użyj konta Microsoft</span>
                    <span class="p-ink"></span>
                </button>
                <button pButton pRipple class="ml-2 w-6 py-3 px-2 align-items-center justify-content-center" (click)="login('twitter')">
                    <i class="pi pi-twitter mr-2"></i>
                    <span>Użyj konta Twitter</span>
                    <span class="p-ink"></span>
                </button>
                -->
        </div>

        <p-divider align="center">
            <span class="text-600 font-normal text-sm" i18n>albo</span>
        </p-divider>

        <div class="text-center">
            <span class="p-float-label py-3">
                <input id="email-login" type="email" pInputText [(ngModel)]="email" class="ng-dirty w-full"
                       [class.ng-invalid]="emailInvalid">
                <label for="email-login" i18n>Email</label>
            </span>

            <span class="p-float-label py-3 mb-3">
                <p-password id="password-login" class="ng-dirty" styleClass="w-full" inputStyleClass="w-full"
                            [(ngModel)]="password" [toggleMask]="true" [feedback]="false"
                            [class.ng-invalid]="!password">
                </p-password>
                <label for="password-login" i18n>Hasło</label>
            </span>

            <!--
                <div class="flex align-items-center justify-content-between mb-6">
                    <div class="flex align-items-center">
                        <p-checkbox id="rememberme1" [binary]="true" styleClass="mr-2"></p-checkbox>
                        <label for="rememberme1" class="text-900">Remember me</label>
                    </div>
                    <a class="font-medium no-underline ml-2 text-right cursor-pointer">Forgot password?</a>
                </div>
                -->

            <button
                pButton pRipple
                label="Zaloguj się" i18n-label
                icon="pi pi-user" class="w-12rem"
                [disabled]="emailInvalid || !password"
                (click)="login('email')"></button>
        </div>

        <div class="text-center text-xl mt-4 mb-6 flex flex-wrap">
            <div class="min-w-20rem flex-1">
                <span class="text-600 font-medium line-height-3" i18n>Nie pamiętasz hasła?</span>
                <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer" (click)="showPasswordResetDialog()" i18n>Zresetuj je.</a>
            </div>
            <div class="min-w-20rem flex-1">
                <span class="text-600 font-medium line-height-3" i18n>Nie masz jeszcze konta?</span>
                <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer" (click)="showRegisterDialog()" i18n>Załóż je teraz!</a>
            </div>
        </div>

        <div class="text-center mt-4">
            <a [href]="linkToTerms" target="blank" i18n>Warunki użytkowania</a>
            <a [href]="linkToPrivacy" class="pl-3" target="blank" i18n>Polityka prywatności</a>
        </div>
    </div>
</div>

<!-- SIGN UP -->
<div *ngIf="registerDialogVisible" class="flex flex-column shadow-4 p-4 mx-auto mt-3 w-full lg:w-6"
          (keydown.enter)="signUp()">
    <div class="w-full xl:w-30rem m-auto">
        <div class="py-3 text-center text-xl">
            <!--
            <img src="assets/images/blocks/logos/hyper.svg" alt="Image" height="50" class="mb-3">
            -->
            <div class="text-900 text-5xl font-medium mb-3" i18n>Zarejestruj się</div>
            <span class="text-600 font-medium line-height-3" i18n>Jednak masz konto?</span>
            <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer" (click)="showSignInDialog()" i18n>Zaloguj się teraz!</a>
        </div>

        <div class="py-3 flex justify-content-center"> <!-- justify-content-between -->
            <button pButton pRipple class="w-8 py-3 px-2 align-items-center justify-content-center" (click)="login('google')">
                <i class="pi pi-google mr-2"></i>
                <span i18n>Użyj konta Google</span>
                <span class="p-ink"></span>
            </button>
        </div>

        <p-divider align="center">
            <span class="text-600 font-normal text-sm" i18n>albo</span>
        </p-divider>

        <div>
            <span class="p-float-label py-3">
                <input id="email" type="email" pInputText [(ngModel)]="email" class="ng-dirty w-full"
                    [class.ng-invalid]="emailInvalid">
                <label for="email" i18n>Email</label>
            </span>
            <span class="p-float-label py-3">
                <p-password id="password" class="ng-dirty" styleClass="w-full mr-2" inputStyleClass="w-full"
                    [(ngModel)]="password" [toggleMask]="true" [feedback]="false"
                    [class.ng-invalid]="password.length < 8">
                </p-password>
                <label for="password" i18n>Hasło</label>
            </span>
            <span class="pl-2" i18n>Minimum 8 znaków.</span>
        </div>
    </div>

    <div class="flex align-items-center justify-content-center w-full xl:w-30rem m-auto">
        <div class="py-4 m-auto flex flex-column">
            <div class="flex py-1" tooltipPosition="top" i18n-pTooltip
                pTooltip="Informacje o aktualizacjach, akcjach serwisowych, wsparcie techniczne i inne wiadomości dotyczące parkour.design.">
                <p-checkbox inputId="newsletterConsent" [binary]="true" [(ngModel)]="newsletterConsent"></p-checkbox>
                <label class="px-2" for="newsletterConsent" i18n>Zgadzam się na otrzymywanie wiadomości email dotyczących parkour.design</label>
            </div>
            <div class="flex py-1" tooltipPosition="top" i18n-pTooltip
                pTooltip="Zgoda na warunki użytkowania i politykę prywatności jest niezbędna do rejestracji.">
                <p-checkbox inputId="termsConsent" [binary]="true" [(ngModel)]="termsConsent"></p-checkbox>
                <label class="px-2" for="termsConsent">
                    <span i18n>Zgadzam się na</span>
                    <span>&nbsp;</span>
                    <a class="underline" [href]="linkToTerms" target="_blank" i18n>warunki użytkowania</a>
                    <span>&nbsp;</span>
                    <span i18n>i</span>
                    <span>&nbsp;</span>
                    <a class="underline" [href]="linkToPrivacy" target="_blank" i18n>politykę prywatności</a>
                </label>
            </div>
        </div>
    </div>

    <div class="py-2 mb-4 w-full xl:w-30rem m-auto">
        <div class="text-center">
            <div class="w-full" *ngIf="signUpInProgress; else signUpButton">
                <p-progressBar styleClass="w-full" mode="indeterminate"></p-progressBar>
            </div>
            <ng-template #signUpButton>
                <button
                    pButton pRipple
                    label="Zarejestruj się" i18n-label
                    icon="pi pi-user" class="w-12rem"
                    [disabled]="emailInvalid || password.length < 8 || !termsConsent"
                    (click)="signUp()">
                </button>
            </ng-template>
        </div>
    </div>
</div>


<!-- PASSWORD RESET -->
<div *ngIf="passwordResetDialogVisible" class="flex shadow-4 mx-auto mt-3 w-full lg:w-6"
     (keydown.enter)="sendPasswordResetEmail()">
    <div class="px-4 py-6 w-full xl:w-30rem m-auto">
        <div class="text-center mb-4 line-height-3">
            <!--
                <img src="assets/images/blocks/logos/hyper.svg" alt="Image" height="50" class="mb-3">
                -->
                <div class="text-900 text-5xl font-medium mb-4" i18n>Zresetuj hasło</div>
                <div i18n>Problemy z logowaniem?</div>
                <div i18n>Podaj swój adres e-mail, a wyślemy Ci link do zresetowania hasła do Twojego konta.</div>
        </div>

        <div class="text-center">
            <span class="p-float-label py-3">
                <input id="email-reset" type="email" pInputText [(ngModel)]="email" class="ng-dirty w-full"
                       [class.ng-invalid]="emailInvalid">
                <label for="email-reset" i18n>Email</label>
            </span>

            <button
                pButton pRipple
                label="Wyślij" i18n-label
                icon="pi pi-send" class="w-8rem"
                [disabled]="emailInvalid"
                (click)="sendPasswordResetEmail()"></button>
        </div>

        <div class="text-center font-medium mt-4 text-xl">
            <span class="text-600" i18n>Pamiętasz hasło?</span>
            <a class="no-underline ml-2 text-blue-500 cursor-pointer" (click)="showSignInDialog()" i18n>Wróc do logowania.</a>
        </div>

        <div class="text-center mt-4">
            <a [href]="linkToTerms" target="blank" i18n>Warunki użytkowania</a>
            <a [href]="linkToPrivacy" class="pl-3" target="blank" i18n>Polityka prywatności</a>
        </div>
    </div>
</div>
