import paper from 'paper'
import { DesignObstacleObject, Direction } from "../../design.schema"
import { LayerId } from '../../parkour-canvas/parkour-canvas'
import { OutlinedText } from "../detail.paper.extensions"
import { SelectorKind } from "../detail.selectors"
import { UiCommandId } from "../detail.ui.commands.defs"
import { Warning } from "../detail.validator"
import { ParkourObjectConfig } from "./parkour-object"
import { PathObject } from "./path-object"


type ObstacleWarnings = {
    height: boolean
    width: boolean
    warnings: Warning[]
}

export abstract class Obstacle extends PathObject {
    linesOfSightGroup = new paper.Group()
    lineOfSight1: paper.Shape | undefined
    lineOfSight2: paper.Shape | undefined
    labelsPerDirection: paper.Group[] = [new paper.Group(), new paper.Group()] // labels per direction
    labelsPerRound: (paper.Group | undefined)[][] = [[undefined, undefined], [undefined, undefined]] // labels per round and per direction
    protected readonly preferredSelectorKind: SelectorKind = SelectorKind.CIRCLE
    warnings: ObstacleWarnings = {
        height: false,
        width: false,
        warnings: []
    }
    private _score: number = 0
    private _prevScore: number = 0
    scoreTxt?: OutlinedText
    noRouteLabel?: number
    manualLabels: string[]

    get score() {
        return this._score
    }

    set score(s: number) {
        this._prevScore = this._score
        this._score = s
    }

    constructor(protected config: ParkourObjectConfig) {
        super(config)
        const object = config.object
        if (object.angle === undefined) {
            this.angle = 90
        }
        this.canvas.getLayer(LayerId.UI_EXTRAS).addChild(this.linesOfSightGroup)
        this.linesOfSightGroup.sendToBack()

        this.allGroup.addChildren(this.labelsPerDirection)

        this.layer = object.layer || this.preferredLayer
        this.score = object.score || 0
        this.manualLabels = object.manualLabels || ['', '', '', '']
        this.loadObject()

        if (this.view && this.contextMenu) {
            const c = this.view.uiCommands
            this.contextMenu.add(
                c.getContextMenuItem(UiCommandId.EDIT_MATERIALS),
                c.getContextMenuItem(UiCommandId.CLEAR_MANUAL_LABELS),
                c.getContextMenuItem(UiCommandId.MAKE_COMBINATION),
            )
        }
    }

    fixPointsToLimits() {
        if (this.score > 200) {
            this.score = 200
        } else if (this.score > 120 && this.score < 200) {
            if (this._prevScore < this._score) {
                this.score = 200
            } else {
                this.score = 120
            }
        } else if (this.score < 10 && this.score > 0) {
            this.score = 10
        } else if (this.score < 0) {
            this.score = 0
        }
    }

    protected objectReady(success: boolean): void {
        if (this.isReady) {
            return
        }
        super.objectReady(success)
        if (success) {
            const cx = this.getPosition().x
            const cy = this.getPosition().y
            let size = this.getExternalSize()
            const width = size.width * 0.5
            const length = 1300

            // line of sight
            this.linesOfSightGroup.removeChildren()
            this.lineOfSight1 = new paper.Shape.Rectangle({
                point: [cx - width / 2, cy - length],
                size: [width, length],
                fillColor: '#aaa',
                opacity: 0.1,
            })
            this.lineOfSight1.rotate(this.angle, [cx, cy])
            this.lineOfSight2 = this.lineOfSight1.clone()
            this.lineOfSight1.rotate(-this.getEntryAngle(Direction.forward), [cx, cy])
            this.lineOfSight2.rotate(180 - this.getExitAngle(Direction.forward), [cx, cy])
            this.linesOfSightGroup.addChild(this.lineOfSight1)
            this.linesOfSightGroup.addChild(this.lineOfSight2)
            this.setArrowDirection(this.getArrowDirection(1), 1)
            this.setArrowDirection(this.getArrowDirection(2), 2)
        }
    }

    protected setNamePosition() {
        super.setNamePosition()
        if (this.nameText.content) {
            const name = this.nameText.bounds
            const gap = 25
            this.labelsPerDirection.forEach(l => {
                const label = l.bounds
                if (name.left <= label.right && name.right >= label.left &&
                    name.top <= label.bottom && name.bottom >= label.top) {
                    // name overlaps with label, move name to the side that has more
                    // free space over the obstacle shape
                    const p = this.getPosition()
                    if (label.center.x > p.x) {
                        // more space to the left of the label
                        this.nameText.position.x = label.left - name.width / 2 - gap
                    } else {
                        // more space to the right of the label
                        this.nameText.position.x = label.right + name.width / 2 + gap
                    }
                }
            })
        }
    }

    protected rotateGroups(angle: number, position: paper.Point): void {
        super.rotateGroups(angle, position)
        this.linesOfSightGroup.rotate(angle, position)
    }

    reset() {
        super.reset()
        this.resetWarnings()
        this._setScorePosition()
        this.clearLabels()
    }

    resetWarnings() {
        this.warnings.height = false
        this.warnings.width = false
        this.warnings.warnings = []
    }

    protected _move(delta: paper.Point) {
        super._move(delta)
        this.linesOfSightGroup.translate(delta)
    }

    destroy(): void {
        super.destroy()
        if (this.linesOfSightGroup.isInserted()) {
            this.linesOfSightGroup.remove()
        }
    }

    isObstacle() {
        return true
    }

    doubleClick(point?: paper.Point): boolean {

        // when clicked on the obstacle, open editing of the first label on the round or first label if no round
        if (this.useCountAll === 0) {
            let i = this.manualLabels.findIndex(l => l)
            if (i < 0) {
                i = 0
            }
            this.view?.objectPanelEl?.selectLabelBox(Math.floor(i / 2) + 1, (i % 2) + 1)
        } else {
            this.view?.objectPanelEl?.selectLabelBox(this.useCount[0] === 0 && this.useCount[1] > 0 ? 2 : 1, 1)
        }
        const ret = super.doubleClick(point)
        if (!point) {
            return ret
        }
        this.labelsPerRound.forEach((passes, roundNo) => {
            for (let pass = 0; pass < passes.length; pass++) {
                if (passes[pass]?.contains(point)) {
                    this.view?.objectPanelEl?.selectLabelBox(roundNo + 1, pass + 1)
                    break
                }
            }
        })
        return ret
    }

    private _getLabelPosition(group: paper.Group, direction: Direction): paper.Point {
        let p = this.externalFrame.segments[direction == Direction.backward ? 1 : 3].point
        let d = p.subtract(this.getPosition())
        d.length = group.bounds.width > 120 ? (group.bounds.width - 120) / 2 : 0
        return p.add(d)
    }

    getManualLabel(round: number, pass: number): string {
        if (round < 1 || round > 2 || pass < 1 || pass > 2) {
            return ''
        }
        return this.manualLabels[(round - 1) * 2 + pass - 1]
    }

    clearManualLabels(): boolean {
        const empty = this.manualLabels.every(l => !l)
        if (empty) {
            return false
        }
        this.manualLabels = ['', '', '', '']
        return true
    }

    addLabel(label: string, roundNo: number, direction: Direction, pass: number) {
        if (roundNo < 1 || roundNo > 2) {
            return
        }
        const manualLabel = this.getManualLabel(roundNo, pass)
        if (!manualLabel && !label) {
            return
        }
        let labelColor = this.cfg.params.colors.objectLabel
        let fillColor = this.cfg.params.colors.objectLabelFill
        if (roundNo === 2) {
            labelColor = this.cfg.params.colors.objectLabel2 || '#f88'
            fillColor = this.cfg.params.colors.objectLabel2Fill
        }

        let txt = new paper.PointText({
            content: manualLabel || label,
            fillColor: labelColor,
            fontFamily: 'Courier New',
            fontSize: this.cfg.getLabelFontSize()
        })
        const w = txt.strokeBounds.width
        const h = txt.strokeBounds.height
        txt.translate([0, h / 4])

        const radius = Math.sqrt(w * w / 4 + h * h / 4)
        let c
        if (roundNo === 1) {
            c = new paper.Shape.Circle({
                radius: radius,
                fillColor: fillColor,
                strokeColor: labelColor,
                strokeWidth: this.cfg.params.lineWidth,
                strokeScaling: false
            })
        } else {
            c = new paper.Shape.Rectangle({
                size: [radius * 2, radius * 2],
                fillColor: fillColor,
                strokeColor: labelColor,
                strokeWidth: this.cfg.params.lineWidth,
                strokeScaling: false
            })
        }

        const group = this.labelsPerDirection[direction === Direction.forward ? 0 : 1]
        c.position = txt.position = group.isEmpty() ? new paper.Point(0, 0) :
            group.bounds.rightCenter.add([c.bounds.width / 2 + 25, 0])
        
        const lgroup = new paper.Group([c, txt])
        group.addChild(lgroup)
        group.position = this._getLabelPosition(group, direction)
        group.bringToFront()
        this.labelsPerRound[roundNo - 1][pass - 1] = lgroup
        this._setNoRouteLabelPosition()
    }

    drawExtraElements(): void {
        super.drawExtraElements()
        if (this.cfg.isScoreAllowed()) {
            if (!this.scoreTxt) {
                this.scoreTxt = new OutlinedText({
                    strokeColor: '#000',
                    fontFamily: 'Roboto',
                    fontSize: this.cfg.getFontSize()
                })
                this.allGroup.addChild(this.scoreTxt)
            }
            this._setScorePosition()
        }
    }

    private _setScorePosition() {
        if (this.scoreTxt) {
            if (this.score) {
                this.scoreTxt.content = this.score.toFixed(0)
            } else {
                this.scoreTxt.content = ''
            }
            const dy = Math.max(this.objectSize.width, this.objectSize.height)
            this.scoreTxt.position = this.getPosition().add([0, dy * 0.75])
            this.scoreTxt.setRotation(0)
        }
    }

    private _setNoRouteLabelPosition() {
        if (this.cfg.isNoRouteMode()) {
            const pos = this.getPosition()
            const g: paper.Group = this.labelsPerDirection[0]
            if (g.position.y > pos.y) {
                // move label to the other side of the obstacle center to
                // avoid collision with points
                g.translate(pos.subtract(g.position).multiply(2))
            }
        }
    }

    rotationDone() {
        super.rotationDone()
        this._setNoRouteLabelPosition()
    }

    clearLabels() {
        this.labelsPerDirection.forEach(l => l.removeChildren())
        this.labelsPerRound = [[undefined, undefined], [undefined, undefined]]
        this.noRouteLabel = undefined
    }

    setLabelsVisibility(visible: boolean, round?: number) {
        if (round === undefined) {
            this.labelsPerDirection.forEach(l => l.visible = visible)
        } else if (round >= 1 && round <= 2) {
            this.labelsPerDirection.forEach(l => l.visible = true)
            this.labelsPerRound[round - 1].forEach(l => {
                if (l) {
                    l.visible = visible
                }
            })
        }
    }

    createSelectionGfx() {
        super.createSelectionGfx()
        this.linesOfSightGroup.visible = this.selected && this.cfg.params.showLinesOfSight
    }

    select(point?: paper.Point): void {
        super.select(point)
        this.linesOfSightGroup.visible = this.cfg.params.showLinesOfSight
    }

    deselect(): void {
        super.deselect()
        this.linesOfSightGroup.visible = false
    }

    setArrowDirection(direction: Direction, roundNo?: number): void {
        super.setArrowDirection(direction, roundNo)
        direction = this.getArrowDirection()
        if (this.lineOfSight1 && this.lineOfSight2) {
            switch (direction) {
                case Direction.none:
                    // for disconnected obstacles show lines of sight in both directions
                    this.lineOfSight1.visible = true
                    this.lineOfSight2.visible = true
                    break
                case Direction.forward:
                    this.lineOfSight1.visible = true
                    this.lineOfSight2.visible = false
                    break
                case Direction.backward:
                    this.lineOfSight1.visible = false
                    this.lineOfSight2.visible = true
                    break
                case Direction.both:
                    this.lineOfSight1.visible = true
                    this.lineOfSight2.visible = true
                    break
            }
        }
    }

    contains(point: paper.Point): boolean {
        let r = super.contains(point)
        for (let i = 0; !r && i < this.labelsPerDirection.length; i++) {
            r = this.labelsPerDirection[i].contains(point)
        }
        return r
    }

    toJson(): DesignObstacleObject {
        return {
            ...super.toJson(),
            score: this.score || 0,
            manualLabels: this.manualLabels
        } as DesignObstacleObject
    }
}
