import { DecimalPipe } from '@angular/common'
import { Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { metersToFeet } from './utils'

@Pipe({
    name: 'safe'
})
export class SafePipe implements PipeTransform {

    constructor(protected sanitizer: DomSanitizer) {}

    transform(value: any, args: any): any  {
        switch (args) {
        case 'html': return this.sanitizer.bypassSecurityTrustHtml(value);
        case 'style': return this.sanitizer.bypassSecurityTrustStyle(value);
        case 'script': return this.sanitizer.bypassSecurityTrustScript(value);
        case 'url': return this.sanitizer.bypassSecurityTrustUrl(value);
        case 'resourceUrl': return this.sanitizer.bypassSecurityTrustResourceUrl(value);
        default: console.log('Invalid safe type specified');
        }
    }
}

@Pipe({
    name: 'length',
    standalone: true
})
export class LengthPipe implements PipeTransform {

    private format(v: number, rounding?: number, precision?: number): string {
        let x = 0
        if (v < 0) {
            x = 0
        } else if (rounding === 1 || !rounding) {
            x = v
        } else {
            x = Math.round(v / rounding)
            x = x * rounding
        }
        return x === 0 ? '0' : x.toFixed(precision)
    }

    transform(value: string | number, unit: string, roundTo5M?: boolean, precision?: number, precisionFt?: number, skipUnit?: boolean): string | undefined {
        const v = (+value) / 100
        let t = ''
        if (unit.startsWith('ft-')) {
            t = this.format(metersToFeet(v), 0, precisionFt !== undefined ? precisionFt : (precision !== undefined ? precision : 2))
        } else if (unit.startsWith('m-')) {
            t = this.format(v, roundTo5M ? 5 : 0, precision !== undefined ? precision : 0)
        }
        if (t && !skipUnit) {
            switch(unit) {
                case 'ft-ft':
                    t += ' ft'
                    break
                case 'ft-sign':
                    t += '\''
                    break
                case 'm-m':
                default:
                    t += ' m'
                    break
            }
        }
        return t || undefined
    }

    static getUnit(unit: string): string {
        if (unit.startsWith('ft-')) {
            return 'ft'
        } else if (unit.startsWith('m-')) {
            return 'm'
        }
        return '?'
    }
}
