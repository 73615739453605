import { Injectable } from '@angular/core';

import { User } from '@angular/fire/auth';
import { BehaviorSubject, Observable, Subscription, combineLatest, filter, map, distinctUntilChanged } from 'rxjs'

import posthog from 'posthog-js'

import { AuthService } from './auth.service';
import { StorageService } from './storage.service';
import { StripeService } from './stripe.service';
import { BrevoService } from './brevo.service';
import { PdfOption, SorterSchema, SubscriptionLevel, UserRole } from '../design.schema';
import { ShortcutStore } from '../detail/detail.ui.shortcuts';

export type UserFeatures = {
    obstacleLimit: number,
    customImagesAndLogos: boolean,
    saveToPDFAndPrint: boolean,
    materialsEditor: boolean,
    printMasterPlanAndTables: boolean,
    workOffline: boolean,
    parkourDesignLabelChangable: boolean,
}

export type DesignDisplayOptions = { [id: string]: any }
export type PdfOptions = { [key in PdfOption]? : boolean }

export type UserProfile = {
    version?: number,
    uid?: string,
    name?: string,
    createdAt?: string,
    updatedAt?: string,
    lastLoginAt?: string,
    planSelected?: boolean,
    designDisplayOptions?: DesignDisplayOptions,
    shortcuts?: ShortcutStore[],
    undoLimit?: number,
    email?: string | null,
    attentionForStart?: boolean,
    photoURL?: string | null,
    subscriptionLevel?: string,
    role?: UserRole,
    newsletterConsent?: boolean,
    termsConsent?: boolean,
    testerPlan?: string,
    compDisplayMode?: number,
    compSorter?: SorterSchema,
    eventSorter?: SorterSchema,
    compSorterForAllEvents?: SorterSchema,
    pdfOptions?: PdfOptions,
}

@Injectable({
    providedIn: 'root'
})
export class UserService {
    static readonly userProfileDefaults: UserProfile = {
        undoLimit: 20,
        newsletterConsent: true,
        pdfOptions: {
            [PdfOption.MAIN_DESIGN]: true
        }
    }

    // current version of the user profile scheme
    readonly currentVersion = 1
    private user?: User
    private userProfile: UserProfile | undefined | null = null
    /**
     *  userProfileSubject:
     *  - UserProfile object when user is logged in and user profile exists;
     *  - undefined when user is logged in but user profile does not exist yet;
     *  - null when user is is not logged in.
     */
    private userProfileSubject = new BehaviorSubject<UserProfile | undefined | null>(null)

    private getUserProfileSub?: Subscription

    readonly levels: { [id: string]: UserFeatures } = {
        [SubscriptionLevel.STARTER]: {
            obstacleLimit: 10,
            customImagesAndLogos: false,
            saveToPDFAndPrint: false,
            materialsEditor: false,
            printMasterPlanAndTables: false,
            workOffline: false,
            parkourDesignLabelChangable: false,
        },
        [SubscriptionLevel.ADVANCED]: {
            obstacleLimit: 100,
            customImagesAndLogos: true,
            saveToPDFAndPrint: true,
            materialsEditor: false,
            printMasterPlanAndTables: false,
            workOffline: false,
            parkourDesignLabelChangable: false,
        },
        [SubscriptionLevel.MASTERS]: {
            obstacleLimit: 100,
            customImagesAndLogos: true,
            saveToPDFAndPrint: true,
            materialsEditor: true,
            printMasterPlanAndTables: true,
            workOffline: true,
            parkourDesignLabelChangable: true,
        },
        [SubscriptionLevel.FULL]: {
            obstacleLimit: 100,
            customImagesAndLogos: true,
            saveToPDFAndPrint: true,
            materialsEditor: true,
            printMasterPlanAndTables: true,
            workOffline: true,
            parkourDesignLabelChangable: true,
        }
    }

    constructor(
        private store: StorageService,
        private auth: AuthService,
        protected stripe: StripeService,
        private brevoService: BrevoService,
    ) {
        this.auth.remoteUser.subscribe({
            next: (user: User | null) => {
                if (user) {
                    this.user = user
                    this.userProfile = undefined
                    this.userProfileSubject.next(this.userProfile)
                    this._getUserProfile()
                } else {
                    // reset profile after logout
                    this.userProfile = null
                    this.userProfileSubject.next(this.userProfile)
                }
            },
            error: (err) => {
                console.error('error occured', err)
            }
        })
        this._getUserProfile()
    }

    updateUserProfile(userProfilePatch: UserProfile) {
        if (!this.userProfile) {
            this.userProfile = {}
        }
        Object.assign(this.userProfile, userProfilePatch)
        this.userProfile.version = this.currentVersion
        const p: any = this.userProfile as any
        if (p.dismissedTips) {
            delete p.dismissedTips
        }
        if (p.dissmissedTips) {
            delete p.dissmissedTips
        }
        if (p.toolTips) {
            delete p.toolTips
        }
        return this.store.setUserProfile(this.userProfile).pipe(
            map(up => {
                this.userProfileSubject.next(this.userProfile)
            })
        )
    }

    private _setUserProfile(patch: UserProfile) {
        this.updateUserProfile(patch).subscribe({
            next: () => {
            },
            error: (err) => {
                console.error('error occured', err)
            }
        })
    }

    private _getUserProfile() {
        if (this.getUserProfileSub) {
            this.getUserProfileSub.unsubscribe()
        }
        this.getUserProfileSub = this.store.getUserProfile().subscribe({
            next: (userProfile) => {
                if (userProfile) {
                    this.userProfile = userProfile
                } else {
                    userProfile = this.userProfile = {}
                }

                const patch: UserProfile = {}
                if (!userProfile.email) {
                    patch.email = this.user!.email
                    patch.photoURL = this.user!.photoURL
                }
                if (this.user!.metadata.creationTime && this.userProfile?.createdAt !== this.user!.metadata.creationTime) {
                    patch.createdAt = this.user!.metadata.creationTime
                }
                if (this.user!.metadata.lastSignInTime && this.userProfile?.lastLoginAt !== this.user!.metadata.lastSignInTime) {
                    patch.lastLoginAt = this.user!.metadata.lastSignInTime
                }
                // set defaults for undefined user profile fields
                for (let i of Object.keys(UserService.userProfileDefaults)) {
                    if (!userProfile.hasOwnProperty(i)) {
                        (patch as any)[i] = (UserService.userProfileDefaults as any)[i]
                    }
                }
                if (Object.keys(patch).length > 0) {
                    this._setUserProfile(patch)
                } else {
                    this.userProfileSubject.next(this.userProfile)
                }
            },
            error: (err) => {
                console.error('error occured', err)
            }
        })
    }

    /**
     * Returns user profile.
     *
     * @returns UserProfile object is returned when user is logged in and user profile exists;
     *   undefined is returned when user is logged in but user profile does not exist yet;
     *   null is returned when user is is not logged in.
     */
    getUserProfile(): Observable<UserProfile | null | undefined> {
        return this.userProfileSubject.pipe(distinctUntilChanged())
    }

    getStartingFeatures(): UserFeatures {
        return this.levels[SubscriptionLevel.STARTER]
    }

    getFeatures(): Observable<UserFeatures> {
        return this.stripe.getSubscriptionPlan().pipe(
            map(plan => {
                let planName: SubscriptionLevel = SubscriptionLevel.STARTER
                if ((plan?.name == 'advanced') ||
                    this.userProfile?.testerPlan == 'advanced') {
                    planName = SubscriptionLevel.ADVANCED
                } else if ((plan?.name == 'masters') ||
                    this.userProfile?.testerPlan == 'masters') {
                    planName = SubscriptionLevel.MASTERS
                }

                let feats = this.levels[planName]

                if (plan) {
                    if (this.userProfile && !this.userProfile.planSelected) {
                        this.userProfile.planSelected = true
                        this._setUserProfile({ planSelected: true })
                        this.userProfileSubject.next(this.userProfile)
                    }
                }

                if (this.userProfile?.email) {
                    this.brevoService.updateUserPlan(this.userProfile.email, planName)
                }
                posthog.setPersonProperties({
                    pd_plan: planName,
                })

                return feats
            })
        )
    }

    isPlanSelected(): Observable<boolean> {
        return combineLatest(this.stripe.getSubscriptionPlan(), this.userProfileSubject).pipe(
            filter(([plan, profile]: any[]) => (profile && plan)),
            map(([plan, profile]: any[]) => {
                if (profile.planSelected || profile.testerPlan) {
                    return true
                }
                if (plan.name !== 'starter') {
                    return true
                }
                return false
            })
        )
    }
}
