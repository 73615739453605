import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from "@sentry/angular-ivy";
import posthog from 'posthog-js'

import { AppModule } from './app/app.module';
import { APP_CONFIG } from './environments/environment';
import { getBuildVersion } from './app/utils';

function initSentry(env: string, tracesSampleRate: number) {
    console.info('sentry enabled')
    Sentry.init({
        dsn: "https://8276b88e34333d0d2fc7c334d744a7a1@o191849.ingest.sentry.io/4505860837867520",
        environment: env,
        release: getBuildVersion(),
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ["localhost", /^https:\/\/parkour\.design\//],
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
            new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: tracesSampleRate, // 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

if (APP_CONFIG.production) {
    enableProdMode();
    initSentry('production', 0.1)
    posthog.init('phc_szXMF9obVJMJAzfkjYpOwZz0jrCSorYG0Xxpzdxflkw', { api_host: 'https://eu.posthog.com' })
    console.info('posthog enabled')
} else {
    //initSentry('development', 1.0)
    console.info('sentry disabled')
    console.info('posthog disabled')
}

platformBrowserDynamic()
    .bootstrapModule(AppModule, {
        preserveWhitespaces: false
    })
    .catch(err => console.error(err));
