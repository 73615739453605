import paper from 'paper'
import { DesignUserImageObject } from "../../design.schema"
import { showErrorBox } from '../../utils'
import { SelectorKind } from '../detail.selectors'
import { UiCommandId } from '../detail.ui.commands.defs'
import { ParkourObject, ParkourObjectConfig } from "./parkour-object"
import { LayerId } from '../../parkour-canvas/parkour-canvas'

export class UserImage extends ParkourObject {
    image?: paper.Raster
    imageData: any
    defaultImage: boolean = true
    minSize: paper.Size = new paper.Size(1500, 1500)
    imageSize?: paper.Size
    border: boolean
    imageId: string
    imageFormat: string
    specialId: string
    readonly sizeLimitMB: number = 5
    locked: boolean = false
    protected readonly snapRotation: boolean = true
    protected canRotate: boolean = true
    protected canMove: boolean = true
    protected canResize: boolean = true      // user can resize object
    protected readonly preferredSelectorKind: SelectorKind = SelectorKind.RECTANGLE
    protected readonly preferredLayer: LayerId = LayerId.FRONT
    protected readonly configurableLayer: boolean = true

    constructor(protected config: ParkourObjectConfig) {
        super(config)
        const object = config.object
        this.imageId = object.imageId || 'default'
        this.imageFormat = object.imageFormat || 'media/png'
        this.layer = object.layer || this.preferredLayer
        this.objectSize.height = 0
        this.objectSize.width = 0
        if (object.imageSize) {
            this.imageSize = new paper.Size(object.imageSize[0], object.imageSize[1])
        }
        this.layer = object.layer || this.preferredLayer
        this.border = object.border || false
        this.locked = object.locked || false
        this.canResize = !this.locked
        this.canMove = this.canRotate = !this.locked
        this.specialId = object.specialId || ''
        if (object.imageData) {
            // handle legacy embedded image
            this._loadImage(object.imageData, false, this.imageSize)
            this.defaultImage = false

            // now save image separatelly
            const startIdx = object.imageData.indexOf(":") + 1
            const endIdx = object.imageData.indexOf(";")
            this.imageFormat = object.imageData.substring(startIdx, endIdx)
            this.store.saveUserImage(this.imageId, this.imageFormat, null, object.imageData, null).subscribe({
                next: () => {
                    if (!this.canvas.paper) {
                        return
                    }
                    this.view?.saveData()
                },
                error: (err) => {
                    if (!this.canvas.paper) {
                        return
                    }
                    console.error('error occured', err)
                }
            })
        } else if (object.imageId && object.imageId != 'default') {
            this._loadImageFromStore()
            this.defaultImage = false
        } else {
            this._loadImage('assets/logo-placeholder.svg', false, this.imageSize)
            this.imageData = undefined
        }
        if (this.view && this.contextMenu) {
            this.contextMenu.add(this.view.uiCommands.getContextMenuItem(UiCommandId.IMAGE_SELECT))
        }
    }

    getExternalSize(): paper.Size {
        return this.imageSize || this.minSize
    }

    scaleObject(scale: number): boolean {
        if (this.locked) {
            return false
        }
        this._scale(scale)
        return true
    }

    doLockUnlock() {
        if (this.locked) {
            this.canResize = this.canMove = this.canRotate = false
        } else {
            this.canResize = this.canMove = this.canRotate = true
        }
        this.createSelectionGfx()
    }

    setSize(size: paper.Size) {
        if (this.imageSize) {
            if (this.imageSize.width > this.imageSize.height) {
                this._scale(size.width / this.imageSize.width)
            } else {
                this._scale(size.height / this.imageSize.height)
            }
            this.createSelectionGfx()
        }
    }

    drawExtraElements(): void {
        this.configExternalFrame(this.border)
    }

    doubleClick(): boolean {
        this.view?.openImageSelectTab(false)
        return false
    }

    protected _scale(factor: number) {
        if (this.imageSize) {
            this.imageSize = this.imageSize.multiply(factor)
            if (this.image) {
                // Raster.scale produces pixelized images, it depends on browser canvas capability
                this.image.scale(factor)
                // Other scaling method by setting size - looses quality with scale down/up
                //this.image.size = this.imageSize
            }
        }
    }

    private _loadImageFromStore() {
        this.subs.add(
            this.store.loadUserImage(this.imageId, this.cfg.params.origAuthor).subscribe({
                next: (dataUrl: string | undefined) => {
                    if (!this.canvas.paper) {
                        return
                    }
                    if (dataUrl) {
                        this._loadImage(dataUrl, false, this.imageSize)
                    } else {
                        console.error('error while loading user image')
                        if (this.view) {
                            showErrorBox(this.view.msgSvc, $localize`Błąd przy ładowaniu obrazka`, $localize`Wystąpił nieznany błąd`)
                        }
                        this.objectReady(false)
                    }
                },
                error: (err) => {
                    if (!this.canvas.paper) {
                        return
                    }
                    console.error('error occured', err)
                    if (this.view) {
                        showErrorBox(this.view.msgSvc, $localize`Błąd przy ładowaniu obrazka`, $localize`Wystąpił nieznany błąd`)
                    }
                    this.objectReady(false)
                }
            })
        )
    }

    private _loadImage(imageData: any, replacing: boolean, size?: paper.Size) {
        let position = this.initialPos
        if (this.image) {
            position = this.image.position
        }
        const image = new paper.Raster({
            crossOrigin: 'anonymous',
            source: imageData,
            position: position
        })
        image.on('load', () => {
            if (!this.canvas.paper) {
                return
            }
            const oldImage = this.image
            this.image = image
            this.imageData = imageData
            if (!size) {
                this.imageSize = this.image.size
                if (this.image.size.width * this.image.scaling.x < this.minSize.width) {
                    this._scale(this.minSize.width / (this.image.size.width * this.image.scaling.x))
                }
                if (this.image.size.height * this.image.scaling.y < this.minSize.height) {
                    this._scale(this.minSize.height / (this.image.size.height * this.image.scaling.y))
                }
            } else {
                this.image.size = size
                this.imageSize = size
            }
            this.image.position = this.getPosition()
            if (oldImage) {
                oldImage.replaceWith(this.image)
            } else {
                this.allGroup.addChild(this.image)
            }
            if (replacing) {
                this.angle = 0
                this.view?.saveData()
            }
            this.objectReady(true)
        })
        image.on('error', () => {
            if (!this.canvas.paper) {
                return
            }
            if (this.view) {
                showErrorBox(this.view.msgSvc, $localize`Błąd przy ładowaniu obrazka`, $localize`Błąd tworzenia obrazka`)
            }
            if (!replacing) {
                this.objectReady(false)
            }
        })
    }

    destroy(): void {
        super.destroy()
        //        this.view.store.deleteUserImage(this.imageId)
    }

    toJson(): DesignUserImageObject {
        return {
            ...super.toJson(),
            imageId: this.imageId,
            imageFormat: this.imageFormat,
            imageSize: this.imageSize ? [this.imageSize.width, this.imageSize.height] : null,
            border: this.border,
            locked: this.locked,
            specialId: this.specialId
        } as DesignUserImageObject
    }
}
