import paper from 'paper'

import { DesignBannerObject } from "../../design.schema"
import { ClassNo, ObstacleHeights } from "../../services/limits.service"
import { dateToLocalizedString, DynamicValue, justifyStrings } from '../../utils'
import { LayerId } from '../../parkour-canvas/parkour-canvas'
import { SecondRoundType } from '../detail.path'
import { SelectorKind } from "../detail.selectors"
import { ParkourDialogId } from '../dialogs/detail.dialog.interface'
import { ParkourObject, ParkourObjectConfig } from "./parkour-object"
import { LengthPipe } from '../../pipes'
//
// How to build a banner:
// - constructor()
//     - create the structure of the banner
// - build()
//     - create the paper.js objects of the groups in banner (all are in point [0, 0])
//     - should be called when all values or labels need updating
//     - destroys and creates all paper.js objects
//     - calls layout() at the end
// - layout()
//     - establish the positions and sizes of the paper.js objects
//     - called by build() and can be called individually to reposition objects
// - update()
//     - update dynamic fields only and layout banner
//
//

type GroupParameters = {
    fontSize?: DynamicValue<number>,
    fontFactor?: DynamicValue<number>,
    fontWeight?: DynamicValue<string>,
    fontFamily?: DynamicValue<string>,
    fillColor?: DynamicValue<string>,
    horizontalGapMin?: number,
    horizontalGapMax?: number,
    verticalGapMin?: number,
    verticalGapMax?: number,
    fieldValueGap?: number,
    displayEmpty?: DynamicValue<boolean>,
    horizontalWidthMax?: number,
    alignment?: DynamicValue<GroupAlignment>,
    layout?: Orientation,
}

type GroupDefinition = {
    when?: DynamicValue<boolean>
    params?: GroupParameters
    children?: GroupDefinition[]
    label?: DynamicValue<string>,
    value?: () => string,
}

enum GroupAlignment {
    LEFT = 1,
    RIGHT,
    CENTER,
    STRETCH,
    LABEL_EDGE
}

export enum Orientation {
    HORIZONTAL = 1,
    VERTICAL
}

function _getValue<T>(str: DynamicValue<T>): T {
    if (str instanceof Function) {
        return str()
    }
    return str
}

function _getFontSize(params?: GroupParameters): number {
    return _getValue(params?.fontSize || 0) * (_getValue(params?.fontFactor || 1))
}

class TableGroup implements GroupDefinition {
    when?: () => boolean
    label?: DynamicValue<string>
    value?: () => string
    params?: GroupParameters
    levelToBottom: number = 0
    maxLabelWidth: number = 0

    readonly group: paper.Group = new paper.Group()
    labelItem?: paper.PointText
    valueItem?: paper.PointText

    children: TableGroup[] = []

    get activeChildren(): number {
        return this.children.reduce((v, c) => v + (c.visible ? 1 : 0), 0)
    }

    get labelWidth(): number {
        return this.group?.visible && this.labelItem ? this.labelItem.internalBounds.width : 0
    }

    get verticalGapMinPx(): number {
        return (this.params?.verticalGapMin || 0) * _getFontSize(this.params)
    }

    get horizontalGapMinPx(): number {
        return (this.params?.horizontalGapMin || 0) * _getFontSize(this.params)
    }

    get fieldValueGapPx(): number {
        return (this.params?.fieldValueGap || 0) * _getFontSize(this.params)
    }

    get alignment(): GroupAlignment {
        return _getValue(this.params?.alignment) || GroupAlignment.CENTER
    }

    set leftTop(point: paper.Point) {
        if (this.group) {
            this.group.position = point.add(this.bounds.size.divide(2))
        }
    }

    set rightTop(point: paper.Point) {
        if (this.group) {
            this.group.position = point.add([-this.width / 2, this.height / 2])
        }
    }

    get center(): paper.Point {
        return this.group.position
    }

    set center(point: paper.Point) {
        if (this.group) {
            this.group.position = point
        }
    }

    get visible(): boolean {
        return this.group && this.group.visible ? true : false
    }

    set visible(visible: boolean) {
        if (this.group) {
            this.group.visible = visible
        }
    }

    private get bounds(): paper.Rectangle {
        return this.visible ? this.group.internalBounds : new paper.Rectangle(0, 0, 0, 0)
    }

    get height(): number {
        return this.bounds.height
    }

    get width(): number {
        return this.bounds.width
    }

    contains(point: paper.Point): boolean {
        if (this.group && this.visible && point.isInside(this.group.bounds)) {
            return true
        }
        return false
    }

    private _isLabelVisible(): boolean {
        if (this.labelItem && this.valueItem) {
            if (_getValue(this.params?.displayEmpty)) {
                return this.labelItem.content != '' || this.valueItem.content != ''
            }
            return this.valueItem.content != ''
        }
        return false
    }

    build() {
        this.levelToBottom = 0
        this.maxLabelWidth = -1
        this.group.removeChildren()
        this.children.forEach(c => {
            c.build()
            this.levelToBottom = Math.max(c.levelToBottom + 1, this.levelToBottom)
            this.maxLabelWidth = Math.max(c.maxLabelWidth, this.maxLabelWidth)
            this.group?.addChild(c.group)
        })
        if (this.label && this.value) {
            const fontStyle = {
                fontWeight: _getValue(this.params?.fontWeight),
                fontFamily: _getValue(this.params?.fontFamily),
                fillColor: _getValue(this.params?.fillColor)
            }
            const label = _getValue(this.label)
            const item = new paper.PointText({
                ...fontStyle,
                fontSize: _getFontSize(this.params),
                point: [0, 0],
                content: label ? label + ':' : ''
            })
            this.group.addChild(item)
            this.labelItem = item

            const value = new paper.PointText({
                ...fontStyle,
                fontSize: _getFontSize(this.params),
                point: [0, 0],
                content: _getValue(this.value)
            })
            this.group.addChild(value)
            this.valueItem = value

            this.visible = this._isLabelVisible()
            if (this.visible) {
                this.maxLabelWidth = this.labelItem.internalBounds.width
            } else {
                this.maxLabelWidth = 0
            }
        }
        this.group?.bringToFront()
    }

    clear() {
        if (this.group && this.group.isInserted()) {
            this.group.remove()
        }
    }

    layoutVertical() {
        // layout all children in this group top-down
        let point = new paper.Point(0, 0)
        this.children.forEach(f => {
            if (f.visible) {
                if (this.alignment === GroupAlignment.LABEL_EDGE) {
                    if (f.maxLabelWidth >= 0) {
                        f.leftTop = point.subtract([f.maxLabelWidth, 0])
                    } else {
                        f.rightTop = point
                    }
                } else if (this.alignment === GroupAlignment.CENTER) {
                    f.center = point.add([0, f.height / 2])
                } else if (this.alignment === GroupAlignment.LEFT) {
                    f.leftTop = point
                } else if (this.alignment === GroupAlignment.RIGHT) {
                    f.rightTop = point
                }
                point = point.add([0, f.height + this.verticalGapMinPx])
            }
        })
    }

    layoutHorizontal() {
        // layout all children in this group left-right
        let point = new paper.Point(0, 0)
        this.children.forEach(f => {
            f.leftTop = point
            if (f.visible) {
                point = point.add([f.bounds.width + this.horizontalGapMinPx, 0])
            }
        })
    }

    layout(layout: Orientation) {
        if (this.when && !this.when()) {
            this.visible = false
            return
        }

        if (this.valueItem && this.labelItem) {
            const point = new paper.Point(0, 0)
            this.labelItem.point = point
            let width = this.labelItem.internalBounds.width
            this.valueItem.point = point.add([(width > 0 ? width + this.fieldValueGapPx : 0), 0])
            return
        }

        this.visible = true
        let anything = false
        this.children?.forEach(c => {
            c.layout(layout === Orientation.VERTICAL ? Orientation.HORIZONTAL : Orientation.VERTICAL)
            anything ||= c.visible
        })
        if (!anything) {
            this.visible = false
            return
        }

        if (this.params && this.params.layout) {
            layout = this.params.layout
        }
        if (layout === Orientation.VERTICAL) {
            this.layoutVertical()
        } else {
            this.layoutHorizontal()
        }
    }

    constructor(group: GroupDefinition) {
        if (group.hasOwnProperty('when')) {
            if (group.when instanceof Function) {
                this.when = group.when
            } else {
                if (!this.when) {
                    return
                }
            }
        }

        if (!group.children || !group.children.length) {
            this.label = group.label
            this.value = group.value
        }

        this.params = {
            fontSize: 100,
            fontFactor: 1,
            fontWeight: 'normal',
            fontFamily: 'Roboto',
            fillColor: '#000',
            horizontalGapMin: 0,
            horizontalGapMax: 0,
            verticalGapMin: 0,
            verticalGapMax: 0,
            fieldValueGap: 0,
            horizontalWidthMax: 1000,
            alignment: GroupAlignment.CENTER,
            ...group.params
        }

        group.children?.forEach(c => this.children.push(new TableGroup({
            ...c,
            params: { ...this.params, ...c.params }
        })))
    }
}

type BannerVisibility = {
    title: boolean,
    subtitle: boolean,
    table: boolean,
    combinations: boolean,
}

export class ParkourBannerObject extends ParkourObject {
    private static _lengthPipe: LengthPipe = new LengthPipe()
    private _unit: string

    bannerDef: GroupDefinition = {
        params: {
            horizontalGapMin: 3,
            horizontalGapMax: 10,
            horizontalWidthMax: this.canvas.field.width,
            verticalGapMin: 1,
            fieldValueGap: 1,
            displayEmpty: () => this.cfg.params.displayEmptyTableItems,
            fillColor: '#000',
            fontFamily: 'Roboto',
            fontSize: () => this.cfg.getTableFontSize() * 1.1 * this.scale,
            alignment: GroupAlignment.CENTER,
        },
        children: [{
            params: {
                fontFactor: 2,
                verticalGapMin: 0.5,
                fontWeight: 'bold'
            },
            when: () => this.visibility.title && this.orientation == Orientation.HORIZONTAL,
            label: () => '',
            value: () => this.cfg.params.eventName,
        }, {
            params: {
                fontFactor: 1.2,
                layout: Orientation.HORIZONTAL,
            },
            when: () => this.visibility.subtitle && this.orientation == Orientation.HORIZONTAL,
            children: [{
                label: () => this._getLangLabel('Klasa', 'Class No.', 'Klasse'),
                value: () => this.cfg.limitsService.classesToName(this.cfg.params.classes, 20)
            }, {
                label: () => '',
                value: () => this.cfg.params.title,
            }, {
                label: () => '',
                value: () => this.cfg.params.shortDescr,
            }, {
                label: () => '',
                value: () => this.localizeEventDate(),
            }]
        }, {
            params: {
                verticalGapMin: 1,
                alignment: () => this.orientation === Orientation.HORIZONTAL ? GroupAlignment.CENTER : GroupAlignment.LABEL_EDGE,
            },
            when: () => this.visibility.table,
            children: [{
                params: {
                    alignment: GroupAlignment.LABEL_EDGE,
                    layout: Orientation.VERTICAL,
                    verticalGapMin: 0
                },
                children: [{
                    label: () => this._getLangLabel('Tabela', 'Table', 'Tabelle'),
                    value: () => (this.cfg.params.table)
                }, {
                    label: () => this._getLangLabel('Przepisy krajowe', 'National rules', 'Nationale Regeln'),
                    value: () => (this.cfg.params.nationalRules)
                }, {
                    label: () => this._getLangLabel('Przepisy FEI', 'FEI rules', 'FEI-Regeln'),
                    value: () => (this.cfg.params.feiRules)
                }]
            }, {
                params: {
                    alignment: GroupAlignment.LABEL_EDGE,
                    layout: Orientation.VERTICAL,
                    verticalGapMin: 0
                },
                children: [{
                    when: () => this.cfg.getSpeed() > 0,
                    label: () => this._getLangLabel('Tempo [m/min]', 'Speed [m/min]', 'Tempo [m/min]'),
                    value: () => (this.cfg.getSpeed().toFixed(0))
                }, {
                    when: () => !this.cfg.isNoRouteMode() && !this.cfg.noFinishInFirstRound(),
                    label: () => this._getLangLabel('Dystans', 'Distance', 'Bahnlänge') +  ' [' + this._unit + ']',
                    value: () => (
                        this.canvas.obstaclePath.firstRound ?
                            ParkourBannerObject._lengthPipe.transform(this.canvas.obstaclePath.firstRound.correctedLength, 
                                this.cfg.params.distanceUnit, true, undefined, undefined, true) || '' : ''
                    )
                }, {
                    when: () => this.canvas.getTimeLimit(1) > 0,
                    label: () => this._getLangLabel('Norma czasu [s]', 'Time allowed [s]', 'Erlaubte Zeit [s]'),
                    value: () => this.canvas.getTimeLimit(1).toFixed(0)
                }, {
                    when: () => this.cfg.isMaxTimeAllowed(),
                    label: () => this._getLangLabel('Czas maksymalny [s]', 'Time limit [s]', 'Höchstzeit [s]'),
                    value: () => (this.canvas.getTimeLimit(1) * 2).toFixed(0)
                }]
            }, {
                params: {
                    alignment: GroupAlignment.LABEL_EDGE,
                    layout: Orientation.VERTICAL,
                    verticalGapMin: 0
                },
                children: [{
                    label: () => this._getLangLabel('Wysokość', 'Height', 'Höhe'),
                    value: () => {
                        const p = this.cfg.params
                        if (p.classes.length === 0) {
                            return (p.obstacleHeight.toFixed(0))
                        } else if (p.classes.length === 1) {
                            const v = p.obstacleHeights[p.classes[0]]
                            if (v) {
                                return v.toFixed(0)
                            }
                        }
                        const hs = this._getSortedHeights().map(v => this.cfg.limitsService.classesToName([v[0] as ClassNo]) + ' - ' + v[1])
                        return justifyStrings(hs, 20, ', ')
                    }
                }, {
                    label: () => this._getLangLabel('Liczba przeszkód', 'Obstacles', 'Hindernisse'),
                    value: () => (
                        this.canvas.obstaclePath.firstRound ?
                            this.canvas.obstaclePath.firstRound.obstacles.toFixed(0) : ''
                    )
                }, {
                    when: () => !this.cfg.isNoRouteMode(),
                    label: () => this._getLangLabel('Liczba skoków', 'Efforts', 'Sprünge'),
                    value: () => (
                        this.canvas.obstaclePath.firstRound ?
                            this.canvas.obstaclePath.firstRound.efforts.toFixed(0) : ''
                    )
                }, {
                    when: () => !this.cfg.isNoRouteMode() && this.visibility.combinations &&
                        (this.canvas.obstaclePath.firstRound && this.canvas.obstaclePath.firstRound.closedCombinations > 0 || false),
                    label: () => this._getLangLabel('Szeregi zamknięte', 'Closed combinations', 'Geschlossene Kombinationen'),
                    value: () => (
                        this.canvas.obstaclePath.firstRound ?
                            this.canvas.obstaclePath.firstRound.closedCombinations.toFixed(0) : ''
                    )
                }, {
                    when: () => !this.cfg.isNoRouteMode() && this.visibility.combinations &&
                        (this.canvas.obstaclePath.firstRound && this.canvas.obstaclePath.firstRound.partiallyClosedCombinations > 0 || false),
                    label: () => this._getLangLabel('Szeregi częściowo zamknięte', 'Partially closed combinations', 'Teils offene Kombinationen'),
                    value: () => (
                        this.canvas.obstaclePath.firstRound ?
                            this.canvas.obstaclePath.firstRound.partiallyClosedCombinations.toFixed(0) : ''
                    )
                }]
            }, {
                when: () => (this.secondRoundMakesSense()),
                params: {
                    alignment: GroupAlignment.LABEL_EDGE,
                    layout: Orientation.VERTICAL,
                    verticalGapMin: 0
                },
                children: [{
                    label: () => {
                        if (this.canvas.obstaclePath.secondRoundType === SecondRoundType.TWO_PHASE) {
                            return this._getLangLabel('Dwie fazy', 'Two-phase', '2. Phase über')
                        }
                        return this._getLangLabel('Z rozgrywką', 'Jump-off', 'Stechen')
                    },
                    value: () => {
                        const labels = this.canvas.obstaclePath.getSecondRoundLabels()
                        return justifyStrings(labels, 20, '-')
                    }
                }, {
                    label: () => this._getLangLabel('Dystans', 'Distance', 'Bahnlänge') +  ' [' + this._unit + ']',
                    value: () => (
                        this.canvas.obstaclePath.secondRound ?
                            ParkourBannerObject._lengthPipe.transform(this.canvas.obstaclePath.secondRound.correctedLength, 
                                this.cfg.params.distanceUnit, true, undefined, undefined, true) || '' : ''
                    )
                }, {
                    label: () => this._getLangLabel('Norma czasu [s]', 'Time allowed [s]', 'Erlaubte Zeit [s]'),
                    value: () => this.canvas.getTimeLimit(2).toFixed(0),
                    when: () => this.canvas.getTimeLimit(2) > 0
                }, {
                    label: () => this._getLangLabel('Czas maksymalny [s]', 'Time limit [s]', 'Höchstzeit [s]'),
                    value: () => (this.canvas.getTimeLimit(2) * 2).toFixed(0),
                    when: () => this.cfg.isMaxTimeAllowed()
                }]
            }, {
                when: () => (this.secondRoundMakesSense()),
                params: {
                    alignment: GroupAlignment.LABEL_EDGE,
                    layout: Orientation.VERTICAL,
                    verticalGapMin: 0
                },
                children: [{
                    label: () => this._getLangLabel('Liczba przeszkód', 'Obstacles', 'Hindernisse'),
                    value: () => (
                        this.canvas.obstaclePath.secondRound ?
                            this.canvas.obstaclePath.secondRound.obstacles.toFixed(0) : ''
                    )
                }, {
                    label: () => this._getLangLabel('Liczba skoków', 'Efforts', 'Sprünge'),
                    value: () => (
                        this.canvas.obstaclePath.secondRound ?
                            this.canvas.obstaclePath.secondRound.efforts.toFixed(0) : ''
                    )
                }, {
                    when: () => !this.cfg.isNoRouteMode() && this.visibility.combinations &&
                        (this.canvas.obstaclePath.secondRound && this.canvas.obstaclePath.secondRound.closedCombinations > 0 || false),
                    label: () => this._getLangLabel('Szeregi zamknięte', 'Closed combinations', 'Geschlossene Kombinationen'),
                    value: () => (
                        this.canvas.obstaclePath.secondRound ?
                            this.canvas.obstaclePath.secondRound.closedCombinations.toFixed(0) : ''
                    )
                }, {
                    when: () => !this.cfg.isNoRouteMode() && this.visibility.combinations &&
                        (this.canvas.obstaclePath.secondRound && this.canvas.obstaclePath.secondRound.partiallyClosedCombinations > 0 || false),
                    label: () => this._getLangLabel('Szeregi częściowo zamknięte', 'Partially closed combinations', 'Teils offene Kombinationen'),
                    value: () => (
                        this.canvas.obstaclePath.secondRound ?
                            this.canvas.obstaclePath.secondRound.partiallyClosedCombinations.toFixed(0) : ''
                    )
                }]
            }]
        }]
    }

    tool: TableGroup
    content: paper.Group | undefined
    scale: number
    protected readonly preferredSelectorKind: SelectorKind = SelectorKind.RECTANGLE
    protected readonly preferredLayer: LayerId = LayerId.BACKGROUND
    protected readonly configurableLayer: boolean = true
    protected readonly canRotate: boolean = true
    protected readonly canResize: boolean = true
    protected readonly snapRotation: boolean = true

    orientation: Orientation
    bannerSize: paper.Size = new paper.Size(100, 100)
    visibility: BannerVisibility = {
        title: true,
        subtitle: true,
        table: true,
        combinations: true,
    }

    set visible(visible: boolean) {
        this.allGroup.visible = visible
    }

    get visible(): boolean {
        return this.allGroup.visible
    }

    private secondRoundMakesSense(): boolean {
        // there is no point to display second round data for competitions which
        // don't have a route defined, only the obstacles with order
        return this.canvas.obstaclePath.secondRoundType !== SecondRoundType.NONE &&
            !this.cfg.isNoRouteMode()
    }

    private _getSortedHeights(): [string, number][] {
        const heights: ObstacleHeights | undefined = this.cfg.params.obstacleHeights
        const keys = Object.keys(heights)
        if (heights) {
            return this.cfg.limitsService.classNoOptions.filter(c => keys.includes(c.value)).map(c => [c.value, heights[c.value] || 0])
        }
        return []
    }

    scaleObject(scale: number): boolean {
        const pos = this.getPosition()
        this.content?.scale(scale, pos)
        this.bannerSize = this.bannerSize.multiply(scale)
        this.scale *= scale
        this.externalFrame.position = pos
        return true
    }

    getExternalSize(): paper.Size {
        return this.bannerSize
    }

    localizeEventDate() {
        if (!this.cfg.params.eventDate) {
            return ''
        }
        return dateToLocalizedString(this.cfg.params.eventDate, this.cfg.params.tableLanguage)
    }

    build(): paper.Group {
        const pos = this.getPosition()
        if (this.content && this.content.isInserted()) {
            this.content.remove()
        }
        this.tool.build()
        this.content = this.tool.group
        this._layout()
        this.content.position = pos
        this.allGroup.addChild(this.content)
        this.content.rotate(this.angle, pos)
        this.createSelectionGfx()
        this.levelItem = this.content
        return this.content
    }

    clear() {
        this.tool.clear()
    }

    _layout() {
        // top level layout is the opposite of the banner orientation
        this.tool.layout(this.orientation === Orientation.VERTICAL ? Orientation.HORIZONTAL : Orientation.VERTICAL)
        if (this.content) {
            this.bannerSize = this.content.internalBounds.size
        }
    }

    layout() {
        if (this.content) {
            const pos = this.getPosition()
            const angle = this.angle
            this.rotate(-angle)
            this._layout()
            this.rotate(angle)
            this.content.position = pos
            this.createSelectionGfx()
        }
    }

    update() {
        if (this.content) {
            const pos = this.getPosition()
            const angle = this.angle
            this.rotate(-angle)
            this.tool.build()
            this._layout()
            this.rotate(angle)
            this.content.position = pos
            this.createSelectionGfx()
        }
    }

    select(point?: paper.Point | undefined): void {
        super.select(point)
        this.levelItem = this.allGroup
    }

    deselect(): void {
        super.deselect()
        this.levelItem = this.content || this.allGroup
    }

    contains(point: paper.Point): boolean {
        if (this.levelItem === this.content) {
            return this.content.bounds.contains(point)
        }
        return super.contains(point)
    }

    doubleClick(): boolean {
        this.view?.openDialog(ParkourDialogId.COMPETITION_PARAMS)
        return false
    }

    constructor(protected config: ParkourObjectConfig) {
        super(config)
        this._unit = LengthPipe.getUnit(config.cfg.params.distanceUnit)
        const object = config.object
        if (object.visibility) {
            this.visibility = object.visibility
            if (this.visibility.combinations === undefined) {
                this.visibility.combinations = true
            }
        }
        const angle = this.angle
        this.angle = 0
        this.orientation = object.orientation || Orientation.HORIZONTAL
        this.scale = object.scale || 1
        this.layer = object.layer || this.preferredLayer
        this.tool = new TableGroup(this.bannerDef)
        this.build()
        if (this.content) {
            this.content.position = this.initialPos
        }
        this.angle = angle
        this.objectReady(true)
    }

    _getLangLabel(labelPl: string, labelEn: string, labelDe: string) {
        const lang = this.cfg.params.tableLanguage
        if (lang === 'pl') {
            return labelPl
        } else if (lang === 'de') {
            return labelDe
        }
        return labelEn
    }

    // is anything visible
    isVisible(title: boolean | undefined, subtitle: boolean | undefined, table: boolean | undefined): boolean {
        const p = this.cfg.params
        const v = structuredClone(this.visibility)
        if (title !== undefined) {
            v.title = title
        }
        if (subtitle !== undefined) {
            v.subtitle = subtitle
        }
        if (table !== undefined) {
            v.table = table
        }
        return !(
            (!v.title  || !p.eventName) &&
            (!v.subtitle || !p.classes && !p.title && !p.shortDescr && !p.eventDate) &&
            !v.table
        )
    }

    toJson(): DesignBannerObject {
        return {
            ...super.toJson(),
            visibility: this.visibility,
            orientation: this.orientation,
            scale: this.scale
        } as DesignBannerObject
    }
}
