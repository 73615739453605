import { Component, ElementRef, HostListener, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { User } from '@angular/fire/auth'
import { MessageService } from 'primeng/api'
import { FileUpload } from 'primeng/fileupload'
import { Subscription } from 'rxjs'

import { Validators } from '@angular/forms'
import { DesignSchema, ShareMode } from '../design.schema'
import { MyDesignsListComponent } from '../my-designs-list/my-designs-list.component'
import { ParkourConfig } from '../parkour.config'
import { AuthService } from '../services/auth.service'
import { ArticleOption, ClassNoNameValue, CompLocNameValue, LimitsService } from "../services/limits.service"
import { StorageService } from '../services/storage.service'
import { UserProfile, UserService } from '../services/user.service'
import { feetToMetersRounded, showErrorBox, showInfoBox, showSuccessBox } from '../utils'

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
    private subs: Subscription = new Subscription()

    @ViewChild('myDesignsList') myDesignsList: MyDesignsListComponent | undefined
    @ViewChild('articleInHome') articleInHomeEl?: ElementRef<HTMLElement>
    events: any[] = []

    user: User | null = null
    newDesignDlgVisible = false
    cfg: ParkourConfig | null = null
    errorMsg: string = ''
    bigPreviewPngUrl: string = ''
    private isOffline: boolean = false
    previewDlgVisible: boolean = false
    classNoOptions: ClassNoNameValue[]
    compLocOptions: CompLocNameValue[]
    articleOptions: ArticleOption[]
    shareModeOptions = [
        { name: $localize`Projekt prywatny`, value: ShareMode.PRIVATE, tooltip: $localize`Projekt nie będzie widoczny dla innych użytkowników` },
        { name: $localize`Projekt publiczny`, value: ShareMode.PUBLIC, tooltip: $localize`Projekt będzie widoczny dla innych użytkowników` }
    ]
    articleTooltip = ''

    @ViewChild('fileUpload') fileUpload: FileUpload | undefined
    importDesignDlgVisible: boolean = false
    //importUrl = "http://192.168.68.108:10600/parkour-test/europe-west1/on_request_example"
    importUrl: string
    importing = false
    importError = ''
    importData: any = null
    acceptImportPhase: string = ''

    private userProfile?: UserProfile = undefined
    selectedItemsLabel: string = $localize`Wybrano {0} wartości`

    lengthUnitOptions = [
        { name: $localize`Metry (m)`, value: 'm-m' },
        { name: $localize`Stopy (ft)`, value: 'ft-ft' },
        { name: $localize`Stopy (\')`, value: 'ft-sign' }
    ]

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private auth: AuthService,
        private store: StorageService,
        private msgSvc: MessageService,
        @Inject(LOCALE_ID) private locale: string,
        private userService: UserService,
        private limitsService: LimitsService,
    ) {
        if (window.location.hostname === "parkour.design" || window.location.hostname === "app.parkour.design") {
            this.importUrl = "https://europe-west1-parkour-37a76.cloudfunctions.net/on_request_example"
        } else {
            this.importUrl = "https://europe-west1-parkour-test.cloudfunctions.net/on_request_example"
        }

        this.classNoOptions = structuredClone(limitsService.classNoOptions)
        this.compLocOptions = structuredClone(limitsService.compLocOptions)
        this.articleOptions = structuredClone(limitsService.articleOptions)

        this.subs.add(
            this.auth.user.subscribe({
                next: (aUser: User | null) => {
                    if (!aUser) {
                        this.user = aUser
                        return
                    }
                    if (!this.user) {
                        this.user = aUser
                    } else {
                        this.user = aUser
                    }
                },
                error: (err) => {
                    console.error('error occured', err)
                    showErrorBox(this.msgSvc, $localize`Pobieranie informacji o użytkowniku`, $localize`Wystąpił nieznany błąd`)
                }
            })
        )
    }

    ngOnInit(): void {
        this.subs.add(
            this.route.queryParamMap.subscribe({
                next: (params) => {
                    const param = params.get('newDesign')
                    if (param === 'true') {
                        this.showNewDesignDialog()
                    } else {
                        this.hideNewDesignDialog()
                    }
                },
                error: (err) => {
                    console.error('error occured', err)
                    showErrorBox(this.msgSvc, $localize`Pobieranie informacji o parametrach z URL`, $localize`Wystąpił nieznany błąd`)
                }
            })
        )
        this.subs.add(
            this.userService.getUserProfile().subscribe({
                next: (userProfile: UserProfile | null | undefined) => {
                    if (!userProfile) {
                        return
                    }
                    this.userProfile = userProfile
                },
                error: (err) => {
                    console.error('error occured', err)
                }
            })
        )
    }

    ngOnDestroy() {
        this.subs.unsubscribe()
    }

    onEvents(events: any[]) {
        this.events = events
    }

    togglePreview(previewUrl: string) {
        this.bigPreviewPngUrl = previewUrl
        this.previewDlgVisible = !this.previewDlgVisible
    }

    private setBasicDesignData(design: DesignSchema) {
        if (this.user) {
            design.author = this.user.uid
        }
        if (this.locale === 'pl') {
            design.tableLanguage = 'pl'
        } else if (this.locale === 'de') {
            design.tableLanguage = 'de'
        }
        if (this.userProfile?.designDisplayOptions?.distanceUnit) {
            design.distanceUnit = this.userProfile.designDisplayOptions.distanceUnit
        } else {
            design.distanceUnit = 'm-m'
        }
    }

    onUnitChange() {
        this.loadArenaDimensions()
    }

    showNewDesignDialog(initialData?: any) {
        if (!initialData) {
            initialData = {}
        }
        this.setBasicDesignData(initialData)

        this.cfg = new ParkourConfig(this.limitsService, initialData)
        this.classNoOptions.forEach(c => c.disabled = false)
        this.cfg.form.controls.title.setValidators(Validators.required)
        this.loadArenaDimensions()

        this.newDesignDlgVisible = true
    }

    hideNewDesignDialog() {
        this.cfg = null
        this.newDesignDlgVisible = false
    }

    updateClasses() {
        this.setBaseArticleAndTooltipForm()
        this.cfg?.updateCompatibleClassesInForm(this.classNoOptions)
    }

    createDesign() {
        this.errorMsg = ''

        if (!this.cfg) {
            return
        }

        const controls = this.cfg.form.controls
        const unit = controls.distanceUnit.value
        if (unit.startsWith('ft-')) {
            controls.parkourHeight.patchValue(feetToMetersRounded(controls.parkourHeight.value))
            controls.parkourWidth.patchValue(feetToMetersRounded(controls.parkourWidth.value))
        }

        if (unit !== this.userProfile?.designDisplayOptions?.distanceUnit) {
            const patch: UserProfile = {
                designDisplayOptions: {
                    ...this.userProfile?.designDisplayOptions,
                    distanceUnit: unit
                }
            }
            this.userService.updateUserProfile(patch)
        }

        this.cfg.getValuesFromForm()
        this.cfg.params.title = this.cfg.params.title.trim()
        if (!this.cfg.params.title) {
            this.errorMsg = $localize`Nazwa konkursu nie może być pusta`
            return
        }

        this.cfg.params.loadDefaultParams(this.limitsService, this.userProfile?.designDisplayOptions, this.cfg.params.classes, this.cfg.params.compLoc)

        // preserve the selected distance unit
        this.cfg.params.distanceUnit = unit

        const [localId, obs] = this.store.addDesign(this.cfg.params)

        // IMPORTANT: do not put this subscription to subs because in case of navigate to designer page
        // it will be canceled in ngOnDestroy
        obs.subscribe({
            next: (docRef: any) => {
                showSuccessBox(this.msgSvc, $localize`Tworzenie nowego projektu`, $localize`Projekt zapisano zdalnie, na serwerze`)
            },
            error: (err: any) => {
                console.error("Error adding design: ", err)
                showErrorBox(this.msgSvc, $localize`Tworzenie nowego projektu`, $localize`Wystąpił nieznany błąd`)
            }
        })
        showInfoBox(this.msgSvc, $localize`Tworzenie nowego projektu`, $localize`Projekt zapisano lokalnie`)
        this.router.navigate(['/designer'], { queryParams: { id: localId, newDesign: true } })
    }


    @HostListener('window:offline')
    setNetworkOffline(): void {
        this.isOffline = true
    }

    @HostListener('window:online')
    setNetworkOnline(): void {
        this.isOffline = false
    }

    setBaseArticleAndTooltipForm() {
        if (this.cfg) {
            this.articleTooltip = this.limitsService.setBaseArticleAndTooltipForm(this.cfg, this.articleInHomeEl)
        }
    }

    onImportUploadStart() {
        this.importing = true
    }

    onImportUploadDone(ev: any) {
        this.importing = false
        if (!this.importDesignDlgVisible) {
            // it was canceled
            return
        }
        this.importData = ev.originalEvent.body
        console.info('importData', this.importData)
        for (const d of this.importData.designs) {
            d.importStatus = ''
        }

        this.acceptImportPhase = $localize`Zaimportuj`
    }

    onImportError(ev: any) {
        console.error('import error', ev)
        this.importError = $localize`Importowanie nie powiodło się.`
    }

    closeDesignsImport() {
        this.importing = false
        this.importData = null
        this.importError = ''
        this.importDesignDlgVisible = false
        this.acceptImportPhase = ''
        if (this.fileUpload) {
            this.fileUpload.clear()
        }
    }

    acceptDesignsImport() {
        if (this.acceptImportPhase === $localize`Zaimportuj`) {
            this.acceptImportPhase = $localize`Zamknij`

            for (const d of this.importData.designs) {
                if (d.error) {
                    d.importStatus = ' - ' + $localize`Zapisywanie niepowiodło się`
                    console.info("Error converting design", d.error)
                    continue
                }
                this.setBasicDesignData(d)
                const [localId, obs] = this.store.importDesign(d)
                d.importStatus = ' - ' + $localize`Zapisywanie`

                this.subs.add(
                    obs.subscribe({
                        next: (docRef: any) => {
                            d.importStatus = ' - ' + $localize`Zapisane`
                        },
                        error: (err: any) => {
                            console.error("Error adding design: ", err)
                            d.importStatus = ' - ' + $localize`Zapisywanie niepowiodło się`
                        }
                    })
                )
            }
        } else if (this.acceptImportPhase === $localize`Zamknij`) {
            this.closeDesignsImport()
            this.myDesignsList?.loadDesignsList()
        }
    }

    getFieldHeightLimits(): number[] {
        if (this.cfg?.form.controls.distanceUnit.value.startsWith('ft-')) {
            return [30, 1000, 1]
        }
        return [10, 300, 5]
    }

    getFieldWidthLimits(): number[] {
        if (this.cfg?.form.controls.distanceUnit.value.startsWith('ft-')) {
            return [30, 1000, 1]
        }
        return [10, 300, 5]
    }

    private loadArenaDimensions() {
        if (this.cfg) {
            const controls = this.cfg.form.controls
            if (controls.distanceUnit.value.startsWith('ft-')) {
                controls.parkourHeight.patchValue(100)
                controls.parkourWidth.patchValue(200)
            } else {
                controls.parkourHeight.patchValue(40)
                controls.parkourWidth.patchValue(80)
            }
        }
    }
}
