<p-colorPicker #picker
    [(ngModel)]="value" (ngModelChange)="onNgModelChange()" appendTo="body"
    (onShow)="onPickerShow()" (onHide)="onPickerHide()"
    (onChange)="onColorChange($event)">
</p-colorPicker>
<div #buttons 
    class="absolute custom-picker p-colorpicker-overlay-panel p-colorpicker-panel p-2 pt-1 border-none"
    [hidden]="!visible">
    <p-button label="Kopiuj" i18n-label size="small" 
        styleClass="mr-1" icon="fa-regular fa-copy" (onClick)="colorCopy()">
    </p-button>
    <p-button size="small" (onClick)="colorPaste($event)">
        <i class="fa-regular fa-paste pr-2"></i>
        <span i18n>Wklej</span>
        <div *ngIf="copyColor"
            class="border-solid border-1 ml-2" style="width: 13px; height: 13px"
            [ngStyle]="{ 'background-color': copyColor }">
        </div>
    </p-button>
</div>
<p-contextMenu #menu 
    [target]="menuTarget" 
    [model]="colorCopyMenu"
    appendTo="body"
    (onShow)="onMenuShow(menu)">
</p-contextMenu>
