import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';
import { TooltipModule } from 'primeng/tooltip';
import { DetailComponent } from '../../detail.component';
import { DialogModule } from 'primeng/dialog'
import { ParkourDialog } from '../detail.dialog.interface'
import { CheckboxModule } from 'primeng/checkbox'
import { formatBytes } from '../../../utils'
import { UserService } from '../../../services/user.service'

@Component({
    selector: 'app-parkour-debug-options',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonModule,
        InputNumberModule,
        TooltipModule,
        DialogModule,
        CheckboxModule,
    ],
    templateUrl: './parkour-debug-options.component.html',
    styleUrl: './parkour-debug-options.component.scss'
})
export class ParkourDebugOptionsComponent implements ParkourDialog {
    @Input({ required: true }) view!: DetailComponent
    @Output() onOk = new EventEmitter<void>()
    @Output() onCancel = new EventEmitter<void>()

    visibility: boolean = false
    errorMsg: string = ''

    paperjsItems: string = ''
    heapSizeLimit: string = ''
    heapSizeTotal: string = ''
    heapSizeUsed: string = ''
    readUserProfileVer: number | undefined
    currentUserProfileVer: number

    timer?: NodeJS.Timeout

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        userService: UserService
    ) {
        this.currentUserProfileVer = userService.currentVersion
    }

    update() {
        this.paperjsItems = this.view.canvas?.paper?.getItems({ recursive: true }).length.toFixed(0) || 'error'
        this.heapSizeLimit = this.heapSizeTotal = this.heapSizeUsed = 'error'
        const p: any = performance
        if (p && p.memory) {
            if (p.memory.jsHeapSizeLimit) {
                this.heapSizeLimit = formatBytes(p.memory.jsHeapSizeLimit)
            }
            if (p.memory.totalJSHeapSize) {
                this.heapSizeTotal = formatBytes(p.memory.totalJSHeapSize)
            }
            if (p.memory.usedJSHeapSize) {
                this.heapSizeUsed = formatBytes(p.memory.usedJSHeapSize)
            }
        }
        this.readUserProfileVer = this.view.userProfile.version
        this.changeDetectorRef.detectChanges()
    }

    onCloseDialog() {
    }

    onShowDialog() {
        this.view.cfg.setValuesToForm()
        this.update()
        if (this.timer) {
            clearInterval(this.timer)
        }
        this.timer = setInterval(this.update.bind(this), 1000)
    }

    onHideDialog() {
        if (this.timer) {
            clearInterval(this.timer)
            this.timer = undefined
        }
    }

    onOkDialog() {
        this.visibility = false
        this.onOk.emit()
    }

    onCancelDialog() {
        this.visibility = false
        this.onCancel.emit()
    }

    open() {
        this.visibility = true
    }

    closeOk() {
        this.onOkDialog()
    }

    closeCancel() {
        this.onCancelDialog()
    }
}