import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnDestroy, OnInit, Output } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { Router, RouterModule } from '@angular/router'

import { User } from '@angular/fire/auth'
import { MessageService } from 'primeng/api'
import { ButtonModule } from 'primeng/button'
import { DropdownModule } from 'primeng/dropdown'
import { InputTextModule } from 'primeng/inputtext'
import { SkeletonModule } from 'primeng/skeleton'
import { TooltipModule } from 'primeng/tooltip'
import { Subscription } from 'rxjs'

import { AuthService } from '../services/auth.service'
import { StorageService } from '../services/storage.service'
import { showErrorBox, showInfoBox, showSuccessBox } from '../utils'

@Component({
    selector: 'app-designs-list',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ButtonModule,
        DropdownModule,
        InputTextModule,
        SkeletonModule,
        TooltipModule,
    ],
    templateUrl: './designs-list.component.html',
    styleUrls: ['./designs-list.component.scss']
})
export class DesignsListComponent implements OnInit, OnDestroy {
    private subs: Subscription = new Subscription()

    @Input() scope: string = 'my'
    @Output() events = new EventEmitter<any[]>()
    @Output() preview = new EventEmitter<string>()

    user: any = null

    eventsWithDesigns: any[] = []
    designs: any[] = []

    listHeader: string = ''
    readonly emptyMessageInMyList = $localize`Nie masz jeszcze żadnego projektu. Kliknij "Nowy konkurs" aby stworzyć nowy projekt.`
    readonly emptyMessageInPublicList = $localize`Nie masz jeszcze żadnego publicznego projektu.`
    readonly inProgressMessage = $localize`Ładowanie listy...`
    listMsg: string = ''
    loadingList = false

    searchPanelVisible = false
    searchText: string = ''
    sortField: string = 'updatedAt'
    sortDir: string = 'desc'
    sortOption: string = 'updatedAt-desc'
    sortOptions: any[] = [
        { name: $localize`Data zmiany: od najnowszych`, value: 'updatedAt-desc' },
        { name: $localize`Data zmiany: od najstarszych`, value: 'updatedAt-asc' },
        { name: $localize`Data wydarzenia: od najnowszych`, value: 'eventDate-desc' },
        { name: $localize`Data wydarzenia: od najstarszych`, value: 'eventDate-asc' },
    ]

    constructor(
        private router: Router,
        private auth: AuthService,
        private store: StorageService,
        private msgSvc: MessageService,
        @Inject(LOCALE_ID) private locale: string
    ) {
    }

    ngOnInit(): void {
        if (this.scope === 'my') {
            this.listHeader = $localize`Twoje Ostatnie Projekty`
        } else {
            this.listHeader = $localize`Ostatnie Publiczne Projekty`
        }

        this.subs.add(
            this.auth.user.subscribe({
                next: (aUser: User | null) => {
                    if (!aUser) {
                        this.user = aUser
                        return
                    }
                    if (!this.user) {
                        this.user = aUser
                        if (this.scope === 'my') {
                            this.loadLocalDesigns()
                        }
                    } else {
                        this.user = aUser
                    }
                },
                error: (err) => {
                    console.error('error occured', err)
                    showErrorBox(this.msgSvc, $localize`Pobieranie informacji o użytkowniku`, $localize`Wystąpił nieznany błąd`)
                }
            })
        )

        if (this.scope === 'public') {
            this.loadDesignsList()
        }
    }

    ngOnDestroy() {
        this.subs.unsubscribe()
    }

    prepareEventsList() {
        const events = []
        this.eventsWithDesigns = []
        for (const d of this.designs) {
            if (this.searchText) {
                if ((d.eventName && d.eventName.includes(this.searchText)) ||
                    (d.title && d.title.includes(this.searchText)) ||
                    (d.authorName && d.authorName.includes(this.searchText)) ||
                    (d.shortDescr && d.shortDescr.includes(this.searchText)) ||
                    (d.nationalRules && d.nationalRules.includes(this.searchText)) ||
                    (d.feiRules && d.feiRules.includes(this.searchText))) {
                    //ok
                } else {
                    continue
                }
            }

            let event: any
            if (d.eventName) {
                event = events.find(v => v.eventName === d.eventName)
                if (!event) {
                    event = {
                        eventName: d.eventName,
                        eventDate: d.eventDate?.toLocaleDateString(this.locale, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }),
                        designs: [],
                        expanded: false,
                    }
                    this.eventsWithDesigns.push(event)
                    events.push(event)
                }
                event.designs.push(d)
            } else {
                // design with empty event
                event = {
                    eventName: '',
                    eventDate: d.eventDate?.toLocaleDateString(this.locale, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }),
                    designs: [d],
                }
                this.eventsWithDesigns.push(event)
            }
        }
        this.events.emit(events)
    }

    loadLocalDesigns() {
        this.subs.add(
            this.store.getLocalDesigns().subscribe({
                next: (designs: any[]) => {
                    this.listMsg = ''
                    this.designs = designs
                    this.prepareEventsList()
                },
                error: (err) => {
                    console.error('error occured', err)
                    showErrorBox(this.msgSvc, $localize`Pobieranie lokalnych projeków`, $localize`Wystąpił nieznany błąd`)
                }
            })
        )
    }

    loadDesignsList() {
        this.loadingList = true
        if (this.designs.length === 0) {
            this.listMsg = this.inProgressMessage
        } else {
            this.listMsg = ''
        }
        let obs
        if (this.scope === 'my') {
            obs = this.store.loadMyDesigns(10, this.sortField, this.sortDir)
        } else {
            obs = this.store.loadPublicDesigns(30, this.sortField, this.sortDir)
        }
        this.subs.add(
            obs.subscribe({
                next: (designs: any[]) => {
                    this.loadingList = false
                    this.designs = designs
                    this.prepareEventsList()

                    if (this.designs.length === 0) {
                        if (this.scope === 'my') {
                            this.listMsg = this.emptyMessageInMyList
                        } else {
                            this.listMsg = this.emptyMessageInPublicList
                        }
                    } else {
                        this.listMsg = ''
                    }
                },
                error: (err) => {
                    this.loadingList = false
                    this.listMsg = $localize`Pobieranie projektów nie powiodło się`
                    console.error('error occured', err)
                    let msg = $localize`Pobieranie publicznych projektów`
                    if (this.scope === 'my') {
                        msg = $localize`Pobieranie Twoich projektów`
                    }
                    showErrorBox(this.msgSvc, msg, $localize`Wystąpił nieznany błąd`)
                }
            })
        )
    }

    editDesign(d: any) {
        this.router.navigate(['/designer'], { queryParams: { id: d.localId, author: d.author } })
    }

    cloneDesign(oldDesign: any) {
        const [localId, _, obs] = this.store.cloneDesign(oldDesign)

        // IMPORTANT: do not put this subscription to subs because in case of navigate to designer page
        // it will be canceled in ngOnDestroy
        obs.subscribe({
            next: (docRef: any) => {
                showSuccessBox(this.msgSvc, $localize`Duplikowanie projektu`, $localize`Projekt zapisano zdalnie, na serwerze`)
            },
            error: (err: any) => {
                console.error("Error adding design: ", err)
                showErrorBox(this.msgSvc, $localize`Duplikowanie projektu`, $localize`Wystąpił nieznany błąd`)
            }
        })
        showInfoBox(this.msgSvc, $localize`Duplikowanie projektu`, $localize`Projekt zapisano lokalnie`)
        this.router.navigate(['/designer'], { queryParams: { id: localId } })
    }

    deleteDesign(design: any) {
        for (let i = 0; i < this.designs.length; i++) {
            if (this.designs[i].localId === design.localId) {
                this.designs.splice(i, 1)
                showInfoBox(this.msgSvc, $localize`Usuwanie projektu`, $localize`Projekt jest usuwany`)
                break
            }
        }
        this.subs.add(
            this.store.deleteDesign(design).subscribe({
                next: (ok) => {
                    showSuccessBox(this.msgSvc, $localize`Usuwanie projektu`, $localize`Projekt został usunięty`)
                    this.loadDesignsList()
                },
                error: (error) => {
                    console.error("Error deleting design: ", error)
                    showErrorBox(this.msgSvc, $localize`Usuwanie projektu`, $localize`Wystąpił nieznany błąd`)
                }
            })
        )
    }

    onImageError(design: any) {
        design.pngUrl = null
    }

    onImageLoad(ev: any, design: any) {
        const img = ev.target
        const canvas = document.createElement("canvas");
        canvas.width = img.naturalWidth
        canvas.height = img.naturalHeight
        const ctx = canvas.getContext("2d");
        ctx!.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);
        const dataURL = canvas.toDataURL("image/png");
        this.store.savePngPreviewLocally(design.localId, dataURL)
    }

    handlePreview(d: any) {
        let previewPngUrl = ''

        if (d.pngUrl) {
            previewPngUrl = d.pngUrl
        } else if (d.png) {
            previewPngUrl = d.png
        }

        this.preview.emit(previewPngUrl)
    }

    expandEventPanel(event: any) {
        event.expanded = !event.expanded
    }

    doSearch(event: any) {
        if (event.key === "Escape") {
            this.searchText = ''
            this.searchPanelVisible = false
        }
        this.prepareEventsList()
    }

    sortChange() {
        const [sortField, sortDir] = this.sortOption.split('-')
        this.sortField = sortField
        this.sortDir = sortDir
        this.loadDesignsList()
    }
}
