import paper from 'paper'
import { ParkourCanvas } from '../../parkour-canvas/parkour-canvas'
import { ParkourConfig } from '../../parkour.config'
import { ParkourObjectTemplate, ParkourObjectTemplateOptions } from '../parkour-object-factory/parkour-object-factory.service'
import { ParkourObjectConfig } from './parkour-object'

type GeneratedObject = {
    objKind: ParkourObjectTemplate,
    point: paper.Point,
    angle?: number,
    doNotDropOnPath?: boolean,
}

type GeneratedObjects = {
    objects: GeneratedObject[],
    groups: GeneratedObject[][],
}

export class ObjectGenerator {
    protected position: paper.Point
    protected options?: ParkourObjectTemplateOptions
    public callback?: () => void
    protected canvas: ParkourCanvas
    protected cfg: ParkourConfig

    constructor(config: ParkourObjectConfig) {
        this.canvas = config.canvas
        this.cfg = config.cfg
        const object = config.object
        if (object.x !== undefined && object.y !== undefined) {
            this.position = new paper.Point(object.x, object.y)
        } else {
            this.position = new paper.Point(0, 0)
        }
        if (object.options) {
            this.options = object.options
        }
        if (object.callback) {
            this.callback = object.callback
        }
    }

    public generate(): GeneratedObjects {
        if (this.callback) {
            this.callback()
        }
        return {
            objects: [],
            groups: []
        }
    }
}

export class CombinationGenerator extends ObjectGenerator {

    private _calcObstacleWidth(c: ParkourObjectTemplate): number {
        let obstacleWidth = 0
        if (c.bars) {
            switch(c.bars) {
                case 1:
                    obstacleWidth = this.cfg.currentLimits.width?.vertical?.default || 0
                    break
                case 2:
                    obstacleWidth = this.cfg.currentLimits.width?.oxer.default || 0
                    break
                case 2:
                    obstacleWidth = this.cfg.currentLimits.width?.tripleBarre.default || 0
                    break
            }
        }
        return obstacleWidth    
    }

    public generate(): GeneratedObjects {
        let ret: GeneratedObject[] = []
        if (this.options && this.options.combination) {            
            const angle = 90
            const combs = this.options.combination
            const p = this.cfg.params
            const distance = Math.round((p.compMaxDist - p.compMinDist) / 2 + p.compMinDist) * 100
            const widths = distance * (combs.length - 1) + combs.reduce((s, c, idx) => {
                const w = this._calcObstacleWidth(c)
                if (idx === 0 || idx === combs.length - 1) {
                    return w / 2 + s
                }
                return w + s
            }, 0)
            let length: number = this.cfg.currentLimits.length?.default || 0
            let position = this.position.subtract([widths / 2, 0])
            position.x = Math.max(position.x, this.canvas.field.left)
            position.x = Math.min(position.x, this.canvas.field.right - widths)
            position.y = Math.max(position.y, this.canvas.field.top + length / 2)
            position.y = Math.min(position.y, this.canvas.field.bottom - length / 2)
            ret = combs.map(c => {
                const obj = {
                    objKind: c,
                    point: position,
                    angle: angle,
                    doNotDropOnPath: true,
                } as GeneratedObject
                position = position.add([this._calcObstacleWidth(c) + distance, 0])
                return obj
            })
        }
        super.generate()
        return {
            objects: [],
            groups: [ret]
        }
    }
}
