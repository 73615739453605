import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule, ErrorHandler, APP_INITIALIZER, isDevMode } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Router } from "@angular/router";
import { ServiceWorkerModule } from '@angular/service-worker';

// firebase
import { provideFirebaseApp, initializeApp, getApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { initializeFirestore, provideFirestore, persistentLocalCache, persistentMultipleTabManager } from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';

// Sentry
import * as Sentry from "@sentry/angular-ivy";

// PrimeNG
import { MessageService } from 'primeng/api'
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast'
import { TooltipModule } from 'primeng/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { DividerModule } from 'primeng/divider';
import { PanelModule } from 'primeng/panel';
import { DialogModule } from 'primeng/dialog';
import { PasswordModule } from 'primeng/password';
import { ProgressBarModule } from 'primeng/progressbar';
import { CarouselModule } from 'primeng/carousel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CheckboxModule } from 'primeng/checkbox';

import { APP_CONFIG } from '../environments/environment';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { HomeModule } from './home/home.module';
import { DetailModule } from './detail/detail.module';
import { AppComponent } from './app.component';
import { I18nModule } from './i18n.module';
import { MainPageComponent } from './main-page/main-page.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { PricingPanelComponent } from './pricing-panel/pricing-panel.component';
import { LeftTopBarComponent } from './left-top-bar/left-top-bar.component';
import { RightTopBarComponent } from './right-top-bar/right-top-bar.component';
import { LoginPageComponent } from './login-page/login-page.component';


@NgModule({
    declarations: [AppComponent, MainPageComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,

        // firebase
        provideFirebaseApp(() => initializeApp(APP_CONFIG.firebase)),
        provideAuth(() => {
            const auth = getAuth()
            if (!APP_CONFIG.production) {
                //connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
            }
            return auth
        }),
        provideFirestore(() => {
            const firestore = initializeFirestore(getApp(), {
                localCache: persistentLocalCache({ tabManager: persistentMultipleTabManager() })
            })
            if (!APP_CONFIG.production) {
                //connectFirestoreEmulator(firestore, 'localhost', 9185);
            }
            return firestore;
        }),
        provideStorage(() => getStorage()),
        provideAnalytics(() => getAnalytics()),

        // primeng
        MenuModule,
        ButtonModule,
        ToastModule,
        TooltipModule,
        InputTextModule,
        DividerModule,
        PanelModule,
        DialogModule,
        PasswordModule,
        ProgressBarModule,
        CarouselModule,
        SelectButtonModule,
        CheckboxModule,

        CoreModule,
        SharedModule,
        AppRoutingModule,
        HomeModule,
        DetailModule,
        AdminHomeComponent,
        PricingPanelComponent,
        LeftTopBarComponent,
        RightTopBarComponent,
        LoginPageComponent,

        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            //registrationStrategy: 'registerWhenStable:30000'
            registrationStrategy: 'registerImmediately'
        })
    ],
    providers: [
        I18nModule.setLocale(),
        I18nModule.setLocaleId(),

        MessageService,

        // Sentry
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false, // was true
            }),
        }, {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },

    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
