import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PageNotFoundComponent } from './components/';
import { WebviewDirective } from './directives/';
import { FormsModule } from '@angular/forms';

import { SafePipe } from '../pipes'


@NgModule({
    declarations: [PageNotFoundComponent, WebviewDirective, SafePipe],
    imports: [CommonModule, FormsModule],
    exports: [WebviewDirective, FormsModule, SafePipe]
})
export class SharedModule { }
