import { LayerId } from '../../parkour-canvas/parkour-canvas'
import { UiCommandId } from "../detail.ui.commands.defs"
import { ParkourObjectConfig } from "./parkour-object"
import { SvgImage } from "./svg-image"


export class Landscape extends SvgImage {
    protected readonly canResize: boolean = true;
    protected readonly preferredLayer: LayerId = LayerId.FRONT;
    protected readonly configurableLayer: boolean = true;

    constructor(protected config: ParkourObjectConfig) {
        super(config)
        const object = config.object
        this.layer = object.layer || this.preferredLayer
        this.loadObject()
        if (this.view && this.contextMenu) {
            this.contextMenu.add(this.view.uiCommands.getContextMenuItem(UiCommandId.IMAGE_SELECT))
        }
    }

    public scaleObject(scale: number): boolean {
        return this.scaleObjectInternal(scale)
    }

    doubleClick(): boolean {
        this.view?.openImageSelectTab(false)
        return false
    }
}
