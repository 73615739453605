import paper from 'paper'
import { OutlinedText } from "../detail.paper.extensions"
import { ParkourObjectConfig } from "./parkour-object"
import { SvgImage } from "./svg-image"

export class InOutMarker extends SvgImage {
    readonly inOutText: OutlinedText
    protected readonly canResize: boolean = true

    constructor(protected config: ParkourObjectConfig) {
        super(config)
        const object = config.object
        if (!object.angle) {
            this.angle = 0
        }
        this.loadObject()
        this.inOutText = new OutlinedText({
            content: 'in/out',
            strokeColor: '#000',
            fontFamily: 'Roboto',
            fontSize: this.cfg.getFontSize()
        })
        this.allGroup.addChild(this.inOutText)
        this.inOutText.bringToFront()
    }

    _setInOutPos() {
        const cx = this.getPosition().x
        const cy = this.getPosition().y
        const angle = (this.angle + 360) % 360

        if (angle >= 45 && angle < 135) {
            // left
            this.inOutText.position = new paper.Point(cx - 200, cy)
        } else if (angle >= 135 && angle < 225) {
            // top
            this.inOutText.position = new paper.Point(cx, cy - 200)
        } else if (angle >= 225 && angle < 315) {
            // right
            this.inOutText.position = new paper.Point(cx + 200, cy)
        } else {
            // bottom
            this.inOutText.position = new paper.Point(cx, cy + 200)
        }
        this.inOutText.setRotation(0)
    }

    drawExtraElements() {
        this._setInOutPos()
        this.inOutText.bringToFront()
    }

    rotationDone() {
        super.rotationDone()
        this._setInOutPos()
    }

    scaleObject(scale: number): boolean {
        return this.scaleObjectInternal(scale)
    }
}
