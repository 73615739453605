<p-dialog [(visible)]="visibility"
    [style]="{'min-width': '40rem', 'max-width': '75rem','width': 'fit-content'}"
    (keydown.enter)="onOkDialog()" (keydown.escape)="onCancelDialog()"
    (onShow)="onShowDialog()" (onClose)="onCloseDialog()" (onHide)="onHideDialog()">
    <ng-template pTemplate="header">
        <span class="p-dialog-title"><i class="fa-solid fa-list-ol mr-2"></i> <span i18n>Statystyki projektu</span></span>
    </ng-template>
    <div class="flex flex-column w-45rem" *ngIf="view.designData && designStats">
        <div class="flex w-full" *ngIf="view.cfg.params.eventName">
            <div class="col-6" i18n>Wydarzenie</div>
            <div class="col-6">{{ view.cfg.params.eventName }}</div>
        </div>
        <div class="flex w-full">
            <div class="col-6" i18n>Tytuł</div>
            <div class="col-6">{{ view.cfg.params.title }}</div>
        </div>
        <div class="flex w-full pb-0" *ngIf="view.cfg.params.authorName">
            <div class="col-6" i18n>Autor</div>
            <div class="col-6">{{ view.cfg.params.authorName }}</div>
        </div>
        <div class="flex w-full">
            <div class="col-6"></div>
            <div class="col-3"><h3 i18n>Runda 1</h3></div>
            <div class="col-3" *ngIf="designStats.length[1]"><h3 i18n>Runda 2</h3></div>
        </div>
        <div class="flex w-full">
            <div class="col-6" i18n>Długość</div>
            <div class="col-3">{{ designStats.length[0] | length: view.cfg.params.distanceUnit : true }}</div>
            <div class="col-3" *ngIf="designStats.length[1]">{{ designStats.length[1] | length: view.cfg.params.distanceUnit : true }}</div>
        </div>
        <div class="flex w-full">
            <div class="col-6" i18n>Skorygowana długość</div>
            <div class="col-3">{{ designStats.correctedLength[0] | length: view.cfg.params.distanceUnit : true }}</div>
            <div class="col-3" *ngIf="designStats.length[1]">{{ designStats.correctedLength[1] | length: view.cfg.params.distanceUnit : true }}</div>
        </div>
        <div class="flex w-full">
            <div class="col-6" i18n>Skoków</div>
            <div class="col-3">{{ designStats.effortsCount[0] }}</div>
            <div class="col-3" *ngIf="designStats.length[1]">{{ designStats.effortsCount[1] }}</div>
        </div>
        <div class="flex w-full">
            <div class="col-6" i18n>Przeszkód</div>
            <div class="col-3">{{ designStats.obstaclesCount[0] }}</div>
            <div class="col-3" *ngIf="designStats.length[1]">{{ designStats.obstaclesCount[1] }}</div>
        </div>
        <div class="flex w-full">
            <div class="col-6" i18n>Skrętów w lewo</div>
            <div class="col-3">{{ designStats.leftTurnsCount[0] }}</div>
            <div class="col-3" *ngIf="designStats.length[1]">{{ designStats.leftTurnsCount[1] }}</div>
        </div>
        <div class="flex w-full">
            <div class="col-6" i18n>Skrętów w prawo</div>
            <div class="col-3">{{ designStats.rightTurnsCount[0] }}</div>
            <div class="col-3" *ngIf="designStats.length[1]">{{ designStats.rightTurnsCount[1] }}</div>
        </div>
        <div class="flex w-full">
            <div class="col-6" i18n>Szeregów</div>
            <div class="col-3">{{ designStats.combinations[0] }}</div>
            <div class="col-3" *ngIf="designStats.length[1]">{{ designStats.combinations[1] }}</div>
        </div>
        <div class="flex w-full">
            <hr>
        </div>
        <div class="flex w-full align-items-center" *ngFor="let o of designStats.obstacles">
            <div class="col-6 flex py-0 align-items-center gap-2">
                <div class="w-10rem">{{ o.kind }}</div>
                <svg *ngIf="o.svg"
                     width="30px" height="30px" viewBox="-25 -25 50 50"
                     fill="none" xmlns="http://www.w3.org/2000/svg" [innerHTML]="o.svg"></svg>
            </div>
            <div class="col-3">{{ o.counts[0] }}</div>
            <div class="col-3" *ngIf="designStats.length[1]">{{ o.counts[1] }}</div>
        </div>
        <div class="flex w-full">
            <hr>
        </div>
        <div class="flex w-full">
            <div class="col-6" i18n>Pozostałych obiektów</div>
            <div class="col-3">{{ designStats.objectsCount }}</div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="onOkDialog()"
                  label="Zamknij" i18n-label></p-button>
    </ng-template>
</p-dialog>

