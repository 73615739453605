import paper from 'paper';
import { UiContextMenu } from './detail.ui.commands.defs'

export interface Selectable {
    selectedBeforeMouseDown: boolean
    changed: boolean
    levelItem?: paper.Item
    focusLock: boolean
    bounds?: paper.Rectangle
    parentSelector?: Selectable
    children?: Selectable[]
    contextMenu?: UiContextMenu

    select(point?: paper.Point): void
    deselect(): void
    getPosition(): paper.Point
    setPosition(point: paper.Point): void
    drag(delta: paper.Point, point: paper.Point): void
    move(delta: paper.Point): void
    rotate(angleDelta: number): void
    getRotation(): number
    scaleObject(scale: number): boolean
    doubleClick(point?: paper.Point): boolean // return true if path needs updating
    isSelected(): boolean
    update(): void // redraw elements
}

export interface Editable {
    readonly thisIsEditable: true
    editMode: boolean
    onMouseDown?(point: paper.Point): boolean // return true to stop processing further
    onMouseUp?(point: paper.Point): boolean // return true to stop processing further
    onMouseMove?(point: paper.Point): boolean // return true to stop processing further
    onMouseDrag?(delta: paper.Point): boolean // return true to stop processing further
    onMouseWheel?(delta: number): boolean // return true to stop processing further
    delete?(): boolean // return true if whole object should be deleted
    onEditStart(point: paper.Point, idx?: number): void
    onEditEnd(): boolean // return true if whole object should be deleted
}

export function isEditable(o: any): Editable | undefined {
    if (o && 'thisIsEditable' in o) {
        return o as Editable
    }
    return undefined
}

export class Selection {
    selectedItems: Selectable[] = []
    focusItem?: Selectable
    rectangle?: paper.Rectangle
    shape?: paper.Path
    group?: paper.Group
    startPoint?: paper.Point
    frozen?: Selectable[]
    keepSelected: boolean = false

    // all items including content of groups
    get selectedFlatItems(): Selectable[] {
        const r: Selectable[] = []
        for (let i of this.selectedItems) {
            if (!r.includes(i)) {
                r.push(i)
            }
            if (i.children) {
                for (let c of i.children) {
                    if (!r.includes(c)) {
                        r.push(c)
                    }
                }
            }
        }
        return r
    }

    getFocusItem(): Selectable | null {
        return this.focusItem || null
    }

    clone(): Selection {
        let sel = new Selection()
        sel.selectedItems = this.selectedItems.slice()
        return sel
    }

    inLastSelection(item: Selectable): boolean {
        return (this.frozen && this.frozen.includes(item)) || false
    }

    getSelectedOfType<T>(type: abstract new (...args: any[]) => T): T[] {
        const out: T[] = []
        for (let o of this.selectedItems) {
            if (o instanceof type) {
                out.push(<T>o)
            }
        }
        return out
    }

    _createShape() {
        if (!this.group) {
            this.group = new paper.Group()
        }
        if (this.rectangle) {
            let oldShape = this.shape
            this.shape = new paper.Path.Rectangle(this.rectangle)
            this.shape.strokeColor = new paper.Color('#aaa')
            this.shape.strokeScaling = false

            this.shape.strokeCap = 'round'
            this.shape.dashArray = [5, 5]
            if (oldShape) {
                oldShape.replaceWith(this.shape)
            } else {
                this.group.addChild(this.shape)
            }
        }
    }

    startSelection(point: any) {
        this.frozen = this.selectedItems.slice()
        this.startPoint = point
        this.rectangle = new paper.Rectangle(point, [1, 1])
        this._createShape()
        this.keepSelected = false
    }

    expandSelection(point: any) {
        if (this.rectangle && this.startPoint) {
            this.rectangle.topLeft.x = Math.min(this.startPoint.x, point.x)
            this.rectangle.topLeft.y = Math.min(this.startPoint.y, point.y)
            this.rectangle.bottomRight.x = Math.max(this.startPoint.x, point.x)
            this.rectangle.bottomRight.y = Math.max(this.startPoint.y, point.y)
            this._createShape()
            this.keepSelected = true
        }
    }

    endSelection() {
        if (this.shape && this.shape.isInserted()) {
            this.shape.remove()
        }
        this.shape = undefined
        this.rectangle = undefined
        this.frozen = undefined
        this.keepSelected = false
    }

    selectItem(item: Selectable, point?: any, makeFocus?: boolean, after?: Selectable, before?: Selectable) {
        let idx = this.selectedItems.indexOf(item)
        if (idx < 0) {
            let insertIdx = this.selectedItems.length
            if (after) {
                const afterIdx = this.selectedItems.indexOf(after)
                if (afterIdx >= 0) {
                    insertIdx = afterIdx + 1
                }
            } else if (before) {
                const beforeIdx = this.selectedItems.indexOf(before)
                if (beforeIdx >= 0) {
                    insertIdx = beforeIdx
                }
            }
            this.selectedItems.splice(insertIdx, 0, item)
        } else if (after) {
            let afterIdx = this.selectedItems.indexOf(after)
            if (afterIdx !== idx && afterIdx + 1 !== idx) {
                // change location of the selected item in the selection
                this.selectedItems.splice(idx, 1)
                if (idx < afterIdx) {
                    afterIdx--
                }
                this.selectedItems.splice(afterIdx + 1, 0, item)
            }
        } else if (before) {
            let beforeIdx = this.selectedItems.indexOf(before)
            if (beforeIdx !== idx && beforeIdx - 1 !== idx) {
                // change location of the selected item in the selection
                this.selectedItems.splice(idx, 1)
                if (idx < beforeIdx) {
                    beforeIdx--
                }
                this.selectedItems.splice(beforeIdx, 0, item)
            }
        }
        if (makeFocus) {
            this.focusItem = item
        } else {
            this.focusItem = undefined
        }
        item.select(point)
        item.parentSelector?.select(point)
    }

    deselectItem(item: Selectable) {
        let idx = this.selectedItems.indexOf(item)
        if (idx >= 0) {
            this.selectedItems.splice(idx, 1)
        }
        if (this.focusItem === item) {
            this.focusItem = undefined
        }
        item.deselect()
        if (item.parentSelector) {
            let selected = false
            if (item.parentSelector.children) {
                for (let c of item.parentSelector.children) {
                    if (c.isSelected()) {
                        selected = true
                        break
                    }
                }
            }
            if (!selected) {
                item.parentSelector.deselect()
            }
        }
    }

    isSelected(item: Selectable) {
        let idx = this.selectedItems.indexOf(item)
        return idx != -1 ? true : false
    }

    clear() {
        this.focusItem = undefined
        for (const item of this.selectedItems) {
            item.deselect()
            item.parentSelector?.deselect()
        }
        this.selectedItems.length = 0
    }

    move(delta: paper.Point) {
        for (const item of this.selectedItems) {
            item.move(delta)
        }
    }

    rotate(deltaAngle: number) {
        for (const item of this.selectedItems) {
            item.rotate(deltaAngle)
        }
    }
}
