
<p-dialog [(visible)]="previewVisible" (onHide)="closePreview()">
    <div class="w-full h-full max-w-full max-h-full">
        <img class="big-preview-png" *ngIf="preview" [src]="preview" (click)="closePreview()"/>
    </div>
</p-dialog>

<div *ngIf="userProfile && userProfile.role && userProfile.role === 'admin'; else noAccessHere" class="flex flex-column p-4 w-full">
    <div class="flex align-items-center font-bold text-indigo-400 text-3xl pb-2 border-bottom-2">
        <span i18n>Użytkownicy</span>
    </div>

    <div class="grid" style="height: calc(100vh - 210px); max-height: calc(100vh - 210px);">
        <div class="col-12 md:col-8 flex flex-column h-full overflow-y-auto">
            <div *ngIf="listMsg" class="m-2">
                {{ listMsg }}
            </div>
            <p-table #dt1 [value]="users" dataKey="uid" [tableStyle]="{ 'min-width': '50rem' }" [loading]="loadingList"
                [scrollable]="true"
                [globalFilterFields]="['uid', 'email', 'name', 'role', 'createdAt', 'lastLoginAt']">
                <ng-template pTemplate="caption">
                    <div class="flex align-items-center">
                        <i class="pi pi-search" style="width:25px"></i>
                        <input pInputText class="p-2 mr-1" type="text" (input)="filterValue($event, dt1)" placeholder="Szukaj" i18n-placeholder/>
                        <p-button
                            label="Załaduj z projektów" i18n-label class="px-1 pl-3"
                            [rounded]="false" [text]="true" [outlined]="true"
                            (onClick)="loadUsersFromDesignsList()"></p-button>
                        <p-button
                            label="Załaduj z profili" i18n-label class="px-1"
                            [rounded]="false" [text]="true" [outlined]="true"
                            (onClick)="loadUsersFromProfiles()"></p-button>
                        <p-button
                            label="Wyczyść tabelę" i18n-label class="px-1"
                            [rounded]="false" [text]="true" [outlined]="true"
                            (onClick)="users = []"></p-button>
                        <input type="file" (change)="importDesignFromFile($event)">

                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 5rem"></th>
                        <th></th>
                        <th>ID</th>
                        <th pSortableColumn="designsCount"><span i18n>Projekty</span><p-sortIcon field="designsCount"></p-sortIcon></th>
                        <th pSortableColumn="designsCountLastWeek"><span i18n>7 dni</span><p-sortIcon field="designsCountLastWeek"></p-sortIcon></th>
                        <th pSortableColumn="email" >Email<p-sortIcon field="email"></p-sortIcon></th>
                        <th pSortableColumn="newsletterConsent"><span i18n>Zgoda email</span><p-sortIcon field="newsletterConsent"></p-sortIcon></th>
                        <th pSortableColumn="name"><span i18n>Nazwa</span><p-sortIcon field="name"></p-sortIcon></th>
                        <th pSortableColumn="role"><span i18n>Rola</span><p-sortIcon field="role"></p-sortIcon></th>
                        <th pSortableColumn="createdAt"><span i18n>Utworzony</span><p-sortIcon field="createdAt"></p-sortIcon></th>
                        <th pSortableColumn="lastLoginAt"><span i18n>Zalogowany</span><p-sortIcon field="lastLoginAt"></p-sortIcon></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-user let-expanded="expanded">
                    <tr>
                        <td>
                            <button *ngIf="user.events" type="button" pButton pRipple [pRowToggler]="user" class="p-button-text p-button-rounded p-button-plain"
                                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'">
                            </button>
                        </td>
                        <td><img *ngIf="user.photoURL" width="30" [src]="user.photoURL"/></td>
                        <td style="font-family: 'Courier New', Courier, monospace;">{{ user.uid }}</td>
                        <td>{{ user.designsCount ? user.designsCount : '' }}</td>
                        <td>{{ user.designsCountLastWeek ? user.designsCountLastWeek : '' }}</td>
                        <td>{{ user.email }}</td>
                        <td>
                            <i *ngIf="user.newsletterConsent === true" class="fa-solid fa-check text-xl"></i>
                            <i *ngIf="user.newsletterConsent === false" class="fa-solid fa-xmark text-xl"></i>
                        <td>{{ user.name }}</td>
                        <td>{{ user.role }}</td>
                        <td>{{ user.createdAt | date:'yyyy-MM-dd HH:mm'}}</td>
                        <td>{{ user.lastLoginAt | date:'yyyy-MM-dd HH:mm'}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-user>
                    <tr>
                        <td></td>
                        <td colspan="8">
                            <p-table [value]="user.events" dataKey="name" [scrollable]="true"
                                sortField="createdAt" [sortOrder]="-1" styleClass="p-datatable-gridlines p-datatable-striped">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width: 5rem"></th>
                                        <th pSortableColumn="name"><span i18n>Wydarzenie</span><p-sortIcon field="name"></p-sortIcon></th>
                                        <th pSortableColumn="designs.length"><span i18n>Projekty</span><p-sortIcon field="designs.length"></p-sortIcon></th>
                                        <th pSortableColumn="date"><span i18n>Data</span><p-sortIcon field="date"></p-sortIcon></th>
                                        <th pSortableColumn="createdAt"><span i18n>Utworzono</span><p-sortIcon field="createdAt"></p-sortIcon></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-event let-expanded="expanded">
                                    <tr>
                                        <td>
                                            <button *ngIf="event.designs" type="button" pButton pRipple [pRowToggler]="event" class="p-button-text p-button-rounded p-button-plain"
                                                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                                                (click)="loadPreviews(event.designs)">
                                            </button>
                                        </td>
                                        <td>
                                            {{ event.name ? event.name : noEventName }}
                                        </td>
                                        <td>
                                            {{ event.designs.length }}
                                        </td>
                                        <td>
                                            {{ event.date | date:'yyyy-MM-dd' }}
                                        </td>
                                        <td>
                                            {{ event.createdAt | date:'yyyy-MM-dd' }}
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="rowexpansion" let-event>
                                    <tr>
                                        <td></td>
                                        <td colspan="6">
                                            <p-table [value]="event.designs" dataKey="title" [scrollable]="true"
                                                sortField="createdAtDate" [sortOrder]="-1" styleClass="p-datatable-gridlines p-datatable-striped">
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th i18n>Konkurs</th>
                                                        <th i18n>Podgląd</th>
                                                        <th pSortableColumn="eventDate"><span i18n>Data</span><p-sortIcon field="eventDate"></p-sortIcon></th>
                                                        <th pSortableColumn="createdAtDate"><span i18n>Utworzono</span><p-sortIcon field="createdAtDate"></p-sortIcon></th>
                                                        <th i18n>Akcje</th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-design>
                                                    <tr>
                                                        <td>
                                                            <a [routerLink]="['/designer']" [queryParams]="{id: design.localId, author: design.author}">{{ design.title }}</a>
                                                        </td>
                                                        <td>
                                                            <div class="preview-png flex justify-content-center"
                                                                *ngIf="design.pngUrl != 'progress' && (design.pngUrl || design.png)"
                                                                (click)="handlePreview(design)">
                                                                <img [src]="design.pngUrl || design.png" crossorigin="anonymous" (error)="design.pngUrl = null">
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {{ design.eventDate | date:'yyyy-MM-dd' }}
                                                        </td>
                                                        <td>
                                                            {{ design.createdAtDate | date:'yyyy-MM-dd' }}
                                                        </td>
                                                        <td>
                                                            <p-button
                                                                icon="pi pi-clone"
                                                                [rounded]="true" [outlined]="true" severity="info"
                                                                styleClass="ml-2"
                                                                (click)="cloneDesign(design)"
                                                                pTooltip="Sklonuj projekt do siebie" i18n-pTooltip></p-button>
                                                            <p-button
                                                                icon="pi pi-download"
                                                                [rounded]="true" [outlined]="true" severity="info"
                                                                styleClass="ml-2"
                                                                (click)="exportDesignToFile(design)"
                                                                pTooltip="Zapisz do pliku" i18n-pTooltip></p-button>
                                                        </td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<ng-template #noAccessHere>
    <div class="font-bold text-indigo-400 text-3xl p-4" i18n>Brak dostępu do tej strony.</div>
</ng-template>
