import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';
import { TooltipModule } from 'primeng/tooltip';
import { DetailComponent } from '../../detail.component';
import { DialogModule } from 'primeng/dialog'
import { ParkourDialog } from '../detail.dialog.interface'
import { SelectButtonModule } from 'primeng/selectbutton'
import { ObstacleMaterial } from '../../../design.schema'
import { SharedModule } from '../../../shared/shared.module'
import { ObstacleWithBars } from '../../parkour-objects/obstacle-with-bars'
import { Obstacle } from '../../parkour-objects/obstacle'

@Component({
    selector: 'app-parkour-materials',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        ButtonModule,
        InputNumberModule,
        TooltipModule,
        DialogModule,
        SelectButtonModule,
    ],
    templateUrl: './parkour-materials.component.html',
    styleUrl: './parkour-materials.component.scss'
})
export class ParkourMaterialsComponent implements ParkourDialog {
    @Input({ required: true }) view!: DetailComponent
    @Output() onOk = new EventEmitter<Obstacle>()
    @Output() onCancel = new EventEmitter<Obstacle>()
    @Output() onDelete = new EventEmitter<Obstacle>()

    topMaterialOptions = [
        { name: $localize`Drąg`, value: ObstacleMaterial.BAR },
        { name: $localize`Drąg wyżej`, value: ObstacleMaterial.BAR_RAISED, styleClass: "w-full" },
    ]

    materialOptionsWithWall = [
        { name: $localize`Drąg`, value: ObstacleMaterial.BAR },
        { name: $localize`Deska`, value: ObstacleMaterial.PLANK },
        { name: $localize`Mur`, value: ObstacleMaterial.BRICKS },
    ]

    materialOptionsNoWall = [
        { name: $localize`Drąg`, value: ObstacleMaterial.BAR },
        { name: $localize`Deska`, value: ObstacleMaterial.PLANK },
        { name: $localize`Mur`, value: ObstacleMaterial.BRICKS, disabled: true },
    ]

    visibility: boolean = false
    object?: ObstacleWithBars

    addRemoveMaterialsRow(add: boolean) {
        const o = this.object
        if (o && o.materials) {
            if (add) {
                o.materials.addMaterialRow()
            } else {
                o.materials.removeMaterialRow()
            }
            o.drawMaterials(false)
            o.materials.generateSvg()
        }
    }

    finishChangeMaterials() {
        const o = this.object
        if (o && o.materials) {
            o.drawMaterials(false)
            o.materials.generateSvg()
        }
    }

    onCloseDialog() {
    }

    onShowDialog() {
    }

    onHideDialog() {
    }

    onOkDialog() {
        if (!this.object) {
            return
        }
        this.object.drawMaterials(true)
        this.visibility = false
        this.onOk.emit(this.object)
    }

    onCancelDialog() {
        if (!this.object) {
            return
        }
        this.object.restoreMaterials(true)
        this.visibility = false
        this.onCancel.emit()
    }

    onDeleteDialog() {
        if (!this.object) {
            return
        }
        if (this.object.materials) {
            this.object.materials.destroy()
            this.object.materials = undefined
        }
        this.visibility = false
        this.onDelete.emit(this.object)
    }

    open(o?: ObstacleWithBars) {
        if (!o) {
            return
        }
        this.object = o
        o.addMaterials(false)
        if (o.materials) {
            o.materials.visible = true
            o.drawMaterials(false)
            o.materials.generateSvg()
            this.visibility = true
        } else {
            this.object = undefined
        }
    }

    closeOk() {
        this.onOkDialog()
    }

    closeCancel() {
        this.onCancelDialog()
    }
}