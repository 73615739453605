<p-dialog [modal]="true" [(visible)]="renameVisible" [resizable]="false"
    [style]="{ minWidth: '20vw', maxWidth: '50vw', width: 'auto' }"
    (keydown.enter)="renameEvent()" (keydown.escape)="cancelRenameEvent()">
    <ng-template pTemplate="header">
        <span class="p-dialog-title"><i class="fa-regular fa-pen-to-square mr-2"></i> <span i18n>Zmień nazwę wydarzenia</span></span>
    </ng-template>
    <div class="flex align-items-center gap-3 mb-3 w-full">
        <input type="text" class="flex-auto" pInputText autoSizeInput [includeBorders]=true [(ngModel)]="newEventName"/>
    </div>
    <div class="flex justify-content-end gap-2">
        <p-button icon="pi pi-times" (click)="cancelRenameEvent()" label="Anuluj" i18n-label severity="danger"></p-button>
        <p-button icon="pi pi-check" (click)="renameEvent()" label="Zapisz dane" i18n-label></p-button>
    </div>
</p-dialog>

<p-confirmDialog/>

<div class="flex flex-column p-4 w-full">
    <!-- content panel -->
    <div class="grid" style="height: calc(100vh - 140px); max-height: calc(100vh - 140px);">

        <!-- left panel with list of events -->
        <div class="col-12 md:col-4 flex flex-column h-full">

            <!-- top tool bar -->
            <div class="flex justify-content-between font-bold text-indigo-400 mb-4 pb-2 border-bottom-2">
                <div class="text-3xl" i18n>Twoje Projekty</div>
                <div>
                    <p-button
                        icon="pi pi-search"
                        [rounded]="true" [text]="true"
                        (onClick)="searchPanelVisible = !searchPanelVisible"></p-button>
                    <p-button class="mr-3"
                        icon="pi pi-refresh"
                        [rounded]="true" [text]="true"
                        [loading]="loadingList"
                        (onClick)="loadDesignsList()"></p-button>
                    <p-button *ngFor="let s of eventSorter.modes" 
                        [icon]="s.icon" [rounded]="true" [text]="true" [pTooltip]="s.name" tooltipPosition="top" 
                        (onClick)="eventSort(s)" [outlined]="s.active">
                    </p-button>
                </div>
            </div>

            <div class="flex flex-column align-content-start p-2 h-full w-full overflow-y-auto">

                <div *ngIf="searchPanelVisible" class="mx-2 mt-0 pt-0 mb-3 pb-3">
                    <input class="py-0 my-0"
                        pInputText
                        id="searchText"
                        [(ngModel)]="searchText"
                        (keyup)="doSearch($event)"
                        class="w-12rem"/>
                </div>

                <div *ngIf="listMsg" class="m-2">
                    {{ listMsg }}
                </div>

                <ng-container *ngFor="let e of eventsWithDesigns">
                    <div class="p-2 border-bottom-1 border-300 cursor-pointer"
                        [ngClass]="e === selectedEvent ? 'surface-200' : ''"
                        (click)="showEventDesigns(e)">

                        <div class="flex font-bold pb-2 border-indigo-100 text-indigo-600 text-xl align-items-baseline justify-content-between">
                            <div>{{ e.eventName }}</div>
                            <div pTooltip="Data wydarzenia" i18n-pTooltip
                                class="text-indigo-500 text-base min-w-max text-right">
                                {{ e.eventDatesRange }}
                            </div>
                        </div>
                        <div class="flex justify-content-between">
                            <div class="flex align-items-baseline">
                                <div pTooltip="Wymiary pola" i18n-pTooltip class="p-1 text-600">
                                    {{ e.designs[0].parkourWidth }} <span class="text-500">x</span> {{ e.designs[0].parkourHeight }}
                                    <span class="text-500">m</span>
                                </div>
                                <div pTooltip="Data aktualizacji" i18n-pTooltip class="text-500 p-1 pl-2 text-sm">
                                    {{ e.updatedAtDate | date:'short' }}
                                    <i class="pi pi-save text-red-200 text-sm" *ngIf="!e.designs[0].saved"
                                        pTooltip="Ostatnie zmiany nie są zapisane na serwerze, tylko lokalnie" i18n-pTooltip>
                                    </i>
                                </div>
                                <div pTooltip="Liczba projektów" i18n-pTooltip class="text-500 p-1 pl-3 text-sm">
                                    {{ e.designs.length }}
                                </div>
                            </div>
                            <div (click)="eventMenu.toggle($event)">
                                <i class="pi pi-ellipsis-h text-sm text-500"></i>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <p-menu #eventMenu [model]="eventMenuItems" [popup]="true">
            </p-menu>
        </div>

        <div class="col-12 md:col-8 flex flex-column h-full" *ngIf="displayModes[displayMode] as mode">
            <!-- top tool bar -->
            <div class="flex justify-content-between align-items-center font-bold text-indigo-400 mb-4 pb-2 border-bottom-2">
                <div class="flex align-items-center">
                    <p-button class="mr-3" [icon]="mode.icon" pTooltip="Przełącz widok" i18n-pTooltip tooltipPosition="top" 
                        [rounded]="true" [text]="true" (onClick)="toggleDisplayMode()">
                    </p-button>
                    <p-button *ngFor="let s of competitionSorter.modes" 
                        [icon]="s.icon" [rounded]="true" [text]="true" [pTooltip]="s.name" tooltipPosition="top" 
                        (onClick)="competitionSort(s)" [outlined]="s.active">
                    </p-button>
                </div>
                <div class="flex align-items-center">
                    <i class="fa-solid fa-plus fa-2xl cursor-pointer" 
                        (click)="createNewDesignInEvent()" pTooltip="Nowy konkurs w tym wydarzeniu" i18n-pTooltip>
                    </i>
                </div>
            </div>

            <div *ngIf="!selectedEvent && eventsWithDesigns.length > 0" class="pl-2" i18n>Wybierz wydarzenie z listy po lewej</div>
            <ng-container *ngIf="selectedEvent">

                <!-- right panel with grid with designs -->
                <div *ngIf="displayMode === 0" class="flex flex-wrap align-content-start p-2 h-full w-full overflow-y-auto cursor-move">

                    <div *ngFor="let d of selectedEvent.designs" class="flex flex-column p-2 pb-3"
                        [ngClass]="selectedEvent.designs.length <= 2 ? 'col-12 md:col-8' :
                            (selectedEvent.designs.length <= 4 ? 'col-12 md:col-6' : 'col-12 md:col-4')" 
                        [attr.draggable]="!useDragHandles" (dragstart)="onTileDragStart(d, $event)" (dragend)="onTileDragEnd()"
                        (dragenter)="onTileDropEnter(d, $event)" (dragover)="onTileDropOver($event)" (drop)="onTileDrop(d)">
                        <div class="flex flex-row align-items-center pl-0 pb-2 border-bottom-1 border-indigo-100 ">
                            <div *ngIf="useDragHandles" class="fa-solid fa-bars fa-2xl" draggable="true"></div>
                            <div class="w-full font-bold text-xl pl-3 unselectable">
                                <a [routerLink]="user ? ['/designer'] : null" [queryParams]="{id: d.localId, author: d.author}" class="text-indigo-400">
                                    <div class="flex flex-row justify-content-between align-content-start">
                                        <span class="pr-2">{{ d.title || ''}}</span>
                                        <div class="flex flex-column text-base min-w-max">
                                            <span>{{ d.eventDate | date:'yyyy-MM-dd' }}</span>
                                            <span>{{ d.eventDate | date:'EEEE' }}</span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div class="flex justify-content-between">
                            <div class="flex p-1 w-full" style="overflow-y: clip;"
                                [ngStyle]="{'height': selectedEvent.designs.length <= 2 ? '17rem' :
                                            (selectedEvent.designs.length <= 4 ? '15rem' : '13rem')}">
                                <div class="preview-png" *ngIf="d.png || (d.pngUrl && d.pngUrl !== 'progress')"
                                    pTooltip="Kliknij aby obejrzeć podgląd"
                                    i18n-pTooltip
                                    (click)="handlePreview(d)">
                                    <img [src]="d.png || d.pngUrl" style="width: 100%; object-fit: contain; object-position: top;" crossorigin="anonymous"
                                        (error)="onImageError(d)" (load)="onImageLoad($event, d)">
                                </div>
                                <div class="preview-png" *ngIf="d.pngUrl === 'progress' && !d.png">
                                    <p-skeleton width="200px" height="8rem" styleClass="m-2"></p-skeleton>
                                </div>
                                <div class="preview-png m-auto text-400" *ngIf="!d.pngUrl && !d.png" i18n>brak podglądu</div>
                            </div>

                            <div class="flex flex-column pl-4 pt-2">
                                <p-button
                                    icon="pi pi-pencil"
                                    [rounded]="true" [outlined]="true" severity="success"
                                    styleClass="m-1"
                                    (click)="editDesign(d)"
                                    pTooltip="Edytuj projekt" i18n-pTooltip></p-button>
                                <p-button
                                    icon="pi pi-clone"
                                    [rounded]="true" [outlined]="true" severity="info"
                                    styleClass="m-1"
                                    (click)="cloneDesign(d)"
                                    pTooltip="Zduplikuj projekt" i18n-pTooltip></p-button>
                                <p-button
                                    icon="pi pi-trash"
                                    [rounded]="true" [outlined]="true" severity="danger"
                                    styleClass="m-1"
                                    (click)="deleteDesign(d)"
                                    pTooltip="Usuń projekt" i18n-pTooltip></p-button>
                            </div>
                        </div>
                    </div>
                </div>
        
                <!-- right panel with list with designs -->
                <div *ngIf="displayMode === 1" class="flex flex-wrap align-content-start p-2 h-full w-full overflow-y-auto">

                    <p-table [value]="selectedEvent.designs" class="w-full" (onRowReorder)="compRowReorder()">
                        <ng-template pTemplate="body" let-d let-index="rowIndex">
                            <tr [pReorderableRow]="index">
                                <td pReorderableRowHandle>
                                    <ng-container *ngIf="!useDragHandles">
                                        <span class="preview-png shadow-2 vertical-align-middle" style="max-width: fit-content;"
                                            *ngIf="d.pngUrl !== 'progress' && (d.pngUrl || d.png)" (click)="handlePreview(d)">
                                            <img [src]="d.png || d.pngUrl" style="width: 5rem" crossorigin="anonymous"
                                                (error)="onImageError(d)" (load)="onImageLoad($event, d)">
                                        </span>
                                        <span class="preview-png text-center text-400 vertical-align-middle" style="max-width: 5rem" *ngIf="!d.pngUrl && !d.png" i18n>brak</span>
                                    </ng-container>
                                    <span *ngIf="useDragHandles" class="fa-solid fa-bars fa-2xl"></span>
                                </td>
                                <td pReorderableRowHandle>
                                    <span class="unselectable cursor-pointer" (click)="editDesign(d)">{{ d.title }}</span>
                                </td>
                                <td pReorderableRowHandle>{{ d.eventDate | date:'yyyy-MM-dd' }}<br>{{ d.eventDate | date:'EEEE' }}</td>
                                <td class="vertical-align-middle" pReorderableRowHandle>
                                    <div class="flex flex-row flex-wrap pt-2 justify-content-end align-items-center">
                                        <p-button
                                            icon="pi pi-pencil"
                                            [rounded]="true" [outlined]="true" severity="success"
                                            styleClass="m-1"
                                            (click)="editDesign(d)"
                                            pTooltip="Edytuj projekt" i18n-pTooltip tooltipPosition="top"></p-button>
                                        <p-button
                                            icon="pi pi-clone"
                                            [rounded]="true" [outlined]="true" severity="info"
                                            styleClass="m-1"
                                            (click)="cloneDesign(d)"
                                            pTooltip="Zduplikuj projekt" i18n-pTooltip tooltipPosition="top"></p-button>
                                        <p-button
                                            icon="pi pi-trash"
                                            [rounded]="true" [outlined]="true" severity="danger"
                                            styleClass="m-1"
                                            (click)="deleteDesign(d)"
                                            pTooltip="Usuń projekt" i18n-pTooltip tooltipPosition="top"></p-button>
                                    </div>        
                                </td>
                            </tr>
                        </ng-template>                  
                    </p-table>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<parkour-canvas class="hidden" #canvas [cfg]="previewGenerationCfg">
</parkour-canvas>
