<div class="flex flex-column m-4">
    <div class="flex justify-content-between font-bold text-indigo-400 mb-4 pb-2 border-bottom-2">
        <div class="text-3xl">{{ listHeader }}</div>
        <div>
            <p-button
                icon="pi pi-search"
                [rounded]="true" [text]="true"
                (onClick)="searchPanelVisible = !searchPanelVisible"></p-button>
            <p-button
                icon="pi pi-sort-amount-down-alt"
                [rounded]="true" [text]="true"
                (onClick)="searchPanelVisible = !searchPanelVisible"></p-button>
            <p-button
                icon="pi pi-refresh"
                [rounded]="true" [text]="true"
                [loading]="loadingList"
                (onClick)="loadDesignsList()"></p-button>
        </div>
    </div>

    <div *ngIf="searchPanelVisible" class="mx-2 mt-1 mb-3">
        <input pInputText id="searchText" [(ngModel)]="searchText" (keyup)="doSearch($event)"/>
        <p-dropdown
            class="ml-4"
            [options]="sortOptions" [(ngModel)]="sortOption"
            optionLabel="name" optionValue="value"
            (onChange)="sortChange()"></p-dropdown>
    </div>

    <div *ngIf="listMsg" class="m-2">
        {{ listMsg }}
    </div>

    <ng-container *ngFor="let e of eventsWithDesigns">
    <div class="flex flex-column mx-1 my-2 p-3 pb-2 shadow-1"
         [ngClass]="e.designs.length == 1 ? '' : 'surface-50'">

        <div *ngIf="e.eventName && e.eventName.trim()" class="flex justify-content-between align-items-center pb-2">
            <div class="font-bold pt-1 pr-2 border-indigo-100 text-indigo-600 text-2xl">
                <i class="pi pi-calendar text-2xl text-indigo-400" pTooltip="Wydarzenie" i18n-pTooltip></i> {{ e.eventName.trim() }}
            </div>
            <div class="text-400 cursor-pointer" (click)="expandEventPanel(e)">
                <i class="pi text-3xl"
                   [ngClass]="e.expanded ? 'pi-chevron-circle-down' : 'pi-chevron-circle-left'"
                   pTooltip="Rozwiń lub zwiń listę projektów" i18n-pTooltip></i>
            </div>
        </div>

        <div style="overflow-y: clip;"
             [ngStyle]="{'height': e.designs.length > 1 && !e.expanded ? '200px' : '',
                         'background': e.designs.length > 1 && !e.expanded ? 'linear-gradient(0deg, rgba(237,237,237,0.7) 0%, rgba(250,248,248,0.6) 21%, rgba(255,255,255,0) 100%)' : ''}">

            <div *ngFor="let d of e.designs" class="flex flex-column p-1 pb-2">

                <div class="font-bold pl-2 pb-2 border-bottom-1 border-indigo-100 text-xl">
                    <a [routerLink]="user ? ['/designer'] : null" [queryParams]="{id: d.localId, author: d.author}" class="text-indigo-400">
                        <i class="pi pi-map text-xl text-indigo-200" pTooltip="Wydarzenie" i18n-pTooltip></i>&ngsp;
                        <span *ngIf="d.title && d.title.trim()">{{ d.title.trim() }}</span>
                        <span *ngIf="!d.title || !d.title.trim()" i18n>(bez nazwy)</span>
                    </a>
                </div>
                <div class="flex">
                    <div class="flex flex-column" style="width: 50%; max-width: 50%;">
                        <div pTooltip="Wymiary pola" i18n-pTooltip class="p-1">
                            <b>{{ d.parkourWidth }}</b> x <b>{{ d.parkourHeight }}</b> m
                        </div>
                        <div *ngIf="scope == 'public' && d.authorName" pTooltip="Autor" class="p-1">
                            {{ d.authorName }}
                        </div>
                        <div pTooltip="Data utworzenia" i18n-pTooltip class="text-500 p-1">
                            {{ d.createdAt | date:'short' }}
                        </div>
                        <div pTooltip="Data aktualizacji" i18n-pTooltip class="text-500 p-1">
                            {{ d.updatedAt | date:'short' }}
                            <i class="pi pi-save text-red-200" *ngIf="scope == 'my' && !d.saved" pTooltip="Ostatnie zmiany nie są zapisane na serwerze, tylko lokalnie" i18n-pTooltip></i>
                        </div>
                        <div *ngIf="user" class="mt-1">
                            <p-button
                                icon="pi pi-pencil"
                                [rounded]="true" [outlined]="true" severity="success"
                                (click)="editDesign(d)"
                                pTooltip="Edytuj projekt" i18n-pTooltip></p-button>
                            <p-button
                                *ngIf="scope == 'my'"
                                icon="pi pi-clone"
                                [rounded]="true" [outlined]="true" severity="info"
                                styleClass="ml-2"
                                (click)="cloneDesign(d)"
                                pTooltip="Zduplikuj projekt" i18n-pTooltip></p-button>
                            <p-button
                                *ngIf="scope == 'my'"
                                icon="pi pi-trash"
                                [rounded]="true" [outlined]="true" severity="danger"
                                styleClass="ml-2"
                                (click)="deleteDesign(d)"
                                pTooltip="Usuń projekt" i18n-pTooltip></p-button>
                        </div>
                    </div>
                    <div class="flex" style="width: 50%; max-width: 50%; height: 11rem; overflow-y: clip;">
                        <div class="preview-png" *ngIf="d.pngUrl != 'progress' && (d.pngUrl || d.png)"
                             pTooltip="Kliknij aby obejrzeć podgląd"
                             i18n-pTooltip
                             (click)="handlePreview(d)">
                            <img [src]="d.pngUrl || d.png" style="width: 100%; object-fit: cover; object-position: top;" crossorigin="anonymous"
                                 (error)="onImageError(d)" (load)="onImageLoad($event, d)">
                        </div>
                        <div class="preview-png" *ngIf="d.pngUrl == 'progress' && !d.png">
                            <p-skeleton width="200px" height="8rem" styleClass="m-2"></p-skeleton>
                        </div>
                        <div class="preview-png m-auto text-400" *ngIf="!d.pngUrl && !d.png" i18n>brak podglądu</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </ng-container>
</div>
