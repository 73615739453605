import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'primeng/tooltip';

import { getBuildVersion, getBuildDate } from '../utils';

@Component({
    selector: 'app-left-top-bar',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        TooltipModule,
    ],
    templateUrl: './left-top-bar.component.html',
    styleUrl: './left-top-bar.component.scss'
})
export class LeftTopBarComponent {
    version = getBuildVersion()
    buildDate = getBuildDate()
}
