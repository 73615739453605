import { Component, Inject, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { Subscription } from 'rxjs'
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { PasswordModule } from 'primeng/password';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { ProgressBarModule } from 'primeng/progressbar';

import { AuthService } from '../services/auth.service';
import { UserService, UserProfile } from '../services/user.service';
import { showErrorBox, showInfoBox, getWPWebLink } from '../utils';

@Component({
    selector: 'app-login-page',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        DialogModule,
        DividerModule,
        PasswordModule,
        CheckboxModule,
        ButtonModule,
        InputTextModule,
        TooltipModule,
        ProgressBarModule,
    ],
    templateUrl: './login-page.component.html',
    styleUrl: './login-page.component.scss'
})
export class LoginPageComponent {
    private subs: Subscription = new Subscription()

    signUpInProgress: boolean = false

    signInDialogVisible: boolean = true
    registerDialogVisible: boolean = false
    passwordResetDialogVisible: boolean = false
    registerPreselectedPlan?: any

    private emailInternal: string = ''
    emailInvalid: boolean = true
    password: string = ''
    newsletterConsent: boolean = false
    termsConsent: boolean = false

    linkToTerms: string
    linkToPrivacy: string

    constructor(
        private msgSvc: MessageService,
        protected auth: AuthService,
        private userService: UserService,
        @Inject(LOCALE_ID) private locale: string,
    ) {
        this.linkToPrivacy = getWPWebLink('privacy-policy', locale)
        this.linkToTerms = getWPWebLink('terms-and-conditions', locale)
    }

    get email(): string {
        return this.emailInternal
    }

    set email(e: string) {
        this.emailInternal = e
        this.emailInvalid = !this.validateEmail(e)
    }

    login(provider: string) {
        this.subs.add(
            this.auth.login(provider, this.email, this.password).subscribe({
                next: (resp) => {
                    console.info('LOGIN resp', resp)
                },
                error: (error) => {
                    console.info('LOGIN error', error)
                    let msg = ''
                    if (error.code === 'auth/user-not-found') {
                        msg = $localize`Nie ma takiego użytkownika`
                    } else if (error.code === 'auth/wrong-password') {
                        msg = $localize`Nieprawidłowe hasło`
                    } else if (error.code === 'auth/invalid-login-credentials' || error.code === 'auth/invalid-credential') {
                        msg = $localize`Nieprawidłowy użytkownik lub hasło`
                    } else if (error.code === 'auth/invalid-email') {
                        msg = $localize`Nieprawidłowy adres email`
                    } else if (error.code === 'auth/missing-password') {
                        msg = $localize`Nie podano hasła`
                    } else if (error.code === 'auth/popup-blocked') {
                        msg = $localize`Zablokowane wyskakujące okno. Odblokuj je aby się zalogować.`
                    } else {
                        msg = $localize`Nieznany błąd`
                    }
                    showErrorBox(this.msgSvc, $localize`Błąd przy logowaniu`, msg)
                }
            })
        )
    }

    signUp() {
        this.signUpInProgress = true
        this.subs.add(
            this.auth.signUp(this.email, this.password).subscribe({
                next: (userCredential) => {
                    const sub = this.userService.getUserProfile().subscribe({
                        next: (userProfile: UserProfile | undefined | null) => {
                            if (userProfile === null) {
                                // null means that user is not logged in so stop here
                                return
                            }
                            this.userService.updateUserProfile({
                                newsletterConsent: this.newsletterConsent,
                                termsConsent: this.termsConsent,
                            }).subscribe({
                                error: (err) => {
                                    console.error('error occured updating profile', err)
                                }
                            })
                            sub.unsubscribe()
                        },
                        error: (err) => {
                            console.error('error occured getting profile', err)
                            sub.unsubscribe()
                        }
                    })
                    this.signUpInProgress = false
                    this.clearDialogs()
                },
                error: (error) => {
                    console.info('sign up err', error)
                    let msg = ''
                    if (error.code === 'auth/weak-password') {
                        msg = $localize`Słabe hasło, powinno mieć przynajmniej 6 znaków`
                    } else if (error.code === 'auth/email-already-in-use') {
                        msg = $localize`Konto już istnieje`
                    } else if (error.code === 'auth/invalid-email') {
                        msg = $localize`Nieprawidłowy adres email`
                    } else if (error.code === 'auth/missing-password') {
                        msg = $localize`Puste hasło`
                    } else if (error.code === 'auth/popup-blocked') {
                        msg = $localize`Zablokowane wyskakujące okno. Odblokuj je aby się zalogować.`
                    } else {
                        msg = $localize`Nieznany błąd`
                    }
                    showErrorBox(this.msgSvc, $localize`Błąd przy rejestracji`, msg)
                    this.signUpInProgress = false
                }
            })
        )
    }

    showSignInDialog() {
        this.registerDialogVisible = false
        this.signInDialogVisible = true
        this.passwordResetDialogVisible = false
    }

    showRegisterDialog() {
        this.registerDialogVisible = true
        this.signInDialogVisible = false
        this.passwordResetDialogVisible = false
        this.termsConsent = false
        this.newsletterConsent = false
    }

    showPasswordResetDialog() {
        this.registerDialogVisible = false
        this.signInDialogVisible = false
        this.passwordResetDialogVisible = true
    }

    clearDialogs() {
        this.registerDialogVisible = false
        this.signInDialogVisible = true
        this.passwordResetDialogVisible = false
        this.email = ''
        this.emailInvalid = true
        this.password = ''
    }

    validateEmail(txt: string): boolean {
        if (!txt) {
            return false
        }
        const first = txt.indexOf('@')
        const last = txt.lastIndexOf('@')
        if (first > 0 && last === first && first < txt.length - 1) {
            const m = txt.match('^[a-z|0-9|A-Z]*([_+][a-z|0-9|A-Z]+)*([.][a-z|0-9|A-Z]+)*([.][a-z|0-9|A-Z]+)*(([_+][a-z|0-9|A-Z]+)*)?@[a-z][a-z|0-9|A-Z]*\.([a-z][a-z|0-9|A-Z]*(\.[a-z][a-z|0-9|A-Z]*)?)$')
            return !!m
        }
        return false
    }

    sendPasswordResetEmail() {
        this.subs.add(
            this.auth.sendResetPasswdEmail(this.emailInternal).subscribe({
                next: (resp: any) => {
                    this.clearDialogs()
                    showInfoBox(this.msgSvc, $localize`Resetowanie hasła`, $localize`Emaill do zresetowania hasła został wysłany.`)
                },
                error: (error) => {
                    console.info('password reset err', error)
                    let msg = $localize`Nieznany błąd`
                    if (error.code === 'auth/missing-email') {
                        msg = $localize`Pusty adres email`
                    } else if (error.code === 'auth/invalid-email') {
                        msg = $localize`Nieprawidłowy adres email`
                    }
                    showErrorBox(this.msgSvc, $localize`Błąd przy resetowaniu hasła`, msg)
                }
            })
        )
    }

}
