import { Component } from '@angular/core';

import { AuthService } from '../services/auth.service';

@Component({
    selector: 'app-main-page',
    templateUrl: './main-page.component.html',
    styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent {

    constructor(
        protected auth: AuthService,
    ) {
    }

}
