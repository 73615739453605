
export const timezoneToCurrency: { [id: string]: string; } =
{
    "Andorra": "EUR", // "Andorra",
    "Dubai": "USD", // AED "United Arab Emirates",
    "Kabul": "USD", // AFN "Afghanistan",
    "Tirane": "EUR", // ALL "Albania",
    "Yerevan": "USD", // AMD "Armenia",
    "Casey": "USD", // "Antarctica",
    "Davis": "USD", // "Antarctica",
    "Mawson": "USD", // "Antarctica",
    "Palmer": "USD", // "Antarctica",
    "Rothera": "USD", // "Antarctica",
    "Troll": "USD", // "Antarctica",
    "Vostok": "USD", // "Antarctica",
    "Buenos_Aires": "USD", // ARS "Argentina",
    "Cordoba": "USD", // "Argentina",
    "Salta": "USD", // "Argentina",
    "Jujuy": "USD", // "Argentina",
    "Tucuman": "USD", // "Argentina",
    "Catamarca": "USD", // "Argentina",
    "La_Rioja": "USD", // "Argentina",
    "San_Juan": "USD", // "Argentina",
    "Mendoza": "USD", // "Argentina",
    "San_Luis": "USD", // "Argentina",
    "Rio_Gallegos": "USD", // "Argentina",
    "Ushuaia": "USD", // "Argentina",
    "Pago_Pago": "USD", // WST "Samoa (American)",
    "Vienna": "EUR", // "Austria",
    "Lord_Howe": "AUD", // "Australia",
    "Macquarie": "AUD", // "Australia",
    "Hobart": "AUD", // "Australia",
    "Melbourne": "AUD", // "Australia",
    "Sydney": "AUD", // "Australia",
    "Broken_Hill": "AUD", // "Australia",
    "Brisbane": "AUD", // "Australia",
    "Lindeman": "AUD", // "Australia",
    "Adelaide": "AUD", // "Australia",
    "Darwin": "AUD", // "Australia",
    "Perth": "AUD", // "Australia",
    "Eucla": "AUD", // "Australia",
    "Baku": "USD", // AZN "Azerbaijan",
    "Barbados": "USD", // "Barbados",
    "Dhaka": "USD", // "Bangladesh",
    "Brussels": "EUR", // "Belgium",
    "Sofia": "EUR", // "Bulgaria",
    "Bermuda": "USD", // "Bermuda",
    "Brunei": "USD", // "Brunei",
    "La_Paz": "USD", // "Bolivia",
    "Noronha": "USD", // "Brazil",
    "Belem": "USD", // "Brazil",
    "Fortaleza": "USD", // "Brazil",
    "Recife": "USD", // "Brazil",
    "Araguaina": "USD", // "Brazil",
    "Maceio": "USD", // "Brazil",
    "Bahia": "USD", // "Brazil",
    "Sao_Paulo": "USD", // "Brazil",
    "Campo_Grande": "USD", // "Brazil",
    "Cuiaba": "USD", // "Brazil",
    "Santarem": "USD", // "Brazil",
    "Porto_Velho": "USD", // "Brazil",
    "Boa_Vista": "USD", // "Brazil",
    "Manaus": "USD", // "Brazil",
    "Eirunepe": "USD", // "Brazil",
    "Rio_Branco": "USD", // "Brazil",
    "Thimphu": "INR", // "Bhutan",
    "Minsk": "USD", // "Belarus",
    "Belize": "USD", // "Belize",
    "St_Johns": "USD", // "Canada",  TODO
    "Halifax": "USD", // "Canada",
    "Glace_Bay": "USD", // "Canada",
    "Moncton": "USD", // "Canada",
    "Goose_Bay": "USD", // "Canada",
    "Toronto": "USD", // "Canada",
    "Nipigon": "USD", // "Canada",
    "Thunder_Bay": "USD", // "Canada",
    "Iqaluit": "USD", // "Canada",
    "Pangnirtung": "USD", // "Canada",
    "Winnipeg": "USD", // "Canada",
    "Rainy_River": "USD", // "Canada",
    "Resolute": "USD", // "Canada",
    "Rankin_Inlet": "USD", // "Canada",
    "Regina": "USD", // "Canada",
    "Swift_Current": "USD", // "Canada",
    "Edmonton": "USD", // "Canada",
    "Cambridge_Bay": "USD", // "Canada",
    "Yellowknife": "USD", // "Canada",
    "Inuvik": "USD", // "Canada",
    "Dawson_Creek": "USD", // "Canada",
    "Fort_Nelson": "USD", // "Canada",
    "Whitehorse": "USD", // "Canada",
    "Dawson": "USD", // "Canada",
    "Vancouver": "USD", // "Canada",
    "Cocos": "AUD", // "Cocos (Keeling) Islands",
    "Zurich": "CHF", // "Switzerland",
    "Abidjan": "USD", // "Côte d'Ivoire",
    "Rarotonga": "NZD", // "Cook Islands",
    "Santiago": "USD", // "Chile",
    "Punta_Arenas": "USD", // "Chile",
    "Easter": "USD", // "Chile",
    "Shanghai": "USD", // "China",
    "Urumqi": "USD", // "China",
    "Bogota": "USD", // "Colombia",
    "Costa_Rica": "USD", // "Costa Rica",
    "Havana": "USD", // "Cuba",
    "Cape_Verde": "EUR", // "Cape Verde",
    "Christmas": "AUD", // "Christmas Island",
    "Nicosia": "EUR", // "Cyprus",
    "Famagusta": "EUR", // "Cyprus",
    "Prague": "EUR", // "Czech Republic",
    "Berlin": "EUR", // "Germany",
    "Copenhagen": "DKK", // "Denmark",
    "Santo_Domingo": "USD", // "Dominican Republic",
    "Algiers": "USD", // "Algeria",
    "Guayaquil": "USD", // "Ecuador",
    "Galapagos": "USD", // "Ecuador",
    "Tallinn": "EUR", // "Estonia",
    "Cairo": "USD", // "Egypt",
    "El_Aaiun": "USD", // "Western Sahara",
    "Madrid": "EUR", // "Spain",
    "Ceuta": "EUR", // "Spain",
    "Canary": "EUR", // "Spain",
    "Helsinki": "EUR", // "Finland",
    "Fiji": "USD", // "Fiji",
    "Stanley": "GBP", // "Falkland Islands",
    "Chuuk": "USD", // "Micronesia",
    "Pohnpei": "USD", // "Micronesia",
    "Kosrae": "USD", // "Micronesia",
    "Faroe": "DKK", // "Faroe Islands",
    "Paris": "EUR", // "France",
    "London": "GBP", // "Britain (UK)",
    "Tbilisi": "USD", // "Georgia",
    "Cayenne": "EUR", // "French Guiana",
    "Gibraltar": "GBP", // "Gibraltar",
    "Nuuk": "DKK", // "Greenland",
    "Danmarkshavn": "DKK", // "Greenland",
    "Scoresbysund": "DKK", // "Greenland",
    "Thule": "DKK", // "Greenland",
    "Athens": "EUR", // "Greece",
    "South_Georgia": "GBP", // "South Georgia & the South Sandwich Islands",
    "Guatemala": "USD", // "Guatemala",
    "Guam": "USD", // "Guam",
    "Bissau": "USD", // "Guinea-Bissau",
    "Guyana": "USD", // "Guyana",
    "Hong_Kong": "USD", // "Hong Kong",
    "Tegucigalpa": "USD", // "Honduras",
    "Port-au-Prince": "USD", // "Haiti",
    "Budapest": "EUR", // "Hungary",
    "Jakarta": "USD", // "Indonesia",
    "Pontianak": "USD", // "Indonesia",
    "Makassar": "USD", // "Indonesia",
    "Jayapura": "USD", // "Indonesia",
    "Dublin": "EUR", // "Ireland",
    "Jerusalem": "USD", // "Israel",
    "Kolkata": "INR", // "India",
    "Calcutta": "INR", // "India",
    "Chagos": "USD", // "British Indian Ocean Territory",
    "Baghdad": "USD", // "Iraq",
    "Tehran": "USD", // "Iran",
    "Reykjavik": "EUR", // "Iceland",
    "Rome": "EUR", // "Italy",
    "Jamaica": "USD", // "Jamaica",
    "Amman": "USD", // "Jordan",
    "Tokyo": "USD", // "Japan",
    "Nairobi": "USD", // "Kenya",
    "Bishkek": "USD", // "Kyrgyzstan",
    "Tarawa": "AUD", // "Kiribati",
    "Kanton": "AUD", // "Kiribati",
    "Kiritimati": "AUD", // "Kiribati",
    "Pyongyang": "USD", // "Korea (North)",
    "Seoul": "USD", // "Korea (South)",
    "Almaty": "USD", // "Kazakhstan",
    "Qyzylorda": "USD", // "Kazakhstan",
    "Qostanay": "USD", // "Kazakhstan",
    "Aqtobe": "USD", // "Kazakhstan",
    "Aqtau": "USD", // "Kazakhstan",
    "Atyrau": "USD", // "Kazakhstan",
    "Oral": "USD", // "Kazakhstan",
    "Beirut": "USD", // "Lebanon",
    "Colombo": "USD", // "Sri Lanka",
    "Monrovia": "USD", // "Liberia",
    "Vilnius": "USD", // "Lithuania",
    "Luxembourg": "EUR", // "Luxembourg",
    "Riga": "EUR", // "Latvia",
    "Tripoli": "USD", // "Libya",
    "Casablanca": "USD", // "Morocco",
    "Monaco": "EUR", // "Monaco",
    "Chisinau": "EUR", // "Moldova",
    "Majuro": "USD", // "Marshall Islands",
    "Kwajalein": "USD", // "Marshall Islands",
    "Yangon": "USD", // "Myanmar (Burma)",
    "Ulaanbaatar": "USD", // "Mongolia",
    "Hovd": "USD", // "Mongolia",
    "Choibalsan": "USD", // "Mongolia",
    "Macau": "USD", // "Macau",
    "Martinique": "EUR", // "Martinique",
    "Malta": "EUR", // "Malta",
    "Mauritius": "USD", // "Mauritius",
    "Maldives": "USD", // "Maldives",
    "Mexico_City": "USD", // "Mexico",
    "Cancun": "USD", // "Mexico",
    "Merida": "USD", // "Mexico",
    "Monterrey": "USD", // "Mexico",
    "Matamoros": "USD", // "Mexico",
    "Mazatlan": "USD", // "Mexico",
    "Chihuahua": "USD", // "Mexico",
    "Ojinaga": "USD", // "Mexico",
    "Hermosillo": "USD", // "Mexico",
    "Tijuana": "USD", // "Mexico",
    "Bahia_Banderas": "USD", // "Mexico",
    "Kuala_Lumpur": "USD", // "Malaysia",
    "Kuching": "USD", // "Malaysia",
    "Maputo": "USD", // "Mozambique",
    "Windhoek": "USD", // "Namibia",
    "Noumea": "USD", // "New Caledonia",
    "Norfolk": "AUD", // "Norfolk Island",
    "Lagos": "USD", // "Nigeria",
    "Managua": "USD", // "Nicaragua",
    "Amsterdam": "EUR", // "Netherlands",
    "Oslo": "NOK", // "Norway",
    "Kathmandu": "INR", // "Nepal",
    "Nauru": "AUD", // "Nauru",
    "Niue": "NZD", // "Niue",
    "Auckland": "NZD", // "New Zealand",
    "Chatham": "NZD", // "New Zealand",
    "Panama": "USD", // "Panama",
    "Lima": "USD", // "Peru",
    "Tahiti": "USD", // "French Polynesia",
    "Marquesas": "USD", // "French Polynesia",
    "Gambier": "USD", // "French Polynesia",
    "Port_Moresby": "USD", // "Papua New Guinea",
    "Bougainville": "USD", // "Papua New Guinea",
    "Manila": "USD", // "Philippines",
    "Karachi": "USD", // "Pakistan",
    "Warsaw": "PLN", // "Poland",
    "Miquelon": "EUR", // "St Pierre & Miquelon",
    "Pitcairn": "NZD", // "Pitcairn",
    "Puerto_Rico": "USD", // "Puerto Rico",
    "Gaza": "USD", // "Palestine",
    "Hebron": "USD", // "Palestine",
    "Lisbon": "EUR", // "Portugal",
    "Madeira": "EUR", // "Portugal",
    "Azores": "EUR", // "Portugal",
    "Palau": "USD", // "Palau",
    "Asuncion": "USD", // "Paraguay",
    "Qatar": "USD", // "Qatar",
    "Reunion": "EUR", // "Réunion",
    "Bucharest": "EUR", // "Romania",
    "Belgrade": "EUR", // "Serbia",
    "Kaliningrad": "USD", // "Russia",
    "Moscow": "USD", // "Russia",
    "Simferopol": "USD", // "Russia",
    "Kirov": "USD", // "Russia",
    "Volgograd": "USD", // "Russia",
    "Astrakhan": "USD", // "Russia",
    "Saratov": "USD", // "Russia",
    "Ulyanovsk": "USD", // "Russia",
    "Samara": "USD", // "Russia",
    "Yekaterinburg": "USD", // "Russia",
    "Omsk": "USD", // "Russia",
    "Novosibirsk": "USD", // "Russia",
    "Barnaul": "USD", // "Russia",
    "Tomsk": "USD", // "Russia",
    "Novokuznetsk": "USD", // "Russia",
    "Krasnoyarsk": "USD", // "Russia",
    "Irkutsk": "USD", // "Russia",
    "Chita": "USD", // "Russia",
    "Yakutsk": "USD", // "Russia",
    "Khandyga": "USD", // "Russia",
    "Vladivostok": "USD", // "Russia",
    "Ust-Nera": "USD", // "Russia",
    "Magadan": "USD", // "Russia",
    "Sakhalin": "USD", // "Russia",
    "Srednekolymsk": "USD", // "Russia",
    "Kamchatka": "USD", // "Russia",
    "Anadyr": "USD", // "Russia",
    "Riyadh": "USD", // "Saudi Arabia",
    "Guadalcanal": "USD", // "Solomon Islands",
    "Mahe": "USD", // "Seychelles",
    "Khartoum": "USD", // "Sudan",
    "Stockholm": "SEK", // "Sweden",
    "Singapore": "USD", // "Singapore",
    "Paramaribo": "USD", // "Suriname",
    "Juba": "USD", // "South Sudan",
    "Sao_Tome": "EUR", // "Sao Tome & Principe",
    "El_Salvador": "USD", // "El Salvador",
    "Damascus": "USD", // "Syria",
    "Grand_Turk": "USD", // "Turks & Caicos Is",
    "Ndjamena": "USD", // "Chad",
    "Kerguelen": "EUR", // "French Southern & Antarctic Lands",
    "Bangkok": "USD", // "Thailand",
    "Dushanbe": "USD", // "Tajikistan",
    "Fakaofo": "NZD", // "Tokelau",
    "Dili": "USD", // "East Timor",
    "Ashgabat": "USD", // "Turkmenistan",
    "Tunis": "USD", // "Tunisia",
    "Tongatapu": "USD", // "Tonga",
    "Istanbul": "USD", // "Turkey",
    "Funafuti": "AUD", // "Tuvalu",
    "Taipei": "USD", // "Taiwan",
    "Kiev": "USD", // "Ukraine",
    "Uzhgorod": "USD", // "Ukraine",
    "Zaporozhye": "USD", // "Ukraine",
    "Wake": "USD", // "US minor outlying islands",
    "New_York": "USD", // "United States",
    "Detroit": "USD", // "United States",
    "Louisville": "USD", // "United States",
    "Monticello": "USD", // "United States",
    "Indianapolis": "USD", // "United States",
    "Vincennes": "USD", // "United States",
    "Winamac": "USD", // "United States",
    "Marengo": "USD", // "United States",
    "Petersburg": "USD", // "United States",
    "Vevay": "USD", // "United States",
    "Chicago": "USD", // "United States",
    "Tell_City": "USD", // "United States",
    "Knox": "USD", // "United States",
    "Menominee": "USD", // "United States",
    "Center": "USD", // "United States",
    "New_Salem": "USD", // "United States",
    "Beulah": "USD", // "United States",
    "Denver": "USD", // "United States",
    "Boise": "USD", // "United States",
    "Phoenix": "USD", // "United States",
    "Los_Angeles": "USD", // "United States",
    "Anchorage": "USD", // "United States",
    "Juneau": "USD", // "United States",
    "Sitka": "USD", // "United States",
    "Metlakatla": "USD", // "United States",
    "Yakutat": "USD", // "United States",
    "Nome": "USD", // "United States",
    "Adak": "USD", // "United States",
    "Honolulu": "USD", // "United States",
    "Montevideo": "USD", // "Uruguay",
    "Samarkand": "USD", // "Uzbekistan",
    "Tashkent": "USD", // "Uzbekistan",
    "Caracas": "USD", // "Venezuela",
    "Ho_Chi_Minh": "USD", // "Vietnam",
    "Efate": "USD", // "Vanuatu",
    "Wallis": "USD", // "Wallis & Futuna",
    "Apia": "USD", // "Samoa (western)",
    "Johannesburg": "USD", // "South Africa",
    "Antigua": "USD", // "Antigua & Barbuda",
    "Anguilla": "USD", // "Anguilla",
    "Luanda": "USD", // "Angola",
    "McMurdo": "USD", // "Antarctica",
    "DumontDUrville": "USD", // "Antarctica",
    "Syowa": "USD", // "Antarctica",
    "Aruba": "USD", // "Aruba",
    "Mariehamn": "USD", // "Åland Islands",
    "Sarajevo": "EUR", // "Bosnia & Herzegovina",
    "Ouagadougou": "USD", // "Burkina Faso",
    "Bahrain": "USD", // "Bahrain",
    "Bujumbura": "USD", // "Burundi",
    "Porto-Novo": "USD", // "Benin",
    "St_Barthelemy": "EUR", // "St Barthelemy",
    "Kralendijk": "USD", // "Caribbean NL",
    "Nassau": "USD", // "Bahamas",
    "Gaborone": "USD", // "Botswana",
    "Blanc-Sablon": "USD", // "Canada",
    "Atikokan": "USD", // "Canada",
    "Creston": "USD", // "Canada",
    "Kinshasa": "USD", // "Congo (Dem. Rep.)",
    "Lubumbashi": "USD", // "Congo (Dem. Rep.)",
    "Bangui": "USD", // "Central African Rep.",
    "Brazzaville": "USD", // "Congo (Rep.)",
    "Douala": "USD", // "Cameroon",
    "Curacao": "USD", // "Curaçao",
    "Busingen": "EUR", // "Germany",
    "Djibouti": "USD", // "Djibouti",
    "Dominica": "USD", // "Dominica",
    "Asmara": "USD", // "Eritrea",
    "Addis_Ababa": "USD", // "Ethiopia",
    "Libreville": "USD", // "Gabon",
    "Grenada": "USD", // "Grenada",
    "Guernsey": "GBP", // "Guernsey",
    "Accra": "USD", // "Ghana",
    "Banjul": "USD", // "Gambia",
    "Conakry": "USD", // "Guinea",
    "Guadeloupe": "EUR", // "Guadeloupe",
    "Malabo": "USD", // "Equatorial Guinea",
    "Zagreb": "EUR", // "Croatia",
    "Isle_of_Man": "GBP", // "Isle of Man",
    "Jersey": "GBP", // "Jersey",
    "Phnom_Penh": "USD", // "Cambodia",
    "Comoro": "EUR", // "Comoros",
    "St_Kitts": "USD", // "St Kitts & Nevis",
    "Kuwait": "USD", // "Kuwait",
    "Cayman": "USD", // "Cayman Islands",
    "Vientiane": "USD", // "Laos",
    "St_Lucia": "USD", // "St Lucia",
    "Vaduz": "EUR", // "Liechtenstein",
    "Maseru": "USD", // "Lesotho",
    "Podgorica": "EUR", // "Montenegro",
    "Marigot": "EUR", // "St Martin (French)",
    "Antananarivo": "USD", // "Madagascar",
    "Skopje": "EUR", // "North Macedonia",
    "Bamako": "USD", // "Mali",
    "Saipan": "USD", // "Northern Mariana Islands",
    "Nouakchott": "USD", // "Mauritania",
    "Montserrat": "USD", // "Montserrat",
    "Blantyre": "USD", // "Malawi",
    "Niamey": "USD", // "Niger",
    "Muscat": "USD", // "Oman",
    "Kigali": "USD", // "Rwanda",
    "St_Helena": "GBP", // "St Helena",
    "Ljubljana": "EUR", // "Slovenia",
    "Longyearbyen": "NOK", // "Svalbard & Jan Mayen",
    "Bratislava": "EUR", // "Slovakia",
    "Freetown": "USD", // "Sierra Leone",
    "San_Marino": "EUR", // "San Marino",
    "Dakar": "USD", // "Senegal",
    "Mogadishu": "USD", // "Somalia",
    "Lower_Princes": "USD", // "St Maarten (Dutch)",
    "Mbabane": "USD", // "Eswatini (Swaziland)",
    "Lome": "USD", // "Togo",
    "Port_of_Spain": "USD", // "Trinidad & Tobago",
    "Dar_es_Salaam": "USD", // "Tanzania",
    "Kampala": "USD", // "Uganda",
    "Midway": "USD", // "US minor outlying islands",
    "Vatican": "EUR", // "Vatican City",
    "St_Vincent": "USD", // "St Vincent",
    "Tortola": "USD", // "Virgin Islands (UK)",
    "St_Thomas": "USD", // "Virgin Islands (US)",
    "Aden": "USD", // "Yemen",
    "Mayotte": "EUR", // "Mayotte",
    "Lusaka": "USD", // "Zambia",
    "Harare": "USD", // "Zimbabwe"
}
