import { Injectable } from '@angular/core';

import { BehaviorSubject, map } from 'rxjs'

import { StorageService } from '../services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class UserImagesService {

    private imagesList: any[] = []
    private images = new BehaviorSubject<any[]>([])

    constructor(
        public store: StorageService,
    ) {
        this.store.listUserImages().subscribe({
            next: (resp) => {
                this.imagesList = resp
                this.images.next(this.imagesList)
            },
            error: (err) => {
                console.error('unexpected error', err)
            }
        })
    }

    getImages() {
        return this.images
    }

    uploadImage(imageId: string, imageFormat: string, file: any) {
        return this.store.saveUserImage(imageId, imageFormat, file, null, null).pipe(
            map(resp => {
                // add image id to this.images
                this.imagesList.push({ id: imageId, url: resp[1] })
                this.images.next(this.imagesList)
            }))

    }
}
