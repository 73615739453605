import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, NgModule, Output, booleanAttribute, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown'

export type PathPattern = {
    name: string,
    value: number,
    pattern: [number, number, number, number]
}

@Component({
    selector: 'pathPatternSelector',
    templateUrl: './path-pattern-selector.html',
    styleUrls: [ './path-pattern-selector.scss' ],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => PathPatternSelector),
        multi: true
    }],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PathPatternSelector implements ControlValueAccessor {
    @Input({ required: true }) patterns!: PathPattern[] 
    @Input({ transform: booleanAttribute }) disabled: boolean = false
    @Input() color: string = 'black'
    @Input() width: number = 1
    @Input() styleClass: string = "w-full"
    @Input() panelStyleClass: string = "w-30rem"
    @Input() scrollHeight: string = "30rem"

    // pass-through of dropdown onChange events
    @Output() onChange: EventEmitter<DropdownChangeEvent> = new EventEmitter<DropdownChangeEvent>()

    // callbacks that angular will register
    onModelChange: Function = () => {}
    onModelTouched: Function = () => {}
    value?: number
    visible: boolean = false

    constructor(
        public cd: ChangeDetectorRef,
    ) {
    }

    // required by ControlValueAccessor, called when form value changed
    writeValue(value: any) {
        this.value = value
        this.cd.markForCheck()
    }

    // required by ControlValueAccessor, registers callback to handle model changes
    registerOnChange(f: Function) {
        this.onModelChange = f
    }

    // required by ControlValueAccessor
    registerOnTouched(f: Function) {
        this.onModelTouched = f
    }

    // required by ControlValueAccessor, changes state when form state changes
    setDisabledState(val: boolean) {
        this.disabled = val
        this.cd.markForCheck()
    }

    // called when dropdown model is changed, to propagate the change to the form
    onNgModelChange() {
        this.onModelChange(this.value)
    }

    onValueChange(ev: DropdownChangeEvent) {
        this.onChange.emit(ev)
    }

    getBackgroundAttribute(p: PathPattern): string {
        if (p.pattern.length < 4) {
            return 'none'
        }
        return 'repeating-linear-gradient(90deg, ' + 
                this.color + ' 0 ' + p.pattern[0] + 'px, #0000 0 ' + (p.pattern[0] + p.pattern[1]) + 'px, ' + 
                this.color + ' 0 ' + (p.pattern[0] + p.pattern[1] + p.pattern[2]) + 'px, #0000  0 ' + 
                (p.pattern[0] + p.pattern[1] + p.pattern[2] + p.pattern[3]) + 'px)'
    }
}

@NgModule({
    imports: [ CommonModule, FormsModule, DropdownModule ],
    exports: [ PathPatternSelector ],
    declarations: [ PathPatternSelector ]
})

export class PathPatternSelectorModule {}

