import { CommonModule } from '@angular/common'
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { Canvg, presets } from 'canvg'
import paper from 'paper'
import { Observable, Subscription, throwError, zip } from "rxjs"
import { SUUID } from "short-uuid"
import { Article, BannerPosition, CompetitionLocation, Direction, LimitRange, NodeFlags } from "../design.schema"
import { DetailComponent } from "../detail/detail.component"
import { ParkourObjectGroup } from "../detail/detail.group"
import { ObstaclePath } from "../detail/detail.path"
import { ObstaclePathNode } from "../detail/detail.path.node"
import { Selectable } from "../detail/detail.selection"
import { UiCommandId } from "../detail/detail.ui.commands.defs"
import { ParkourObjectFactory } from "../detail/parkour-object-factory/parkour-object-factory.service"
import { Orientation, ParkourBannerObject } from "../detail/parkour-objects/banner"
import { Obstacle } from "../detail/parkour-objects/obstacle"
import { ObstacleWithBars } from '../detail/parkour-objects/obstacle-with-bars'
import { ParkourObject } from "../detail/parkour-objects/parkour-object"
import { TextBox } from "../detail/parkour-objects/text-box"
import { PathObject } from "../detail/parkour-objects/path-object"
import { ParkourConfig } from "../parkour.config"
import { LimitsService, TimeLimits } from "../services/limits.service"
import { StorageService } from "../services/storage.service"
import { SharedModule } from '../shared/shared.module'
import { feetToMeters, metersToFeet, showInfoBox } from "../utils"
import { LengthPipe } from '../pipes'


export enum ExportMode {
    REGULAR = 'regular',         // plan for jockeys
    MASTERPLAN = 'masterplan',   // plan for designer and to setup arena
    SNAPSHOT = 'snap',           // snapshot
    ARENA = 'blank',             // only arena without obstacles
}

export type PngImage = {
    data: string,
    width: number,
    height: number,
}

export enum LayerId {
    NONE = -1,
    UI_EXTRAS = 0,// page limits and lines of sight - not for printing
    RULERS = 1,
    BACKGROUND = 2,
    OBSTACLES = 3,
    FRONT = 4,
    LAST = 5
}

@Component({
    standalone: true,
    imports: [
        CommonModule,
        SharedModule,
    ],
    selector: 'parkour-canvas',
    templateUrl: './parkour-canvas.html',
    styleUrls: ['./parkour-canvas.scss'],
})
export class ParkourCanvas implements AfterViewInit, OnInit, OnDestroy {
    private _paper?: paper.Project
    private _layers: paper.Layer[] = []
    private _field = new paper.Rectangle(0, 0, 0, 0)
    private _rulers?: paper.Group
    private _grid?: paper.Group
    private _pageLimits?: paper.Shape
    private _pageLimitsRect?: paper.Rectangle
    private _rulersGap: number = 0
    private _objects: { [id: SUUID]: ParkourObject } = {}
    private _obstacles: { [id: SUUID]: PathObject } = {}
    private _obstaclePath!: ObstaclePath
    private _jokers: Obstacle[] = []
    private _jokerNodes: ObstaclePathNode[] = []
    private _objectGroups: ParkourObjectGroup[] = []
    private _uiZoom: number = 100
    private _userMovedOrZoomedField: boolean = false
    private _prevViewZoom: number = 0
    private _zoomCenter?: paper.Point
    private _subs: Subscription = new Subscription()
    private static _lengthPipe: LengthPipe = new LengthPipe()

    private readonly _jokerTooltipRoute = $localize`Przeszkody będące na ścieżce nie mogą być Jokerem`
    private readonly _jokerTooltips: string[] = [
        $localize`Możesz wyznaczyć tę przeszkodę jako pierwszego Jokera`,
        $localize`Możesz wyznaczyć tę przeszkodę jako drugiego Jokera`,
        $localize`Są już wyznaczone dwa Jokery`
    ]

    @Input({ required: true }) cfg!: ParkourConfig
    @Input() view?: DetailComponent
    @ViewChild('canvas') private _element: ElementRef<HTMLCanvasElement> | undefined

    set layoutLinesVisibility(visibility: boolean) {
        this.objects.forEach(o => {
            if (o instanceof ObstacleWithBars) {
                o.layoutLinesVisibility = visibility
            }
        })
    }

    get layoutLinesVisibility(): boolean {
        for (let o of this.objects) {
            if (o instanceof ObstacleWithBars && o.layoutLinesVisibility) {
                return true
            }
        }
        return false
    }

    get zoom(): number {
        return this._uiZoom
    }

    setZoom(z: number, point?: paper.Point) {
        this._uiZoom = z
        this.updateZoom(point)
    }

    get paper(): paper.Project | undefined {
        return this._paper
    }

    get field(): paper.Rectangle {
        return this._field
    }

    get jokers(): Obstacle[] {
        return this._jokers
    }

    get jokerNodes(): ObstaclePathNode[] {
        return this._jokerNodes
    }

    get grid(): paper.Group | undefined {
        return this._grid
    }

    get pageLimits(): paper.Shape | undefined {
        return this._pageLimits
    }

    get pageLimitsRect(): paper.Rectangle {
        return this._pageLimitsRect || new paper.Rectangle(0, 0, 0, 0)
    }

    get objects(): ParkourObject[] {
        return Object.values(this._objects)
    }

    get objectGroups(): ParkourObjectGroup[] {
        return this._objectGroups
    }

    get obstacles(): PathObject[] {
        return Object.values(this._obstacles)
    }

    get obstaclePath(): ObstaclePath {
        return this._obstaclePath
    }

    private get _banners(): ParkourBannerObject[] {
        return Object.values(this.objects).filter(o => o instanceof ParkourBannerObject) as ParkourBannerObject[]
    }

    constructor(
        private objectFactory: ParkourObjectFactory,
        private store: StorageService,
        private limitsService: LimitsService,
    ) {
    }

    public ngOnInit() {
        this._obstaclePath = new ObstaclePath(this.cfg, this, this.objectFactory, this.view)
    }

    public ngAfterViewInit() {
        this._userMovedOrZoomedField = false
        // paper.setup will internally create a new paper.Project and set it in paper scope
        if (this._element) {
            paper.setup(this._element.nativeElement)
            paper.settings.handleSize = 17
            this._paper = paper.project
        } else {
            console.error('ParkourCanvas has no canvas element available.')
        }

        for (let i = 0; i < LayerId.LAST; i++) {
            this._layers.push(new paper.Layer())
            if (i > 0) {
                this._layers[i].insertAbove(this._layers[i - 1])
            }
            this._layers[i].visible = true
        }
        this._layers[LayerId.OBSTACLES].activate()
    }

    public ngOnDestroy() {
        this._subs.unsubscribe()
        this.obstaclePath.clear()
        Object.values(this._objectGroups).forEach(g => g.destroy())
        this._objectGroups = []
        this.objects.forEach(o => o.destroy())
        this._objects = {}
        this._paper?.remove()
        this._paper = undefined
    }

    public moveArena(delta: paper.Point) {
        if (!this.paper) {
            return
        }
        let dx = 0
        let dy = 0
        const pageLimits = this.pageLimits
        const view = this.paper.view
        if (pageLimits) {
            if (view.bounds.x - delta.x < pageLimits.bounds.x &&
                view.bounds.right - delta.x > pageLimits.bounds.right) {
                dx = delta.x
            } else {
                if ((view.bounds.x > pageLimits.bounds.x && view.center.x - delta.x < pageLimits.bounds.right) ||
                    (view.bounds.right < pageLimits.bounds.right && view.center.x - delta.x > pageLimits.bounds.x)) {
                    dx = delta.x
                }
            }

            if (view.bounds.y - delta.y < pageLimits.bounds.y &&
                view.bounds.bottom - delta.y > pageLimits.bounds.bottom) {
                dy = delta.y
            } else {
                if ((view.bounds.y > pageLimits.bounds.y && view.center.y - delta.y < pageLimits.bounds.bottom) ||
                    (view.bounds.bottom < pageLimits.bounds.bottom && view.center.y - delta.y > pageLimits.bounds.y)) {
                    dy = delta.y
                }
            }
        } else {
            dx = delta.x
            dy = delta.y
        }
        if (dx != 0 || dy != 0) {
            const dp = new paper.Point(dx, dy)
            view.translate(dp)
            this._userMovedOrZoomedField = true
        }
    }

    public clearPath() {
        if (!this.paper) {
            return false
        }
        this.obstaclePath.clearPath()
        this.updatePath()
    }

    public clearPathForward(objects: Selectable[]) {
        if (!this.paper) {
            return false
        }
        this.obstaclePath.clearPathForward(objects)
        this.updatePath()
    }

    public clearPathBackward(objects: Selectable[]) {
        if (!this.paper) {
            return false
        }
        this.obstaclePath.clearPathBackward(objects)
        this.updatePath()
    }

    public splitPathAfter(objects: Selectable[]): boolean {
        if (!this.paper) {
            return false
        }
        const ret = this.obstaclePath.splitPathAfter(objects)
        if (ret) {
            this.updatePath()
        }
        return ret
    }

    public loadDesign(data: any): Observable<void> {
        if (!this.paper) {
            return throwError(() => new Error('Canvas not ready'))
        }
        const position = this.paper.view.center
        this._clearArena()
        this.cfg.importDesign(data)

        if (!this.cfg.params.localId || data.objects.length === 0 && data.route.length > 0) {
            return throwError(() => new Error('Design malformed'))
        }

        // create objects
        for (let o of data.objects) {
            const co = this.createObject({
                ...o,
                x: o.x,
                y: o.y
            })
        }

        // create groups
        if (data.groups) {
            let idx = 0
            for (let g of data.groups) {
                if (g.uuids) {
                    const children: Selectable[] = []
                    for (let uuid of g.uuids) {
                        const obj = this._objects[uuid]
                        if (obj) {
                            let found = false
                            for (let f of this.objectGroups) {
                                if (f.children.includes(obj)) {
                                    found = true
                                    break
                                }
                            }
                            if (!found) {
                                children.push(obj)
                            }
                        }
                    }
                    if (children.length > 0) {
                        const group = new ParkourObjectGroup(children, this.cfg, this, idx++, g.combinationType)
                        this.objectGroups.push(group)
                    }
                }
            }
        }

        this.changeArenaSize()

        // if this is new design then add start and top banner
        if (data.objects.length === 0) {
            this._addStart()
            this.setupBanners(this.cfg.params.bannerPosition)
        }

        for (let p of data.route) {
            if (p.uuid && this._obstacles.hasOwnProperty(p.uuid)) {
                const midPoints = p.midPoints || []
                const o = this._obstacles[p.uuid]
                this.obstaclePath.add(o, midPoints, false, true, p.flags)
            }
        }
        this.updatePath()
        this.paper.view.center = position

        if (this.obstaclePath.firstRound && this.cfg.params.distanceCorrection) {
            this.cfg.params.overrideDistance1 = true
            this.cfg.params.distance1M = this.obstaclePath.firstRound.roundedLengthTo5M + this.cfg.params.distanceCorrection
            this.cfg.params.distanceCorrection = 0
        }
        if (this.obstaclePath.secondRound && this.cfg.params.distanceCorrection2) {
            this.cfg.params.overrideDistance2 = true
            this.cfg.params.distance2M = this.obstaclePath.secondRound.roundedLengthTo5M + this.cfg.params.distanceCorrection2
            this.cfg.params.distanceCorrection2 = 0
        }

        return new Observable(subscriber => {
            zip(this.objects.map(o => o.ready)).subscribe({
                next: () => {
                    this._objectsReady()
                    subscriber.next()
                    subscriber.complete()
                },
                error: (err) => {
                    subscriber.error(err)
                }
            })
        })
    }

    public reset() {
        if (!this.paper) {
            return
        }
        this._clearArena()
        this._addStart()
        this.updatePath()
        this.resetView()
    }

    public changeViewSize() {
        if (!this.paper) {
            return
        }
        if (this.paper?.view && this._element) {
            const centerBefore = this.paper.view.center
            const p = this._element.nativeElement.parentElement
            if (p) {
                const newPs = new paper.Size(p.offsetWidth, p.offsetHeight)
                this.paper.view.viewSize = newPs
                this.updateZoom()
                this.paper.view.center = centerBefore
            }
        }
    }

    public updateZoom(point?: paper.Point) {
        if (!this.paper) {
            return
        }
        // user defined zoom factor (0-100)
        if (this._uiZoom < 10) {
            this._uiZoom = 10
        } else if (this._uiZoom > 400) {
            this._uiZoom = 400
        }
        if (this._uiZoom !== 100) {
            this._userMovedOrZoomedField = true
        }
        const vs = this.paper.view.viewSize
        if (vs.height <= 0 || vs.width <= 0) {
            return
        }
        // zoom factor on top of user-defined zoom that scales the content to fit the view
        let zoomFactor
        const fs = this.getBoundsForAllLayers().size
        if (fs.width / fs.height > vs.width / vs.height) {
            zoomFactor = vs.width / fs.width
        } else {
            zoomFactor = vs.height / fs.height
        }
        // combine both zoom factors
        this.paper.view.zoom = zoomFactor * this._uiZoom / 100
        // zoom around current mouse point
        if (point) {
            this._zoomCenter = point
        }
        const p = this._zoomCenter
        if (p) {
            let c = p
            if (this.pageLimits) {
                let dx = p.x
                let dy = p.y
                if (p.x < this.pageLimits.bounds.x) {
                    dx = this.pageLimits.bounds.x
                }
                if (p.y < this.pageLimits.bounds.y) {
                    dy = this.pageLimits.bounds.y
                }
                if (p.x > this.pageLimits.bounds.right) {
                    dx = this.pageLimits.bounds.right
                }
                if (p.y > this.pageLimits.bounds.bottom) {
                    dy = this.pageLimits.bounds.bottom
                }
                c = new paper.Point(dx, dy)
            }
            this.paper.view.center = c.subtract(c.subtract(this.paper.view.center).multiply(this._prevViewZoom / this.paper.view.zoom))
        }
        this._prevViewZoom = this.paper.view.zoom
    }

    // place center of the view in the center of content
    public centerView() {
        if (this.paper) {
            this.paper.view.center = this.getBoundsForAllLayers().center
        }
    }

    // set zoom and center to default positions to expose all the content in the view
    public resetView() {
        if (!this.paper) {
            return
        }
        this._uiZoom = 100
        this.updateZoom()
        this.centerView()
    }

    public updateBanners() {
        if (!this.paper) {
            return
        }
        this._banners.forEach(b => b.update())
    }

    public getLayer(id: LayerId): paper.Layer {
        if (!this.paper || this._layers.length === 0) {
            throw new Error('Canvas not initialized')
        }
        if (id >= 0 && id < this._layers.length) {
            return this._layers[id]
        }
        return this._layers[LayerId.BACKGROUND]
    }

    public getBoundsForAllLayers(exclude?: LayerId[]): paper.Rectangle {
        let rect = new paper.Rectangle(0, 0, 0, 0)
        if (this.paper) {
            this._layers.forEach((l, idx) => {
                if (!exclude || !exclude.includes(idx)) {
                    rect = rect.unite(l.internalBounds)
                }
            })
        }
        return rect
    }


    public updatePath(changeMidPointsCount?: boolean) {
        if (!this.paper) {
            return
        }
        // reset objects before path update and update dynamically drawn parts
        this._jokers = []
        this._jokerNodes = []
        const toCheckDisable: Obstacle[] = []
        for (let o of Object.values(this.objects)) {
            o.reset()
            // allow for max number of jokers among obstacles that are not on the route
            if (this.cfg.isJokerAllowed() && o instanceof Obstacle) {
                if (this._obstaclePath.includes(o)) {
                    o.joker = false
                    o.jokerNumber = 0
                    o.jokerDisabled = true
                    o.jokerTooltip = this._jokerTooltipRoute
                } else if (this._jokers.length === 2) {
                    o.joker = false
                    o.jokerNumber = 0
                    o.jokerDisabled = true
                    o.jokerTooltip = this._jokerTooltips[2]
                } else {
                    o.jokerDisabled = false
                    if (o.joker) {
                        o.jokerTooltip = ''
                        this._jokers.push(o)
                    } else {
                        o.jokerNumber = 0
                        toCheckDisable.push(o)
                    }
                }
            }
        }
        toCheckDisable.forEach(o => {
            o.jokerTooltip = this._jokerTooltips[this._jokers.length]
            if (this._jokers.length === 2) {
                o.jokerDisabled = true
            }
        })

        this.obstaclePath.update(changeMidPointsCount)

        // label obstacles in mode where there is no route
        if (this.cfg.isNoRouteMode()) {
            let i = 1
            Object.keys(this._obstacles).sort().forEach(k => {
                const o = this._obstacles[k as SUUID]
                if (o instanceof Obstacle) {
                    o.addLabel(i.toFixed(0), 1, Direction.forward, 1)
                    o.noRouteLabel = i
                    i++
                }
            })
        }

        // add labels to jokers
        if (this.obstaclePath.firstRound && this.cfg.isJokerAllowed() && this._jokers.length > 0) {
            // reset joker numbers
            if (this._jokers.length === 1) {
                this._jokers[0].jokerNumber = 1
            } else if (this._jokers.length === 2) {
                if (this._jokers[0].jokerNumber === this._jokers[1].jokerNumber) {
                    this._jokers[0].jokerNumber = 1
                    this._jokers[1].jokerNumber = 2
                } else if (this._jokers[0].jokerNumber && !this._jokers[1].jokerNumber) {
                    this._jokers[1].jokerNumber = (this._jokers[0].jokerNumber === 1 ? 2 : 1)
                } else if (this._jokers[1].jokerNumber && !this._jokers[0].jokerNumber) {
                    this._jokers[0].jokerNumber = (this._jokers[1].jokerNumber === 1 ? 2 : 1)
                }
                if (this._jokers[0].jokerNumber > this._jokers[1].jokerNumber) {
                    this._jokers = [this._jokers[1], this._jokers[0]]
                }
            }
            // get label of the last obstacle in the first round
            const route = this.obstaclePath.firstRound.route
            let label: number = 0, last
            for (let i = route.length - 1; i >= 0; i--) {
                if (route[i].obstacle instanceof Obstacle) {
                    last = route[i]
                    break
                }
            }
            if (last && last.label) {
                let t = last.label.match(/^\d+/)
                if (t && t.length > 0) {
                    label = parseInt(t[0])
                    if (isNaN(label)) {
                        label = 0
                    }
                }
            }
            // first joker gets label value of last obstacle x2 (when one joker) or x1.5 (when two jokers)
            // second joker gets 2x
            this._jokers.forEach(j => {
                const node = new ObstaclePathNode(j, [], this.cfg, this, this.view)
                this.jokerNodes.push(node)
                if (!j.manualLabels[0]) {
                    let n = 0
                    if (label) {
                        if (j.jokerNumber === 1) {
                            const factor = (this._jokers.length === 2 ? 1.5 : 2)
                            n = factor * label
                        } else if (j.jokerNumber === 2) {
                            n = 2 * label
                        }
                    }
                    node.setLabel(n > 0 ? n.toFixed(0) : '-', 1)
                }
                j.setLabelsVisibility(this.obstaclePath.getConfig().firstRound?.labelsVisible || false, 1)
            })
        }

        // manually set labels for obstacles that are not connected
        if (!this.cfg.isNoRouteMode()) {
            Object.values(this.obstacles).forEach(o => {
                if (o instanceof Obstacle && o.useCountAll === 0) {
                    o.addLabel('', 1, Direction.forward, 1)
                    o.addLabel('', 2, Direction.forward, 1)
                    o.addLabel('', 1, Direction.backward, 2)
                    o.addLabel('', 2, Direction.backward, 2)
                }
            })
        }

        // update labels visibility when they all have been positioned
        this.obstaclePath.updateLabelsVisibility()
        this._banners.forEach(b => b.update())
    }

    public getNonPathObjects(): ParkourObject[] {
        if (!this.paper) {
            return []
        }
        const compare = (a: ParkourObject, b: ParkourObject): number => {
            return a.getKindName().localeCompare(b.getKindName())
        }
        const objects = this.objects
        if (this.cfg.isNoRouteMode()) {
            return objects.sort(compare)
        }
        let val = objects.filter(o => !(o instanceof PathObject) || !this.obstaclePath.includes(o)).sort(compare)
        if (this.cfg.isJokerAllowed()) {
            val = val.filter(v => !(v instanceof Obstacle) || (v instanceof Obstacle && !v.joker))
        }
        return val
    }

    public createObject(object: { [ID: string]: any }): ParkourObject {
        if (!this.paper) {
            throw new Error('Canvas not initialized')
        }
        const o = this.objectFactory.getObject({
            cfg: this.cfg,
            canvas: this,
            store: this.store,
            subs: this._subs,
            object: object,
            view: this.view,
        })
        if (!(o instanceof ParkourObject)) {
            throw new Error('created not a ParkourObject')
        }
        if (o.isStart()) {
            if (this.obstacles.filter(x => x.isStart()).length > 1) {
                throw new Error('cannot add third start')
            }
        }
        if (o.isFinish()) {
            if (Object.values(this.obstacles).filter(x => x.isFinish()).length > 1) {
                throw new Error('cannot add third start')
            }
        }
        this._objects[o.uuid] = o
        if (o instanceof PathObject) {
            this._obstacles[o.uuid] = o as PathObject
        }
        return o
    }

    public deleteObject(o: ParkourObject | ParkourObjectGroup) {
        if (!this.paper) {
            return
        }
        if (o instanceof ParkourObject) {
            this.replaceObject(o, null)
        } else if (o instanceof ParkourObjectGroup) {
            const idx = this._objectGroups.indexOf(o)
            if (idx >= 0) {
                this._objectGroups.splice(idx, 1)
            }
            o.destroy()
            for (let i = 0; i < this._objectGroups.length; i++) {
                this._objectGroups[i].index = i
            }
        }
    }

    public replaceObject(from: ParkourObject, to: ParkourObject | null) {
        if (!this.paper) {
            return
        }
        if (from.isStart()) {
            const obstacles = this.obstaclePath.toObstacles()
            for (let o of obstacles) {
                if (o.isStart()) {
                    if (from === o) {
                        if (this.view) {
                            showInfoBox(this.view.msgSvc, $localize`Usuwanie przeszkody`, $localize`Nie można usunąć startu pierwszej rundy`)
                        }
                        return
                    }
                    break
                }
            }
            this.obstaclePath.detachSecondRoundObstacles()
        }

        if (from.isFinishStart()) {
            this.obstaclePath.flattenToOneRound()
        }

        if (from instanceof PathObject) {
            if (to instanceof PathObject) {
                this.obstaclePath.replaceObject(from, to)
            } else {
                this.obstaclePath.delete(from)
            }
        }
        const parent = from.parentSelector
        if (parent instanceof ParkourObjectGroup) {
            parent.replaceObject(from, to)
            if (parent.children.length <= 1) {
                this.deleteObject(parent)
            }
        }
        delete this._objects[from.uuid]
        if (to) {
            this._objects[to.uuid] = to
        }
        delete this._obstacles[from.uuid]
        if (to && to instanceof PathObject) {
            this._obstacles[to.uuid] = to
        }
        this.updatePath()
        from.destroy()
        if (from instanceof ParkourBannerObject) {
            if (!this._banners.length) {
                this.cfg.params.bannerPosition = BannerPosition.NONE
            }
        }
        this.view?.saveData()
        this.drawPageLimits()
    }

    public changeArenaSize(scale?: boolean) {
        if (!this.paper) {
            return
        }
        const newSize = new paper.Size(this.cfg.params.parkourWidth * 100, this.cfg.params.parkourHeight * 100)
        if (scale) {
            this._scaleOutOfField(new paper.Rectangle(this.field.point, newSize), new paper.Point(
                newSize.width / this.field.width, newSize.height / this.field.height
            ))
            this.setupBanners(this.cfg.params.bannerPosition)
        }
        this.field.set(new paper.Point(0, 0), newSize)
        this._drawRulers()
        if (!this.view?.performingUndo) {
            this.updateZoom()
            this.centerView()
        }
        this.updatePath()
        this.drawPageLimits()
    }

    public updateAllGroups() {
        this.objectGroups.forEach(g => {
            g.findBestAngle()
            g.update()
        })
    }

    public setupBanners(position: BannerPosition): ParkourObject[] {
        if (!this.paper) {
            return []
        }
        this._banners.forEach(b => this.deleteObject(b))
        this.cfg.params.bannerPosition = position
        if (position === BannerPosition.NONE) {
            return []
        }
        const field = new paper.Rectangle(0, 0, this.cfg.params.parkourWidth * 100, this.cfg.params.parkourHeight * 100)

        if (position === BannerPosition.TOP || position === BannerPosition.BOTTOM) {
            const pos = new paper.Point(
                field.topCenter.x,
                position === BannerPosition.TOP ? field.top : field.bottom)
            let o = this.createObject({
                kind: 'table-banner',
                x: pos.x,
                y: pos.y,
                angle: 0,
                orientation: Orientation.HORIZONTAL,
                visibility: {
                    title: true,
                    subtitle: true,
                    table: true
                }
            }) as ParkourBannerObject
            o.layout()
            o.move(new paper.Point([0,
                (position === BannerPosition.TOP ? -1 : 1) * (o.getExternalSize().height / 2 + this._rulersGap)]))
            this._banners.push(o)
        } else {
            let o = this.createObject({
                kind: 'table-banner',
                x: field.topCenter.x,
                y: field.top,
                angle: 0,
                orientation: Orientation.HORIZONTAL,
                visibility: {
                    title: true,
                    subtitle: true,
                    table: false,
                    combinations: true,
                }
            }) as ParkourBannerObject
            o.layout()
            o.move(new paper.Point([0, -o.getExternalSize().height / 2 - this._rulersGap]))
            this._banners.push(o)

            const pos = position === BannerPosition.LEFT ? field.leftCenter : field.rightCenter
            o = this.createObject({
                kind: 'table-banner',
                x: pos.x,
                y: pos.y,
                angle: 0,
                orientation: Orientation.VERTICAL,
                visibility: {
                    title: false,
                    subtitle: false,
                    table: true,
                    combinations: true,
                }
            }) as ParkourBannerObject
            o.layout()
            o.move(new paper.Point([
                (position === BannerPosition.LEFT ? -1 : 1) * (o.getExternalSize().width / 2 + this._rulersGap), 0]))
            this._banners.push(o)
        }
        return this._banners
    }

    public getTimeLimit(roundNo: number, override?: number): number {
        if (!this.paper || this.obstaclePath.sections.length < roundNo) {
            return 0
        }
        const distance = override || this.obstaclePath.sections[roundNo - 1].correctedRoundedLengthTo5M
        const tl = this.cfg.params.timeLimit = this.getAdjustedTimeLimit(this.cfg.params.timeLimit || 0,
            this.cfg.params.compLoc, this.cfg.currentLimits.timeLimits)
        return tl !== null && tl >= 0 ? tl : this._estimateTime(distance, this.cfg.getSpeed())
    }

    public getAdjustedTimeLimit(limit: number, loc: CompetitionLocation, tl: TimeLimits | undefined): number | null {
        if (!this.paper) {
            return null
        }
        if (tl) {
            const val: LimitRange = tl[loc]
            if (tl === this.limitsService.timeLimitsTableC) {
                // current time limit depends on distance
                if (!this.cfg.isNoRouteMode()) {
                    const distance = this.obstaclePath.firstRound ? this.obstaclePath.firstRound.correctedRoundedLengthTo5M : 0
                    if (distance < 600) {
                        return 2 * (val.default || 0) / 3
                    }
                }
                return val.default || 0
            }
            if (!Math.ceil(limit) || limit > val.max! || limit < val.min!) {
                return val.default || 0
            }
            return limit
        } else {
            // calculate based on distance and speed
            return null
        }
    }

    public drawPageLimits() {
        if (!this.paper) {
            return
        }
        const bounds = this.getBoundsForAllLayers([LayerId.UI_EXTRAS])
        const marginPx = this.cfg.getPageMarginPx()
        const pageSize = this.cfg.getPageSizePx().subtract(marginPx * 2)
        if (bounds.size.width < bounds.size.height) {
            let w = pageSize.width
            pageSize.width = pageSize.height
            pageSize.height = w
        }
        let newSize, newMargin
        if (bounds.size.width / bounds.size.height > pageSize.width / pageSize.height) {
            newSize = new paper.Size(
                bounds.size.width,
                bounds.size.width * pageSize.height / pageSize.width,
            )
            newMargin = bounds.size.width * marginPx / pageSize.width
        } else {
            newSize = new paper.Size(
                bounds.size.height * pageSize.width / pageSize.height,
                bounds.size.height
            )
            newMargin = bounds.size.height * marginPx / pageSize.height
        }
        const visibility = this.pageLimits?.visible
        if (this.pageLimits && this.pageLimits.isInserted()) {
            this.pageLimits.remove()
        }
        const size = newSize.add(newMargin * 2)
        this._pageLimits = new paper.Shape.Rectangle({
            center: bounds.center,
            size: size,
            dashArray: [5, 8],
            strokeColor: this.cfg.params.colors.paperLimits,
            strokeWidth: 1,
            strokeScaling: false,
            visible: visibility
        })
        this._pageLimitsRect = new paper.Rectangle({
            center: bounds.center,
            size: size
        })
        this.getLayer(LayerId.UI_EXTRAS).addChild(this._pageLimits)
    }

    public async exportToPng(mode: string, width: number, secondRoundVisibleOnPlan?: boolean, layooutLinesVisible?: boolean): Promise<PngImage> {
        // prepare view for printing
        const layer = this.getLayer(LayerId.UI_EXTRAS)
        const uiExtrasVisibility = layer.visible || false
        layer.visible = false

        // place white rectangle at the back of the whole project - this fixes a problem
        // with blending white objects with the background, where a ghost outline appears in Chrome
        let rect
        if (this.pageLimitsRect) {
            rect = new paper.Shape.Rectangle(this.pageLimitsRect)
            rect.strokeColor = null
            rect.fillColor = new paper.Color('white')
            this.getLayer(LayerId.RULERS).addChild(rect)
            rect.sendToBack()
        }

        let parkourDesignLabel
        if (this.cfg.params.showParkourDesignLabel) {
            let someTextNearBottomLeftCorner = false
            const bottom = this.field.y + this.field.height
            const xMiddle = this.field.x + this.field.width / 2
            for (let o of this.objects) {
                if (o instanceof TextBox) {
                    let p = o.getPosition()
                    if (p.y < bottom) {
                        continue
                    }
                    if (p.x < xMiddle) {
                        someTextNearBottomLeftCorner = true
                        break
                    }
                }
            }
            const fs = this.cfg.getRulerFontSize() * 1.1
            let px = this.field.x
            if (someTextNearBottomLeftCorner) {
                if (this.pageLimitsRect) {
                    px = this.pageLimitsRect.right
                } else {
                    px += this.field.width
                }
                px -= fs * 16 // shift left by the lenght of this label text
            }
            parkourDesignLabel = new paper.PointText({
                point: [px, this.field.y + this.field.height + fs * 5],
                content: 'Designed in https://parkour.design',
                fontFamily: 'Roboto',
                fontSize: fs,
            });
            this.getLayer(LayerId.RULERS).addChild(parkourDesignLabel)
        }


        let gridWasVisible = false
        const regular = (mode === ExportMode.REGULAR)
        const prevPathConfig = structuredClone(this.obstaclePath.getConfig())

        let layoutLinesWereVisible = this.view?.canvas?.layoutLinesVisibility

        if (mode !== ExportMode.SNAPSHOT) {
            if (mode === ExportMode.ARENA) {
                this.getLayer(LayerId.OBSTACLES).visible = false
            } else {
                // Art.263.4 - for this competition no fixed track
                const hunting = (this.cfg.baseFeiRules === Article.ART_263_HUNTING_HANDINESS)
                this.obstaclePath.setConfig({
                    firstRound: {
                        labelsVisible: !(regular && hunting),
                        pathVisible: !(regular && hunting),
                        pathControlPointsVisible: false,
                        distancesVisible: !regular,
                        midPointsVisible: false,
                    },
                    secondRound: {
                        labelsVisible: !(regular && hunting),
                        pathVisible: !regular || secondRoundVisibleOnPlan,
                        pathControlPointsVisible: false,
                        distancesVisible: !regular,
                        midPointsVisible: false,
                    },
                    others: {
                        labelsVisible: false,
                        pathVisible: false,
                        pathControlPointsVisible: false,
                        distancesVisible: false,
                        midPointsVisible: false,
                    }
                })

                if (regular) {
                    this._setMaterialsVisibility(false)
                    this.layoutLinesVisibility = false
                    this.objects.forEach(o => {
                        if (o instanceof PathObject) {
                            o.hideName()
                        }
                    })
                } else {
                    this.layoutLinesVisibility = layooutLinesVisible || false
                }

                if (this.grid?.visible) {
                    gridWasVisible = true
                    this.grid.visible = false
                }
                this.objects.forEach(o => {
                    if (o instanceof PathObject) {
                        o.fadeIfNotUsed()
                    }
                })
            }
            if (this.paper) {
                // force rendering canvas now
                (this.paper.view as any)._needsUpdate = true
                this.paper.view.update()
            }
        }

        const size = this.getBoundsForAllLayers([LayerId.UI_EXTRAS]).size
        let height
        if (size.height < size.width) {
            // horizontal orientation
            height = width * size.height / size.width
        } else {
            // vertical orientation
            height = width
            width = height * size.width / size.height
        }
        const canvas = new OffscreenCanvas(width, height)
        const context = canvas.getContext('2d')!
        const svg = this._exportToSvg()
        if (rect && rect.isInserted()) {
            rect.remove()
        }

        // restore original view
        if (mode !== ExportMode.SNAPSHOT) {
            if (mode === ExportMode.ARENA) {
                this.getLayer(LayerId.OBSTACLES).visible = true
            } else {
                this.objects.forEach(o => {
                    if (o instanceof PathObject) {
                        o.removeFading()
                    }
                })
                if (regular) {
                    this.objects.forEach(o => {
                        if (o instanceof PathObject) {
                            o.showName()
                        }
                    })
                }
                if (this.grid) {
                    this.grid.visible = gridWasVisible
                }
                this.layoutLinesVisibility = layoutLinesWereVisible || false
            }
        }
        this.obstaclePath.setConfig(prevPathConfig)
        this._setMaterialsVisibility(true)
        this.getLayer(LayerId.UI_EXTRAS).visible = uiExtrasVisibility
        if (this.cfg.params.showParkourDesignLabel && parkourDesignLabel) {
            parkourDesignLabel.remove()
        }

        const canvg = await Canvg.from(context, svg, presets.offscreen())
        canvg.resize(width, height)
        await canvg.render()
        const blob = await canvas.convertToBlob()
        const pngStr = await this._blobToDataURL(blob)

        return { data: pngStr, width: width, height: height }
    }

    private async _blobToDataURL(blob: Blob): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = () => resolve(reader.result as string)
            reader.onerror = () => reject(reader.error)
            reader.onabort = () => reject(new Error("Read aborted"))
            reader.readAsDataURL(blob)
        })
    }

    private _setMaterialsVisibility(visible: boolean) {
        this.objects.forEach(o => {
            if (o instanceof ObstacleWithBars && o.materials) {
                o.materials.visible = visible
            }
        })
    }

    private _exportToSvg(): string {
        if (!this.paper) {
            return ''
        }
        const svgStr = this.paper.exportSVG({
            asString: true,
            bounds: 'content',
            applyMatrix: false,
            onExport: (item: any, node: any) => {
                if (item._class === 'PointText') {
                    node.textContent = null
                    for (let i = 0; i < item._lines.length; i++) {
                        const tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan')
                        tspan.textContent = `\u200b${item._lines[i]}`;
                        let dy = item.leading
                        if (i === 0) {
                            dy = 0
                        }
                        tspan.setAttributeNS(null, 'x', node.getAttribute('x'))
                        tspan.setAttributeNS(null, 'dy', dy)
                        node.appendChild(tspan)
                    }
                }
                return node
            }
        }) as string
        return svgStr
    }

    private _addStart() {
        // create start object
        let y = this.field.y + this.field.height / 2
        let angle = 90
        let o = this.createObject({
            kind: 'start',
            x: this.field.x + 1000,
            y: y,
            angle: angle
        })
        this.obstaclePath.add(o as PathObject, [], false, true, NodeFlags.NONE)
    }

    private _objectsReady() {
        this.updatePath()
        if (!this._userMovedOrZoomedField) {
            this.resetView()
        }
    }

    private _clearArena() {
        this.obstaclePath.clear()
        this._layers.forEach(l => l.removeChildren())
        this._objectGroups.forEach(g => g.destroy())
        this._objectGroups = []
        this.objects.forEach(o => o.destroy())
        this._objects = {}
        this._obstacles = {}
        this._drawRulers()
    }

    private _scaleOutOfField(newField: paper.Rectangle, scale: paper.Point) {
        Object.values(this.objects).forEach(o => {
            const pos = o.getPosition()
            if (pos.isInside(this.field)) {
                // retain the layout of the field that fits into the new field,
                // scale the objects that don't fit the new field into the new field
                if (!pos.isInside(newField)) {
                    o.setPosition(pos.multiply(scale))
                }
            } else {
                // objects that were originally outside the field, scale their position
                o.setPosition(pos.multiply(scale))
                // scale their size to the smaller scale coordinate factor
                let s = (Math.abs(scale.x - 1) < Math.abs(scale.y - 1)) ? scale.x : scale.y
                o.scaleObject(s)
            }
            if (o instanceof PathObject) {
                const nodes = this.obstaclePath.getPathNodes(o)
                nodes.forEach(([node]) => {
                    node.midPoints.forEach(mp => {
                        const mpos = mp.getPosition()
                        if (!mpos.isInside(newField)) {
                            mp.setPosition(mp.getPosition().multiply(scale))
                        }
                    })
                })
            }
        })
    }

    private _drawRulers() {
        if (this.grid && this.grid.isInserted()) {
            this.grid.remove()
        }
        this._grid = new paper.Group()
        this._grid.visible = this.view?.uiCommands.getToggler(UiCommandId.TOGGLE_GRID)?.onOffState || false
        if (this._rulers && this._rulers.isInserted()) {
            this._rulers.remove()
        }
        this._rulers = new paper.Group()
        const rulers = this._rulers
        const layer = this.getLayer(LayerId.RULERS)
        layer.addChild(this._grid)
        layer.addChild(rulers)

        this._banners.forEach(b => b.build())

        const fontSize = this.cfg.getRulerFontSize()

        // field margin
        const fm = fontSize * 1.3
        this._rulersGap = fm * 1.5

        const strokeParams = {
            strokeWidth: 0.5 * this.cfg.params.lineWidth,
            strokeScaling: false
        }

        // main field frame
        let fr = new paper.Shape.Rectangle({
            ...strokeParams,
            point: [this.field.x, this.field.y],
            size: this.field.size,
            radius: this.cfg.params.fieldRadius || 0,
            strokeColor: this.cfg.params.hideFieldBorder ? null : this.cfg.params.colors.fieldFrame
        });
        rulers.addChild(fr)

        let unitRatio = this.cfg.params.distanceUnit.startsWith('ft-') ? feetToMeters(1) : 1
        let gap = this._getTickGap(this.field.width)
        let lastContent = ''
        if (!this.cfg.params.hideFieldRuler) {
            // X axis ruler
            let x = 0;
            while (x * unitRatio < this.cfg.params.parkourWidth * 100) {
                // top tick
                if (x > 0) {
                    let plxt = new paper.Path.Line({
                        ...strokeParams,
                        from: [this.field.x + x * unitRatio, this.field.y - fm * 0.8],
                        to: [this.field.x + x * unitRatio, this.field.y],
                        strokeColor: this.cfg.params.colors.rulerMarks
                    });
                    rulers.addChild(plxt)
                }
                // top label
                let ptx = new paper.PointText({
                    point: [this.field.x + x * unitRatio, this.field.y],
                    content: lastContent = '' + (x / 100),
                    fillColor: this.cfg.params.colors.rulerMarks,
                    fontFamily: 'Courier New',
                    fontSize: fontSize
                });
                ptx.translate([-ptx.internalBounds.width - fm * 0.2, - fm * 0.2])
                rulers.addChild(ptx)

                // bottom tick
                if (this.cfg.params.fieldRadius === 0 || x > 0) {
                    let plxb = new paper.Path.Line({
                        ...strokeParams,
                        from: [this.field.x + x * unitRatio, this.field.bottom],
                        to: [this.field.x + x * unitRatio, this.field.bottom + fm * 0.8],
                        strokeColor: this.cfg.params.colors.rulerMarks
                    });
                    rulers.addChild(plxb)
                }

                if (x > 0) {
                    // bottom label
                    let pbx = new paper.PointText({
                        point: [this.field.x + x * unitRatio, this.field.bottom],
                        content: '' + (x / 100),
                        fillColor: this.cfg.params.colors.rulerMarks,
                        fontFamily: 'Courier New',
                        fontSize: fontSize
                    });
                    pbx.translate([-ptx.internalBounds.width - fm * 0.2, fm * 0.8])
                    rulers.addChild(pbx)
                }

                x += gap;
            }
            const txt = ParkourCanvas._lengthPipe.transform(this.cfg.params.parkourWidth * 100, this.cfg.params.distanceUnit, false, 0, 0, true)
            if (this.cfg.params.parkourWidth * 100 <= x && txt !== lastContent) {
                // last top label
                let ptx = new paper.PointText({
                    point: [this.field.right - fm * 0.2, this.field.y - fm * 0.2],
                    content: txt,
                    fillColor: this.cfg.params.colors.rulerMarks,
                    fontFamily: 'Courier New',
                    fontSize: fontSize
                });
                rulers.addChild(ptx)
            }
        }

        const gridStep = this.cfg.params.distanceUnit.startsWith('ft-') ? feetToMeters(10) * 100 : 250

        // vertical grid lines, every 2.5m
        for (let xgl = gridStep; xgl < this.cfg.params.parkourWidth * 100; xgl += gridStep) {
            let vgl = new paper.Path.Line({
                ...strokeParams,
                from: [this.field.x + xgl, this.field.y],
                to: [this.field.x + xgl, this.field.bottom],
                strokeColor: this.cfg.params.colors.grid
            });
            this._grid.addChild(vgl)
        }

        // Y axis ruler
        gap = this._getTickGap(this.field.height)
        lastContent = ''
        if (!this.cfg.params.hideFieldRuler) {
            let y = gap
            while (y * unitRatio < this.cfg.params.parkourHeight * 100) {
                // left tick
                let plyl = new paper.Path.Line({
                    ...strokeParams,
                    from: [this.field.x - fm * 1.1, this.field.y + y * unitRatio],
                    to: [this.field.x, this.field.y + y * unitRatio],
                    strokeColor: this.cfg.params.colors.rulerMarks
                });
                rulers.addChild(plyl)

                // left label
                let pty = new paper.PointText({
                    point: [this.field.x, this.field.y + y * unitRatio],
                    content: lastContent = '' + (y / 100),
                    fillColor: this.cfg.params.colors.rulerMarks,
                    fontFamily: 'Courier New',
                    fontSize: fontSize
                });
                pty.translate([- pty.internalBounds.width - fm * 0.2, - fm * 0.2])
                rulers.addChild(pty)

                // right tick
                let plyr = new paper.Path.Line({
                    ...strokeParams,
                    from: [this.field.right, this.field.y + y * unitRatio],
                    to: [this.field.right + fm * 1.1, this.field.y + y * unitRatio],
                    strokeColor: this.cfg.params.colors.rulerMarks
                });
                rulers.addChild(plyr)

                // right label
                let ply = new paper.PointText({
                    point: [this.field.right, this.field.y + y * unitRatio],
                    content: '' + (y / 100),
                    fillColor: this.cfg.params.colors.rulerMarks,
                    fontFamily: 'Courier New',
                    fontSize: fontSize
                });
                ply.translate([- pty.internalBounds.width + fm * 1.1, -fm * 0.2])
                rulers.addChild(ply)

                y += gap;
            }
            const txt = ParkourCanvas._lengthPipe.transform(this.cfg.params.parkourHeight * 100, this.cfg.params.distanceUnit, false, 0, 0, true)
            if (this.cfg.params.parkourHeight <= y && txt !== lastContent) {
                // last left label
                let ptx = new paper.PointText({
                    point: [this.field.x, this.field.bottom + fm * 0.1],
                    content: txt,
                    fillColor: this.cfg.params.colors.rulerMarks,
                    fontFamily: 'Courier New',
                    fontSize: fontSize
                });
                ptx.translate([-ptx.internalBounds.width - fm * 0.2, 0])
                rulers.addChild(ptx)
            }
        }

        // horizontal grid lines
        for (let ygl = gridStep; ygl < this.cfg.params.parkourHeight * 100; ygl += gridStep) {
            let hgl = new paper.Path.Line({
                ...strokeParams,
                from: [this.field.x, this.field.y + ygl],
                to: [this.field.right, this.field.y + ygl],
                strokeColor: this.cfg.params.colors.grid
            });
            this._grid.addChild(hgl)
        }
    }

    private _getTickGap(value: number): number {
        if (this.cfg.params.distanceUnit.startsWith('ft-')) {
            value = metersToFeet(value)
        }
        let xstep = 0
        for (let i of [20, 10, 5, 2, 1]) {
            xstep = Math.round(value / (i * 1000)) * (i * 100)
            if (xstep != 0) {
                break
            }
        }
        if (xstep == 0) {
            xstep = 1
        }
        return xstep
    }

    private _estimateTime(distance: number, speed: number): number {
        if (speed === 0) {
            return 0
        }
        return Math.ceil(distance / speed * 60)
    }
}
