import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { authGuard } from './auth.guard'
import { PageNotFoundComponent } from './shared/components';
import { MainPageComponent } from './main-page/main-page.component';
import { DetailComponent } from './detail/detail.component';
import { UserProfileComponent } from './user-profile/user-profile.component'
import { PublicDesignsPageComponent } from './public-designs-page/public-designs-page.component'
import { AdminHomeComponent } from './admin-home/admin-home.component';

const routes: Routes = [
    {
        path: '',
        component: MainPageComponent,
        pathMatch: 'full'
    },
    {
        path: 'admin-home',
        component: AdminHomeComponent,
        canActivate: [authGuard],
    },
    {
        path: 'designer',
        component: DetailComponent,
        canActivate: [authGuard],
    },
    {
        path: 'user',
        component: UserProfileComponent,
        canActivate: [authGuard],
    },
    {
        path: 'user-plan',
        component: UserProfileComponent,
        canActivate: [authGuard],
    },
    {
        path: 'user-delete',
        component: UserProfileComponent,
        canActivate: [authGuard],
    },
    {
        path: 'public-designs',
        component: PublicDesignsPageComponent,
        canActivate: [authGuard],
    },
    {
        path: '**',
        component: PageNotFoundComponent
        //redirectTo: 'detail',
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {}),
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
