import { FormBuilder, FormGroup, Validators } from '@angular/forms'

import paper from 'paper'
import { Article, DesignBaseObject, DesignColors, DesignFormParams, DesignNode, DesignObjectGroup, DesignSchema } from './design.schema'
import { ClassNo, ClassNoNameValue, CompetitionTable, LimitSet, LimitSetCombined, LimitsService, ObstacleHeights } from "./services/limits.service"


export class ParkourConfig {
    public readonly form: FormGroup
    public params: DesignSchema

    // a value that assists form when parsing article numbers when config window is open and form loaded
    public baseFeiRulesForm: Article = Article.NONE

    // a value of the base FEI rules article that is configured - derived from params.feiRules
    public baseFeiRules: Article = Article.NONE

    public formLimits: LimitSetCombined = {}
    public currentLimits: LimitSetCombined
    public regulationsLimits?: LimitSet

    public debugParams: { [id: string]: any } = {
        imgBoundingBox: false
    }

    private readonly _fb: FormBuilder = new FormBuilder()

    constructor(public limitsService: LimitsService, initialData: Partial<DesignSchema>) {
        this.params = new DesignSchema()
        this.params.copy(initialData)
        this.form = this._buildControls(this.params, new DesignFormParams())
        this.currentLimits = {
            heights: limitsService.getHeightLimits(this.params.classes),
            ...LimitsService.defaults
        }
    }

    public setRoute(route: DesignNode[]) {
        this.params.route = route
        this._updateDate()
    }

    public setObjects(objects: DesignBaseObject[]) {
        this.params.objects = objects
        this._updateDate()
    }

    public setGroups(groups: DesignObjectGroup[]) {
        this.params.groups = groups
        this._updateDate()
    }

    public setValuesToForm() {
        this.form.patchValue(this.params)
    }

    public getValuesFromForm() {
        this._getValuesFromForm(this.params, this.form)
    }

    public importDesign(design: any) {
        this.params = new DesignSchema()
        this.params.copy(design)
        this._loadLimits()
        // for configured classes, if height is not configured, set it to default for that class
        for (let c of this.params.classes) {
            const v = this.params.obstacleHeights[c] || this.params.obstacleHeight
            const l = this.currentLimits.heights?.[c]
            if (l && l.min && l.max && v <= l.max && v >= l.min) {
                this.params.obstacleHeights[c] = v
            } else if (l?.default) {
                this.params.obstacleHeights[c] = l.default
            }
        }
        this.setValuesToForm()
        this._updateDate()
    }

    public getSpeed(): number {
        const v = this.params.speed
        if (typeof (v) === 'string') {
            return +v
        } else if (typeof (v) === 'number') {
            return v
        }
        return 0
    }

    public canUserEditArrows(): boolean {
        return this.currentLimits.userCanEditArrows || false
    }

    public isNoRouteMode(): boolean {
        return this.currentLimits.noRoute || false
    }

    public isJokerAllowed(): boolean {
        return (this.baseFeiRules === Article.ART_269_ACCUMULATOR)
    }

    public isScoreAllowed(): boolean {
        return (this.baseFeiRules === Article.ART_270_TOP_SCORE)
    }

    public isMaxTimeAllowed(): boolean {
        if (this.params.table === CompetitionTable.TABLE_C) {
            // Art.239.3 - no time allowed under Table C
            return false
        }
        return !this.currentLimits.noMaxTime
    }

    public noFinishInFirstRound(): boolean {
        return (this.baseFeiRules === Article.ART_267_HIT_AND_HURRY)
    }

    public updateCompatibleClassesInForm(classNoOptions: ClassNoNameValue[]) {
        const article: Article = this.baseFeiRulesForm
        const classes = this.form.controls.classes.value as ClassNo[]

        for (let c of classNoOptions) {
            c.disabled = false
        }
        const toRemove: ClassNo[] = []
        for (let v of classes) {
            for (let c of classNoOptions) {
                if (c.value !== v) {
                    if (!toRemove.includes(c.value) && this.limitsService.getManyLimits([v, c.value], article) === null) {
                        c.disabled = true
                        if (classes.includes(c.value)) {
                            toRemove.push(c.value)
                        }
                    }
                }
            }
        }
        if (toRemove.length > 0) {
            for (let c of toRemove) {
                const i = classes.indexOf(c)
                if (i >= 0) {
                    classes.splice(i, 1)
                }
            }
            this.form.controls.classes.patchValue(classes)
        }
    }

    public scaleAccordingToFieldSize(value: number) {
        return value * 0.6 * Math.max(this.params.parkourWidth, this.params.parkourHeight) + 40
    }

    private _calcFontSize(size: number) {
        return this.scaleAccordingToFieldSize(size / 100)
    }

    public getFontSize(): number {
        return this._calcFontSize(this.params.fontSize)
    }

    public getTableFontSize(): number {
        return this._calcFontSize(this.params.tableFontSize)
    }

    public getDistanceFontSize(): number {
        return this._calcFontSize(this.params.distFontSize)
    }

    public getRulerFontSize(): number {
        return this._calcFontSize(this.params.rulerFontSize)
    }

    public getLabelFontSize(): number {
        return this._calcFontSize(this.params.labelFontSize)
    }

    public getPageMarginPx(): number {
        return this.params.paperMarginsCm * 10 * 2.835
    }

    public getPageSizePx(): paper.Size {
        return new paper.Size(841.995, 595.35)
    }

    private _buildControls(params: { [id: string]: any }, refObj: any): FormGroup {
        const controls: { [id: string]: any } = {}
        // go through only from params, skip non-form params
        for (let p of Object.keys(refObj)) {
            if (params.hasOwnProperty(p)) {
                const pVal = params[p as keyof DesignFormParams]
                if (p === 'colors') {
                    controls[p] = this._buildControls(pVal, new DesignColors())
                } else if (p === 'obstacleHeights') {
                    const hs: ObstacleHeights = Object.fromEntries(Object.values(ClassNo).filter(c => !!c).map(c => [c, 0]))
                    // create all form controls for all classes
                    controls[p] = this._buildControls(hs, hs)
                } else {
                    controls[p] = [pVal, Validators.nullValidator]
                }
            }
        }
        return this._fb.group(controls)
    }

    private _updateDate() {
        this.params.updatedAt = (new Date()).toISOString()
        this.params.saved = false
    }

    private _getValuesFromForm(params: any, form: FormGroup) {
        let control: any, key: string
        for ([key, control] of Object.entries(form.controls)) {
            if (control instanceof FormGroup) {
                this._getValuesFromForm(params[key], control)
            } else {
                params[key] = control.value
            }
        }
        this._updateDate()
    }

    private _loadLimits() {
        const classes = this.params.classes
        const article = this.params.feiRules as Article
        this.baseFeiRules = this.limitsService.getArticleBase(article)
        this.currentLimits = this.limitsService.getManyLimits(classes, this.baseFeiRules) || {
            heights: this.limitsService.getHeightLimits(classes),
            ...LimitsService.defaults
        }
        this.regulationsLimits = this.limitsService.getManyRegulationsLimits(classes, this.baseFeiRules) || LimitsService.defaults
    }
}
