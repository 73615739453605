<p-dialog [(visible)]="visibility"
    [style]="{'min-width': '15vw'}"
    (keydown.enter)="onOkDialog()" (keydown.escape)="onCancelDialog()"
    (onShow)="onShowDialog()" (onClose)="onCloseDialog()" (onHide)="onHideDialog()">
    <ng-template pTemplate="header">
        <span class="p-dialog-title"><i class="fa-solid fa-maximize mr-2"></i> <span i18n>Rozmiar placu</span></span>
    </ng-template>
    <form [formGroup]="view.cfg.form">
        <div class="flex flex-wrap gap-3">
            <div class="flex-auto">
                <label for="parkourHeight" class="block mb-2 flex align-items-center">
                    <span i18n>Szerokość</span><span>&nbsp;[{{ units }}]&nbsp;</span><i class="pi pi-arrows-v"></i>
                </label>
                <p-inputNumber
                    inputId="parkourHeight" formControlName="parkourHeight" inputStyleClass="w-full" mode="decimal"
                    [min]="getFieldHeightLimits()[0]" [max]="getFieldHeightLimits()[1]" [step]="getFieldHeightLimits()[2]" [useGrouping]="false" [showButtons]="true">
                </p-inputNumber>
            </div>
            <div class="flex-auto">
                <label for="parkourWidth" class="block mb-2 flex align-items-center">
                    <span i18n>Długość</span><span>&nbsp;[{{ units }}]&nbsp;</span><i class="pi pi-arrows-h"></i>
                </label>
                <p-inputNumber
                    inputId="parkourWidth" formControlName = "parkourWidth" inputStyleClass="w-full" mode="decimal"
                    [min]="getFieldWidthLimits()[0]" [max]="getFieldWidthLimits()[1]" [step]="getFieldWidthLimits()[2]"
                    [useGrouping]="false" [showButtons]="true">
                </p-inputNumber>
            </div>
        </div>
    </form>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="onCancelDialog()" label="Anuluj" severity="danger"></p-button>
        <p-button icon="pi pi-check" (click)="onOkDialog()"
                  label="Zmień rozmiar" i18n-label></p-button>
    </ng-template>
</p-dialog>
