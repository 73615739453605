import { CommonModule } from '@angular/common';
import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter,
    Input, NgModule, OnDestroy, Output, Renderer2, ViewChild, ViewEncapsulation, booleanAttribute, forwardRef
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ColorPicker, ColorPickerChangeEvent, ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenu, ContextMenuModule } from 'primeng/contextmenu';

@Component({
    selector: 'new-colorPicker',
    templateUrl: 'new-colorpicker.html',
    styleUrls: [ './new-colorpicker.scss' ],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => NewColorPicker),
        multi: true
    }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'p-element'
    }
})

export class NewColorPicker implements ControlValueAccessor, AfterViewInit, OnDestroy {
    @Input({ transform: booleanAttribute }) disabled: boolean | undefined
    
    // element on which RMB can be clicked to show menu
    @Input() menuTarget: string | HTMLElement | undefined

    // pass-through of color picker onChange events
    @Output() onChange: EventEmitter<ColorPickerChangeEvent> = new EventEmitter<ColorPickerChangeEvent>()

    // primeng color picker
    @ViewChild('picker') colorPicker?: ColorPicker

    // copy/paste buttons
    @ViewChild('buttons') buttonsDiv?: ElementRef<HTMLElement>

    // RMB ontext menu
    @ViewChild('menu') contextMenu?: ContextMenu

    // callbacks that angular will register
    onModelChange: Function = () => {}
    onModelTouched: Function = () => {}

    static copyColor?: string
    static menus: ContextMenu[] = []

    get copyColor(): string | undefined {
        return NewColorPicker.copyColor
    }

    value?: string
    visible: boolean = false

    readonly colorCopyMenu: MenuItem[] = [{
        label: $localize`Kopiuj`,
        icon: 'fa-regular fa-copy',
        command: () => this.colorCopy(),
    },
    {
        label: $localize`Wklej`,
        icon: 'fa-regular fa-paste',
        command: (ev: any) => this.colorPaste(ev),
        disabled: true
    }]

    constructor(
        public cd: ChangeDetectorRef,
        public renderer: Renderer2,
    ) {
    }

    ngAfterViewInit(): void {
        if (this.contextMenu) {
            if (!NewColorPicker.menus.includes(this.contextMenu)) {
                NewColorPicker.menus.push(this.contextMenu)
            }
        }
    }

    ngOnDestroy() {
        if (this.contextMenu) {
            const i = NewColorPicker.menus.indexOf(this.contextMenu)
            if (i >= 0) {
                NewColorPicker.menus.splice(i, 1)
            }
        }
    }

    colorCopy() {
        if (this.value) {
            NewColorPicker.copyColor = this.value
            NewColorPicker.menus.forEach(m => {
                if (m.model) {
                    m.model[1].disabled = false
                    m.model[1].icon = 'fa-solid fa-square'
                    m.model[1].iconStyle = { 'color': this.value }
                }
            })
        }
    }

    colorPaste(ev: any) {
        if (NewColorPicker.copyColor) {
            this.value = NewColorPicker.copyColor
            this.cd.markForCheck()
            this.onModelChange(this.value) // propagate change to form
            this.onChange.emit({
                originalEvent: ev,
                value: this.value
            })
        }
    }

    onPickerShow() {
        this.visible = true
        this._disableSelect()
        if (this.colorPicker && this.buttonsDiv) {
            this.renderer.appendChild(this.colorPicker.overlay, this.buttonsDiv.nativeElement)
        }
    }

    onPickerHide() {
        this.visible = false
        this._enableSelect()
    }

    // required by ControlValueAccessor, called when form value changed, to propagate to primeng color picker
    writeValue(value: any) {
        this.value = value
        this.cd.markForCheck()
    }

    // required by ControlValueAccessor, registers callback to handle model changes
    registerOnChange(f: Function) {
        this.onModelChange = f
    }

    // required by ControlValueAccessor
    registerOnTouched(f: Function) {
        this.onModelTouched = f
    }

    // required by ControlValueAccessor, changes state when form state changes
    setDisabledState(val: boolean) {
        this.disabled = val
        this.cd.markForCheck()
    }

    // called when primeng color picker model is changed, to propagate the change to the form
    onNgModelChange() {
        this.onModelChange(this.value)
    }

    // called when context menu is opened, to close all other context menus
    onMenuShow(menu: ContextMenu) {
        NewColorPicker.menus.forEach(m => {
            if (m !== menu) {
                m.hide()
            }
        })
    }

    // called when primeng color picker changed value, to propagate change
    onColorChange(ev: ColorPickerChangeEvent) {
        this.onChange.emit(ev)
    }

    // block text selection (when picker is open)
    private _enableSelect() {
        window.removeEventListener('selectstart', this._preventDefault)
    }

    private _disableSelect() {
        window.addEventListener('selectstart', this._preventDefault)
    }

    private _preventDefault(e: Event) {
        e.preventDefault()
    }
}

@NgModule({
    imports: [ CommonModule, FormsModule, ColorPickerModule, ContextMenuModule, ButtonModule ],
    exports: [ NewColorPicker ],
    declarations: [ NewColorPicker ]
})

export class NewColorPickerModule {}

