import { inject } from '@angular/core'
import { CanActivateFn } from '@angular/router'
import { Router } from '@angular/router'

import { AuthService } from './services/auth.service'

export const authGuard: CanActivateFn = (route, state) => {
    const auth: AuthService = inject(AuthService)
    const router: Router = inject(Router)
    if (auth.isLogged()) {
        return true
    }
    return router.parseUrl('/')
}
