import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormGroup, FormsModule, ValidationErrors, Validators } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';
import { DetailComponent } from '../../detail.component';
import { PanelModule } from 'primeng/panel'
import { DialogModule } from 'primeng/dialog'
import { Article, CompetitionLocation, LimitRange, ShareMode } from '../../../design.schema'
import { CompetitionTable } from "../../../services/limits.service"
import { ClassNo } from "../../../services/limits.service"
import { ArticleOption, ClassNoNameValue, CompLocNameValue, LimitsService } from "../../../services/limits.service"
import { TabMenu, TabMenuModule } from 'primeng/tabmenu'
import { MenuItem } from 'primeng/api'
import { CalendarModule } from 'primeng/calendar'
import { MultiSelectModule } from 'primeng/multiselect'
import { SelectButtonModule } from 'primeng/selectbutton'
import { feetToMeters, feetToMetersRounded, formatLimits, metersToFeet, metersToFeetRounded } from '../../../utils'
import { ParkourDialog } from '../detail.dialog.interface'
import { SharedModule } from '../../../shared/shared.module'
import { LengthPipe } from "../../../pipes";


export interface AllValidationErrors {
    control_name: string;
    error_name: string;
    error_value: any;
}

export interface FormGroupControls {
    [key: string]: AbstractControl;
}

@Component({
    selector: 'app-parkour-competition-params',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonModule,
        InputNumberModule,
        CheckboxModule,
        InputTextModule,
        TooltipModule,
        DropdownModule,
        PanelModule,
        DialogModule,
        TabMenuModule,
        CalendarModule,
        MultiSelectModule,
        SelectButtonModule,
        LengthPipe
    ],
    templateUrl: './parkour-competition-params.component.html',
    styleUrl: './parkour-competition-params.component.scss'
})
export class ParkourCompetitionParamsComponent implements ParkourDialog {
    @Input({ required: true }) view!: DetailComponent
    @Output() onOk = new EventEmitter<void>()
    @Output() onCancel = new EventEmitter<void>()

    @ViewChild('articleInParams') articleInParamsEl: ElementRef<HTMLElement> | undefined
    @ViewChild('paramsTabMenu') paramsTabMenuEl: TabMenu | undefined

    visibility: boolean = false
    paramsTabMenuItems: MenuItem[] = [
        { label: $localize`Podstawowe`, icon: 'pi pi-fw pi-sliders-h' },
        { label: $localize`Dystans, tempo i czas`, icon: 'pi pi-fw pi-stopwatch' },
        { label: $localize`Wysokości`, icon: 'pi pi-fw pi-arrows-v', tooltipOptions: { tooltipLabel: $localize`Wysokości wszystkich przeszkód, dla których nie ustawiono ich indywidualnie.` } },
        { label: $localize`Szerokości i inne`, icon: 'pi pi-fw pi-arrows-h', tooltipOptions: { tooltipLabel: $localize`Szerokości wszystkich przeszkód, dla których nie ustawiono ich indywidualnie oraz inne wymiary.` } },
        { label: $localize`Udostępnianie`, icon: 'pi pi-fw pi-share-alt' },
    ]
    paramsActiveItem: MenuItem = this.paramsTabMenuItems[0]

    tableOptions = [
        { name: $localize`:used in a list of items@@classNo.none:brak`, value: CompetitionTable.NONE },
        { name: 'A', value: CompetitionTable.TABLE_A },
        { name: 'C', value: CompetitionTable.TABLE_C }
    ]

    tableLanguageOptions = [
        { name: $localize`Polski`, value: 'pl' },
        { name: $localize`Angielski`, value: 'en' },
        { name: $localize`Niemiecki`, value: 'de' }
    ]

    shareModes = [
        { label: $localize`Publiczny`, value: ShareMode.PUBLIC },
        { label: $localize`Prywatny`, value: ShareMode.PRIVATE },
    ]

    errorMsg: string = ''
    articleTooltip = ''
    classNoOptions: ClassNoNameValue[]
    articleOptions: ArticleOption[]
    compLocOptions: CompLocNameValue[]
    speedOptions: any[] = []
    timeLimitTooltip?: string
    selectedItemsLabel: string = $localize`Wybrano {0} wartości`

    firstRoundTimeLimit: string = ''
    secondRoundTimeLimit: string = ''
    timeLimitLimits: string = ''

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        public limitsService: LimitsService,
    ) {
        this.classNoOptions = structuredClone(this.limitsService.classNoOptions)
        this.articleOptions = structuredClone(limitsService.articleOptions)
        this.compLocOptions = structuredClone(this.limitsService.compLocOptions)
    }

    initialize(setToDefault: boolean) {
        const form = this.view.cfg.form
        const classes = form.controls.classes.value as ClassNo[]
        const loc: CompetitionLocation = form.controls.compLoc.value
        this.setBaseArticleAndTooltipForm()
        const article: Article = this.view.cfg.baseFeiRulesForm

        this.speedOptions = [
            { name: '300', value: 300, disabled: !this.limitsService.isSpeedValid(classes, loc, article, 300) },
            { name: '325', value: 325, disabled: !this.limitsService.isSpeedValid(classes, loc, article, 325) },
            { name: '350', value: 350, disabled: !this.limitsService.isSpeedValid(classes, loc, article, 350) },
            { name: '375', value: 375, disabled: !this.limitsService.isSpeedValid(classes, loc, article, 375) },
            { name: '400', value: 400, disabled: !this.limitsService.isSpeedValid(classes, loc, article, 400) },
        ]

        this.view.cfg.updateCompatibleClassesInForm(this.classNoOptions)

        const currentLimits = this.limitsService.getManyLimits(classes, article) || {
            heights: {},
            ...LimitsService.defaults
        }
        this.view.cfg.formLimits = currentLimits

        let singleObstacleHeightShouldBeValidated = true
        const f = form.controls.obstacleHeights
        if (f instanceof FormGroup) {
            for (const c of Object.values(ClassNo).filter(c => !!c)) {
                const v = f.controls[c]
                if (v !== undefined) {
                    const val = currentLimits.heights?.[c]
                    if (classes.includes(c) && val) {
                        v.setValidators([Validators.min(val.min!), Validators.max(val.max!), Validators.required])
                        v.updateValueAndValidity()
                        if (v.value === 0 && val.default) {
                            v.patchValue(val.default)
                        }
                        singleObstacleHeightShouldBeValidated = false
                    } else {
                        v.setValidators(Validators.nullValidator)
                    }
                }
            }
        }

        if (singleObstacleHeightShouldBeValidated) {
            let val = currentLimits.height!
            if (setToDefault && val.default) {
                form.controls.obstacleHeight.patchValue(val.default)
            }
            form.controls.obstacleHeight.setValidators([Validators.min(val.min!), Validators.max(val.max!), Validators.required])
            form.controls.obstacleHeight.updateValueAndValidity()
        }

        let val = currentLimits.length!
        if (setToDefault && val.default) {
            form.controls.obstacleLength.patchValue(val.default)
        }
        form.controls.obstacleLength.setValidators([Validators.min(val.min!), Validators.max(val.max!), Validators.required])
        form.controls.obstacleLength.updateValueAndValidity()

        if (setToDefault && currentLimits.speeds && currentLimits.speeds[loc].default) {
            form.controls.speed.patchValue(currentLimits.speeds[loc].default)
        }
        form.controls.speed.setValidators((control: AbstractControl): ValidationErrors | null => {
            const speeds = currentLimits.speeds![loc]
            if (speeds === undefined || !speeds.values.includes(control.value)) {
                if (speeds.values.length === 0 || speeds.default === 0) {
                    // no speed for this competition return validation passed
                    return null
                }
                return { speedNotAllowed: true }
            }
            return null
        })
        const speeds = currentLimits.speeds![loc]
        if (speeds.values.length === 0 || speeds.default === 0) {
            this.speedOptions.unshift({ name: 'brak', value: 0, disabled: true })
            form.controls.speed.patchValue(0)
            form.controls.speed.disable()
        } else {
            if (form.controls.speed.value === 0) {
                form.controls.speed.patchValue(speeds.default)
            }
            form.controls.speed.enable()
        }
        form.controls.speed.updateValueAndValidity()

        if (currentLimits.width) {
            val = currentLimits.width.oxer
            if (setToDefault && val.default) {
                form.controls.oxerWidth.patchValue(val.default)
            }
            form.controls.oxerWidth.setValidators([Validators.min(val.min!), Validators.max(val.max!), Validators.required])
            form.controls.oxerWidth.updateValueAndValidity()

            val = currentLimits.width.tripleBarre
            if (setToDefault && val.default) {
                form.controls.tripleBarWidth.patchValue(val.default)
            }
            form.controls.tripleBarWidth.setValidators([Validators.min(val.min!), Validators.max(val.max!), Validators.required])
            form.controls.tripleBarWidth.updateValueAndValidity()

            val = currentLimits.width.ditch!
            if (setToDefault && val.default) {
                form.controls.ditchWidth.patchValue(val.default)
            }
            form.controls.ditchWidth.setValidators([Validators.min(val.min!), Validators.max(val.max!), Validators.required])
            form.controls.ditchWidth.updateValueAndValidity()

            val = currentLimits.width.liverpool!
            if (setToDefault && val.default) {
                form.controls.liverPoolWidth.patchValue(val.default)
            }
            form.controls.liverPoolWidth.setValidators([Validators.min(val.min!), Validators.max(val.max!), Validators.required])
            form.controls.liverPoolWidth.updateValueAndValidity()

            val = currentLimits.width.wall!
            if (setToDefault && val.default) {
                form.controls.wallWidth.patchValue(val.default)
            }
            form.controls.wallWidth.setValidators([Validators.min(val.min!), Validators.max(val.max!), Validators.required])
            form.controls.wallWidth.updateValueAndValidity()
        }

        const tl = currentLimits.timeLimits
        if (tl) {
            val = tl[loc]
            if (setToDefault && val.default) {
                form.controls.timeLimit.patchValue(val.default)
            }
            form.controls.timeLimit.setValidators([Validators.min(val.min!), Validators.max(val.max!)])
            form.controls.timeLimit.patchValue(
                this.view.canvas?.getAdjustedTimeLimit(form.controls.timeLimit.value, loc, currentLimits.timeLimits))
            if (val.max! > val.min!) {
                form.controls.timeLimit.enable()
            } else {
                form.controls.timeLimit.disable()
            }
            form.controls.timeLimit.updateValueAndValidity()
        } else {
            form.controls.timeLimit.setValidators(Validators.nullValidator)
            form.controls.timeLimit.patchValue(null)
            form.controls.timeLimit.disable()
            form.controls.timeLimit.updateValueAndValidity()
        }

        if (form.controls.overrideDistance1.value) {
            form.controls.distance1M.enable()
        } else {
            form.controls.distance1M.disable()
            if (this.view.cfg.params.distanceUnit.startsWith('ft-')) {
                form.controls.distance1M.patchValue(metersToFeetRounded((this.view.canvas?.obstaclePath.firstRound?.length || 0) / 100))
            } else {
                form.controls.distance1M.patchValue(this.view.canvas?.obstaclePath.firstRound?.roundedLengthTo5M || 0)
            }
        }
        if (form.controls.overrideDistance2.value) {
            form.controls.distance2M.enable()
        } else {
            form.controls.distance2M.disable()
            if (this.view.cfg.params.distanceUnit.startsWith('ft-')) {
                form.controls.distance2M.patchValue(metersToFeetRounded((this.view.canvas?.obstaclePath.secondRound?.length || 0) / 100))
            } else {
                form.controls.distance2M.patchValue(this.view.canvas?.obstaclePath.secondRound?.roundedLengthTo5M || 0)
            }
        }

        this.establishTimeLimitLimitsToDisplay()
    }

    establishTimeLimitLimitsToDisplay() {
        this.firstRoundTimeLimit = ''
        this.secondRoundTimeLimit = ''
        this.timeLimitTooltip = undefined
        this.timeLimitLimits = ''

        const tl = this.view.cfg.formLimits.timeLimits
        if (tl) {
            const loc: CompetitionLocation = this.view.cfg.form.controls.compLoc.value
            const val = tl[loc]
            if (tl === this.limitsService.timeLimitsTableC) {
                this.timeLimitTooltip = $localize`Wartość zależna od długości trasy`
                this.firstRoundTimeLimit = '120 / 180 s'
                this.secondRoundTimeLimit = ''
            } else if (val.max! === val.min!) {
                if (val.max! === 0) {
                    this.timeLimitTooltip = $localize`Brak limitu czasu`
                    this.firstRoundTimeLimit = $localize`(brak)`
                } else {
                    this.timeLimitTooltip = $localize`Dopuszczalna jest tylko jedna wartość`
                }
            } else {
                this.timeLimitLimits = formatLimits(val, 's')
            }
        } else {
            this.timeLimitTooltip = $localize`Wartość wyliczana na podstawie tempa i długości trasy`
            if (this.view.canvas?.obstaclePath.firstRound) {
                let v = this.view.cfg.form.controls.distance1M.value
                let dist = this.view.cfg.params.distanceUnit.startsWith('ft-') ? feetToMeters(v) : v
                let s = this.getTimeLimitForm(1, this.view.cfg.form.controls.overrideDistance1.value ?
                    dist : undefined).toFixed(0) + ' s'
                this.firstRoundTimeLimit = s

                if (this.view.canvas.obstaclePath.secondRound) {
                    v = this.view.cfg.form.controls.distance2M.value
                    dist = this.view.cfg.params.distanceUnit.startsWith('ft-') ? feetToMeters(v) : v
                    this.secondRoundTimeLimit = this.getTimeLimitForm(2, this.view.cfg.form.controls.overrideDistance2.value ?
                        dist : undefined).toFixed(0) + ' s'
                }
            }
        }
    }

    getTimeLimitForm(roundNo: number, override?: number): number {
        if (!this.view.canvas || this.view.canvas.obstaclePath.sections.length < roundNo) {
            return 0
        }
        const form = this.view.cfg.form
        const section = this.view.canvas.obstaclePath.sections[roundNo - 1]
        const distance = override || (this.view.cfg.params.distanceUnit.startsWith('ft-') ? section.length / 100 : section.roundedLengthTo5M)
        let tl = this.view.canvas.getAdjustedTimeLimit(form.controls.timeLimit.value || 0,
            form.controls.compLoc.value, this.view.cfg.formLimits.timeLimits)
        if (tl) {
            return tl
        }
        const speed = form.controls.speed.value
        if (speed) {
            tl = Math.ceil(distance / speed * 60)
        } else {
            tl = 0
        }
        return tl
    }

    getHeightLimitsForm(classNo?: ClassNo): string {
        if (!classNo || !this.view.cfg.formLimits.heights || !this.view.cfg.formLimits.heights[classNo]) {
            return formatLimits(this.view.cfg.formLimits.height, 'cm')
        }
        return formatLimits(this.view.cfg.formLimits.heights[classNo], 'cm')
    }

    getSortedClassesForm(): ClassNo[] {
        const classes: ClassNo[] | undefined = this.view.cfg.form.controls.classes?.value
        if (classes) {
            return this.classNoOptions.filter(c => classes.includes(c.value)).map(c => c.value)
        }
        return []
    }

    getLengthLimitsForm(): string {
        return formatLimits(this.view.cfg.formLimits.length, 'cm')
    }

    getTimeLimitLimitsRangeForm(): LimitRange {
        const loc: CompetitionLocation = this.view.cfg.form.controls.compLoc.value
        const limits = this.view.cfg.formLimits.timeLimits
        if (limits && loc) {
            return limits[loc]
        }
        return { min: 0, max: 0 }
    }

    getOxerWidthLimitsForm(): string {
        return formatLimits(this.view.cfg.formLimits.width?.oxer, 'cm')
    }

    getTripleBarreWidthLimitsForm(): string {
        return formatLimits(this.view.cfg.formLimits.width?.tripleBarre, 'cm')
    }

    getDitchWidthLimitsForm(): string {
        return formatLimits(this.view.cfg.formLimits.width?.ditch, 'cm')
    }

    getLiverPoolWidthLimitsForm(): string {
        return formatLimits(this.view.cfg.formLimits.width?.liverpool, 'cm')
    }

    getWallWidthLimitsForm(): string {
        return formatLimits(this.view.cfg.formLimits.width?.wall, 'cm')
    }

    setBaseArticleAndTooltipForm() {
        this.articleTooltip = this.limitsService.setBaseArticleAndTooltipForm(this.view.cfg, this.articleInParamsEl)
    }

    hideArticleTooltip() {
        this.articleInParamsEl?.nativeElement.dispatchEvent(new Event('mouseleave'));
        this.articleTooltip = ''
    }

    paramsFormSizesInvalid(item?: MenuItem): boolean {
        const c = this.view.cfg.form.controls
        return (!item || item === this.paramsTabMenuItems[1]) && this.view.cfg.form.invalid && (
            c.obstacleHeight.invalid ||
            c.obstacleLength.invalid ||
            c.speed.invalid ||
            c.oxerWidth.invalid ||
            c.tripleBarWidth.invalid ||
            c.ditchWidth.invalid ||
            c.liverPoolWidth.invalid ||
            c.wallWidth.invalid ||
            c.compMinDist.invalid ||
            c.compMaxDist.invalid ||
            c.strideMinLenM.invalid ||
            c.strideMaxLenM.invalid ||
            c.jumpBeforeLenM.invalid ||
            c.landAfterLenM.invalid
        )
    }

    resetParamsTabMenu() {
        this.paramsActiveItem = this.paramsTabMenuItems[0]
        this.paramsTabMenuEl?.itemClick(new Event('click'), this.paramsActiveItem)
        this.changeDetectorRef.detectChanges()
    }

    onCloseDialog() {
    }

    onShowDialog() {
        this.view.cfg.setValuesToForm()
        const controls = this.view.cfg.form.controls
        if (this.view.cfg.params.distanceUnit.startsWith('ft-')) {
            controls.distance1M.patchValue(metersToFeetRounded(controls.distance1M.value))
            controls.distance2M.patchValue(metersToFeetRounded(controls.distance2M.value))
        }
        this.initialize(false)
        this.resetParamsTabMenu()
    }

    onHideDialog() {
        this.resetParamsTabMenu()
        this.hideArticleTooltip()
    }

    onOkDialog() {
        if (this.view.cfg.params.distanceUnit.startsWith('ft-')) {
            const controls = this.view.cfg.form.controls
            controls.distance1M.patchValue(feetToMetersRounded(controls.distance1M.value))
            controls.distance2M.patchValue(feetToMetersRounded(controls.distance2M.value))
        }
        this.visibility = false
        this.onOk.emit()
    }

    getFormValidationErrors(controls: FormGroupControls): AllValidationErrors[] {
        let errors: AllValidationErrors[] = [];
        Object.keys(controls).forEach(key => {
            const control = controls[key];
            if (control instanceof FormGroup) {
                errors = errors.concat(this.getFormValidationErrors(control.controls));
            }
            const controlErrors: ValidationErrors | null = controls[key].errors;
            if (controlErrors !== null) {
                Object.keys(controlErrors).forEach(keyError => {
                    errors.push({
                        control_name: key,
                        error_name: keyError,
                        error_value: controlErrors[keyError]
                    });
                });
            }
        });
        return errors;
    }

    onCancelDialog() {
        const errors = this.getFormValidationErrors(this.view.cfg.form.controls)
        if (errors.length > 0) {
            console.info('form errors', errors)
        }

        this.visibility = false
        this.onCancel.emit()
    }

    open() {
        this.visibility = true
    }

    closeOk() {
        this.onOkDialog()
    }

    closeCancel() {
        this.onCancelDialog()
    }

    formValueToClassNo(c: any): ClassNo {
        return c as ClassNo
    }

    isObstacleHeightDirty(c: ClassNo): boolean {
        const f = this.view.cfg.form.controls.obstacleHeights
        if (f instanceof FormGroup && f.controls[c]) {
            return f.controls[c].invalid
        }
        return false
    }
}
function meters2feet(length: number | undefined): number {
    throw new Error('Function not implemented.')
}
