import { DeviceInfo } from "angular-device-information"
import { DetailComponent } from "./detail.component"
import { KeyModifiers, UiCommandId } from "./detail.ui.commands.defs"
import { UiShortcut } from "./detail.ui.commands.defs"

export type ShortcutStore = {
    id: UiCommandId,
    key: string,
    modifiers: KeyModifiers
}

type Shortcut = UiShortcut & {
    readonly id: UiCommandId,
    descr: string,
    current?: string,
    currentModifiers?: KeyModifiers,
    label?: string,
    previous?: string,
    prevModifiers?: KeyModifiers,
}

export type ShortcutGroup = {
    label: string,
    shortcuts: Shortcut[]
}

type ShortcutMigration = {
    pairs: {
        from: ShortcutStore,
        to: ShortcutStore
    }[],
    oldVersion: number,
    newVersion: number
}

export class KeyShortcuts {
    mac: boolean
    idMap: { [id: string]: Shortcut } = {}
    names: { [id: string]: string }
    separator: string

    migrations: ShortcutMigration[] = [{
        oldVersion: 0,
        newVersion: 1,
        pairs: [{
            from: { id: UiCommandId.TOGGLE_LABELS_1, key: '3', modifiers: KeyModifiers.NONE },
            to: { id: UiCommandId.TOGGLE_LABELS_1, key: '1', modifiers: KeyModifiers.META }
        }, {
            from: { id: UiCommandId.TOGGLE_LABELS_2, key: '4', modifiers: KeyModifiers.NONE },
            to: { id: UiCommandId.TOGGLE_LABELS_2, key: '2', modifiers: KeyModifiers.META }
        }, {
            from: { id: 'toggle_distances' as UiCommandId, key: '5', modifiers: KeyModifiers.NONE },
            to: { id: UiCommandId.TOGGLE_DISTANCES_1, key: '1', modifiers: KeyModifiers.CONTROL }
        }]
    }]

    constructor(public view: DetailComponent, public shortcuts: ShortcutGroup[]) {
        const info: DeviceInfo = view.deviceInfo.getDeviceInfo()
        this.mac = (info.os === 'Mac OS X')
        this.separator = this.mac ? '' : '-'

        this.names = {
            ' ': $localize`spacja`,
            'arrowleft': '&larr;',
            'arrowright': '&rarr;',
            'arrowup': '&uarr;',
            'arrowdown': '&darr;'
        }
        if (this.mac) {
            this.names = {
                ...this.names,
                'backspace': '⌫',
                'delete': '⌦',
                'enter': '↵',
                'escape': '⎋',
                'alt': '⌥',
                'command': '⌘',
                'shift': '⇧',
                'control': '⌃'
            }
        } else {
            this.names = {
                ...this.names,
                'backspace': 'Backspace',
                'delete': 'Delete',
                'enter': 'Enter',
                'escape': 'Escape',
                'alt': 'Alt',
                'command': 'Win',
                'shift': 'Shift',
                'control': 'Ctrl'
            }
        }

        this.shortcuts.forEach(g => {
            g.shortcuts.forEach(v => {
                v.current = v.default
                v.currentModifiers = v.modifiers
                v.label = this.getKeyName(v)
                if (v.id) {
                    this.idMap[v.id] = v
                }
            })
        })
    }

    getShortcutLabelOrUndefined(id: UiCommandId): string | undefined {
        const s = this.idMap[id]
        if (s && s.label) {
            return s.label
        }
        return undefined
    }

    getShortcutLabel(id: UiCommandId): string {
        const s = this.getShortcutLabelOrUndefined(id)
        return s ? s : $localize`nieznany`
    }

    private _getNameOrUndefined(key: string): string | undefined {
        return this.names[key.toLowerCase()]
    }

    private _getName(key: string): string {
        const n = this._getNameOrUndefined(key)
        return n ? n : '?'
    }

    private _capitalizeFirstLetter(t: string): string {
        return t.charAt(0).toUpperCase() + t.slice(1)
    }

    getKeyName(s: Shortcut): string {
        let name = this._getModifier(s.currentModifiers, KeyModifiers.SHIFT) ? this._getName('shift') + this.separator : ''
        name += this._getModifier(s.currentModifiers, KeyModifiers.CONTROL) ? this._getName('control') + this.separator : ''
        name += this._getModifier(s.currentModifiers, KeyModifiers.META) ? this._getName('command') + this.separator : ''
        if (s.current) {
            name += this._getNameOrUndefined(s.current) || this._capitalizeFirstLetter(s.current)
        }
        return name
    }

    onKeyDown(event: KeyboardEvent) {
        this._onKey(false, event)
    }

    onKeyUp(event: KeyboardEvent) {
        this._onKey(true, event)
    }

    setKey(sh: Shortcut, event: KeyboardEvent) {
        event.stopPropagation()
        event.preventDefault()

        if (!event.key || sh.ro) {
            return
        }
        if (['Meta', 'Shift', 'Alt', 'Control'].includes(event.key)) {
            return
        }

        let modifiers = KeyModifiers.NONE
        modifiers |= event.ctrlKey ? KeyModifiers.CONTROL : 0
        modifiers |= event.metaKey ? KeyModifiers.META : 0
        modifiers |= event.shiftKey ? KeyModifiers.SHIFT : 0

        for (let g of this.shortcuts) {
            for (let s of g.shortcuts) {
                if (s.current?.toUpperCase() === event.key.toUpperCase() && (s.currentModifiers || KeyModifiers.NONE) === modifiers) {
                    if (s.ro) {
                        // attempt to take a value of read only shortcut
                        return
                    }
                    s.current = ''
                    s.currentModifiers = KeyModifiers.NONE
                    s.label = ''
                }
            }
        }

        sh.currentModifiers = modifiers
        sh.current = event.key
        sh.label = this.getKeyName(sh)
    }

    restoreDefault() {
        this.shortcuts.forEach(g => {
            g.shortcuts.forEach(s => {
                s.current = s.default
                s.currentModifiers = s.modifiers
                s.label = this.getKeyName(s)
            })
        })
    }

    save() {
        this.shortcuts.forEach(g => {
            g.shortcuts.forEach(s => {
                s.previous = s.current
                s.prevModifiers = s.currentModifiers
            })
        })
    }

    restore() {
        this.shortcuts.forEach(g => {
            g.shortcuts.forEach(s => {
                s.current = s.previous
                s.currentModifiers = s.prevModifiers
                s.label = this.getKeyName(s)
            })
        })
    }

    toJson(): ShortcutStore[] {
        this.view.buildMenuItems()
        return this.shortcuts.reduce((pg, cg) => pg.concat(cg.shortcuts), new Array<Shortcut>()).map(s => ({
            id: s.id,
            key: s.current || $localize`niezdefiniowany`,
            modifiers: s.currentModifiers || KeyModifiers.NONE
        } as ShortcutStore))
    }

    fromJson(json: ShortcutStore[], version?: number): boolean {
        // load default values before restoring them from JSON, so any new shortcuts that are
        // missing in the JSON but exist in the current code have correct values
        this.restoreDefault()
        let changed: boolean = false
        if (Array.isArray(json)) {
            changed = this.migrate(json, version)
            json.forEach(s => {
                if (s.id && s.key) {
                    const item = this.idMap[s.id]
                    if (item) {
                        item.current = s.key
                        item.currentModifiers = s.modifiers || KeyModifiers.NONE
                        item.label = this.getKeyName(item)
                    }
                }
            })
        }
        this.view.buildMenuItems()
        return changed
    }

    migrate(store: ShortcutStore[], oldVersion: number | undefined): boolean {
        const newVersion = this.view.userService.currentVersion
        let changed: boolean = false
        this.migrations.forEach(m => {
            if (oldVersion === undefined) {
                oldVersion = 0
            }
            if (oldVersion <= m.oldVersion && newVersion >= m.newVersion) {
                m.pairs.forEach(p => {
                    const elem = store.find(i => i.id === p.from.id)
                    if (elem) {
                        changed = true
                        elem.id = p.to.id
                        if (elem.key === p.from.key && elem.modifiers === p.from.modifiers) {
                            elem.key = p.to.key
                            elem.modifiers = p.to.modifiers
                        }
                    }
                })
            }
        })
        return changed
    }

    getMenuLabel(label: string, shortcut?: UiCommandId): string {
        let t = '<div>' + label + '</div>'
        if (shortcut) {
            let s = this.getShortcutLabelOrUndefined(shortcut)
            if (s) {
                t += '<div>&nbsp;&nbsp;&nbsp;&nbsp;</div><div class="shortcut">' + s + '</div>'
            }
        }
        return t
    }

    private _getModifier(value: KeyModifiers | undefined, modifier: KeyModifiers): boolean {
        return value && ((value & modifier) === modifier) || false
    }

    private _onKey(up: boolean, event: KeyboardEvent) {
        if (event.repeat) {
            return
        }
        const control = event.ctrlKey || false
        const meta = event.metaKey || false
        const shift = event.shiftKey || false
        this.shortcuts.forEach(g => {
            g.shortcuts.forEach(s => {
                const s_control = this._getModifier(s.currentModifiers, KeyModifiers.CONTROL)
                const s_meta = this._getModifier(s.currentModifiers, KeyModifiers.META)
                const s_shift = this._getModifier(s.currentModifiers, KeyModifiers.SHIFT)
                if ((control === s_control || event.key === 'Control') && meta === s_meta && shift === s_shift && s.current &&
                    event.key && event.key.toLowerCase() === s.current.toLowerCase()) {
                    if (!up && s.keyDownAction && (s.outOfFocusKeyDown || 
                        document.activeElement === document.body || this.view.toolbarElement?.nativeElement.contains(document.activeElement))) {
                        s.keyDownAction(this.view, event)
                        this.view.actionLog(s.id, 'shortcut')
                        event.preventDefault()
                    } else if (up && s.keyUpAction && (s.outOfFocusKeyUp || 
                        document.activeElement === document.body || this.view.toolbarElement?.nativeElement.contains(document.activeElement))) {
                        s.keyUpAction(this.view, event)
                        event.preventDefault()
                    }
                }
            })
        })
    }
}
