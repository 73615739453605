<ng-container *ngIf="$any(obj) as o">

    <!-- Midpoints panel -->
    <div class="grid grid-nogutter align-items-center pt-1 pb-2" *ngIf="o && o.midpointobject; else notmidpoint">
        <div class="col-12 text-lg py-3" i18n>Punkt na trasie</div>
        <div class="col-4 py-1" i18n>Pozycja</div>
        <div class="col-3 py-1">
            {{ (o.point.x - (view.canvas?.field?.x || 0)) / 100 | number:'1.0-0' }},
            {{ (o.point.y - (view.canvas?.field?.y || 0)) / 100 | number:'1.0-0' }}
        </div>
        <div class="col-5 py-1"></div>
        <div class="col-4 py-1" i18n>Moc wygięcia łuku</div>
        <div class="col-3 py-1">
            <p-inputNumber
                [(ngModel)]="o.handleFactor"
                [min]="0"
                [max]="5"
                [step]="0.1" 
                [showButtons]="true"
                inputStyleClass="object-detail-inputnumber-input"
                incrementButtonClass="object-detail-inputnumber-button"
                decrementButtonClass="object-detail-inputnumber-button"
                (ngModelChange)="view.updatePath();view.saveData();">
            </p-inputNumber>
        </div>
    </div>

    <ng-template #notmidpoint>
        <div class="grid grid-nogutter align-items-center pt-1 pb-2" *ngIf="o && o.groupobject; else notgroup">
            <ng-container *ngIf="o.combination.length > 0">
                <div class="col-12 text-lg py-3" i18n>Szereg</div>
                <div *ngFor="let t of combinationTypes" class="flex align-items-center p-1">
                    <p-radioButton name="combinationType" [value]="t.value" [inputId]="t.value" [(ngModel)]="o.combinationType" (ngModelChange)="view.updatePath();view.saveData()"/>
                    <label [for]="t.value" class="m-1">{{ t.label }}</label>
                </div>
            </ng-container>
        </div>    
    </ng-template>
    
    <ng-template #notgroup>
        <!-- Top line of the panel with object type and obstacle name -->
        <div class="grid grid-nogutter pt-1 pb-2 align-items-center" style="height: 70px">

            <!-- Object type button and object type change panel -->
            <div class="pr-3 col-5">
                <p-overlayPanel #opChangeType>
                    <ng-template pTemplate="content" *ngIf="!o.isTerminal()">
                        <div class="font-semibold mb-1" i18n>Zmień typ obiektu na:</div>
                        <div class="flex flex-wrap max-w-22rem">
                            <div *ngFor="let k of view.objectFactory.getObjectDefinitionsByCategory(view.activeTab.id!, o.kind)"
                                (click)="view.changeObjectKind(o, k); opChangeType.hide()"
                                class="flex align-items-center gap-2 w-10rem shadow-1 m-1 cursor-pointer">
                                <svg *ngIf="!k.url && !k.icon" width="40px" height="40px" viewBox="-25 -25 50 50"
                                    fill="none" xmlns="http://www.w3.org/2000/svg" [innerHTML]="k.svg"></svg>
                                <img *ngIf="k.url || k.icon" [src]="k.url || k.icon"
                                    style="width: 40px; height: 40px;"/>
                                <div>{{ k.name }}</div>
                            </div>
                        </div>
                    </ng-template>
                </p-overlayPanel>
                <div class="flex align-items-center justify-content-between shadow-1 p-1" [class.cursor-pointer]="!o.isTerminal()"
                    (click)="o.isTerminal() ? true : opChangeType.toggle($event)"
                    style="height: 50px">
                    <div class="flex align-items-center justify-content-center p-1 w-full">
                        <svg *ngIf="!o.kind.url && !o.kind.icon" width="40px" height="40px" viewBox="-25 -25 50 50"
                            fill="none" xmlns="http://www.w3.org/2000/svg" [innerHTML]="o.kind.svg"></svg>
                        <img class="pr-2" *ngIf="o.kind.url || o.kind.icon" [src]="o.kind.url || o.kind.icon"
                            style="width: 30px; height: 30px;"/>
                        <div style="text-align: center;">{{ o.getKindName() }}</div>
                    </div>
                    <i *ngIf="!o.isTerminal()" class="fa-solid p-1 fa-caret-down"></i>
                </div>
            </div>

            <!-- Obstacle name -->
            <div class="col-7 pr-3" *ngIf="o.nameText">
                <label for="obstacleName" class="block mb-1" i18n>Nazwa:</label>
                <input #nameInputArea class="w-full p-inputtext-sm" type="text" pInputText
                    [(ngModel)]="o.nameText.content" id="obstacleName"
                    (ngModelChange)="view.saveData()"/>
            </div>
        </div>

        <div class="grid grid-nogutter align-items-center">

            <!-- Obstacle/Terminal panel -->

            <ng-container *ngIf="o.isObstacle() || o.isFinish() || o.isFinishStart(); else addPosition">

                <div class="col-4 py-1" i18n>Pozycja</div>
                <div class="col-3 py-1">
                    {{ o.getPositionInField().x | length: view.cfg.params.distanceUnit : false : 0 : 0 : true }},
                    {{ o.getPositionInField().y | length: view.cfg.params.distanceUnit : false : 0 : 0 : true }}
                </div>

                <ng-container *ngIf="o.isObstacleWithBars(); else padPosition">
                    <div class="col-5 p-1 flex align-items-center">
                        <p-checkbox (onChange)="o.changeObjectDecorations('grass', $event.checked)"
                                    [(ngModel)]="o.decorations['grass']" [binary]="true" inputId="grass"></p-checkbox>
                        <label class="px-1" for="grass" i18n>Trawa</label>
                    </div>

                    <label class="col-4" for="obstacleHeight" i18n>Wysokość [cm]</label>
                    <div class="col-3 pb-1">
                        <p-inputNumber
                            [(ngModel)]="o.obstacleHeight"
                            [min]="o.heightLimits.min"
                            [max]="o.heightLimits.max"
                            [step]="5"
                            [showButtons]="true"
                            inputStyleClass="object-detail-inputnumber-input {{ o.obstacleHeight != o.defaultObstacleHeight ? 'font-bold' : ''}} {{ o.warnings.height ? 'text-red-600' : '' }}"
                            incrementButtonClass="object-detail-inputnumber-button"
                            decrementButtonClass="object-detail-inputnumber-button"
                            (ngModelChange)="o.fixSizeToLimits(true, false, false);view.updatePath();view.validateParkour(false);view.saveData()"
                            pTooltip="{{ o.heightLimits.min + ' - ' + o.heightLimits.max }}"
                            tooltipPosition="top"></p-inputNumber>
                    </div>
                    <div class="col-5 p-1 flex align-items-center">
                        <p-checkbox (onChange)="o.changeObjectDecorations('water', $event.checked)"
                                    [(ngModel)]="o.decorations['water']"
                                    [binary]="true" inputId="water"></p-checkbox>
                        <label class="px-2" for="water" i18n>Woda</label>
                    </div>

                    <label class="col-4" for="obstacleWidth" i18n>Szerokość [cm]</label>
                    <div class="col-3 pb-1">
                        <p-inputNumber
                            [(ngModel)]="o.obstacleWidth"
                            [min]="o.widthLimits.min"
                            [max]="o.widthLimits.max"
                            [step]="5"
                            [showButtons]="true"
                            inputStyleClass="object-detail-inputnumber-input {{ o.obstacleWidth != o.defaultObstacleWidth ? 'font-bold' : '' }} {{ o.warnings.width ? 'text-red-600' : '' }}"
                            incrementButtonClass="object-detail-inputnumber-button"
                            decrementButtonClass="object-detail-inputnumber-button"
                            (ngModelChange)="o.fixSizeToLimits(false, true, false);view.updatePath();view.validateParkour(false);view.saveData()"
                            pTooltip="{{ o.widthLimits.min + ' - ' + o.widthLimits.max }}"
                            tooltipPosition="top"></p-inputNumber>
                    </div>
                    <div class="col-5 p-1 flex align-items-center" >
                        <p-checkbox (onChange)="o.changeObjectDecorations('singlePond', $event.checked)"
                                    [(ngModel)]="o.decorations['singlePond']"
                                    [binary]="true" inputId="singlePond">
                        </p-checkbox>
                        <label class="px-2" for="singlePond" i18n>Oczko</label>
                        <p-triStateCheckbox (onChange)="o.changeObjectPondLocation()" [(ngModel)]="o.pondLocation"
                                            checkboxTrueIcon="fa-solid fa-chevron-left" checkboxFalseIcon="fa-solid fa-chevron-right"
                                            [disabled]="!o.decorations['singlePond']"
                                            inputId="singlePondLocation">
                        </p-triStateCheckbox>
                        <label class="px-2" for="singlePondLocation" i18n>Strona</label>
                    </div>

                    <label class="col-4" for="obstacleLength" i18n>Długość [cm]</label>
                    <div class="col-3">
                        <p-inputNumber
                            [(ngModel)]="o.obstacleLength"
                            [min]="o.lengthLimits.min"
                            [max]="o.lengthLimits.max"
                            [step]="50"
                            [showButtons]="true"
                            inputStyleClass="object-detail-inputnumber-input {{ o.obstacleLength != o.defaultObstacleLength ? 'font-bold' : '' }} {{ (o.obstacleLength < o.lengthLimits.min || o.obstacleLength > o.lengthLimits.max) ? 'text-red-600' : '' }}"
                            incrementButtonClass="object-detail-inputnumber-button"
                            decrementButtonClass="object-detail-inputnumber-button"
                            (ngModelChange)="o.fixSizeToLimits(false, false, true);view.updatePath();view.validateParkour(false);view.saveData()"
                            pTooltip="{{ o.lengthLimits.min + ' - ' + o.lengthLimits.max }}"
                            tooltipPosition="top"></p-inputNumber>
                    </div>
                    <div class="col-5 p-1 flex align-items-center" >
                        <p-checkbox (onChange)="o.changeObjectDecorations('doublePond', $event.checked)"
                                    [(ngModel)]="o.decorations['doublePond']" [binary]="true" inputId="doublePond"></p-checkbox>
                        <label class="px-2" for="doublePond" i18n>Podwójne oczko</label>
                    </div>

                </ng-container>
                <ng-template #padPosition>
                    <div class="col-6 p-1"></div>
                </ng-template>

                <!-- Distances table in obstacle details -->

                <div class="grid grid-nogutter align-items-center pt-3 pb-1"
                    *ngIf="[getPathNode(o, 1, 1)[0], getPathNode(o, 2, 1)[0], getPathNode(o, 1, 2)[0], getPathNode(o, 2, 2)[0]] as node">
                    <div class="p-1 col-4"></div>
                    <ng-container *ngFor="let i of [0, 1, 2, 3]">
                        <div class="p-1 col-2 text-700 border-300 border-top-1 border-left-1 flex align-items-center justify-content-center text-xs"
                           [pTooltip]="tableTooltips[i]" i18n-pTooltip tooltipPosition="top"
                           [class.border-right-1]="i === 3">
                            <i [class]="'mr-1 fa-solid fa-' + ((i + 1) / 2).toFixed(0)"></i>
                            <i [class]="'fa-solid fa-' + (i % 2 ? 'backward' : 'forward')"></i>
                        </div>
                    </ng-container>
                    <div class="p-1 col-4 text-700 border-300 border-top-1 border-left-1" i18n>Od poprzedniej</div>
                    <ng-container *ngFor="let i of [0, 1, 2, 3]">
                        <div class="p-1 col-2 border-300 border-top-1 border-left-1"
                            [class.border-right-1]="i === 3">
                            {{ (node[i] && !view.cfg.isNoRouteMode() ? (node[i]!.distFromPrev | length: view.cfg.params.distanceUnit : false : 0) : '-')  }}
                        </div>
                    </ng-container>
                    <div class="p-1 col-4 text-700 border-300 border-top-1 border-left-1" 
                        [class.border-bottom-1]="!o.isObstacle()" i18n>Od startu</div>
                    <ng-container *ngFor="let i of [0, 1, 2, 3]">
                        <div class="p-1 col-2 border-300 border-top-1 border-left-1" 
                            [class.border-right-1]="i === 3" [class.border-bottom-1]="!o.isObstacle()">
                            {{ (node[i] && !view.cfg.isNoRouteMode() ? (node[i]!.distFromStart | length: view.cfg.params.distanceUnit : false : 0) : '-')  }}
                        </div>
                    </ng-container>
                    <ng-container *ngIf="o.isObstacle()">
                        <div class="p-1 col-4 text-700 border-300 border-top-1 border-left-1 border-bottom-1" i18n>Numery</div>
                        <ng-container *ngFor="let i of [0, 1, 2, 3]">
                            <div class="p-1 col-2 border-300 border-top-1 border-left-1 border-bottom-1 flex justify-content-between align-items-center"
                                [class.border-right-1]="i === 3">
                                <input #label type="text" pInputText maxlength="5" [placeholder]="o.noRouteLabel ? (i === 0 ? o.noRouteLabel : '-') :  node[i]?.label || '-'"
                                    class="p-0 m-0 w-full text-sm border-none"
                                    [class.font-bold]="o.manualLabels[i]" 
                                    [class.px-1]="labelFocus[i]" [(ngModel)]="o.manualLabels[i]"
                                    (focus)="onLabelFocus(i, o)" (blur)="onLabelBlur(i)"
                                    (change)="onLabelCommit()" (keyup)="onLabelChange()"
                                    [disabled]="i > 0 && view.cfg.isNoRouteMode()"
                                    (keydown.enter)="onLabelEnter(label)" (keydown.escape)="onLabelEscape(label, i, o)"
                                />
                                <i *ngIf="!labelFocus[i]" class="fa-solid fa-pencil text-600" (click)="label.focus()"></i>
                            </div>        
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>

            <ng-template #addPosition>
                <!-- Position -->
                <ng-container *ngIf="o.kind.kind !== 'text-box' && o.kind.kind !== 'user-image' &&
                                    o.kind.kind !== 'drawing' && o.kind.kind !== 'table-banner'">
                    <div class="col-4 py-1" i18n>Pozycja</div>
                    <div class="col-8 py-1">
                        {{ o.getPositionInField().x | length: view.cfg.params.distanceUnit : false : 0 : 0 : true }},
                        {{ o.getPositionInField().y | length: view.cfg.params.distanceUnit : false : 0 : 0 : true }}
                    </div>
                </ng-container>
            </ng-template>

            <!-- Optional buttons with arrows to set obstacle direction -->

            <ng-container *ngIf="view.cfg.canUserEditArrows() && (o.isObstacle() || o.isFinish() || o.isStart())">
                <div id="arrow-buttons" class="col-8 flex pt-2 pb-1 px-0">
                    <p-selectButton
                        styleClass="w-full" class="w-full"
                        [options]="userArrowDirectionOptions"
                        [(ngModel)]="o.userArrowDirection"
                        (onChange)="view.updatePath();view.saveData()"
                        optionLabel="name"
                        optionValue="value" [unselectable]="true"
                        styleClass="p-button-outlined w-full p-button-sm h-3rem"
                        appendTo="body">
                        <ng-template let-item pTemplate>
                            <img class="h-full" [src]="item.icon"/>
                        </ng-template>
                    </p-selectButton>
                </div>
            </ng-container>

            <!-- Joker option -->

            <div *ngIf="o.isObstacle() && view.cfg.isJokerAllowed()" class="col-3 flex pt-2 pb-1 px-0">
                <div class="flex align-items-center" [pTooltip]="o.jokerTooltip" tooltipPosition="right">
                    <p-checkbox (onChange)="view.updatePath();view.saveData()"
                                [(ngModel)]="o.joker" [disabled]="o.jokerDisabled"
                                [binary]="true" inputId="joker"></p-checkbox>
                    <label class="px-2" for="joker">Joker{{ o.jokerNumber ? ' ' + o.jokerNumber.toFixed(0) : '' }}</label>
                </div>
            </div>

            <!-- Points for top score competition -->

            <ng-container *ngIf="o.isObstacle() && view.cfg.isScoreAllowed()" >
                <label class="col-4 pt-2 pb-1" for="score" i18n>Punkty bonifikacyjne</label>
                <div class="col-3 pt-2 pb-1">
                    <p-inputNumber id="score"
                                [(ngModel)]="o.score"
                                [min]="0" [max]="200" [step]="10"
                                [showButtons]="true"
                                inputStyleClass="object-detail-inputnumber-input"
                                incrementButtonClass="object-detail-inputnumber-button"
                                decrementButtonClass="object-detail-inputnumber-button"
                                (ngModelChange)="o.fixPointsToLimits();view.updatePath();view.validateParkour(false);view.saveData()"
                                pTooltip="10 - 120, 0=brak, 200=Joker" tooltipPosition="top" i18n-pTooltip>
                    </p-inputNumber>
                </div>
            </ng-container>

            <!-- Layer operations -->

            <ng-container *ngIf="o.configurableLayer">
                <div class="col-4 py-1" i18n>Plan</div>
                <div class="col-8 py-1">
                    <p-selectButton
                        styleClass="p-button-sm w-full"
                        [options]="layerOptions" [(ngModel)]="o.layer"
                        (onChange)="view.saveData()" [unselectable]="true"
                        optionLabel="name" optionValue="value"></p-selectButton>
                </div>
                <div class="col-4 py-1" i18n>Warstwa</div>
                <div class="col-8 py-1">
                    <div class="p-buttonset w-12rem">
                        <button pButton icon="pi pi-angle-up" class="p-button-outlined p-button-sm w-3rem"
                                pTooltip="Przesuń do góry" i18n-pTooltip
                                (click)="o.moveUpInLayer();view.saveData()"></button>
                        <button pButton icon="pi pi-angle-down" class="p-button-outlined p-button-sm w-3rem"
                                pTooltip="Przesuń do dołu" i18n-pTooltip
                                (click)="o.moveDownInLayer();view.saveData()"></button>
                        <button pButton icon="pi pi-angle-double-up" class="p-button-outlined p-button-sm w-3rem"
                                pTooltip="Przesuń na wierzch" i18n-pTooltip
                                (click)="o.bringToFront();view.saveData()"></button>
                        <button pButton icon="pi pi-angle-double-down" class="p-button-outlined p-button-sm w-3rem"
                                pTooltip="Przesuń na spód" i18n-pTooltip
                                (click)="o.sendToBack();view.saveData()"></button>
                    </div>
                </div>
            </ng-container>

            <!-- TextBox panel -->

            <ng-container *ngIf="o.kind.kind === 'text-box'">
                <div class="col-4 py-1" i18n>Tekst</div>
                <div class="col-8 py-1 flex align-items-center">
                    <textarea #textBoxInputArea pInputTextarea [(ngModel)]="o.content"
                            class="w-full text-sm"
                            (ngModelChange)="o.updateContent()"
                            (change)="view.saveData()">
                    </textarea>
                </div>
                <div class="col-4 py-1" i18n>Font</div>
                <div class="col-8 py-1">
                    <p-dropdown id="fontPicker" [options]="fontFamilies"
                                [(ngModel)]="o.fontFamily"
                                optionLabel="name"
                                optionValue="value"
                                (onChange)="o.updateContent(); view.saveData()"
                                styleClass="w-12rem"
                                panelStyleClass="w-12rem"
                                appendTo="body">
                            <ng-template let-o pTemplate="selectedItem">
                            <span *ngIf="o" [ngStyle]="{ 'font-family': o.value }">{{ o.name }}</span>
                        </ng-template>
                        <ng-template let-o pTemplate="item">
                            <span *ngIf="o" [ngStyle]="{ 'font-family': o.value }">{{ o.name }}</span>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div class="col-4 py-1" i18n>Wyrównanie</div>
                <div class="col-8 py-1">
                    <p-selectButton [options]="justificationOptions" [(ngModel)]="o.justification" [unselectable]="true"
                                    optionLabel="icon" optionValue="value" (ngModelChange)="o.updateContent(); view.saveData()">
                        <ng-template let-item pTemplate>
                            <i class="w-full" [class]="item.icon"></i>
                        </ng-template>
                    </p-selectButton>
                </div>
                <div class="col-4 py-1" i18n>Kolor fontu</div>
                <div class="col-1 py-1">
                </div>
                <div class="col-7 py-1">
                    <p-colorPicker [(ngModel)]="o.strokeColor" appendTo="body" (onShow)="view.disableSelect()" (onHide)="view.enableSelect()" (ngModelChange)="o.updateContent(); view.saveData()"></p-colorPicker>
                </div>
                <div class="col-4 py-1" i18n>Ramka</div>
                <div class="col-1 py-1">
                    <p-checkbox [(ngModel)]="o.border" [binary]="true" inputId="border"
                                (ngModelChange)="o.updateContent(); view.saveData()"></p-checkbox>
                </div>
                <div class="col-7 py-1">
                    <p-colorPicker [disabled]="!o.border" [(ngModel)]="o.borderColor" appendTo="body" (onShow)="view.disableSelect()" (onHide)="view.enableSelect()"
                                (ngModelChange)="o.updateContent(); view.saveData()"></p-colorPicker>
                </div>
                <div class="col-4 py-2" i18n>Wypełnienie</div>
                <div class="col-1 py-1">
                    <p-checkbox [(ngModel)]="o.fillEnable" [binary]="true" inputId="fillEnable"
                                (ngModelChange)="o.updateContent(); view.saveData()"></p-checkbox>
                </div>
                <div class="col-7 py-1">
                    <p-colorPicker [disabled]="!o.fillEnable" [(ngModel)]="o.fillColor" appendTo="body" (onShow)="view.disableSelect()" (onHide)="view.enableSelect()"
                                (ngModelChange)="o.updateContent(); view.saveData()"></p-colorPicker>
                </div>
            </ng-container>

            <!-- Drawing panel -->

            <ng-container *ngIf="o.kind.kind === 'drawing'">
                <div class="col-4 py-1" i18n>Grubość linii</div>
                <div class="col-8 py-1">
                    <p-inputNumber
                        inputStyleClass="w-6rem"
                        [showButtons]="true"
                        [min]="1" [max]="100" [step]="1"
                        [(ngModel)]="o.path.strokeWidth"
                        (ngModelChange)="o.updateContent(); view.saveData()"
                        inputStyleClass="object-detail-inputnumber-input"
                        incrementButtonClass="object-detail-inputnumber-button"
                        decrementButtonClass="object-detail-inputnumber-button"
                    />
                </div>
                <div class="col-4 py-2" i18n>Zablokuj edycję</div>
                <div class="col-8 py-2">
                    <p-checkbox [(ngModel)]="o.locked" [binary]="true" inputId="drawingLocked"
                                (ngModelChange)="o.doLockUnlock()"></p-checkbox>
                </div>
                <div class="col-4 py-1" i18n>Wypełnienie</div>
                <div class="col-1 py-1">
                    <p-checkbox [(ngModel)]="o.fillEnable" [binary]="true" inputId="fillEnable"
                                (ngModelChange)="o.updateContent(); view.saveData()"></p-checkbox>
                </div>
                <div class="col-7 py-1">
                    <p-colorPicker [disabled]="!o.fillEnable" [(ngModel)]="o.fillColor" appendTo="body" (onShow)="view.disableSelect()" (onHide)="view.enableSelect()"
                                (ngModelChange)="o.updateContent(); view.saveData()"></p-colorPicker>
                </div>
                <div class="col-4 py-1" i18n>Kolor linii</div>
                <div class="col-1 py-1">
                </div>
                <div class="col-7 py-1">
                    <p-colorPicker [(ngModel)]="o.strokeColor" appendTo="body" (onShow)="view.disableSelect()" (onHide)="view.enableSelect()" (ngModelChange)="o.updateContent(); view.saveData()"></p-colorPicker>
                </div>
            </ng-container>

            <!-- Banner panel -->

            <ng-container *ngIf="o.kind.kind === 'table-banner'">
                <div class="col-4 py-1" i18n>Widoczna</div>
                <div class="col-8 py-1">
                    <p-checkbox [(ngModel)]="o.visible" [binary]="true"></p-checkbox>
                </div>
                <div class="col-4 py-1" i18n>Wydarzenie</div>
                <div class="col-8 py-1">
                    <p-checkbox [(ngModel)]="o.visibility.title" [binary]="true"
                                [disabled]="!o.isVisible(false, undefined, undefined) || o.orientation !== 1"
                                (ngModelChange)="o.build(); view.saveData()"></p-checkbox>
                </div>
                <div class="col-4 py-1" i18n>Klasa, konkurs, opis, data</div>
                <div class="col-8 py-1">
                    <p-checkbox [(ngModel)]="o.visibility.subtitle" [binary]="true"
                                [disabled]="!o.isVisible(undefined, false, undefined) || o.orientation !== 1"
                                (ngModelChange)="o.build(); view.saveData()"></p-checkbox>
                </div>
                <div class="col-4 py-1" i18n>Dane szczegółowe</div>
                <div class="col-2 py-1">
                    <p-checkbox [(ngModel)]="o.visibility.table" [binary]="true"
                                [disabled]="!o.isVisible(undefined, undefined, false) || o.orientation !== 1"
                                (ngModelChange)="o.build(); view.saveData()"></p-checkbox>
                </div>
                <div class="col-4 py-1" i18n>Szeregi</div>
                <div class="col-2 py-1">
                    <p-checkbox [(ngModel)]="o.visibility.combinations" [binary]="true"
                                [disabled]="!o.isVisible(undefined, undefined, false) || o.orientation !== 1"
                                (ngModelChange)="o.build(); view.saveData()"></p-checkbox>
                </div>
                <div class="col-4 py-1" i18n>Orientacja danych szczegółowych</div>
                <div class="col-8 py-1">
                    <p-selectButton
                        styleClass="w-full" [(ngModel)]="o.orientation"
                        [options]="orientationOptions" [unselectable]="true"
                        [disabled]="!o.visibility.table"
                        (ngModelChange)="o.build(); view.saveData()"
                        optionLabel="name" optionValue="value">
                    </p-selectButton>
                </div>
            </ng-container>

            <!-- UserImage panel -->

            <ng-container *ngIf="o.kind.kind === 'user-image'">
                <div class="col-4 py-1" i18n>Ramka</div>
                <div class="col-8 py-1">
                    <p-checkbox [(ngModel)]="o.border" [binary]="true" inputId="border"
                                (ngModelChange)="o.drawExtraElements(); view.saveData()"></p-checkbox>
                </div>
                <div class="col-4 py-1" i18n>Zablokuj pozycję</div>
                <div class="col-8 py-1">
                    <p-checkbox [(ngModel)]="o.locked" [binary]="true" inputId="locked"
                                (ngModelChange)="o.doLockUnlock(); view.saveData()"></p-checkbox>
                </div>
            </ng-container>

            <div class="pt-2 col-12"></div>

        </div>
    </ng-template>

    <div class="grid grid-nogutter align-items-center">
        <!-- Buttons in the object details panel -->
        <ng-container *ngFor="let action of o.contextMenu?.items">
            <div *ngIf="!action.getDisabled({ view: view, items: view.selection.selectedItems, flatItems: view.selection.selectedFlatItems })" class="col-4 flex p-1">
                <p-splitButton *ngIf="action.options && action.options.length > 0; else singleActionButton"
                    class="w-full" outlined size="small"
                    [label]="action.rawLabel" [model]="action.options"
                    styleClass="w-full h-3rem"
                    (click)="view.executeObjectAction(action, $event)"
                    (onDropdownClick)="view.setSplitMenuObject(o)" appendTo="body">
                </p-splitButton>
                <ng-template #singleActionButton>
                    <p-button class="w-full"
                        [label]="action.rawLabel"
                        styleClass="p-button-outlined w-full p-button-sm h-3rem"
                        (click)="view.executeObjectAction(action, $event)"
                        appendTo="body">
                    </p-button>
                </ng-template>
            </div>

        </ng-container>
    </div>
</ng-container>
