import { MenuItem } from 'primeng/api'
import { UiCommandId } from "./detail.ui.commands.defs"
import { UiToggleMenuItem } from './detail.ui.commands.defs'
import { KeyShortcuts } from './detail.ui.shortcuts'

export class ToggleMenuItem implements MenuItem, UiToggleMenuItem {
    onOffState: boolean
    labelOn: string
    labelOff: string
    id: UiCommandId | undefined
    _tooltip: string | undefined
    icon: string
    command: (event?: any) => void
    escape: boolean

    get tooltip(): string | undefined {
        if (this._tooltip && this.id) {
            return this._tooltip + '\n\n' + $localize`Skrót` + ': ' + this.shortcuts.getShortcutLabelOrUndefined(this.id)
        }
        return this._tooltip
    }

    get label(): string {
        const l = this.onOffState ? this.labelOn : this.labelOff
        return this.shortcuts.getMenuLabel(l, this.id)
    }

    constructor(private shortcuts: KeyShortcuts, params: UiToggleMenuItem) {
        this.id = params.id as UiCommandId
        this.onOffState = params.onOffState
        this.labelOn = params.labelOn
        this.labelOff = params.labelOff
        this._tooltip = params.tooltip
        this.icon = params.icon || ''
        this.command = params.command || (() => {})
        this.escape = params.escape || false
    }

    toggle() {
        this.onOffState = !this.onOffState
    }
}
