import { Component } from '@angular/core';

import { DesignsListComponent } from '../designs-list/designs-list.component'


@Component({
    selector: 'app-public-designs-page',
    standalone: true,
    imports: [
        DesignsListComponent,
    ],
    templateUrl: './public-designs-page.component.html',
    styleUrl: './public-designs-page.component.scss'
})
export class PublicDesignsPageComponent {

}
