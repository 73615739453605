import { Article, CompetitionLocation, LimitRange } from "../design.schema"
import { CompetitionTable } from "../services/limits.service"
import { ClassNo } from "../services/limits.service"
import { AllowedRounds } from "../services/limits.service"
import { formatLimits } from "../utils"
import { DetailComponent } from "./detail.component"
import { ParkourObject } from "./parkour-objects/parkour-object"
import { Obstacle } from "./parkour-objects/obstacle"
import { ObstacleWithBars } from "./parkour-objects/obstacle-with-bars"
import { PathObject } from "./parkour-objects/path-object"
import { Terminal } from "./parkour-objects/terminal"
import { SecondRoundType } from "./detail.path"
import { ObstaclePathNode } from './detail.path.node'


enum Severity {
    ERROR = 0,
    WARNING = 1
}

export type Warning = {
    id?: number,
    text: string,
    severity: Severity,
    objTxt?: string,
    objects?: ParkourObject[],
    article?: string
}

export class ParkourValidator {
    validationWarnings: Warning[] = []
    validationWarningsCount: number[] = [0, 0]

    constructor (public view: DetailComponent) {
    }

    validate() {
        if (!this.view.canvas) {
            return
        }
        let id = 1
        const warnings: Warning[] = []
        const path = this.view.canvas.obstaclePath
        const firstRound = path.firstRound
        const secondRound = path.secondRound
        
        let bothPhasesCombinations: ObstaclePathNode[][] = firstRound?.combinations.slice() || []
        if (path.secondRoundType === SecondRoundType.TWO_PHASE && secondRound) {
            bothPhasesCombinations.push(...secondRound.combinations)
        }

        if (path.route.length === 0 || !firstRound) {
            return
        }
        const rounds = [ firstRound ]
        if (secondRound) {
            rounds.push(secondRound)
        }
        const allObstacles = (firstRound?.obstacles || 0) + (secondRound?.obstacles || 0)
        let efforts2ndRoundOpposite = 0, efforts2ndRoundSame = 0
        
        const addW = (w: Warning) => {
            warnings.push({
                ...w, id: id++,
                article: w.article?.replace('Art.', '')
            })
        }

        const addWO = (w: Warning) => {
            const ww = {
                ...w, id: id++,
                article: w.article?.replace('Art.', '')
            }
            warnings.push(ww)
            ww.objects?.forEach(obj => {
                if (obj instanceof Obstacle) {
                    obj.warnings.warnings.push(ww)
                }
            })
        }

        for (let o of this.view.canvas.obstacles) {
            if (o instanceof Obstacle) {
                o.resetWarnings()
            }
        }

        const parkourIsComplete =  path.secondRoundType === SecondRoundType.NONE ? firstRound?.complete : firstRound?.complete && 
            (secondRound ? secondRound.complete : true)
        const unusedObstacles = this.view.canvas.obstacles.length
        const limits = this.view.cfg.currentLimits
        let noCombinations: boolean = limits.noCombinations || false
        let twoPhaseAllowed = true
        let noRoute: boolean = limits.noRoute || false
        let ignoreDistance = false
        let ignoreObstacles = false
        let ignore60mRule = false
        let noFinishLine = limits.noFinishLine || false
        let ignoreSequenceCheck = false
        let ignoreFirstCombination = false
        let ignoreStraightLineCheck = false
        let ignoreTripleBarreFirst = false
        let ignoreClassCombinationsCheck = false
        let secondRoundIsOk = false

        const table = this.view.cfg.params.table
        const art = this.view.cfg.baseFeiRules
        const p = this.view.cfg.params
        const classNames = this.view.limitsService.classesToName(p.classes)

        if (!noRoute) {
            if (path.route.length > 1) {
                this.view.attentionForStartSet(false)
            }
            if (path.secondRoundType === SecondRoundType.NONE) {
                if (limits.allowedRounds === AllowedRounds.MANDATORY_JUMP_OFF) {
                    addW({
                        severity: Severity.WARNING, article: art,
                        text: $localize`Wybrano ${art} w którym projekt powinien mieć rozgrywkę, podczas gdy ma tylko przebieg podstawowy`
                    })
                } else if (limits.allowedRounds === AllowedRounds.MANDATORY_TWO_PHASES) {
                    addW({
                        severity: Severity.WARNING, article: art,
                        text: $localize`Wybrano ${art} w którym projekt powinien mieć dwie fazy, podczas gdy ma tylko przebieg podstawowy`
                    })
                }
            } else if (path.secondRoundType === SecondRoundType.TWO_PHASE) {
                if (limits.allowedRounds === AllowedRounds.OPTIONAL_JUMP_OFF) {
                    addW({
                        severity: Severity.WARNING, article: art,
                        text: $localize`Wybrano ${art} w którym projekt powinien mieć przebieg podstawowy z rozgrywką lub bez, podczas gdy ma dwie fazy`
                    })
                } else if (limits.allowedRounds === AllowedRounds.MANDATORY_JUMP_OFF) {
                    addW({
                        severity: Severity.WARNING, article: art,
                        text: $localize`Wybrano ${art} w którym projekt powinien mieć rozgrywkę, podczas gdy ma dwie fazy`
                    })
                } else if (limits.allowedRounds === AllowedRounds.ONE_ROUND_ONLY) {
                    addW({
                        severity: Severity.WARNING, article: art,
                        text: $localize`Wybrano ${art} w którym projekt powinien mieć tylko przebieg podstawowy, podczas gdy ma dwie fazy`
                    })
                } else {
                    secondRoundIsOk = true
                }
            } else if (path.secondRoundType === SecondRoundType.JUMP_OFF) {
                if (limits.allowedRounds === AllowedRounds.OPTIONAL_TWO_PHASES) {
                    addW({
                        severity: Severity.WARNING, article: art,
                        text: $localize`Wybrano ${art} w którym projekt powinien dwie fazy lub tylko przebieg podstawowy, podczas gdy ma rozgrywkę`
                    })
                } else if (limits.allowedRounds === AllowedRounds.MANDATORY_TWO_PHASES) {
                    addW({
                        severity: Severity.WARNING, article: art,
                        text: $localize`Wybrano ${art} w którym projekt powinien mieć dwie fazy, podczas gdy ma rozgrywkę`
                    })
                } else if (limits.allowedRounds === AllowedRounds.ONE_ROUND_ONLY) {
                    addW({
                        severity: Severity.WARNING, article: art,
                        text: $localize`Wybrano ${art} w którym projekt powinien mieć tylko przebieg podstawowy, podczas gdy ma rozgrywkę`
                    })
                } else {
                    secondRoundIsOk = true
                }
            }
            for (let r of rounds) {
                for (let i = 0; i < r.route.length - 1; i++) {
                    const n = path.route[i]
                    if (n.warnAboutStrides) {
                        let objs
                        if (path.route[i + 1].obstacle.isFinishStart() && i < path.route.length - 2) {
                            objs = [n, path.route[i + 1], path.route[i + 2]]
                            i++
                        } else {
                            objs = [n, path.route[i + 1]]
                        }
                        const [labels, objects] = this._addWarningObjects(objs)
                        addW({
                            severity: Severity.WARNING,
                            text: $localize`Odległość między przeszkodami jest nieodpowiednia dla skonfigurowanej długości fouli`,
                            objTxt: labels, objects: objects
                        })    
                    }
                }
            }
        }

        switch (art) {
            case Article.ART_238_GENERAL_TABLE_A:
                break
            case Article.ART_238_1_NOT_AGAINST_THE_CLOCK:
                break
            case Article.ART_238_1_1_NOT_AGAINST_THE_CLOCK:
                break
            case Article.ART_238_1_2_NOT_AGAINST_THE_CLOCK:
                break
            case Article.ART_238_1_3_NOT_AGAINST_THE_CLOCK:
                break
            case Article.ART_238_2_AGAINST_THE_CLOCK:
                break
            case Article.ART_238_2_1_AGAINST_THE_CLOCK:
                break
            case Article.ART_238_2_2_AGAINST_THE_CLOCK:
                break
            case Article.ART_238_2_3_AGAINST_THE_CLOCK:
                break
            case Article.ART_239_GENERAL_TABLE_C:
                break
            case Article.ART_261_NORMAL_AND_GP:
                break
            case Article.ART_262_POWER_AND_SKILL:
                break
            case Article.ART_262_2_PUISSANCE:
                ignoreSequenceCheck = true
                if (firstRound?.complete) {
                    if (firstRound.obstacles < 4 || firstRound.obstacles > 6) {
                        addW({
                            severity: Severity.WARNING, article: '262.2.1',
                            text: $localize`Wybrano ${art} dla którego przebieg podstawywy musi składać się z 4 do 6 pojedynczych przeszkód`,
                        })    
                    }
                    let verticals = 0, ditches: ObstaclePathNode[] = [], lowVerticalWalls: ObstaclePathNode[] = []
                    firstRound?.route.forEach((n, i) => {
                        if (n.obstacle instanceof ObstacleWithBars) {
                            if (n.obstacle.bars === 1) {
                                verticals++
                                if (i > 1 && n.obstacle.obstacleHeight < 150) {
                                    lowVerticalWalls.push(n)
                                }
                            } else if (n.obstacle.isDitch()) {
                                ditches.push(n)
                            } else if (n.obstacle.isWall() && n.obstacle.obstacleHeight < 150) {
                                lowVerticalWalls.push(n)
                            }
                        }
                    })
                    if (verticals === 0) {
                        addW({
                            severity: Severity.WARNING, article: '262.2.1',
                            text: $localize`Wybrano ${art} dla którego musi być przynajmniej jedna stacjonata`,
                        })    
                    }
                    if (lowVerticalWalls.length > 0) {
                        const [labels, objects] = this._addWarningObjects(lowVerticalWalls)
                        addW({
                            severity: Severity.WARNING, article: '262.2.1',
                            text: $localize`Wybrano ${art} dla którego stacjonaty i mury muszą mieć przynajmniej 150 cm wysokości`,
                            objTxt: labels, objects: objects
                        })    
                    }
                    if (ditches.length > 0) {
                        const [labels, objects] = this._addWarningObjects(ditches)
                        addW({
                            severity: Severity.WARNING, article: '262.2.1',
                            text: $localize`Wybrano ${art} dla którego rowy są zabronione`,
                            objTxt: labels, objects: objects
                        })    
                    }
                    if (firstRound.route.length > 2 && firstRound.route[1].obstacle instanceof ObstacleWithBars &&
                        firstRound.route[1].obstacle.obstacleHeight !== 130) {
                        const [labels, objects] = this._addWarningObjects(firstRound.route, 1, 1)
                        addW({
                            severity: Severity.WARNING, article: '262.2.1',
                            text: $localize`Wybrano ${art} dla którego pierwsza przeszkoda musi miec 130 cm wysokości`,
                            objTxt: labels, objects: objects
                        })    
                    }
                    const obst: ObstaclePathNode[] = []
                    for (let i = 2; i < Math.min(4, firstRound.route.length); i++) {
                        const o = firstRound.route[i].obstacle
                        if (o instanceof ObstacleWithBars && o.bars !== 1 && o.obstacleHeight < 130) {
                            obst.push(firstRound.route[i])
                        }
                    }
                    if (obst.length > 0) {
                        const [labels, objects] = this._addWarningObjects(obst)
                        addW({
                            severity: Severity.WARNING, article: '262.2.1',
                            text: $localize`Wybrano ${art} dla którego druga i trzecia przeszkoda muszą mieć przynajmniej 130 cm wysokości`,
                            objTxt: labels, objects: objects
                        })    
                    }
                }
                break
            case Article.ART_262_3_SIX_BAR:
                ignoreSequenceCheck = true
                ignoreFirstCombination = true
                ignoreStraightLineCheck = true
                ignoreTripleBarreFirst = true
                if (firstRound?.complete) {
                    let numVerticals = 0, numAligned = 0
                    const maxIdx = Math.min(6, firstRound.route.length - 2)
                    const hd: number[] = []
                    for (let i = 1; i <= maxIdx; i++) {
                        const n = firstRound.route[i]
                        const o = n.obstacle
                        if (o instanceof ObstacleWithBars) {
                            if (o.bars === 1) {
                                numVerticals++
                            }
                            if (i < maxIdx) { 
                                if (n.isInLineAndAlignedAngle(firstRound.route[i + 1])) {
                                    numAligned++
                                }
                            }
                            if (i > 1) {
                                const prevO = firstRound.route[i - 1].obstacle
                                if (prevO instanceof ObstacleWithBars) {
                                    hd.push(o.obstacleHeight - prevO.obstacleHeight)
                                }
                            }
                        }
                    }
                    if (firstRound.efforts > 6 || firstRound.efforts !== numVerticals) {
                        addW({
                            severity: Severity.WARNING, article: '262.3.1',
                            text: $localize`Wybrano ${art} dla którego musi być ustawionych sześć lub mniej przeszkód pionowych`,
                        })    
                    } else {
                        if (numAligned < maxIdx - 1) {
                            addW({
                                severity: Severity.WARNING, article: '262.3.1',
                                text: $localize`Wybrano ${art} dla którego przeszkody muszą być ustawione w jednej linii`,
                            })
                        }

                        if (hd.length === maxIdx - 1) {
                            // check that height deltas are equal or zero
                            let delta = 0, unequalDeltaIdx = -1
                            for (let i = 0; i < maxIdx - 1; i++) {
                                if (hd[i] === delta || delta === 0 || hd[i] === 0) {
                                    if (hd[i] !== 0) {
                                        delta = hd[i]
                                    }
                                } else {
                                    unequalDeltaIdx = i
                                    break
                                }
                            }
                            if (unequalDeltaIdx >= 0) {
                                const idx = unequalDeltaIdx + 2
                                const [labels, objects] = this._addWarningObjects(firstRound.route, idx,  idx)
                                const hlist: string[] = firstRound.route.slice(1, idx).map(n => (n.obstacle as ObstacleWithBars).obstacleHeight.toFixed(0))
                                hlist.push("<b class='text-red-500'>" + (firstRound.route[idx].obstacle as ObstacleWithBars).obstacleHeight + '</b>')
                                hlist.push(...firstRound.route.slice(idx + 1, maxIdx + 1).map(n => (n.obstacle as ObstacleWithBars).obstacleHeight.toFixed(0)))
                                const heights = '<br/>(' + hlist.join(', ') + ')'
                                addW({
                                    severity: Severity.WARNING, article: '262.3.1',
                                    text: $localize`Wybrano ${art} dla którego wysokość przeszkód musi stopniowo wzrastać lub być taka sama` +
                                        heights,
                                    objTxt: labels, objects: objects
                                })    
                            }
                        }
                    }
                }
                break
            case Article.ART_262_4_MASTERS:
                ignoreClassCombinationsCheck = true
                if (firstRound?.complete) {
                    let v = firstRound.obstacles
                    if (v !== 6) {
                        addW({
                            severity: Severity.WARNING, article: '262.4.1',
                            text: $localize`Wybrano ${art} dla którego musi być ustawionych sześć przeszkód, a jest ${v}`,
                        })
                    }
                    v = firstRound.combinations.filter(c => c.length === 2).length
                    if (v !== 1) {
                        addW({
                            severity: Severity.WARNING, article: '262.4.1',
                            text: $localize`Wybrano ${art} dla którego tor musi zawierać jeden szereg podwójny, a zawiera ${v}`,
                        })
                    }
                }
                break
            case Article.ART_263_HUNTING_HANDINESS:
                break
            case Article.ART_264_NATIONS_CUP:
                ignoreClassCombinationsCheck = true
                let found = false
                for (let c of p.classes) {
                    if ([ClassNo.NC1, ClassNo.NC2, ClassNo.NC3, ClassNo.NC4, ClassNo.NC5].includes(c)) {
                        found = true
                        break
                    }
                }
                if (!found) {
                    addW({
                        severity: Severity.WARNING, article: '264.3.1',
                        text: $localize`Wybrano ${art} dla którego należy wybrać jedną z pięciu klas konkursów zespołowych (1 do 5*KZ)`,
                    })
                }
                const limit1 = this.view.cfg.currentLimits.twoVerticalsHeightLimit
                const limit2 = this.view.cfg.currentLimits.sixOtherHeightLimit
                const limit3 = this.view.cfg.currentLimits.twoSpreadHeightLimit
                const limit4 = this.view.cfg.currentLimits.twoSpreadWidthLimit
                const obj1: ObstaclePathNode[] = [], obj2: ObstaclePathNode[] = [], obj3: ObstaclePathNode []= []
                const obj4: ObstaclePathNode[] = [], obj5: ObstaclePathNode[] = []
                if (firstRound?.complete && (limit1 || limit2 || limit3 || limit4)) {
                    firstRound.route.forEach(n => {
                        const o = n.obstacle
                        if (o instanceof ObstacleWithBars) {
                            if (limit1 && o.bars === 1 && o.obstacleHeight === limit1) {
                                obj1.push(n)
                            } else if (limit2 && o.obstacleHeight === limit2) {
                                obj2.push(n)
                            }
                            if (o.bars > 1) {
                                if (limit3 && o.obstacleHeight >= limit3) {
                                    obj3.push(n)
                                }
                                if (limit4 && o.obstacleWidth >= limit4) {
                                    obj4.push(n)
                                }
                            }
                            if (o instanceof ObstacleWithBars && o.isWaterDitch()) {
                                obj5.push(n)
                            }
                        }
                    })
                    if (limit1 && obj1.length < 2) {
                        const v = limit1, w = obj1.length
                        const [labels, objects] = this._addWarningObjects(obj1)
                        addW({
                            severity: Severity.WARNING, article: '264.3.1',
                            text: $localize`Wybrano ${art} dla którego trasa musi zawierać przynajmniej dwie stacjonaty o wysokości ${v} cm, a zawiera ${w}`,
                            objTxt: labels, objects: objects
                        })    
                    }
                    if (limit2 && obj2.length < 6) {
                        const v = limit2, w = obj2.length
                        const [labels, objects] = this._addWarningObjects(obj2)
                        addW({
                            severity: Severity.WARNING, article: '264.3.1',
                            text: $localize`Wybrano ${art} dla którego trasa musi zawierać przynajmniej sześć przeszkód o wysokości ${v} cm, innych niż stacjonata, a zawiera ${w}`,
                            objTxt: labels, objects: objects
                        })    
                    }
                    if (limit3 && obj3.length < 2) {
                        const v = limit3, w = obj3.length
                        const [labels, objects] = this._addWarningObjects(obj3)
                        addW({
                            severity: Severity.WARNING, article: '264.3.1',
                            text: $localize`Wybrano ${art} dla którego trasa musi zawierać przynajmniej dwie szerokie przeszkody o wysokości minimum ${v} cm, a zawiera ${w}`,
                            objTxt: labels, objects: objects
                        })    
                    }
                    if (limit4 && obj4.length < 2) {
                        const v = limit4, w = obj4.length
                        const [labels, objects] = this._addWarningObjects(obj4)
                        addW({
                            severity: Severity.WARNING, article: '264.3.1',
                            text: $localize`Wybrano ${art} dla którego trasa musi zawierać przynajmniej dwie szerokie przeszkody o szerokości minimum ${v} cm, a zawiera ${w}`,
                            objTxt: labels, objects: objects
                        })    
                    }
                    if (obj5.length === 0 && p.compLoc === CompetitionLocation.OUTDOOR) {
                        addW({
                            severity: Severity.WARNING, article: '264.3.2',
                            text: $localize`Wybrano ${art} dla którego trasa w terenie otwartym musi zawierać rów z wodą lub Liverpool`,
                        })    
                    }
                }
                let doubles = 0, trebles = 0
                firstRound?.combinations.concat(secondRound ? secondRound.combinations : []).forEach(c => {
                    if (c.length > 3) {
                        const [labels, objects] = this._addWarningObjects(c)
                        addW({
                            severity: Severity.WARNING, article: '264.3.3',
                            text: $localize`Wybrano ${art} dla którego szereg nie może wymagać więcej niż trzech skoków`,
                            objTxt: labels, objects: objects
                        })    
                    } else if (c.length === 2) {
                        doubles++
                    } else if (c.length === 3) {
                        trebles++
                    }
                })
                if (!((doubles >= 1 || trebles >= 1) && (doubles <= 3 || doubles === 1 && trebles === 1))) {
                    addW({
                        severity: Severity.WARNING, article: '264.3.4',
                        text: $localize`Wybrano ${art} dla którego tor powinien zawierać przynajmniej jeden szereg podwójny lub jeden szereg potrójny, ale nie więcej niż trzy szeregi podwójne lub jeden podwójny i jeden potrójny`,
                    })    
                }
                break
            case Article.ART_265_SPONSOR_AND_OTHER_TEAM:
                // no specific rules
                break
            case Article.ART_266_5_1_FAULT_AND_OUT_OBSTACLES:
                break
            case Article.ART_266_5_2_FAULT_AND_OUT_TIME:
                break
            case Article.ART_267_HIT_AND_HURRY:
                break
            case Article.ART_269_ACCUMULATOR:
                ignoreObstacles = true
                const obs = firstRound?.obstacles
                if (obs !== undefined && obs !== 6 && obs !== 8 && obs !== 10) {
                    const v = obs
                    addW({
                        severity: Severity.WARNING, article: '269.1',
                        text: $localize`Wybrano ${art} dla którego trasa powinna zawierać 6, 8 lub 10 przeszkód, a zawiera ${v}`,
                    })
                }
                let oPrev: ObstacleWithBars | undefined = undefined
                firstRound?.route.forEach((n, i) => {
                    const o = n.obstacle
                    if (o instanceof ObstacleWithBars) {
                        if (oPrev && !(o.isWall() && !oPrev.isWall())) {
                            if (o.obstacleHeight < oPrev.obstacleHeight) {
                                const [labels, objects] = this._addWarningObjects(path.route, i, i)
                                addW({
                                    severity: Severity.WARNING, article: '269.1',
                                    text: $localize`Wybrano ${art} dla którego trasa powinna być o wzrastającym stopniu trudności, a wykryto spadek wysokości przeszkody`,
                                    objTxt: labels, objects: objects
                                })        
                            }
                        }
                        oPrev = o
                    }
                })
                if (path.secondRoundType === SecondRoundType.JUMP_OFF && secondRound && secondRound.obstacles < 6) {
                    addW({
                        severity: Severity.WARNING, article: '269.3',
                        text: $localize`Wybrano ${art} w którym tor do rozgrywki musi składać się z minimum sześciu przeszkód`
                    })    
                }
                break
            case Article.ART_270_TOP_SCORE:
                let all = 0
                const noScore: Obstacle[] = [], jokers: Obstacle[] = []
                this.view.canvas.obstacles.forEach(o => {
                    if (o instanceof Obstacle) {
                        if (!o.score) {
                            noScore.push(o)
                        } else if (o.score === 200) {
                            jokers.push(o)
                        }
                        all++
                    }
                })
                let v = noScore.length, w = all
                if (v > 0) {
                    addW({
                        severity: Severity.WARNING, article: '270.1',
                        text: $localize`Wybrano ${art} dla którego wszystkie przeszkody muszą mieć nadane punkty bonifikacyjne, a ${v} z ${w} przeszkód nie ma`,
                        objTxt: '', objects: noScore
                    })         
                }
                v = jokers.length
                if (v > 1) {
                    addW({
                        severity: Severity.WARNING, article: '270.12',
                        text: $localize`Wybrano ${art} dla którego można określić jednego Jokera (za 200 punktów), a jest ${v}`,
                        objTxt: '', objects: jokers
                    })         
                }
                break
            case Article.ART_271_TAKE_YOUR_OWN_LINE:
                break
            case Article.ART_273_TWO_ROUNDS:
                break
            case Article.ART_274_1_TWO_PHASES_NORMAL:
            case Article.ART_274_1_5_1_TWO_PHASES_NORMAL:
            case Article.ART_274_1_5_2_TWO_PHASES_NORMAL:
            case Article.ART_274_1_5_3_TWO_PHASES_NORMAL:
            case Article.ART_274_1_5_4_TWO_PHASES_NORMAL:
            case Article.ART_274_1_5_5_TWO_PHASES_NORMAL:
                ignoreObstacles = true
                if (path.secondRoundType === SecondRoundType.TWO_PHASE) {
                    if (firstRound?.complete) {
                        const v = firstRound.obstacles
                        if (v < 7 || v > 9) {
                            const [labels, objects] = this._addWarningObjects(firstRound.route)
                            addW({
                                severity: Severity.WARNING, article: '274.1.2',
                                text: $localize`Wybrano ${art} gdzie pierwsza faza powinna zawierać od 7 do 9 przeszkód, a zawiera ${v}`,
                                objTxt: labels, objects: objects
                            })
                        }
                    }
                    if (secondRound?.complete) {
                        let v = secondRound.obstacles
                        if (v < 4 || v > 6) {
                            const [labels, objects] = this._addWarningObjects(secondRound.route)
                            addW({
                                severity: Severity.WARNING, article: '274.1.2',
                                text: $localize`Wybrano ${art} gdzie druga faza powinna zawierać od 4 do 6 przeszkód, a zawiera ${v}`,
                                objTxt: labels, objects: objects
                            })
                        }
                        v = secondRound.combinations.length
                        if (v > 1) {
                            const c = secondRound.combinations.flat()
                            const [labels, objects] = this._addWarningObjects(c)
                            addW({
                                severity: Severity.WARNING, article: '274.1.2',
                                text: $localize`Wybrano ${art} gdzie druga faza powinna zawierać nie więcej niż jeden szereg, a zawiera ${v}`,
                                objTxt: labels, objects: objects
                            })
                        }
                    }
                }
                break
            case Article.ART_274_2_TWO_PHASES_SPECIAL:
            case Article.ART_274_2_5_TWO_PHASES_SPECIAL:  
                ignoreObstacles = true
                if (path.secondRoundType === SecondRoundType.TWO_PHASE) {
                    if (firstRound?.complete) {
                        const v = firstRound.obstacles
                        if (v < 5 || v > 7) {
                            const [labels, objects] = this._addWarningObjects(firstRound.route)
                            addW({
                                severity: Severity.WARNING, article: '274.2.2',
                                text: $localize`Wybrano ${art} gdzie pierwsza faza powinna zawierać od 5 do 7 przeszkód, a zawiera ${v}`,
                                objTxt: labels, objects: objects
                            })
                        }
                    }
                    if (parkourIsComplete) {
                        let v = allObstacles
                        if (v < 11 || v > 13) {
                            addW({
                                severity: Severity.WARNING, article: '274.2.2',
                                text: $localize`Wybrano ${art} gdzie obie fazy powinny zawierać od 11 dd 13 przeszkód, a zawierają ${v}`,
                            })
                        }
                    }
                    if (secondRound?.complete) {
                        const v = secondRound.combinations.length
                        if (v > 1) {
                            const c = secondRound.combinations.flat()
                            const [labels, objects] = this._addWarningObjects(c)
                            addW({
                                severity: Severity.WARNING, article: '274.2.2',
                                text: $localize`Wybrano ${art} gdzie druga faza powinna zawierać nie więcej niż jeden szereg, a zawiera ${v}`,
                                objTxt: labels, objects: objects
                            })
                        }
                    }
                }
                break
            case Article.ART_275_IN_GROUPS:
                break
            case Article.ART_276_WITH_WINNING_ROUND:
                break
            case Article.ART_277_DERBY:
                ignoreDistance = true
                if (parkourIsComplete && firstRound) {
                    const v = firstRound.correctedRoundedLengthTo5M
                    if (p.compLoc === CompetitionLocation.OUTDOOR) {
                        if (v < 1000 || v > 1300) {
                            addW({
                                severity: Severity.WARNING, article: '277.1',
                                text: $localize`Wybrano ${art} gdzie dystans w terenie otwartym powinien wynosić od 1000 do 1300 metrów, a wynosi ${v}`,
                            })
                        }
                    } else {
                        if (v < 600 || v > 800) {
                            addW({
                                severity: Severity.WARNING, article: '277.1',
                                text: $localize`Wybrano ${art} gdzie dystans halowy powinien wynosić od 600 do 800 metrów, a wynosi ${v}`,
                            })
                        }
                    }
                }
                break
            case Article.ART_278_OVER_COMBINATIONS:
                ignoreObstacles = true
                ignore60mRule = true
                ignoreClassCombinationsCheck = true
                if (path.secondRoundType !== SecondRoundType.TWO_PHASE) {
                    if (firstRound?.complete) {
                        const v = firstRound.obstacles
                        if (v !== 6) {
                            addW({
                                severity: Severity.WARNING, article: '278.1',
                                text: $localize`Wybrano ${art} w którym tor musi składać się z sześciu przeszkód, a składa się z ${v}`
                            })        
                        }
                        const inCombinations = firstRound.combinations.flat()
                        const faulty: ObstaclePathNode[] = []
                        for (let i = 2; i < firstRound.route.length; i++) {
                            const n = firstRound.route[i]
                            if (n.obstacle instanceof Obstacle && !inCombinations.includes(n)) {
                                faulty.push(n)
                            }
                        }
                        if (faulty.length > 0) {
                            const [labels, objects] = this._addWarningObjects(faulty)
                            addW({
                                severity: Severity.WARNING, article: '278.1',
                                text: $localize`Wybrano ${art} gdzie wszystkie przeszkody oprócz pierwszej muszą być szeregami`,
                                objTxt: labels, objects: objects
                            })
                        }
                        let trebles: boolean = false
                        for (let c of firstRound.combinations) {
                            if (c.length === 3) {
                                trebles = true
                                break
                            }
                        }
                        if (!trebles) {
                            addW({
                                severity: Severity.WARNING, article: '278.1',
                                text: $localize`Wybrano ${art} w którym przynajmniej jeden szereg powinien być trójczłonowy`,
                            })
                        }
                    }
                    if (path.secondRoundType === SecondRoundType.JUMP_OFF && secondRound?.complete) {
                        const singles = secondRound.obstacles - secondRound.combinations.length
                        let doubles = 0, trebles = 0, others = 0
                        for (let c of secondRound.combinations) {
                            if (c.length === 2) {
                                doubles++
                            } else if (c.length === 3) {
                                trebles++
                            } else {
                                others++
                            }
                        }
                        if (!(singles === 4 && doubles === 1 && trebles === 1 && others === 0 ||
                              singles === 3 && doubles === 3 && trebles === 0 && others === 0)) {
                            const [labels, objects] = this._addWarningObjects(secondRound.route, 1, secondRound.route.length - 2)
                            addW({
                                severity: Severity.WARNING, article: '278.3',
                                text: $localize`Wybrano ${art} którego rozgrywka musi zawierać: szereg podwójny, szereg potrójny i 4 przeszkody pojedyncze lub 3 szeregi podwójne i 3 przeszkody pojedyncze`,
                                objTxt: labels, objects: objects
                            })
                        }
                    }
                    if (firstRound?.complete) {
                        const v = firstRound.correctedRoundedLengthTo5M
                        if (v > 600) {
                            addW({
                                severity: Severity.WARNING, article: '278.4',
                                text: $localize`Wybrano ${art} gdzie długość trasy nie może przekraczać 600 metrów, a wynosi ${v}`,
                            })
                        }
                    }
                }
                break
        }                

        // Calculate various course characteristics to be used in validation
        // and check series of connected obstacles
        if (!noRoute) {
            rounds.forEach((r, rIdx) => {
                let obstacleCount: number[] = [0, 0, 0, 0]
                let lastBars = 0
                for (let i = 0; i < r.route.length; i++) {
                    const item = r.route[i]
                    const o = item.obstacle
                    if (o instanceof Obstacle && r.round12 === 2 && o.useCount[0] > 0) {
                        if (o.getDirection(2, 1) !== o.getDirection(1, 1)) {
                            // opposite direction in second round
                            efforts2ndRoundOpposite++
                        } else {
                            efforts2ndRoundSame++
                        }
                    }

                    // More than 2 obstacles in a row of the same base kind are not allowed
                    const bars = (o instanceof ObstacleWithBars && o.bars >= 0 && o.bars <= 3) ? o.bars : 0
                    obstacleCount[bars]++
                    if (!ignoreSequenceCheck && lastBars !== bars) {
                        const count = obstacleCount[lastBars]
                        if (count >= 3) {
                            let txt
                            if (lastBars === 1) {
                                txt = $localize`Więcej niż dwie stacjonaty po kolei`
                            } else if (lastBars === 2) {
                                txt = $localize`Więcej niż dwa oksery po kolei`
                            } else if (lastBars === 3) {
                                txt = $localize`Więcej niż dwa triple barre po kolei`
                            }
                            if (txt) {
                                const [labels, objects] = this._addWarningObjects(r.route, i - count, i - 1)
                                addW({ severity: Severity.WARNING, text: txt, objTxt: labels, objects: objects })
                            }
                        }
                        obstacleCount[lastBars] = 0
                        lastBars = bars
                    }

                    // If route between two obstacles is shorter than 18m, it should be a straight path
                    // 3% deviation allowed between straight line and path length
                    if (!ignoreStraightLineCheck && i < r.route.length - 1 && item.forwardPath) {
                        const next = r.route[i + 1]
                        if (next.obstacle instanceof Obstacle && o instanceof Obstacle) {
                            const distance = o.getExitPoint(item.direction).getDistance(next.obstacle.getEntryPoint(next.direction))
                            if (item.forwardPath.length < 1800 && item.forwardPath.length / distance > 1.03) {
                                const [labels, objects] = this._addWarningObjects(r.route, i, i + 1)
                                addW({
                                    severity: Severity.WARNING,
                                    text: $localize`Jeżeli trasa łącząca przeszkody jest krótsza niż 18 metrów, to powinna być prosta`,
                                    objTxt: labels, objects: objects
                                })
                            }
                        }
                    }
                }

                // Art.204.5 - start should be at least 6m from the first obstacle
                if (r.route.length > 1 && r.route[1].obstacle instanceof Obstacle &&
                    (rIdx === 0 || rIdx === 1 && secondRoundIsOk && path.secondRoundType === SecondRoundType.JUMP_OFF)) {
                    const node = r.route[0]
                    const dist = Math.round(node.distToNext / 10)
                    if (dist < 60 && dist > 0) {
                        const [labels, objects] = this._addWarningObjects(r.route, 0, 1)
                        addWO({
                            severity: Severity.WARNING, article: '204.5',
                            text: $localize`Linia startu nie może znajdować się bliżej niż 6 metrów od pierwszej przeszkody`,
                            objTxt: labels, objects: objects
                        })
                    } else if (dist > 150) {
                        const [labels, objects] = this._addWarningObjects(r.route, 0, 1)
                        addWO({
                            severity: Severity.WARNING, article: '204.5',
                            text: $localize`Linia startu nie może znajdować się dalej niż 15 metrów od pierwszej przeszkody`,
                            objTxt: labels, objects: objects
                        })
                    }
                }

                // Art.204.5 - finish should be at least 6m from the first obstacle
                if (r.route.length > 1 &&
                    (r.route[r.route.length - 1].obstacle.isFinish() || r.route[r.route.length - 1].obstacle.isFinishStart()) && 
                    r.route[r.route.length - 2].obstacle instanceof Obstacle &&
                    ((rIdx === 0 && !noFinishLine) || (rIdx === 1 && secondRoundIsOk))) {
                    const dist = Math.round(r.route[r.route.length - 1].distFromPrev / 10)
                    if (dist < 60 && dist > 0) {
                        const [labels, objects] = this._addWarningObjects(r.route, r.route.length - 2, r.route.length - 1)
                        addWO({
                            severity: Severity.WARNING, article: '204.5',
                            text: $localize`Linia mety nie może znajdować się bliżej niż 6 metrów od ostatniej przeszkody`,
                            objTxt: labels, objects: objects
                        })
                    } else if (dist > 150) {
                        const [labels, objects] = this._addWarningObjects(r.route, r.route.length - 2, r.route.length - 1)
                        addWO({
                            severity: Severity.WARNING, article: '204.5',
                            text: $localize`Linia mety nie może znajdować się dalej niż 15 metrów od ostatniej przeszkody`,
                            objTxt: labels, objects: objects
                        })
                    }
                }
                if (!ignoreTripleBarreFirst && !noCombinations) {
                    r.combinations.forEach((c, idx) => {
                        if (idx < r.combinations.length - 1) {
                            const next  = r.combinations[idx + 1]
                            if (c[0].approachCurvature * next[0].approachCurvature > 0) {
                                addWO({
                                    severity: Severity.WARNING,
                                    text: $localize`Dwa kolejne szeregi powinny być najeżdżane z różnych stron`,
                                    objTxt: c[0].label + ', ' + next[0].label, objects: [c[0].obstacle, next[0].obstacle]
                                })
                            }
                        }
                        for (let i = 1; i < c.length; i++) {
                            const o = c[i].obstacle 
                            if (o instanceof ObstacleWithBars && o.bars === 3) {
                                // Art.212.5 - triple bar can only be first in combination
                                addWO({
                                    severity: Severity.WARNING, article: '212.5',
                                    text: $localize`Triple-barre powinien być jako pierwszy w szeregu`,
                                    objTxt: c[i].label, objects: [o]
                                })
                            }
                        }
                    })
                }
            })

            const v = this.view.cfg.currentLimits.table || CompetitionTable.NONE
            const w = table
            if (v !== CompetitionTable.NONE && w !== CompetitionTable.NONE && w !== v) {
                if (art) {
                    addW({
                        severity: Severity.WARNING, article: art,
                        text: $localize`Wybrano ${art} dla którego konkurs powinien być rozgrywany według tabeli ${v}, a wybrano tabelę ${w}`
                    })
                } else if (classNames) {
                    const x = classNames
                    addW({
                        severity: Severity.WARNING, article: classNames,
                        text: $localize`Wybrano klasę ${x} dla której konkurs powinien być rozgrywany według tabeli ${v}, a wybrano tabelę ${w}`
                    })
                }
            }
    
            if (noCombinations && bothPhasesCombinations.length > 0) {
                const [labels, objects] = this._addWarningObjects(bothPhasesCombinations.flat())
                addW({
                    severity: Severity.WARNING, article: art,
                    text: $localize`Wybrano ${art} dla którego szeregi są zabronione`,
                    objTxt: labels, objects: objects
                })    
            }

            if (!noCombinations && !ignoreClassCombinationsCheck && 
                limits.combinations && limits.combinations.min && limits.combinations.min >= 1 &&
                bothPhasesCombinations.length === 0) {
                const v = classNames
                addW({
                    severity: Severity.WARNING, article: classNames,
                    text: $localize`Wybrano klasę ${v} dla której tor powinien zawierać przynajmniej jeden szereg`,
                })    
            }            

            if (noFinishLine && firstRound && firstRound.finishes > 0) {
                const i = firstRound.route.length - 1
                const [labels, objects] = this._addWarningObjects(firstRound.route, i, i)
                addW({
                    severity: Severity.WARNING,
                    text: $localize`Wybrano ${art} dla którego nie powinno być wyznaczonej mety w przebiegu podstawowym`,
                    objTxt: labels, objects: objects
                })    
            }
            
            // A combination should not open the route
            const combs = firstRound?.combinations
            if (combs && !noCombinations && !ignoreFirstCombination && 
                combs.length > 0 && combs[0].length > 0 && path.route.indexOf(combs[0][0]) === 1) {
                const [labels, objects] = this._addWarningObjects(combs[0])
                addW({
                    severity: Severity.WARNING,
                    text: $localize`Szereg nie powinien być pierwszy na trasie`,
                    objTxt: labels, objects: objects
                })
            }

            if (firstRound && (!limits.allowedRounds || limits.allowedRounds === AllowedRounds.MANDATORY_JUMP_OFF || limits.allowedRounds === AllowedRounds.OPTIONAL_JUMP_OFF) && 
                path.secondRoundType === SecondRoundType.JUMP_OFF) {
                if (firstRound.starts > 0 && firstRound.finishes < 1) {
                    const [labels, objects] = this._addWarningObjects(firstRound.route)
                    if (firstRound.route.length > 1) {
                        addW({
                            severity: Severity.ERROR, text: $localize`Brak mety na trasie w przebiegu podstawowym`,
                            objTxt: labels, objects: objects
                        })    
                    } else {
                        if (unusedObstacles > 1) {
                            addW({
                                severity: Severity.ERROR, text: $localize`Brak przebiegu podstawowego - utwórz trasę łącząc przeszkody zaczynając od startu`,
                                objTxt: labels, objects: objects
                            })    

                        } else {
                            addW({
                                severity: Severity.ERROR, text: $localize`Zacznij od dodania przeszkód do placu przeciągając je z paska narzędzi na plac`,
                                objTxt: labels, objects: objects
                            })
                        }
                    }
                } 
                if (secondRound && secondRound.starts > 0 && secondRound.finishes < 1) {
                    const [labels, objects] = this._addWarningObjects(secondRound.route)
                    if (secondRound.route.length > 1) {
                        addW({
                            severity: Severity.ERROR, text: $localize`Brak mety na trasie w rozgrywce`,
                            objTxt: labels, objects: objects
                        })
                    } else {
                        addW({
                            severity: Severity.ERROR, text: $localize`Brak rozgrywki - utwórz trasę łącząc przeszkody zaczynając od finish/start`,
                            objTxt: labels, objects: objects
                        })
                    }
                }
                if (parkourIsComplete) {
                    // Art.246.2 - if first round has combination, jump-off must have combination too
                    if (!noCombinations && firstRound.combinations.length > 0 && (!secondRound || secondRound.combinations.length === 0)) {
                        addW({
                            severity: Severity.WARNING, article: '246.2',
                            text: $localize`Jeżeli przebieg podstawowy zawiera szereg, to rozgrywka też musi zawierać szereg`
                        })
                    }
                }
                if (firstRound?.complete) {
                    if (firstRound.efforts === 0) {
                        const [labels, objects] = this._addWarningObjects(firstRound.route)
                        addW({
                            severity: Severity.ERROR, 
                            text: $localize`Brak przeszkód w przebiegu podstawowym`,
                            objTxt: labels, objects: objects
                        })    
                    }
                }
                if (secondRound?.complete) {
                    if (secondRound.efforts === 0) {
                        const [labels, objects] = this._addWarningObjects(secondRound.route)
                        addW({
                            severity: Severity.ERROR,
                            text: $localize`Brak przeszkód w rozgrywce`, objTxt: labels, objects: objects
                        })
                    } else {
                        // Art.246.7 - jump-off can contain up to two new obstacles. Jumping first round obstacles in
                        // the opposite direction decreases the limit, including two-obstacle combination.
                        if (secondRound.efforts - efforts2ndRoundSame > 2) {
                            const [labels, objects] = this._addWarningObjects(secondRound.route, 1, secondRound.route.length - 2)
                            addW({
                                severity: Severity.WARNING, article: '246.7',
                                text: $localize`Do rozgrywki można włączyć maksymalnie dwie nowe przeszkody pojedyncze, wliczając w to przeszkody z przebiegu podstawowego skakane z przeciwnej strony`,
                                objTxt: labels, objects: objects
                            })
                        }
                    }
                }

            } else if (twoPhaseAllowed && path.secondRoundType === SecondRoundType.TWO_PHASE) {
                if (secondRound && secondRound.finishes < 1) {
                    const [labels, objects] = this._addWarningObjects(secondRound.route)
                    if (secondRound.route.length > 1) {
                        addW({
                            severity: Severity.ERROR, text: $localize`Brak mety na trasie w drugiej rundzie`,
                            objTxt: labels, objects: objects
                        })
                    } else {
                        addW({
                            severity: Severity.ERROR, text: $localize`Brak drugiej rundy - utwórz trasę łącząc przeszkody zaczynając od drugiego startu`,
                            objTxt: labels, objects: objects
                        })
                    }
                }
                if (firstRound?.complete) {
                    if (firstRound.efforts === 0) {
                        const [labels, objects] = this._addWarningObjects(firstRound.route)
                        addW({
                            severity: Severity.ERROR, 
                            text: $localize`Brak przeszkód w pierwszej rundzie`,
                            objTxt: labels, objects: objects
                        })
                    }
                }
                if (secondRound?.complete) {
                    if (secondRound.efforts === 0) {
                        const [labels, objects] = this._addWarningObjects(secondRound.route)
                        addW({
                            severity: Severity.ERROR,
                            text: $localize`Brak przeszkód w drugiej rundzie`,
                            objTxt: labels, objects: objects
                        })
                    }
                }

            } else if (firstRound && path.secondRoundType === SecondRoundType.NONE) { // one round only
                if (!noFinishLine && firstRound.finishes < 1) {
                    const [labels, objects] = this._addWarningObjects(firstRound.route)
                    if (firstRound.route.length > 1) {
                        addW({
                            severity: Severity.ERROR, text: $localize`Brak mety na trasie w przebiegu podstawowym`,
                            objTxt: labels, objects: objects
                        })
                    } else {
                        if (unusedObstacles > 1) {
                            addW({
                                severity: Severity.ERROR, text: $localize`Brak przebiegu podstawowego - utwórz trasę łącząc przeszkody zaczynając od startu`,
                                objTxt: labels, objects: objects
                            })
                        } else {
                            addW({
                                severity: Severity.ERROR, text: $localize`Zacznij od dodania przeszkód do placu przeciągając je z paska narzędzi na plac`,
                                objTxt: labels, objects: objects
                            })
                        }
                    }
                }
                if (parkourIsComplete) {
                    if (firstRound.efforts === 0) {
                        const [labels, objects] = this._addWarningObjects(firstRound.route)
                        addW({
                            severity: Severity.ERROR,
                            text: $localize`Brak przeszkód w przebiegu podstawowym`,
                            objTxt: labels, objects: objects
                        })
                    }   
                }
            }

            if (parkourIsComplete) {
                const finishes = this.view.canvas.objects.filter(o => o instanceof PathObject && 
                    o.isFinish() && o.useCountAll === 0)
                if (finishes.length > 0) {
                    const [labels, objects] = this._addWarningObjects(finishes)
                    addW({
                        severity: Severity.ERROR, text: $localize`Nadmiarowa meta na placu`,
                        objTxt: labels, objects: objects
                    })    
                }
            }

            path.sections.forEach(s => {
                if (!s.round12) {
                    const [labels, objects] = this._addWarningObjects(s.route)
                    addW({
                        severity: Severity.ERROR, text: $localize`Część ścieżki niepodłączona do żadnej rundy`,
                        objTxt: labels, objects: objects
                    })       
                }
            })

            if (parkourIsComplete && !ignore60mRule) {
                // Art.204.5 - number of efforts * 60 should be lower than distance
                const n = allObstacles
                const v = n * 60
                const w = rounds.reduce((v, r) => v + r.correctedRoundedLengthTo5M, 0)
                if (v > 0 && w > v) {
                    addW({
                        severity: Severity.WARNING, article: '204.5',
                        text: $localize`Długość trasy (${w} m) nie może przekraczać liczby przeszkód (${n}) pomnożonej przez 60 metrów (${v} m)`
                    })
                }
            }
        } else {
            // course without a route
            let fn: ParkourObject[] = [], sn: ParkourObject[] = [], fsn: ParkourObject[] = []
            this.view.canvas.objects.forEach(o => {
                if (o.isStart()) {
                    sn.push(o)
                } else if (o.isFinish()) {
                    fn.push(o)
                } else if (o.isFinishStart()) {
                    fsn.push(o)
                }
            })
            if (sn.length !== 1) {
                const [labels, objects] = this._addWarningObjects(sn)
                addW({
                    severity: Severity.WARNING, article: art,
                    text: $localize`Wybrano ${art} w którym na polu powinien być jeden start`,
                    objTxt: labels, objects: objects
                })
            }
            if (fsn.length !== 0) {
                const [labels, objects] = this._addWarningObjects(fsn)
                addW({
                    severity: Severity.WARNING, article: art,
                    text: $localize`Wybrano ${art} w którym na polu nie powinno być obiektu Meta/Start (F/S)`,
                    objTxt: labels, objects: objects
                })
            }
            if (fn.length !== 1) {
                const [labels, objects] = this._addWarningObjects(fn)
                addW({
                    severity: Severity.WARNING, article: art,
                    text: $localize`Wybrano ${art} w którym na polu powinna być jedna meta`,
                    objTxt: labels, objects: objects
                })
            }
            
        }

        let found = false
        for (let c of p.classes) {
            if ([ClassNo.NC1, ClassNo.NC2, ClassNo.NC3, ClassNo.NC4, ClassNo.NC5].includes(c)) {
                found = true
                break
            }
        }
            
        if (found && art !== Article.ART_264_NATIONS_CUP) {
            const v = classNames
            if (art !== Article.NONE) {
                addW({
                    severity: Severity.WARNING, article: '264.3.1',
                    text: $localize`Wybrano klasę ${v} która dotyczy artykułu 264, a wybrano artykuł ${art}`,
                })
            } else {
                addW({
                    severity: Severity.WARNING, article: '264.3.1',
                    text: $localize`Wybrano klasę ${v} która dotyczy artykułu 264, a nie wybrano żadnego artykułu`,
                })
            }
        }

        // validation of dimensions against class requirements and
        // limits recommeded in the configuration parameters
        const l = this.view.cfg.regulationsLimits
        const t = l && (p.classNo !== ClassNo.NONE || art !== Article.NONE)
        const ld = this.view.cfg.currentLimits
        let artNo = ''
        if (classNames) {
            artNo += classNames
        }
        if (artNo && art) {
            artNo += ' / '
        }
        if (art) {
            artNo += art
        }
        const pushIfW = ((w?: Warning): boolean => {
            if (w) {
                addWO({ ...w, article: artNo })
            }
            return w !== undefined
        })

        let v: number = p.obstacleHeight
        if (!t || !pushIfW(this._addDefaultParamsWarning(l.height, v,
            $localize`Domyślna wysokość przeszkód (${v} cm) spoza zakresu dozwolonego dla klasy/artykułu`, true))) {
            pushIfW(this._addDefaultParamsWarning(ld.height, v,
                $localize`Domyślna wysokość przeszkód (${v} cm) spoza zalecanego zakresu`, true))
        }
        v = p.oxerWidth
        if (!t || !pushIfW(this._addDefaultParamsWarning(l.width?.oxer, v,
            $localize`Domyślna szerokość okserów (${v} cm) spoza zakresu dozwolonego dla klasy/artykułu`, false, 2))) {
            pushIfW(this._addDefaultParamsWarning(ld.width?.oxer, v,
                $localize`Domyślna szerokość okserów (${v} cm) spoza zalecanego zakresu`, false, 2))
        }
        v = p.liverPoolWidth
        if (!t || !pushIfW(this._addDefaultParamsWarning(l.width?.liverpool, v,
            $localize`Domyślna szerokość Liverpool (${v} cm) spoza zakresu dozwolonego dla klasy/artykułu`, false, 1))) {
            pushIfW(this._addDefaultParamsWarning(ld.width?.liverpool, v,
                $localize`Domyślna szerokość Liverpool (${v} cm) spoza zalecanego zakresu`, false, 1))
        }
        v = p.tripleBarWidth
        if (!t || !pushIfW(this._addDefaultParamsWarning(l.width?.tripleBarre, v,
            $localize`Domyślna szerokość triple barre (${v} cm) spoza zakresu dozwolonego dla klasy/artykułu`, false, 3))) {
            pushIfW(this._addDefaultParamsWarning(ld.width?.tripleBarre, v,
                $localize`Domyślna szerokość triple barre (${v} cm) spoza zalecanego zakresu`, false, 3))
        }
        if (!t || !pushIfW(this._addNonDefaultParamsWarning(l.height,
            $localize`Indywidualna wysokość przeszkody spoza zakresu dozwolonego dla klasy/artykułu`, true))) {
            pushIfW(this._addNonDefaultParamsWarning(ld.height,
                $localize`Indywidualna wysokość przeszkody spoza zalecanego zakresu`, true))
        }
        if (!t || !pushIfW(this._addNonDefaultParamsWarning(l.width?.oxer,
            $localize`Indywidualna szerokość oksera spoza zakresu dozwolonego dla klasy/artykułu`, false, 2))) {
            pushIfW(this._addNonDefaultParamsWarning(ld.width?.oxer,
                $localize`Indywidualna szerokość oksera spoza zalecanego zakresu`, false, 2))
        }
        if (!t || !pushIfW(this._addNonDefaultParamsWarning(l.width?.liverpool,
            $localize`Indywidualna szerokość Liverpool spoza zakresu dozwolonego dla klasy/artykułu`, false, 1))) {
            pushIfW(this._addNonDefaultParamsWarning(ld.width?.liverpool,
                $localize`Indywidualna szerokość Liverpool spoza zalecanego zakresu`, false, 1))
        }
        if (!t || !pushIfW(this._addNonDefaultParamsWarning(l.width?.tripleBarre,
            $localize`Indywidualna szerokość triple barre spoza zakresu dozwolonego dla klasy/artykułu`, false, 3))) {
            pushIfW(this._addNonDefaultParamsWarning(ld.width?.tripleBarre,
                $localize`Indywidualna szerokość triple barre spoza zalecanego zakresu`, false, 3))
        }
        // obstacle/efforts warnings will be shown only for parkour that has correctly connected starts/finishes
        if (parkourIsComplete && firstRound) {
            if (!ignoreObstacles) {
                v = firstRound.obstacles
                if (t && l.obstacles && (l.obstacles.min !== undefined && v < l.obstacles.min || 
                    l.obstacles.max !== undefined && v > l.obstacles.max)) {
                    addW({
                        severity: Severity.WARNING, article: artNo,
                        text: $localize`Liczba przeszkód (${v}) spoza zakresu dozwolonego dla klasy/artykułu` + ' ' +
                            formatLimits(l.obstacles)
                    })
                } else if (ld.obstacles && (ld.obstacles.min !== undefined && v < ld.obstacles.min ||
                    ld.obstacles.max !== undefined && v > ld.obstacles.max)) {
                    addW({
                        severity: Severity.WARNING,
                        text: $localize`Liczba przeszkód (${v}) spoza zalecanego zakresu` + ' ' +
                            formatLimits(ld.obstacles)
                    })
                }
            }
            if (!noRoute) {
                v = firstRound.efforts
                if (t && l.efforts && (l.efforts.min !== undefined && v < l.efforts.min ||
                    l.efforts.max !== undefined && v > l.efforts.max)) {
                    addW({
                        severity: Severity.WARNING, article: artNo,
                        text: $localize`Liczba skoków (${v}) spoza zakresu dozwolonego dla klasy/artykułu` + ' ' +
                            formatLimits(l.efforts)
                    })
                } else if (ld.efforts && (ld.efforts.min !== undefined && v < ld.efforts.min ||
                    ld.efforts.max !== undefined && v > ld.efforts.max)) {
                    addW({
                        severity: Severity.WARNING,
                        text: $localize`Liczba skoków (${v}) spoza zalecanego zakresu` + ' ' +
                            formatLimits(ld.efforts)
                    })
                }
                v = firstRound.correctedRoundedLengthTo5M
                if (!ignoreDistance && t && l.distance && (l.distance.min !== undefined && p.compLoc !== CompetitionLocation.INDOOR && v < l.distance.min ||
                    l.distance.max !== undefined && v > l.distance.max)) {
                    addW({
                        severity: Severity.WARNING, article: artNo,
                        text: $localize`Dystans (${v} m) spoza zakresu dozwolonego dla klasy/artykułu` + ' ' +
                            formatLimits(l.distance, 'm')
                    })    
                }
            }
        }

        // finish validation
        this.validationWarnings = warnings
        for (let i = 0; i < this.validationWarningsCount.length; i++) {
            this.validationWarningsCount[i] = warnings.filter(w => w.severity === i).length
        }
    }
    
    private _getWarningLabel(n: ObstaclePathNode | ParkourObject, i: number, end: number): string {
        let label
        const r = n instanceof ObstaclePathNode ? n.obstacle : n
        if (r instanceof Terminal) {
            label = r.label
        } else if (r.isFinishStart()) {
            label = 'FS'
        } else {
            label = n instanceof ObstaclePathNode ? n.label || '-' : r.getKindName()
        }
        if (i < end) {
            label += ', '
        }
        return label
    }

    private _addWarningObjects(route: (ObstaclePathNode | ParkourObject)[], start?: number, end?: number): [string, ParkourObject[]] {
        if (!end) {
            end = route.length - 1
        }
        if (!start) {
            start = 0
        }
        let labels = '', objects: ParkourObject[] = []
        for (let j = start; j <= end; j++) {
            const r = route[j]
            labels += this._getWarningLabel(r, j, end)
            if (r instanceof ObstaclePathNode) {
                objects.push(r.obstacle)
            } else if (r instanceof ParkourObject) {
                objects.push(r)
            }
        }
        return [labels, objects]
    }

    private _addWarningObjectsCond(cond: (o: PathObject) => unknown): [string, ParkourObject[]] {
        let labels = '', objects: ParkourObject[] = []
        this.view.canvas?.obstaclePath.route.forEach((r) => {
            if (cond(r.obstacle)) {
                if (labels !== '') {
                    labels += ', '
                }
                if (r.obstacle instanceof Terminal) {
                    labels += r.obstacle.label
                } else {
                    labels += r.label || r.obstacle.getKindName()
                }
                objects.push(r.obstacle)
            }
        })
        return [labels, objects]
    }

    private _addDefaultParamsWarning(l: LimitRange | undefined, v: number, text: string, height: boolean, bars?: number): Warning | undefined {
        if (!l) {
            return undefined
        }
        if (l.min !== undefined && v < l.min || l.max !== undefined && v > l.max) {
            const [labels, objects] = this._addWarningObjectsCond(o => {
                // for bars === 1 this is a liverpool, not all verticals
                if (o instanceof ObstacleWithBars && o.bars === 1 && !o.decorations.water) {
                    return false
                }
                if (o instanceof ObstacleWithBars) {
                    const ov = height ? o.obstacleHeight : o.obstacleWidth
                    const od = height ? o.defaultObstacleHeight : o.defaultObstacleWidth
                    const ret = (!bars || o.bars === bars) && ov === od &&
                        (l.min !== undefined && ov < l.min || l.max !== undefined && ov > l.max)
                    if (ret) {
                        if (height) {
                            o.warnings.height = true
                        } else {
                            o.warnings.width = true
                        }
                    }
                    return ret
                }
                return false
            })
            if (objects.length > 0) {
                return {
                    id: 0, severity: Severity.WARNING,
                    text: text + ' ' + formatLimits(l, 'cm') + '. ' + $localize`Popraw wartość w parametrach konkursu.`,
                    objTxt: labels, objects: objects
                }
            }
        }
        return undefined
    }

    private _addNonDefaultParamsWarning(l: LimitRange | undefined, text: string, height: boolean, bars?: number): Warning | undefined {
        if (!l) {
            return undefined
        }
        const [labels, objects] = this._addWarningObjectsCond(o => {
            // for bars === 1 this is a liverpool, not all verticals
            if (o instanceof ObstacleWithBars && o.bars === 1 && !o.decorations.water) {
                return false
            }
            if (o instanceof ObstacleWithBars) {
                const ov = height ? o.obstacleHeight : o.obstacleWidth
                const od = height ? o.defaultObstacleHeight : o.defaultObstacleWidth
                const ret = (!bars || o.bars === bars) && ov !== od &&
                    (l.min !== undefined && ov < l.min || l.max !== undefined && ov > l.max)
                if (ret) {
                    if (height) {
                        o.warnings.height = true
                    } else {
                        o.warnings.width = true
                    }
                    return ret
                }
            }
            return false
        })
        if (objects.length > 0) {
            return {
                id: 0, severity: Severity.WARNING,
                text: text + ' ' + formatLimits(l, 'cm') + '. ' + $localize`Popraw wartość w paramterach przeszkody.`,
                objTxt: labels, objects: objects
            }
        }
        return undefined
    }
}