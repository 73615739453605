import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';
import { TooltipModule } from 'primeng/tooltip';
import { DetailComponent } from '../../detail.component';
import { DialogModule } from 'primeng/dialog'
import { ParkourDialog } from '../detail.dialog.interface'
import { LengthPipe } from '../../../pipes'
import { feetToMetersRounded, metersToFeetRounded } from '../../../utils'

@Component({
    selector: 'app-parkour-field-size',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonModule,
        InputNumberModule,
        TooltipModule,
        DialogModule,
    ],
    templateUrl: './parkour-field-size.component.html',
    styleUrl: './parkour-field-size.component.scss'
})
export class ParkourFieldSizeComponent implements ParkourDialog {
    @Input({ required: true }) view!: DetailComponent
    @Output() onOk = new EventEmitter<void>()
    @Output() onCancel = new EventEmitter<void>()

    visibility: boolean = false
    units: string = ''

    onCloseDialog() {
    }

    onShowDialog() {
        this.units = LengthPipe.getUnit(this.view.cfg.params.distanceUnit)
        this.view.cfg.setValuesToForm()

        const controls = this.view.cfg.form.controls
        if (this.view.cfg.params.distanceUnit.startsWith('ft-')) {
            controls.parkourHeight.patchValue(metersToFeetRounded(controls.parkourHeight.value))
            controls.parkourWidth.patchValue(metersToFeetRounded(controls.parkourWidth.value))
        }
    }

    onHideDialog() {
    }

    onOkDialog() {
        const controls = this.view.cfg.form.controls
        if (this.view.cfg.params.distanceUnit.startsWith('ft-')) {
            controls.parkourHeight.patchValue(feetToMetersRounded(controls.parkourHeight.value))
            controls.parkourWidth.patchValue(feetToMetersRounded(controls.parkourWidth.value))
        }

        this.visibility = false
        this.onOk.emit()
    }

    onCancelDialog() {
        this.visibility = false
        this.onCancel.emit()
    }

    open() {
        this.visibility = true
    }

    closeOk() {
        this.onOkDialog()
    }

    closeCancel() {
        this.onCancelDialog()
    }

    getFieldHeightLimits(): number[] {
        if (this.view.cfg.form.controls.distanceUnit.value.startsWith('ft-')) {
            return [30, 1000, 1]
        }
        return [10, 300, 5]
    }

    getFieldWidthLimits(): number[] {
        if (this.view.cfg.form.controls.distanceUnit.value.startsWith('ft-')) {
            return [30, 1000, 1]
        }
        return [10, 300, 5]
    }
}