<p-dropdown 
    [options]="patterns" optionLabel="name" optionValue="value" 
    [(ngModel)]="value" (onChange)="onValueChange($event)" (ngModelChange)="onNgModelChange()"
    [disabled]="disabled"
    [styleClass]="styleClass" [panelStyleClass]="panelStyleClass" [scrollHeight]="scrollHeight"
    appendTo="body">
    <ng-template let-o pTemplate="selectedItem">
        <div class="flex align-items-center" *ngIf="o">
            <div style="width: 50%; margin: 5px 0;"
                [ngStyle]="{ 'background': getBackgroundAttribute(o), 'height': (width * 2) + 'px' }">
            </div>
            <div style="width: 50%;" class="pl-3">
                {{ o.name }}
            </div>
        </div>
    </ng-template>
    <ng-template let-o pTemplate="item">
        <div class="flex align-items-center" *ngIf="o">
            <div style="width: 70%; margin: 5px 0;"
                [ngStyle]="{ 'background': getBackgroundAttribute(o), 'height': (width * 2) + 'px' }">
            </div>
            <div style="width: 30%;" class="pl-3">
                {{ o.name }}
            </div>
        </div>
    </ng-template>
</p-dropdown>
