import paper from 'paper'
import { Direction } from '../../design.schema'
import { ParkourConfig } from '../../parkour.config'

export abstract class Arrows {
    arrowForward?: paper.Group
    arrowBackward?: paper.Group
    arrowBi?: paper.Group

    get position(): paper.Point {
        return this.arrowBi?.position || this.arrowForward?.position || this.arrowBackward?.position || new paper.Point(0, 0)
    }

    set position(pos: paper.Point) {
        if (this.arrowForward) {
            this.arrowForward.position = pos
        }
        if (this.arrowBackward) {
            this.arrowBackward.position = pos
        }
        if (this.arrowBi) {
            this.arrowBi.position = pos
        }
    }

    get size(): paper.Size {
        return this.arrowBi?.internalBounds.size || this.arrowForward?.internalBounds.size ||
            this.arrowBackward?.internalBounds.size || new paper.Size(0, 0)
    }

    rotate(angle: number, center?: paper.Point) {
        const pos = center || this.position
        this.arrowForward?.rotate(angle, pos)
        this.arrowBackward?.rotate(angle, pos)
        this.arrowBi?.rotate(angle, pos)
    }

    constructor(protected directions: Direction, protected cfg: ParkourConfig, parent: paper.Group,
        protected initialPos: paper.Point, protected width: number, protected height: number,
        protected color: paper.Color) {
        if (directions === Direction.forward || directions === Direction.both) {
            this.arrowForward = new paper.Group()
            parent.addChild(this.arrowForward)
        }
        if (directions === Direction.backward || directions === Direction.both) {
            this.arrowBackward = new paper.Group()
            parent.addChild(this.arrowBackward)
        }
        if (directions === Direction.both) {
            this.arrowBi = new paper.Group()
            parent.addChild(this.arrowBi)
        }
    }

    abstract draw(): void

    setVisibleDirection(direction: Direction) {
        if (this.arrowForward) {
            this.arrowForward.visible = (direction === Direction.forward)
        }
        if (this.arrowBackward) {
            this.arrowBackward.visible = (direction === Direction.backward)
        }
        if (this.arrowBi) {
            this.arrowBi.visible = (direction === Direction.both)
        }
    }

    destroy() {
        if (this.arrowForward && this.arrowForward.isInserted()) {
            this.arrowForward.remove()
        }
        if (this.arrowBackward && this.arrowBackward.isInserted()) {
            this.arrowBackward.remove()
        }
        if (this.arrowBi && this.arrowBi.isInserted()) {
            this.arrowBi.remove()
        }
    }
}

export class StraightArrows extends Arrows {
    draw(): void {
        this._drawArrows()
    }

    private _getStraightArrowSegments(p: paper.Point, w: number, h: number, lenFactor: number) {
        const x = p.x, y = p.y
        // arrow tail
        const tw = w / 3
        // middle of arrow
        const mx = x + w / 2
        // lower tail
        const lx = x + w - (w - tw) / 2
        // upper tail
        const ux = x + (w - tw) / 2
        // tail end
        const ry = y - h + w / 2
        return [
            [mx, y - h],
            [x + w, ry + 1.5],
            [lx, ry],
            [lx, y - h * (1 - lenFactor)],
            [ux, y - h * (1 - lenFactor)],
            [ux, ry],
            [x, ry + 1.5],
            [mx, y - h]
        ]
    }

    private _drawArrows() {
        const p = this.initialPos
        const w = this.width
        const h = this.height
        const params = {
            strokeColor: this.color,
            fillColor: this.color,
            strokeWidth: 0.5 * this.cfg.params.lineWidth,
            strokeScaling: false,
        }
        let segments = this._getStraightArrowSegments(p, w, h, 0.95)
        let path = new paper.Path({
            ...params,
            segments: segments
        })

        if (this.arrowForward) {
            this.arrowForward.removeChildren()
            this.arrowForward.addChild(path)
            this.arrowForward.bringToFront()
            path = path.clone()
        }
        if (this.arrowBackward) {
            path.rotate(180, [p.x + w / 2, p.y - h / 2])
            this.arrowBackward.removeChildren()
            this.arrowBackward.addChild(path)
            this.arrowBackward.bringToFront()
        }
        if (this.arrowBi) {
            this.arrowBi.removeChildren()
            segments = this._getStraightArrowSegments(p, w, h, 0.6)
            path = new paper.Path({
                ...params,
                segments: segments
            })
            this.arrowBi.addChild(path)
            path = new paper.Path({
                ...params,
                segments: segments
            })
            path.rotate(180, [p.x + w / 2, p.y - h / 2])
            this.arrowBi.addChild(path)
            this.arrowBi.bringToFront()
        }
    }
}

export class ArcArrows extends Arrows {
    constructor(directions: Direction, cfg: ParkourConfig, parent: paper.Group, initialPos: paper.Point, width: number, height: number,
        private radius: number, private angle: number, color: paper.Color) {
        super(directions, cfg, parent, initialPos, width, height, color)
    }

    draw(): void {
        const pos = this.initialPos
        const radius = this.radius
        const angle = this.angle
        const width = this.width
        if (this.arrowForward) {
            this.arrowForward.removeChildren()
            this.arrowForward.addChild(this._drawArcArrow(pos, radius, width, -angle, angle, Direction.forward))
            this.arrowForward.bringToFront()
        }
        if (this.arrowBackward) {
            this.arrowBackward.removeChildren()
            this.arrowBackward.addChild(this._drawArcArrow(pos, radius, width, -angle, angle, Direction.backward))
            this.arrowBackward.bringToFront()
        }
        if (this.arrowBi) {
            this.arrowBi.removeChildren()
            this.arrowBi.addChild(this._drawArcArrow(pos, radius, width, -angle, angle, Direction.both))
            this.arrowBi.bringToFront()
        }
    }

    private _drawArc(center: paper.Point, radius: number, angleFrom: number, angleTo: number): paper.Path {
        const pm = center.add([radius, 0])
        const p1 = pm.rotate(angleFrom, center)
        const p2 = pm.rotate(angleTo, center)
        const arc = new paper.Path.Arc(p1, pm, p2)
        arc.rotate(180, center)
        return arc
    }

    private _drawArcArrowHead(center: paper.Point, radius: number, width: number, angle: number, direction: Direction): paper.Path {
        const pm = center.add([radius, 0])
        const p1 = pm.add([width / 2, 0]).rotate(angle, center)
        const p2 = pm.add([-width / 2, 0]).rotate(angle, center)
        const vec = p2.subtract(p1).rotate(90, [0, 0])
        let p3 = pm.rotate(angle, center)
        p3 = (direction === Direction.forward) ? p3.subtract(vec) : p3.add(vec)
        const head = new paper.Path([p1, p3, p2])
        head.rotate(180, center)
        return head
    }

    private _drawArcArrow(center: paper.Point, radius: number, width: number, angleFrom: number, angleTo: number, direction: Direction): paper.Path {
        if (direction === Direction.forward || direction === Direction.both) {
            angleTo *= 0.85
        }
        if (direction === Direction.backward || direction === Direction.both) {
            angleFrom *= 0.85
        }

        const arc1 = this._drawArc(center, radius + width / 2, angleFrom, angleTo)
        if (direction === Direction.forward || direction === Direction.both) {
            const head1 = this._drawArcArrowHead(center, radius, width * 3, angleTo, Direction.forward)
            arc1.join(head1)
        }

        const arc2 = this._drawArc(center, radius - width / 2, angleFrom, angleTo)
        arc2.reverse()
        arc1.join(arc2)

        if (direction === Direction.backward || direction === Direction.both) {
            const head2 = this._drawArcArrowHead(center, radius, width * 3, angleFrom, Direction.backward)
            arc1.join(head2)
        }
        arc1.strokeColor = this.color
        arc1.fillColor = this.color
        arc1.strokeWidth = this.cfg.params.lineWidth
        arc1.strokeScaling = false
        return arc1
    }
}

