
export class Undo {
    queue: any[] = []

    // index of the next elements to insert (not existing yet)
    pointer: number = 0

    constructor(public limit: number) {
        this.setLimit(limit)
    }

    private logDebug(f: string, obj?: any) {
    }

    private logDebug2(f: string, obj?: any) {
        for (let i = 0; i < this.queue.length; i++) {
            console.debug('Q', i, ':', JSON.parse(this.queue[i]).updatedAt)
        }
        console.debug('FN:    ', f)
        console.debug('QL:    ', this.queue.length)
        console.debug('PTR:   ', this.pointer)
        console.debug('LIMIT: ', this.limit)
        if (obj) {
            console.debug('OBJ  : ', obj ? obj.updatedAt : '-')
        }
        console.debug('\n')
    }

    undo(): any {
        let obj
        if (this.pointer <= this.queue.length && this.pointer > 1) {
            obj = JSON.parse(this.queue[--this.pointer - 1])
        }
        this.logDebug('undo', obj)
        return obj
    }

    redo(): any {
        let obj
        if (this.pointer < this.queue.length) {
            obj = JSON.parse(this.queue[this.pointer++])
        }
        this.logDebug('redo', obj)
        return obj
    }

    peek(): any {
        let obj
        if (this.pointer <= this.queue.length) {
            obj = JSON.parse(this.queue[this.pointer - 1])
        } else {
            console.error('undo: pointer > queue.length', this.pointer, this.queue.length)
        }
        this.logDebug('peek', obj)
        return obj
    }

    setLimit(limit: number): void {
        if (!limit || limit < 1) {
            return
        }
        this.limit = limit
        if (limit < this.queue.length) {
            this.pointer -= this.queue.splice(0, this.queue.length - limit).length
            if (this.pointer < 1) {
                this.pointer = 1
            }
        }
    }

    store(object: any): void {
        this.queue.splice(this.pointer)
        if (this.limit > 1) {
            this.queue.push(JSON.stringify(object))
            if (this.queue.length > this.limit) {
                this.queue.shift()
            }
        }
        if (this.pointer < this.limit) {
            this.pointer++
        } else {
            this.pointer = this.limit
        }
        this.logDebug('store')
    }
}
