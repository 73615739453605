<div class="flex flex-column bg-indigo-50 p-1 h-full max-h-full">

    <div style="width: 100%; height: calc(100% - 42px); max-height: calc(100% - 42px); overflow-y: auto;">
        <ng-container *ngFor="let p of images">
            <img class="image-item m-1 p-1 border-2 {{ p.selected ? 'shadow-6 border-indigo-500' : 'shadow-1 border-transparent' }}"
                 [src]="p.url"
                 (click)="selectImage(p)"
                 draggable="true"
                 (dragstart)="dragImageStart($event, p)">
        </ng-container>
    </div>

    <div class="py-1">
        <p-button icon="pi pi-upload" (click)="openFileSelectDialog()" label="Dodaj własny obrazek" i18n-label></p-button>
        <input #userImageFileInput
               style="display: none;"
               type="file" accept="image/*"
               (change)="uploadUserImage($event)"/>
    </div>

</div>
