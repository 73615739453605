<p-dialog id="validationBox"
          [(visible)]="visibility"
          [style]="{'min-width': '40vw'}" contentStyleClass="py-0"
          [contentStyle]="{'height': '18vh', 'max-height': '18vh'}" position="top"
          (keydown.enter)="onOkDialog()" (keydown.escape)="onCancelDialog()"
          (onShow)="onShowDialog()" (onClose)="onCloseDialog()" (onHide)="onHideDialog()">
    <ng-template pTemplate="header" >
        <span class="p-dialog-title"><i class="fa-solid fa-exclamation-triangle mr-2"></i>
            <span i18n>Znalezione problemy</span>
        </span>
    </ng-template>
    <p-table *ngIf="validator.validationWarnings.length > 0; else problemsSolved"
             [value]="validator.validationWarnings"
             scrollHeight="flex"
             styleClass="p-datatable-sm text-sm"
             [scrollable]="true"
             selectionMode="single" [(selection)]="selectedProblem" dataKey="id" (onRowSelect)="onSelectedProblem()">
        <ng-template pTemplate="header">
            <tr>
                <th></th>
                <th></th>
                <th i18n>Problem</th>
                <th i18n>Przepisy FEI</th>
                <th i18n>Obiekty</th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-p>
            <tr [pSelectableRow]="p">
                <td>{{ p.id }}</td>
                <td>
                    <i class="fa fa-exclamation-triangle" [class.text-yellow-500]="p.severity === 1" [class.text-red-500]="p.severity === 0"></i>
                </td>
                <td [innerHTML]="p.text"></td>
                <td style="white-space: nowrap">{{ p.article }}</td>
                <td>{{ p.objTxt }}</td>
                <td></td>
            </tr>
        </ng-template>
    </p-table>
    <ng-template #problemsSolved>
        <i class="fa fa-4x fa-check text-green-500"></i>
        <span class="text-3xl" i18n>Wszytkie problemy rozwiązane!</span>
    </ng-template>
    <ng-template pTemplate="footer">
        <p-button *ngIf="callbackMode && validator.validationWarnings.length !== 0" icon="pi pi-times" (click)="onOkDialog()" label="Kontynuuj mimo to" i18n-label severity="danger"></p-button>
        <p-button *ngIf="callbackMode && validator.validationWarnings.length !== 0" icon="pi pi-check" (click)="onCancelDialog()" label="Przerwij" i18n-label></p-button>
        <p-button *ngIf="callbackMode && validator.validationWarnings.length === 0" icon="pi pi-check" (click)="onOkDialog()" label="Kontynuuj" i18n-label></p-button>
        <p-button *ngIf="!callbackMode" icon="pi pi-check" (click)="onCancelDialog()" label="Zamknij" i18n-label></p-button>
    </ng-template>
</p-dialog>
